<section class="aside-page" [@asideSlideInOut]="asidePageState$ | async">
  <button type="button" class="aside-close-button" [title]="'actions.close' | translate" (click)="onCloseSideBar()">
    <lib-icon type="cross"></lib-icon>
  </button>
  <ng-container [ngSwitch]="outletName">
    <!-- Names for outlets have to match outlets values -->
    <router-outlet
      *ngSwitchCase="outlets.GlobalAsideOutlet"
      name="globalAside"
      (activate)="setAsideVisibility(true)"
      (deactivate)="setAsideVisibility(false)"
    ></router-outlet>
    <router-outlet
      *ngSwitchCase="outlets.SidebarAsideOutletPrimary"
      name="aside"
      (activate)="setAsideVisibility(true)"
      (deactivate)="setAsideVisibility(false)"
    ></router-outlet>
    <router-outlet
      *ngSwitchCase="outlets.SidebarAsideOutletSecondary"
      name="asideNested"
      (activate)="setAsideVisibility(true)"
      (deactivate)="setAsideVisibility(false)"
    ></router-outlet>
  </ng-container>
</section>
