import { TranslateService } from '@project/translate';

export enum EObservationMethodDTO {
  Binah = 'Binah',
}

export enum EObservationStatusDTO {
  Planning = 'Planning',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Canceled = 'Canceled',
}

export enum EObservationParameterValueCategoryDTO {
  Good = 'Good',
  Normal = 'Normal',
  Bad = 'Bad',
}

export function getObservationMethodLocalization(method: EObservationMethodDTO): string | undefined {
  const map: Record<EObservationMethodDTO, () => string> = {
    [EObservationMethodDTO.Binah]: () => TranslateService.localize('observation-method-enum.binah'),
  };

  return map[method]?.();
}

export function getObservationStatusLocalization(status: EObservationStatusDTO): string | undefined {
  const map: Record<EObservationStatusDTO, () => string> = {
    [EObservationStatusDTO.Planning]: () => TranslateService.localize('observation-status-enum.Planning'),
    [EObservationStatusDTO.InProgress]: () => TranslateService.localize('observation-status-enum.InProgress'),
    [EObservationStatusDTO.Completed]: () => TranslateService.localize('observation-status-enum.Completed'),
    [EObservationStatusDTO.Canceled]: () => TranslateService.localize('observation-status-enum.Canceled'),
  };

  return map[status]?.();
}

export function getObservationParameterValueCategoryLocalization(
  category: EObservationParameterValueCategoryDTO,
): string | undefined {
  const map: Record<EObservationParameterValueCategoryDTO, () => string> = {
    [EObservationParameterValueCategoryDTO.Bad]: () =>
      TranslateService.localize('observation-parameter-value-category.Bad'),
    [EObservationParameterValueCategoryDTO.Good]: () =>
      TranslateService.localize('observation-parameter-value-category.Good'),
    [EObservationParameterValueCategoryDTO.Normal]: () =>
      TranslateService.localize('observation-parameter-value-category.Normal'),
  };

  return map[category]?.();
}
