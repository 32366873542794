import { ActivatedRouteSnapshot } from '@angular/router';

export interface IUrlPagingParams {
  page: number;
}

export function extractPagingParamsFromRouteSnapshot(snapshot: ActivatedRouteSnapshot): IUrlPagingParams {
  return {
    page: +snapshot.queryParams?.page || 1,
  };
}
