<form [formGroup]="form" (ngSubmit)="doDirtySubmit()">
  <lib-modal-header (close)="onCancelClickHandler()">
    {{ "summary-notes.overlay.title" | translate }}
  </lib-modal-header>
  <lib-modal-body>
    <p class="mb-4 fz-m-1">
      {{ "summary-notes.overlay.message" | translate }}
    </p>
    <lib-form-control class="mb-0">
      <label>
        <span libLabel [hidden]="true">{{ "nouns.note" | translate }}</span>
        <lib-textarea
          formControlName="note"
          [placeholder]="'summary-notes.form.placeholder' | translate"
        ></lib-textarea>
      </label>
    </lib-form-control>
  </lib-modal-body>
  <lib-modal-footer class="buttons">
    <button type="button" libButton appearance="outline" (click)="onCancelClickHandler()">
      {{ "actions.cancel" | translate }}
    </button>
    <button
      type="button"
      libButton
      appearance="outline"
      [disabled]="submitting$ | async"
      [isPending]="submitting$ | async"
      (click)="onCloseClickHandler()"
    >
      {{ "actions.close" | translate }}
    </button>
    <button
      type="submit"
      libButton
      appearance="primary"
      [disabled]="!(formValid$ | async) || (submitting$ | async)"
      [isPending]="submitting$ | async"
    >
      {{ "actions.create-and-close" | translate }}
    </button>
  </lib-modal-footer>
</form>
