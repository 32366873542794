import { TEmail, TGuid } from '@core/helpers';
import { EProfileTypeDTO } from './consts/profile-type';
import { HttpErrorResponse } from '@angular/common/http';

export interface IAuthResultDTO {
  token: string;
  refresh_token?: string;
  password_change_required: boolean;
  profile: EProfileTypeDTO;
  health_center_logo_url?: string;
  properties?: { name: string; value: string }[];
  is_live_queue_enabled?: boolean;
  disable_left_nav: boolean;
  only_appointment: boolean;
  only_schedule: boolean;
  health_center_id?: TGuid;
}

export interface ISingleDeviceAuthResultDTO {
  has_any_other_device_signed_in: boolean;
  sign_in_response?: IAuthResultDTO;
}

export interface ISingleDeviceAuthResult {
  hasAnyOtherDeviceSignedIn: boolean;
  response?: IAuthResult;
}

export interface IAuthResult {
  accessToken: string;
  refresh_token?: string;
  passwordChangeRequired: boolean;
  userType: EProfileTypeDTO;
  healthCenterId: TGuid;
  health_center_logo_url?: string;
  properties?: { name: string; value: string }[];
  is_live_queue_enabled?: boolean;
  disable_left_nav: boolean;
  only_appointment: boolean;
  only_schedule: boolean;
}

export interface IAuthorizedUserData {
  accessToken: string;
  refresh_token?: string;
  passwordChangeRequired: boolean;
  userType: EProfileTypeDTO;
  healthCenterId: TGuid;
  health_center_logo_url?: string;
  is_live_queue_enabled?: boolean;
  disable_left_nav: boolean;
  only_appointment: boolean;
  only_schedule: boolean;
}

export enum EAuthError {
  ProfileAlreadyExist = 'Profile already exists',
  ProfileDoesNotExist = 'Profile does not exist',
}

export const CONFLICT_PROFILE_ERROR_STATUS_CODE = 409;
export const HAVE_TO_LINK_SOCIAL_ACCOUNT_ERROR_STATUS_CODE = 428;

export interface IAuthConflictErrorDTO {
  token: string;
  profiles: IAuthConflictProfileDTO[];
}

export interface IAuthConflictProfileDTO {
  role: EProfileTypeDTO;
  health_centers: TGuid[];
  health_center_names: string[];
  dependents: TGuid[];
  dependent_names: string[];
  is_dependent: boolean;
}

export interface IAuthConflictProfileHc {
  id: TGuid;
  name: string;
}

export interface IAuthConflictProfile {
  role: EProfileTypeDTO;
  healthCenters: IAuthConflictProfileHc[];
  dependents: IAuthConflictProfileHc[];
  isDependent: boolean;
}

export class AuthConflictError extends Error {
  public readonly token: string;
  public readonly profiles: IAuthConflictProfile[];

  constructor(err: HttpErrorResponse) {
    super();

    Object.setPrototypeOf(this, AuthConflictError.prototype);
    this.name = 'AuthConflictError';

    const conflictError = err.error;
    this.token = conflictError.token;
    this.profiles = conflictError.profiles.map((profile: IAuthConflictProfileDTO) => ({
      role: profile.role,
      healthCenters: profile.health_centers.map((x, idx) => ({
        id: x,
        name: profile.health_center_names[idx],
      })),
      dependents: profile.dependents.map((x, idx) => ({
        id: x,
        name: profile.dependent_names[idx],
      })),
      isDependent: profile.is_dependent,
    }));
  }
}

export interface IDevicePayload {
  id: TGuid;
  system: string;
  version: string;
  name: string;
  model: string;
}
interface IAuthRequestPayload<T> {
  credentials: T;
  device?: IDevicePayload;
}

export type TOauthAuthRequestPayload = IAuthRequestPayload<{
  access_token: string;
}>;

export type TEmailLoginRequestPayload = IAuthRequestPayload<{
  username: string;
  password: string;
}>;

export interface ISignUpByEmailRequestPayloadDTO {
  email: TEmail;
  password: string;
}

export interface IConfirmUserRequestPayloadDTO {
  user_id: string;
  token: string;
}

export interface IConfirmUserQueryParamsDTO {
  userId: string;
  code: string;
}

export interface ISignInRefreshTokenRequestDTO {
  token: string;
  refresh_token: string;
}

export interface ISignInRefreshTokenResponseDTO {
  token: string;
  refreshToken: string;
}

export class AuthViewModelFactory {
  static createFromDTO(dto: IAuthResultDTO): IAuthResult {
    const result = {
      accessToken: dto.token,
      refresh_token: dto.refresh_token,
      passwordChangeRequired: dto.password_change_required,
      userType: dto.profile,
      health_center_logo_url: dto.health_center_logo_url,
      properties: dto.properties,
      is_live_queue_enabled: dto.is_live_queue_enabled,
      disable_left_nav: dto.disable_left_nav,
      only_appointment: dto.only_appointment,
      only_schedule: dto.only_schedule,
      healthCenterId: dto.health_center_id,
    };

    return result;
  }
  static createFromSingleDeviceDTO(dto: ISingleDeviceAuthResultDTO): ISingleDeviceAuthResult {
    return {
      hasAnyOtherDeviceSignedIn: dto.has_any_other_device_signed_in,
      response: dto.sign_in_response
        ? {
            accessToken: dto.sign_in_response.token,
            refresh_token: dto.sign_in_response.refresh_token,
            passwordChangeRequired: dto.sign_in_response.password_change_required,
            userType: dto.sign_in_response.profile,
            health_center_logo_url: dto.sign_in_response.health_center_logo_url,
            is_live_queue_enabled: dto.sign_in_response.is_live_queue_enabled,
            properties: dto.sign_in_response.properties,
            disable_left_nav: dto.sign_in_response.disable_left_nav,
            only_appointment: dto.sign_in_response.only_appointment,
            only_schedule: dto.sign_in_response.only_schedule,
            healthCenterId: dto.sign_in_response.health_center_id,
          }
        : undefined,
    };
  }
}

export interface CheckFirstRequest {
  birth_year: number;
  id_card: string;
  device: {
    id: string;
  };
}
export interface CheckFirstResponse {
  eligible: boolean;
  token: string;
}

export interface ProcessFirstAccessRequest {
  birth_year: number;
  device_id: string;
  email: string;
  id_card: string;
  phone?: string;
  password: string;
  token: string;
}
