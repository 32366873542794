import { ESocketMessageTypeDTO } from '../consts/socket-message-type';
import { TGuid } from '@core/helpers';
import { ISocketMessage } from './common';

export interface IChatUpdateEventDTO extends ISocketMessage {
  type: ESocketMessageTypeDTO.ChatCreated | ESocketMessageTypeDTO.ChatArchived;
  chat_id: TGuid;
}

export interface IChatUpdateEvent {
  chatId: TGuid;
  type: ESocketMessageTypeDTO.ChatCreated | ESocketMessageTypeDTO.ChatArchived;
}

export class ChatUpdateEventSocketMessageViewModelFactory {
  static createChatEventFromDTO(dto: IChatUpdateEventDTO): IChatUpdateEvent {
    return {
      chatId: dto.chat_id,
      type: dto.type,
    };
  }
}
