import { Store } from '@project/shared';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { TGuid } from '@core/helpers';
import { ChatMessagesHelper, IChatMessage } from '@project/view-models';

interface IMessagesData {
  isLoading: boolean;
  chatMessagesFullyLoaded: boolean;
  messages: IChatMessage[];
}

type TMessagesStore<TChatID extends TGuid = TGuid> = Map<TChatID, IMessagesData>;

export class ChatsMessagesStore extends Store<TMessagesStore> {
  constructor() {
    super(() => new Map());
  }

  getChatMessages$(chatId): Observable<IChatMessage[]> {
    return this.data$.pipe(
      map((data) => data.get(chatId)?.messages || []),
      distinctUntilChanged(),
    );
  }

  getChatMessagesLoadingState$(chatId: TGuid): Observable<boolean> {
    return this.data$.pipe(
      map((data) => data.get(chatId)?.isLoading ?? true),
      distinctUntilChanged(),
    );
  }

  getChatMessagesFullyLoadedState$(chatId: TGuid): Observable<boolean> {
    return this.data$.pipe(
      map((data) => data.get(chatId)?.chatMessagesFullyLoaded ?? true),
      distinctUntilChanged(),
    );
  }

  getOldestMessageInChat(chatId: TGuid): IChatMessage | undefined {
    return this.data.get(chatId)?.messages[0];
  }

  setChatMessagesLoadingState(chatId: TGuid, isLoading: boolean) {
    this.patchChatMessagesState(chatId, { isLoading });
  }

  setChatMessagesFullyLoadedState(chatId: TGuid, isAllMessagesLoaded: boolean) {
    this.patchChatMessagesState(chatId, { chatMessagesFullyLoaded: isAllMessagesLoaded });
  }

  setChatMessages(chatId: TGuid, messages: IChatMessage[]) {
    this.patchChatMessagesState(chatId, { messages: ChatMessagesHelper.sortByDateIncrement(messages) });
  }

  addChatMessages(chatId: TGuid, newMessages: IChatMessage[]) {
    const messages: IChatMessage[] = this.getItemState(chatId)?.messages || [];
    const newMessagesInChat = messages.concat(newMessages.map((message) => ({ ...message })));

    this.patchChatMessagesState(chatId, { messages: ChatMessagesHelper.sortByDateIncrement(newMessagesInChat) });
  }

  areChatMessagesLoaded(chatId: TGuid): boolean {
    return !!this.data.get(chatId);
  }

  clearChatMessagesForChat(chatId: TGuid) {
    const data = new Map(this.data);
    data.delete(chatId);

    this.data$.next(data);
  }

  private patchChatMessagesState(chatId: TGuid, data: Partial<IMessagesData>) {
    this.data$.next(
      new Map(this.data$.value).set(chatId, {
        ...this.getItemState(chatId),
        ...(data || {}),
      }),
    );
  }

  private getItemInitialState(): IMessagesData {
    return {
      messages: [],
      isLoading: true,
      chatMessagesFullyLoaded: false,
    };
  }

  private getItemState(chatId): IMessagesData {
    return {
      ...this.getItemInitialState(),
      ...(this.data$.value.get(chatId) || {}),
    };
  }
}
