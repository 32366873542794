import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IFormErrors } from '@core/helpers';

@Component({
  selector: 'app-form-errors-viewer',
  templateUrl: './form-errors-viewer.component.html',
  styleUrls: ['./form-errors-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormErrorsViewerComponent {
  @Input() formErrors: IFormErrors;
  @Input() controlLabelsMap: { [controlName in string]: string };
}
