import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isToday from 'dayjs/plugin/isToday';
import { TDateISOString } from './types';
import * as moment from 'moment';

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(isToday);

export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY';

export class DateFormatter {
  private static MILLISECONDS_PER_MINUTE = 60000;

  /**
   * Will parse ISO or formatted string as Date object.
   * if `ignoreTimezones === true`, will parse given date in any timezone as local date.
   * For example: 2020-08-26T00:00:00.000Z will be parsed as 2020-08-26T00:00:00.000 GMT+7 for +7 timezone
   */
  public static stringToDate(
    dateString: TDateISOString | string,
    options?: { format?: string; ignoreTimezones?: boolean },
  ): Date | null {
    const dayJsDate = dayjs(dateString, options?.format);
    if (!dayJsDate.isValid()) {
      return null;
    }

    const targetDate = dayJsDate.toDate();

    if (options?.ignoreTimezones) {
      const timeDiff = targetDate.getTimezoneOffset() * this.MILLISECONDS_PER_MINUTE;
      return new Date(targetDate.getTime() + timeDiff);
    } else {
      return targetDate;
    }
  }

  /**
   * if `ignoreTimezones === true`, will parse given date in any timezone as ISO date.
   * For example: Date(2020-08-26T00:00:00.000 GMT +7) will be parsed to 2020-08-26T00:00:00.000Z ISO
   */
  public static dateToString(
    date: Date,
    options?: { format?: string; ignoreTimezones?: boolean },
  ): TDateISOString | string {
    if (options?.format) {
      return dayjs(date).format(options.format);
    }

    if (options?.ignoreTimezones) {
      const timeDiff = date.getTimezoneOffset() * this.MILLISECONDS_PER_MINUTE;
      return new Date(date.getTime() - timeDiff).toISOString();
    } else {
      return date.toISOString();
    }
  }

  public static dateIncludeHours(date: Date, hours: number): TDateISOString | string {
    return moment(date.toISOString()).add(hours, 'hours').toISOString();
  }

  public static isToday(date: Date): boolean {
    return dayjs(date).isToday();
  }

  public static isStringIsValidDate(dateAsString: string, format: string): boolean {
    // This is the only valid way to validate date https://github.com/iamkun/dayjs/issues/320
    return dayjs(dateAsString, format).format(format) === dateAsString;
  }

  public static getYearsSince(date: Date): number {
    return moment().diff(date, 'years');
  }

  /**
   * @example Aug 17, 2020
   */
  public static formatDateToPreviewShort(date: Date, locale: string): string {
    if (!Intl || !Intl.DateTimeFormat) {
      return date.toLocaleDateString();
    }

    return Intl.DateTimeFormat(locale, { month: 'short', day: 'numeric', year: 'numeric' }).format(date);
  }
}

interface IShiftParams {
  minutes?: number;
  hours?: number;
  days?: number;
  months?: number;
}

export class DateUtils {
  static shift(date: Date, shiftParams: IShiftParams): Date {
    const newDate = new Date(date.getTime());

    if (typeof shiftParams.months === 'number') {
      newDate.setMonth(date.getMonth() + shiftParams.months);
    }

    if (typeof shiftParams.days === 'number') {
      newDate.setDate(date.getDate() + shiftParams.days);
    }

    /*if (typeof shiftParams.hours === 'number') {
      newDate.setHours(date.getHours() + shiftParams.hours);
    }

    if (typeof shiftParams.minutes === 'number') {
      newDate.setMinutes(date.getMinutes() + shiftParams.minutes);
    }*/

    return newDate;
  }

  static isDateInsideInterval(date: Date, from: Date, to: Date): boolean {
    return date >= from && date < to;
  }
}
