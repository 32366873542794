import { ChangeDetectionStrategy, Component, EventEmitter, Input } from '@angular/core';
import { IModalComponent } from '@lib/modal';
import { LibValidators } from '@core/helpers';
import { SummaryNotesApiProviderService } from '@project/data-providers';
import { FormComponent } from '@project/shared';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@project/translate';
import { HttpErrorResponse } from '@angular/common/http';
import { IRequisition } from '@project/view-models';

export interface ICloseRequisitionWithSummaryNoteOverlayComponentInputParams {
  requisition: IRequisition;
}

@Component({
  selector: 'app-session-close-with-summary-note-overlay',
  templateUrl: './close-requisition-with-summary-note-overlay.component.html',
  styleUrls: ['./close-requisition-with-summary-note-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloseRequisitionWithSummaryNoteOverlayComponent extends FormComponent
  implements IModalComponent, ICloseRequisitionWithSummaryNoteOverlayComponentInputParams {
  @Input() requisition: IRequisition = null;

  public readonly form = this.fb.group({
    note: ['', LibValidators.required(TranslateService.localize('validations.required'))],
  });

  public readonly submit$ = new EventEmitter();
  public readonly close$ = new EventEmitter();

  constructor(private fb: FormBuilder, private summaryNotesApiProviderService: SummaryNotesApiProviderService) {
    super();
  }

  public onCancelClickHandler() {
    if (this._submitting$.value) {
      return;
    }

    this.close$.emit();
  }

  public onCloseClickHandler() {
    this.submit$.emit();
  }

  protected onFormInit() {}

  protected doCleanSubmit() {
    this.setStartSubmitState();

    const { note } = this.form.value;

    this.summaryNotesApiProviderService.createByRequisitionId(this.requisition.id, note).subscribe(
      () => {
        this.submit$.emit();
      },
      (error: HttpErrorResponse) => {
        this.setEndSubmitState(error);
      },
    );
  }
}
