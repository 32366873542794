import { IMedicalRecord } from './../../../project/view-models/summary.view-model';
import { Injectable } from '@angular/core';
import { ISummaryNotesManagerService } from '@modules';
import { Observable, BehaviorSubject } from 'rxjs';
import { IRequisitionLogsPatient, ISummaryNote, ISummaryNoteHistory } from '@project/view-models';
import {
  RequisitionApiProviderService,
  SummaryApiProviderService,
  SummaryNotesApiProviderService,
} from '@project/data-providers';
import { map, mergeAll } from 'rxjs/operators';

@Injectable()
export class DoctorSummaryNotesManagerService implements ISummaryNotesManagerService<IRequisitionLogsPatient> {
  private readonly _medicalRecords$ = new BehaviorSubject<IMedicalRecord[]>([]);
  public readonly medicalRecords = this._medicalRecords$.asObservable();

  public readonly _requisition$ = new BehaviorSubject<IRequisitionLogsPatient>(null);
  public readonly requisition$ = this._requisition$.asObservable();

  constructor(
    private summaryNotesProvider: SummaryNotesApiProviderService,
    private requisitionApiProviderService: RequisitionApiProviderService,
    private summaryApiProviderService: SummaryApiProviderService,
  ) {}

  getHistoryById(id: string): Observable<ISummaryNoteHistory[]> {
    return this.summaryNotesProvider.getSummaryNoteHistoryById(id);
  }

  private currentNotes$: BehaviorSubject<ISummaryNote[]> = new BehaviorSubject<ISummaryNote[]>([]);
  public notes$: Observable<ISummaryNote[]> = this.currentNotes$.asObservable();

  public setNotes(notes: ISummaryNote[]) {
    this.currentNotes$.next(notes);
  }

  createNote(note: string): Observable<void> {
    throw new Error('Method not implemented.');
  }

  updateNote(id: string, note: string): Observable<void> {
    throw new Error('Method not implemented.');
  }

  selectRequisition(requisition: IRequisitionLogsPatient) {
    this._requisition$.next(requisition);
  }

  public loadMedicalRecords(requisitionID: string): Observable<void> {
    const requisition = this._requisition$.value;

    if (!requisition) {
      return null;
    }

    return this.requisitionApiProviderService.getById(requisitionID).pipe(
      map(({ doctors: [{ id }] }) => id),
      map((doctorID) =>
        this.summaryApiProviderService.getMedicalRecordsById(requisition.id, doctorID).pipe(
          map((medicalRecords) => {
            const currentRequisition = medicalRecords.filter((record) => record.requisition_id === requisitionID);

            this._medicalRecords$.next(currentRequisition);
          }),
        ),
      ),
      mergeAll(),
    );
  }
}
