<span>
  <button type="button" class="back-button" *ngIf="backButtonLabel" (click)="backHandler()">
    <span class="back-icon" style="transform: translateY(0.05em);"></span>&nbsp;&nbsp;{{ backButtonLabel }}
  </button>
</span>

<span class="title">
  <ng-content></ng-content>
</span>

<span></span>
<button type="button" class="close-button" (click)="closeHandler()">
  <span class="cross-icon"></span>
</button>
