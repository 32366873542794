import { IDoctor } from './doctor.view-model';
import { IPatient } from './patient.view-model';
import { IReceptionist } from './receptionist.view-model';
import { TGuid } from '@core/helpers';
import { EProfileTypeDTO, getProfileTypeLocalization } from './consts/profile-type';
import { ProfileHelper } from './helpers/profile.helper';
import { IProfile } from './profile.view-model';

export interface IRequisitionParticipant {
  id: TGuid;
  fullName: string;
  phone?: string;
  photoUrl: string;
  profileType: EProfileTypeDTO;
  summaryInfo: string[];
  cpfNumber: string;
}

export class RequisitionParticipantViewModel {
  static create(profile: IDoctor | IPatient | IReceptionist | IProfile): IRequisitionParticipant {
    let summaryInfo = [];
    let cpfNumber: string;

    switch (profile.profileType) {
      case EProfileTypeDTO.Doctor: {
        summaryInfo = ProfileHelper.getDoctorSummaryInfo({
          specialization: profile.specialization,
        });
        break;
      }
      case EProfileTypeDTO.Patient: {
        summaryInfo = (profile as IPatient).summaryInfo;
        cpfNumber = profile.cpfNumber;
        break;
      }
      case EProfileTypeDTO.Receptionist: {
        summaryInfo = [getProfileTypeLocalization(EProfileTypeDTO.Receptionist)];
        break;
      }
    }

    return {
      id: profile.id,
      fullName: profile.fullName,
      phone: profile.phone,
      profileType: profile.profileType,
      summaryInfo,
      photoUrl: profile.photoUrl,
      cpfNumber,
    };
  }
}
