import { Component, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-saudeid-marketplace',
  templateUrl: './modal-saudeId-marketplace.component.html',
  styleUrls: ['./modal-saudeId-marketplace.component.scss'],
})
export class ModalSaudeIdMarketplaceComponent {
  public close$ = new EventEmitter();
  public submit$ = new EventEmitter();

  public logoSaudeId = '../../../../assets/logos/logo-saudeid.svg';
  public logoPortal = '../../../../assets/logos/logo.svg';

  public scheduleAppointmentURL =
    'https://saudeid.com.br/?utm_source=portaltelemed&utm_medium=modalpopup&utm_campaign=modalexames';
  public scheduleCovidExamURL =
    'https://marketplace.saudeid.com.br/pcr-para-covid-19-influenza-e-vsr-a/p?utm_source=portaltelemed&utm_medium=modalpopup&utm_campaign=modalPCR';

  constructor() {
    this.close$ = new EventEmitter();
  }

  public close(): void {
    this.close$.emit();
  }
}
