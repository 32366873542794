import { TranslateService } from '@project/translate';
import { EObservationParameterValueCategoryDTO } from './observation-consts';

export enum EBinahParamDataKeyDTO {
  BreathingRate = 'breathing_rate',
  HeartRate = 'heart_rate',
  OxygenSaturation = 'oxygen_saturation',
  Sdnn = 'sdnn',
  StressLevel = 'stress_level',
}

export enum EBinahStressLevelDTO {
  UNKNOWN = 0,
  LOW = 1,
  NORMAL = 2,
  MILD = 3,
  HIGH = 4,
  EXTREME = 5,
}

export enum EMeasurementUnit {
  Milliseconds = 'Milliseconds',
  BeatsPerMinute = 'BeatsPerMinute',
  BreathsPerMinute = 'BreathsPerMinute',
  Percentage = 'Percentage',
  Levels = 'Levels',
}

export const BINAH_PARAMETER_MEASUREMENT_UNIT: Record<EBinahParamDataKeyDTO, EMeasurementUnit> = {
  [EBinahParamDataKeyDTO.BreathingRate]: EMeasurementUnit.BreathsPerMinute,
  [EBinahParamDataKeyDTO.HeartRate]: EMeasurementUnit.BeatsPerMinute,
  [EBinahParamDataKeyDTO.OxygenSaturation]: EMeasurementUnit.Percentage,
  [EBinahParamDataKeyDTO.Sdnn]: EMeasurementUnit.Milliseconds,
  [EBinahParamDataKeyDTO.StressLevel]: EMeasurementUnit.Levels,
};

export function getObservationBinahParameterLocalization(parameter: EBinahParamDataKeyDTO): string {
  const map: Record<EBinahParamDataKeyDTO, () => string> = {
    [EBinahParamDataKeyDTO.BreathingRate]: () =>
      TranslateService.localize('observation-binah-parameter-enum.BreathingRate'),
    [EBinahParamDataKeyDTO.HeartRate]: () => TranslateService.localize('observation-binah-parameter-enum.HeartRate'),
    [EBinahParamDataKeyDTO.OxygenSaturation]: () =>
      TranslateService.localize('observation-binah-parameter-enum.OxygenSaturation'),
    [EBinahParamDataKeyDTO.Sdnn]: () => TranslateService.localize('observation-binah-parameter-enum.Sdnn'),
    [EBinahParamDataKeyDTO.StressLevel]: () =>
      TranslateService.localize('observation-binah-parameter-enum.StressLevel'),
  };

  return map[parameter]();
}

export function getObservationBinahParameterMeasurementUnitLocalization(params: {
  unit: EMeasurementUnit;
  useFull?: boolean;
}): string | undefined {
  const short: Record<EMeasurementUnit, () => string> = {
    [EMeasurementUnit.Levels]: () => TranslateService.localize('medical-parameters.labels.Levels.short'),
    [EMeasurementUnit.Milliseconds]: () => TranslateService.localize('medical-parameters.labels.Milliseconds.short'),
    [EMeasurementUnit.BeatsPerMinute]: () =>
      TranslateService.localize('medical-parameters.labels.BeatsPerMinute.short'),
    [EMeasurementUnit.BreathsPerMinute]: () =>
      TranslateService.localize('medical-parameters.labels.BreathsPerMinute.short'),
    [EMeasurementUnit.Percentage]: () => TranslateService.localize('medical-parameters.labels.Percentage.short'),
  };

  const long: Record<EMeasurementUnit, () => string> = {
    [EMeasurementUnit.Levels]: () => TranslateService.localize('medical-parameters.labels.Levels.long'),
    [EMeasurementUnit.Milliseconds]: () => TranslateService.localize('medical-parameters.labels.Milliseconds.long'),
    [EMeasurementUnit.BeatsPerMinute]: () => TranslateService.localize('medical-parameters.labels.BeatsPerMinute.long'),
    [EMeasurementUnit.BreathsPerMinute]: () =>
      TranslateService.localize('medical-parameters.labels.BreathsPerMinute.long'),
    [EMeasurementUnit.Percentage]: () => TranslateService.localize('medical-parameters.labels.Percentage.long'),
  };

  return params.useFull ? long[params.unit]?.() : short[params.unit]?.();
}

export const BINAH_NO_VALUE_LABEL_PLACEHOLDER = '-';

export type TBinahParameterRawValueDTO = number | string | null;

interface IBinahParameterData {
  value: TBinahParameterRawValueDTO;
  status: EObservationParameterValueCategoryDTO;
}

export type TBinalDataDTO = Partial<Record<EBinahParamDataKeyDTO, IBinahParameterData>>;

export function getBinahParameterEnumValueLabel(
  parameter: EBinahParamDataKeyDTO,
  rawValue: TBinahParameterRawValueDTO,
): string | undefined {
  type TParametersEnumValuesLabelsDictionary = {
    [EBinahParamDataKeyDTO.StressLevel]: Record<EBinahStressLevelDTO, () => string>;
  };

  const DICTIONARY: TParametersEnumValuesLabelsDictionary = {
    [EBinahParamDataKeyDTO.StressLevel]: {
      [EBinahStressLevelDTO.UNKNOWN]: () => BINAH_NO_VALUE_LABEL_PLACEHOLDER,
      [EBinahStressLevelDTO.LOW]: () => TranslateService.localize('BINAH-parameter.StressLevel.LOW'),
      [EBinahStressLevelDTO.NORMAL]: () => TranslateService.localize('BINAH-parameter.StressLevel.NORMAL'),
      [EBinahStressLevelDTO.MILD]: () => TranslateService.localize('BINAH-parameter.StressLevel.MILD'),
      [EBinahStressLevelDTO.HIGH]: () => TranslateService.localize('BINAH-parameter.StressLevel.HIGH'),
      [EBinahStressLevelDTO.EXTREME]: () => TranslateService.localize('BINAH-parameter.StressLevel.EXTREME'),
    },
  };

  return DICTIONARY[parameter]?.[rawValue]?.();
}
