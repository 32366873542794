import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EProfileTypeDTO } from '@project/view-models';

@Component({
  selector: 'app-profile-preview',
  templateUrl: './profile-preview.component.html',
  styleUrls: ['./profile-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePreviewComponent {
  @Input() avatarUrl: string;
  @Input() name: string;
  @Input() summaryInfoList: string[] = [];
  @Input() profileType: EProfileTypeDTO;
  @Input() cpfNumber: string;

  public copyCpfNumber(): void {
    navigator.clipboard.writeText(this.cpfNumber);
  }
}
