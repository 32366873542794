import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LibValidators, TGuid } from '@core/helpers';
import { FormComponent } from '@project/shared';
import { ISummaryNote, ISummaryNoteHistory, IMedicalRecord, EProfileTypeDTO } from '@project/view-models';
import { Observable, BehaviorSubject } from 'rxjs';
import { ISummaryNotesManagerService } from '../summary-manager';
import { map } from 'rxjs/operators';
import { RoutesBuilderService } from '@app/config';
import { DoctorEncounterHistoryManagerService } from 'src/app/lazy-modules/doctor-encounter-history/doctor-encounter-history-manager.service';
import { DoctorSummaryNotesManagerService } from './../../../../lazy-modules/doctors/services/doctor-summary-notes-manager.service';
import { PatientEncounterHistoryManagerService } from 'src/app/lazy-modules/patient-encounter-history/patient-encounter-history-manager.service';
import { UserProfileDataService } from '@project/services';
import { RecepcionistEncounterHistoryManagerService } from 'src/app/lazy-modules/recepcionist-encounter-history/services/recepcionist-encounter-history-manager.service';

@Component({
  selector: 'app-session-details-shared-summary',
  templateUrl: './session-details-shared-summary.component.html',
  styleUrls: ['./session-details-shared-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionDetailsSharedSummaryComponent extends FormComponent implements OnInit {
  @Input() canCreateNotes: boolean;
  @Input() editCreateNotes: boolean;
  @Input() canPrintMedicalRecord = false;
  @Input() showReturn = false;
  @Input() showHistory = false;
  @Input() createMedicalRecordURL = '';
  @Input() summaryManagerService: ISummaryNotesManagerService<any>;
  @Input() recepcionistEncounterHistoryManagerService: RecepcionistEncounterHistoryManagerService;

  @Output() noteId = new EventEmitter<TGuid>();
  @Output() onReturn = new EventEmitter<void>();

  public summaryNotes$: Observable<ISummaryNote[]>;
  public medicalRecords$: Observable<IMedicalRecord[]>;
  public summaryNoteHistory$: BehaviorSubject<ISummaryNoteHistory[]> = new BehaviorSubject([]);
  public summaryNoteHistoryId: BehaviorSubject<TGuid | null> = new BehaviorSubject(null);

  public editNotes: boolean;
  public patientName: string;
  public requisitionId: string;

  public readonly doctorEncounterHistoryRoutes = RoutesBuilderService.ENCOUNTER_HISTORY_AS_DOCTOR;

  private isFetchingHistory = false;

  constructor(
    private healthCenterSummaryManagerService: DoctorSummaryNotesManagerService,
    private doctorEncounterHistoryManagerService: DoctorEncounterHistoryManagerService,
    private patientEncounterHistoryManagerService: PatientEncounterHistoryManagerService,
    private userProfileDataService: UserProfileDataService,
  ) {
    super();
  }

  public readonly form = new FormGroup({
    message: new FormControl('', LibValidators.required(``)),
    id: new FormControl(''),
  });

  ngOnInit() {
    this.checkForExistenceOfSummaryManagerService();
    this.summaryNotes$ = this.summaryManagerService.notes$;
    this.medicalRecords$ = this.summaryManagerService.medicalRecords;
    super.ngOnInit();
  }

  checkForExistenceOfSummaryManagerService() {
    if (!this.summaryManagerService) {
      this.setManagerService(this.getProfileType());
    }
  }

  getProfileType(): string {
    let profileType;
    this.userProfileDataService.profile$.subscribe((profile) => {
      if (profile != null) {
        profileType = profile.profileType;
      }
    });
    return profileType;
  }

  setManagerService(profileType: string): void {
    switch (profileType) {
      case EProfileTypeDTO.Doctor:
        this.summaryManagerService = this.doctorEncounterHistoryManagerService;
        break;
      case EProfileTypeDTO.Patient:
        this.summaryManagerService = this.patientEncounterHistoryManagerService;
        break;
      case EProfileTypeDTO.HealthCenterAdmin:
        this.summaryManagerService = this.healthCenterSummaryManagerService;
        break;
      case EProfileTypeDTO.Receptionist:
        this.summaryManagerService = this.recepcionistEncounterHistoryManagerService;
        break;
      default:
        break;
    }
  }

  editSummaryNote(note: ISummaryNote) {
    this.editNotes = true;
    this.form.get('id').setValue(note.id);
    this.form.get('message').setValue(note.note);
    this.noteId.emit(note.id);
  }

  return() {
    this.onReturn.emit();
  }

  protected onFormInit() {}

  protected doCleanSubmit() {
    this.setStartSubmitState();
    this.editNotes = false;

    if (this.form.value?.id === null || this.form.value?.id === '') {
      this.summaryManagerService.createNote(this.form.value.message).subscribe(
        () => {
          this.form.reset();
          this.setEndSubmitState();
        },
        () => {
          this.setEndSubmitState();
        },
      );
    } else {
      this.summaryManagerService.updateNote(this.form.value.id, this.form.value.message).subscribe(
        () => {
          this.form.reset();
          this.setEndSubmitState();
        },
        () => {
          this.setEndSubmitState();
        },
      );
    }
  }

  public openHistory(id: TGuid) {
    if (this.isFetchingHistory) {
      this.return;
    }

    if (this.summaryNoteHistoryId.value !== id) {
      this.summaryManagerService
        .getHistoryById(id)
        .pipe(
          map((x) => {
            x.splice(0, 1);
            return x;
          }),
        )
        .subscribe((x) => {
          this.summaryNoteHistory$.next(x);
          this.summaryNoteHistoryId.next(id);
          this.isFetchingHistory = false;
        });

      this.isFetchingHistory = true;
    } else {
      this.summaryNoteHistoryId.next(null);
    }
  }

  public async onClickDownloadPDF(medicalRecordPrintURL: string): Promise<void> {
    this.getRequisitionInfos();

    const response = await fetch(medicalRecordPrintURL);
    const responseBuffer = await response.arrayBuffer();
    const blob = new Blob([responseBuffer], { type: 'application/pdf' });
    const objectUrl = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = objectUrl;
    link.download = `${this.patientName + '-' + this.requisitionId}.pdf`;

    document.body.append(link);
    link.click();
    link.remove();

    window.addEventListener('focus', () => {
      URL.revokeObjectURL(link.href);
    });
  }

  public getRequisitionInfos(): void {
    this.summaryManagerService.requisition$?.subscribe((req) => {
      this.patientName = req.patient_name.replace(' ', '_');
      this.requisitionId = req.id;
    });
  }
}
