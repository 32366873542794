import { Injectable } from '@angular/core';
import { TGuid } from '@core/helpers';

@Injectable({
  providedIn: 'root',
})
export class GuidService {
  constructor() {}

  generate(): TGuid {
    const blob = new Blob();
    const url = URL.createObjectURL(blob);
    const guid = url.substr(url.lastIndexOf('/') + 1);
    URL.revokeObjectURL(url);
    return guid;
  }

  isValid(guid: TGuid): boolean {
    const validator = new RegExp('^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$', 'i');
    return !!guid && typeof guid === 'string' && validator.test(guid);
  }
}
