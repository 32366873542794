import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as platform from 'platform';

@Injectable()
export class HttpInfoDeviceInterceptorService implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const manufacturer = platform.manufacturer || '';
    const info = `SOS Portal/(Web, ${platform.name} ${platform.version}, ${platform.os}, ${manufacturer})`;
    request = request.clone({
      setHeaders: {
        Device: info,
      },
    });
    return next.handle(request);
  }
}
