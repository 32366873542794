import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IChatMessage, IRequisitionParticipant } from '@project/view-models';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageComponent {
  @Input() message: IChatMessage;
  @Input() hideHeader: boolean;
  @Input() isMyMessage: boolean;
  @Input() user: IRequisitionParticipant;
  @Input() attachment: string;

  isImage(attachment) {
    const imageRegex = /\.(jpg|jpeg|png|ico|svg|webp)(\?.*)?$/i;
    return imageRegex.test(attachment);
  }
  isPdf(attachment) {
    const pdfRegex = /\.pdf(\?.*)?$/i;
    return pdfRegex.test(attachment);
  }
  isAudio(attachment) {
    const audioRegex = /\.(mp3|m4a)(\?.*)?$/i;
    return audioRegex.test(attachment);
  }
}
