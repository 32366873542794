<app-avatar-preview class="avatar" [profileType]="profileType" [url]="avatarUrl"></app-avatar-preview>

<div class="content">
  <h2>
    {{ name }}
  </h2>

  <ul class="badges">
    <li *ngFor="let detail of summaryInfoList" class="text-color-muted">
      {{ detail }}
    </li>
  </ul>
  <span class="text-color-muted cpf" *ngIf="cpfNumber">
    {{ cpfNumber }}
    <button libButton appearance="grey-outline" (click)="copyCpfNumber()">
      {{ "actions.copy" | translate }}
    </button>
  </span>
</div>
