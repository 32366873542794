import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import {
  AuthorizedGuard,
  HasAccessGuard,
  LogoutService,
  NotAuthorizedGuard,
  OnboardingRequiredGuard,
  RedirectToHomepageGuard,
  UserProfileDataLoaderGuard,
} from '@project/services';
import { EAsideOutlets, EUserPermission, EUserPermissionsCheckAs, IRouteWithData } from '@project/shared';
import { AuthorizationLayoutComponent, SidebarLayoutComponent } from '@modules';
import { LocaleInitializerGuard, TranslateService } from '@project/translate';
import { FeatureFlagsGuard } from '@project/modules/feature-flag';
import { SessionsSpecialistModule } from './lazy-modules/sessions/sessions-specialist/sessions-specialist.module';
import {
  AuthorizedUserFeaturesContainerComponent,
  AuthorizedUserFeaturesInitializerService,
} from '@features/container';
import { ISiteModule, RoutesBuilderService, SITE_USER_MODULES, SITE_USER_SETTINGS_MODULES } from '@app/config';

/**
 * Redirects from external sources (like email) to inner routes
 */
const externalRedirects: Route[] = [
  {
    path: RoutesBuilderService.AUTH.baseConfirm,
    redirectTo: RoutesBuilderService.AUTH.confirmRelativeRoute(),
    pathMatch: 'full',
  },
  {
    path: 'reset-password-request',
    redirectTo: RoutesBuilderService.AUTH.resetPasswordRelativeRoute(),
    pathMatch: 'full',
  },
  {
    path: 'forgot-password-request',
    redirectTo: RoutesBuilderService.AUTH.forgotPasswordRelativeRoute(),
    pathMatch: 'full',
  },
];

const notAuthorizedUserRoutes: IRouteWithData[] = [
  {
    path: '',
    loadChildren: () => import('./lazy-modules/authorization/authorization.module').then((m) => m.AuthorizationModule),
  },
];

function mapSiteModulesToRoutes(modules: ISiteModule[]): IRouteWithData[] {
  return modules.map((module) => {
    const canActivateGuards = [];
    const canLoadGuards = [];

    if (module.featureFlagsToCheck) {
      canActivateGuards.push(FeatureFlagsGuard);
    }

    if (module.permissions?.length) {
      canActivateGuards.push(HasAccessGuard);
      canLoadGuards.push(HasAccessGuard);
    }

    return {
      path: module.rootRouteBase,
      loadChildren: module.lazyModule,
      canActivate: [...canActivateGuards],
      canLoad: [...canLoadGuards],
      data: {
        titleCreator: module.browserTabTitleCreator,
        userPermissionsToCheck: module.permissions || [],
      },
    };
  });
}

const authorizedUserRoutes: IRouteWithData[] = [
  {
    path: '',
    component: SidebarLayoutComponent,
    children: [
      ...mapSiteModulesToRoutes(SITE_USER_MODULES),
      ...mapSiteModulesToRoutes(SITE_USER_SETTINGS_MODULES),
      {
        path: RoutesBuilderService.SESSIONS.base,
        canActivate: [HasAccessGuard],
        data: {
          titleCreator: () => TranslateService.localize('sidebar.menu-labels.sessions'),
        },
        children: [
          {
            path: RoutesBuilderService.SESSIONS.baseSpecialistPart,
            canActivate: [HasAccessGuard],
            canLoad: [HasAccessGuard],
            data: {
              userPermissionsToCheck: [EUserPermission.haveDoctorSession],
            },
            loadChildren: () => SessionsSpecialistModule,
          },
          {
            path: RoutesBuilderService.SESSIONS.basePatientPart,
            canActivate: [HasAccessGuard],
            canLoad: [HasAccessGuard],
            data: {
              userPermissionsToCheck: [EUserPermission.havePatientSession],
              userPermissionsFailRedirect: RoutesBuilderService.SESSIONS.specialistSessions(),
            },
            loadChildren: () =>
              import('./lazy-modules/sessions/sessions-patient/sessions-patient.module').then(
                (m) => m.SessionsPatientModule,
              ),
          },
          {
            path: '',
            redirectTo: RoutesBuilderService.SESSIONS.basePatientPart,
            pathMatch: 'prefix',
          },
        ],
      },
      {
        path: '',
        canActivate: [RedirectToHomepageGuard],
        children: [],
      },
    ],
  },
];

const globalAsideOutletRoutes: IRouteWithData[] = [
  {
    path: RoutesBuilderService.SESSION_DETAILS.base,
    outlet: EAsideOutlets.GlobalAsideOutlet,
    canActivate: [AuthorizedGuard, HasAccessGuard],
    canLoad: [AuthorizedGuard, HasAccessGuard],
    data: {
      userPermissionsToCheck: [
        EUserPermission.havePatientSession,
        EUserPermission.haveDoctorSession,
        EUserPermission.haveReceptionistsSession,
      ],
      userPermissionsCheckAs: EUserPermissionsCheckAs.Some,
    },
    loadChildren: () =>
      import('./lazy-modules/session-details/session-details.module').then((m) => m.SessionDetailsModule),
  },
  {
    path: 'information-center/:userType',
    outlet: EAsideOutlets.GlobalAsideOutlet,
    loadChildren: () =>
      import('./lazy-modules/user-information-center/user-information-center.module').then(
        (module) => module.UserInformationCenterModule,
      ),
  },
];

const routes: IRouteWithData[] = [
  ...externalRedirects,
  {
    path: '',
    canActivate: [LocaleInitializerGuard],
    children: [
      {
        path: RoutesBuilderService.LOGOUT.base,
        canActivate: [LogoutService],
        children: [],
      },
      {
        path: RoutesBuilderService.DATA_DELETION.base,
        canActivate: [],
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./lazy-modules/data-deletion/data-deletion.module').then((m) => m.DataDeletionModule),
          },
        ],
      },
      {
        path: RoutesBuilderService.FIRST_ACCESS.base,
        canActivate: [],
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./lazy-modules/first-access/first-access.module').then((m) => m.FirstAccessModule),
          },
        ],
      },
      {
        path: RoutesBuilderService.AUTH.base,
        component: AuthorizationLayoutComponent,
        canActivate: [NotAuthorizedGuard],
        children: [...notAuthorizedUserRoutes],
      },
      {
        path: RoutesBuilderService.ONBOARDING.base,
        component: AuthorizationLayoutComponent,
        canActivate: [AuthorizedGuard],
        children: [
          {
            path: '',
            loadChildren: () => import('./lazy-modules/onboarding/onboarding.module').then((m) => m.OnboardingModule),
          },
        ],
      },
      {
        path: '',
        canActivate: [AuthorizedGuard],
        children: [
          {
            path: '',
            canActivate: [UserProfileDataLoaderGuard],
            children: [
              {
                path: '',
                canActivate: [OnboardingRequiredGuard],
                children: [
                  {
                    path: '',
                    canActivate: [AuthorizedUserFeaturesInitializerService],
                    component: AuthorizedUserFeaturesContainerComponent,
                    children: [...authorizedUserRoutes],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  ...globalAsideOutletRoutes,
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
