import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EProfileTypeDTO } from '@project/view-models';

@Component({
  selector: 'app-avatar-with-content',
  templateUrl: './avatar-with-content.component.html',
  styleUrls: ['./avatar-with-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarWithContentComponent {
  @Input() avatarUrl: string;
  @Input() profileType: EProfileTypeDTO;
}
