import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EObservationMethodDTO, IObservationFull } from '@project/view-models';

@Component({
  selector: 'app-observation-data-table-preview',
  templateUrl: './observation-data-table-preview.component.html',
  styleUrls: ['./observation-data-table-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObservationDataTablePreviewComponent {
  @Input() observationFull: IObservationFull;
  @Input() emptyDataMessage: string;

  public readonly observationMethods = EObservationMethodDTO;
}
