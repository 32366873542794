<div [className]="isPiaui ? 'background -isPiaui' : 'background'">
  <div class="layout">
    <header class="header">
      <div *ngIf="showLogo" class="logo"></div>
    </header>
    <main>
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
