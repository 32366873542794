import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ESessionQueueItemInfoType } from '../consts';

@Component({
  selector: 'app-session-queue-item-simple-info',
  templateUrl: './session-queue-item-simple-info.component.html',
  styleUrls: ['./session-queue-item-simple-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionQueueItemSimpleInfoComponent {
  @Input() type: ESessionQueueItemInfoType;
  @Input() mutedTopMessage: string;

  public readonly types = ESessionQueueItemInfoType;
}
