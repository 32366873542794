import { ChangeDetectionStrategy, Component, EventEmitter, Input } from '@angular/core';
import { IModalComponent } from '@lib/modal';
import { IRequisition, IRequisitionParticipant } from '@project/view-models';
import { Subject } from 'rxjs';
import { RequisitionHelpersService } from '@project/services';

@Component({
  selector: 'app-call-is-ready-to-begin-overlay',
  templateUrl: './call-is-ready-to-begin-overlay.component.html',
  styleUrls: ['./call-is-ready-to-begin-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallIsReadyToBeginOverlayComponent implements IModalComponent {
  @Input() requisition: IRequisition = null;

  get participant(): IRequisitionParticipant {
    return this.requisitionHelpersService.getRequisitionParticipantForCurrentUser(this.requisition);
  }

  close$ = new EventEmitter();
  submit$ = new EventEmitter();

  private _joinClicked$ = new Subject();
  public joinClicked$ = this._joinClicked$.asObservable();

  private _declineClicked$ = new Subject();
  public declineClicked$ = this._declineClicked$.asObservable();

  constructor(private requisitionHelpersService: RequisitionHelpersService) {}

  onJoinClick() {
    this._joinClicked$.next();
  }

  onDeclineClick() {
    this._declineClicked$.next();
  }
}
