import { TGuid } from '@core/helpers';

export interface IScheduleCheckForDoctorResultDTO {
  doctor_id: TGuid;
  set: boolean;
}

export interface IScheduleCheckForDoctorsResult {
  [doctorId: string]: boolean;
}

export class ScheduleCheckForDoctorsResultViewModel {
  static createGetViewModelForResults(dtos: IScheduleCheckForDoctorResultDTO[]): IScheduleCheckForDoctorsResult {
    return dtos.reduce((acc: IScheduleCheckForDoctorsResult, dto) => {
      acc[dto.doctor_id] = dto.set;
      return acc;
    }, {});
  }
}
