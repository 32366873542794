import { Injectable } from '@angular/core';
import { Observable, of, race } from 'rxjs';
import { RequisitionApiProviderService } from '@project/data-providers';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ModalOverlayService } from '@lib/modal';
import {
  AskToCloseRequisitionsOverlayComponent,
  IAskToCloseRequisitionsOverlayComponentInputs,
} from './ask-to-close-requisitions-overlay/ask-to-close-requisitions-overlay.component';
import { IRequisition } from '@project/view-models';

@Injectable()
export class CloseRequisitionsService {
  constructor(
    private requisitionApiProviderService: RequisitionApiProviderService,
    private modalOverlayService: ModalOverlayService,
  ) {}

  askAndCloseRequisitionsIfExist(): Observable<void> {
    return this.requisitionApiProviderService.getAll().pipe(
      catchError(() => of([])),
      switchMap((requisitions) => {
        if (!requisitions?.length) {
          return of(null);
        }

        return this.handleRequisitionCloseOverlay(requisitions);
      }),
      map(() => null),
    );
  }

  private handleRequisitionCloseOverlay(requisitions: IRequisition[]): Observable<void> {
    const ref = this.modalOverlayService.openOverlay<
      AskToCloseRequisitionsOverlayComponent,
      IAskToCloseRequisitionsOverlayComponentInputs
    >(
      AskToCloseRequisitionsOverlayComponent,
      {
        requisitions,
      },
      {
        closeByEsc: false,
        closeByBackdropClick: false,
      },
    );

    return race(ref.submit$, ref.close$);
  }
}
