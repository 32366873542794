import { Injectable } from '@angular/core';

export interface IPagingButton {
  title: string;
  page: number;
  current: boolean;
}

@Injectable()
export class PaginationLayoutBuilderService {
  private intermediateButtonLabel = '...';

  getPagesButtonsWithoutEdges(pagesCount: number, page: number, pagingLength = 10): IPagingButton[] {
    const showBefore = 1;

    const length = pagesCount > pagingLength ? pagingLength : pagesCount;

    if (page === 1 || page - showBefore === 1) {
      return this.buildButtonsRange(1, length, page);
    }

    const lastCount = pagesCount - page;

    if (lastCount + 1 + showBefore <= length) {
      return this.buildButtonsRange(pagesCount - length + 1, pagesCount, page);
    }

    return this.buildButtonsRange(page - showBefore, page - showBefore + length - 1, page);
  }

  getPagesButtonsWithEdges(pagesCount: number, page: number, pagingLength: number = 10): IPagingButton[] {
    if (pagesCount <= pagingLength) {
      return this.buildButtonsRange(1, pagesCount, page);
    }

    /**
     * Examples if pagingLength >= 9:
     * * Current page at beginning [1, 2, 3, 4, 5, 6, '...', N - 1, N],
     * * Current page at center [1, 2, '...', 13, 14, 15, '...', N - 1, N],
     * * Current page at ending [1, 2, '...', N - 5, N - 4, N - 3, N - 2, N - 1, N]
     * * 2 elements before and after '...'
     * Examples if pagingLength < 9:
     * * Current page at beginning [1, 2, 3, 4, 5, '...', N],
     * * Current page at center [1, '...', 13, 14, 15, '...', N],
     * * Current page at ending [1, '...', N - 4, N - 3, N - 2, N - 1, N]
     * * 1 element before and after '...'
     */
    const startAndTailEdgeLength = pagingLength >= 9 ? 2 : 1;
    const startEdgeLength = startAndTailEdgeLength;
    const tailEdgeLength = startAndTailEdgeLength;

    const placeholderButtonLength = 1;

    /**
     * Case when current page at beginning [1, 2, 3, 4, 5, '...', N]
     * pagingLength > 5 will switch to `Center` case if last item of head is selected
     */
    const headEnd = pagingLength - placeholderButtonLength - tailEdgeLength;
    if (page <= (pagingLength > 5 ? headEnd - 1 : headEnd)) {
      // - 1 means that we should always have +1 page visible before '...' begins
      const headLength = pagingLength - tailEdgeLength - placeholderButtonLength;
      const tailStartPageNumber = pagesCount - tailEdgeLength + placeholderButtonLength;

      const intermediateButtonPage = Math.ceil((tailStartPageNumber + headLength) / 2);

      return [
        ...this.buildButtonsRange(1, headLength, page),
        this.buildIButton(intermediateButtonPage, this.intermediateButtonLabel),
        ...this.buildButtonsRange(tailStartPageNumber, pagesCount, page),
      ];
    }

    // Case when current page at ending [1, '...', N - 4, N - 3, N - 2, N - 1, N]
    if (page > pagesCount - (pagingLength - 1 - startEdgeLength)) {
      const tailStartPageNumber = pagesCount - (pagingLength - startEdgeLength - placeholderButtonLength) + 1;

      const intermediateButtonPage = Math.ceil((tailStartPageNumber + startEdgeLength) / 2);

      return [
        ...this.buildButtonsRange(1, startEdgeLength, page),
        this.buildIButton(intermediateButtonPage, this.intermediateButtonLabel),
        ...this.buildButtonsRange(tailStartPageNumber, pagesCount, page),
      ];
    }

    // Case when current page at center [1, '...', 13, 14, 15, '...', N],
    {
      const headEnd = startEdgeLength;
      const tailsStart = pagesCount - tailEdgeLength + 1;

      const centerLength = pagingLength - placeholderButtonLength * 2 - startEdgeLength - tailEdgeLength;
      const centerStart = page - Math.floor((centerLength - 1) / 2);
      const centerEnd = page + Math.ceil((centerLength - 1) / 2);

      const intermediateStartButtonPage = Math.ceil((centerStart + headEnd) / 2);
      const intermediateEndButtonPage = Math.ceil((tailsStart + centerEnd) / 2);

      return [
        ...this.buildButtonsRange(1, headEnd, page),
        this.buildIButton(intermediateStartButtonPage, this.intermediateButtonLabel),
        ...this.buildButtonsRange(centerStart, centerEnd, page),
        this.buildIButton(intermediateEndButtonPage, this.intermediateButtonLabel),
        ...this.buildButtonsRange(tailsStart, pagesCount, page),
      ];
    }
  }

  private getNumberArrayByEdges(from: number, to: number): number[] {
    const length = to >= from ? to - from + 1 : 0;
    return new Array(length).fill(0).map((item, index) => index + from);
  }

  private buildButtonsRange(from: number, to: number, currentPageNumber: number): IPagingButton[] {
    return this.getNumberArrayByEdges(from, to).map((num) =>
      this.buildIButton(num, num.toString(), num === currentPageNumber),
    );
  }

  private buildIButton(page: number, label: string, isCurrent = false): IPagingButton {
    return {
      page,
      title: label,
      current: isCurrent,
    };
  }
}
