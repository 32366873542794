import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsideOutletComponent } from './aside-outlet/aside-outlet.component';
import { IconsModule } from '@lib/icons';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@project/translate';

@NgModule({
  declarations: [AsideOutletComponent],
  exports: [AsideOutletComponent],
  imports: [CommonModule, IconsModule, RouterModule, TranslateModule],
})
export class AsideOutletModule {}
