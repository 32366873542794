import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { TInputSize } from '../input/input.component';

@Component({
  selector: 'lib-input-icon',
  template: '',
  styleUrls: [`./input-icon.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputIconComponent {
  @HostBinding('attr.data-type') @Input() type: 'warning' | 'completed' | 'password' | 'select-arrow';
  @HostBinding('class.password-opened') @Input() passwordOpened: boolean;
  @HostBinding('attr.data-size') @Input() size: TInputSize;
}
