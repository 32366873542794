import { IOutcome } from './../../view-models/summary.view-model';
import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@env';
import { ICID, ITuss, ICIDStatus, IMedicalRecord, INewMedicalRecord } from '@project/view-models';

@Injectable({
  providedIn: 'root',
})
export class SummaryApiProviderService {
  private readonly _isMedicalRecordFilled$ = new BehaviorSubject<boolean>(false);
  public readonly isMedicalRecordFilled$ = this._isMedicalRecordFilled$.asObservable();

  constructor(private apiService: ApiService) {}

  public getCIDBySearch(search: string): Observable<ICID[]> {
    return this.apiService.get<ICID[]>(
      `${environment.environmentVariables.apiCoreUrl}/MedicalRecord/cids?description=${search}`,
    );
  }

  public getTussBySearch(search: string): Observable<ITuss[]> {
    return this.apiService.get<ITuss[]>(
      `${environment.environmentVariables.apiCoreUrl}/MedicalRecord/tuss?description=${search}`,
    );
  }

  public getAllOutcomes(): Observable<IOutcome[]> {
    return this.apiService.get<IOutcome[]>(`${environment.environmentVariables.apiCoreUrl}/MedicalRecord/outcomes`);
  }

  public getAllCIDStatus(): Observable<ICIDStatus[]> {
    return this.apiService.get<ICIDStatus[]>(`${environment.environmentVariables.apiCoreUrl}/MedicalRecord/cid-status`);
  }

  public getMedicalRecordsById(requisitionID: string, doctorID = ''): Observable<IMedicalRecord[]> {
    return this.apiService.get<IMedicalRecord[]>(
      `${environment.environmentVariables.apiCoreUrl}/MedicalRecord?requisitionId=${requisitionID}&doctorId=${doctorID}`,
    );
  }

  public createMedicalRecord(medicalRecord: INewMedicalRecord): Observable<void> {
    return this.apiService.post<void>(
      `${environment.environmentVariables.apiCoreUrl}/MedicalRecord?requisitionId=${medicalRecord.requisition_id}`,
      medicalRecord,
    );
  }

  public setMedicalRecordFilledState(state: boolean): void {
    this._isMedicalRecordFilled$.next(state);
  }
}
