<lib-modal-header (close)="close()">{{ "import-invites.modal-header" | translate }}</lib-modal-header>
<lib-modal-body>
  <p class="mb-6 description">{{ "import-invites.modal-description" | translate }}</p>

  <div class="select-file">
    <div class="mr-3 mb-3">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.group" | translate }}</span>
        </label>
        <app-patient-invite-control></app-patient-invite-control>
      </lib-form-control>
    </div>

    <div class="ml-3">
      <button class="action-with-icon" libButton [appearance]="'primary'" (click)="fileInput.click()">
        <lib-icon [type]="'add'"></lib-icon>{{ "actions.browse" | translate }}
      </button>
    </div>
    <b class="pl-3">{{ fileInput.files.length === 1 ? fileInput.files[0].name : "" }}</b>
  </div>

  <input
    #fileInput
    type="file"
    class="hidden-file-input"
    (change)="onSelectFile($event)"
    name="Select file"
    accept="text/csv"
  />

  <ng-container *ngIf="fileInput.files.length !== 0 && (invalidItems$ | async) as invalidItems" class="mt-5">
    <lib-card class="mb-4">
      <lib-card-body>
        <h3 style="text-align: center;">
          <span style="color: red;">{{ "import-invites.invalid-items" | translate }}: {{ invalidItems.length }}</span> |
          <span>{{ "import-invites.valid-items" | translate }}: {{ (validItems$ | async).length }}</span>
        </h3>
      </lib-card-body>
      <lib-card-body>
        <app-list-items-table [listItemsServiceToken]="" [allowSelection]="false">
          <tr appListTableItem *ngFor="let invitation of invalidItems$ | async">
            <td>
              {{ invitation.record.firstName }}
            </td>
            <td>
              {{ invitation.record.lastName }}
            </td>
            <td>
              {{ invitation.record.email }}
            </td>
            <td>
              {{ invitation.record.gender }}
            </td>
            <td>
              {{ invitation.record.phone }}
            </td>
            <td>
              {{ invitation.record.dateOfBirth }}
            </td>
            <td *ngIf="!invitation.isValid">
              <app-form-errors-viewer
                [formErrors]="invitation.formErrors"
                [controlLabelsMap]="columnLabelsMap"
              ></app-form-errors-viewer>
            </td>
            <td *ngIf="invitation.isValid" style="color: red;">
              {{ "test-import.duplicate" | translate }}
            </td>
          </tr>
        </app-list-items-table>
      </lib-card-body>
    </lib-card>
  </ng-container>
  <lib-modal-footer>
    <div class="errorInvite">
      <a href="assets/templates/patients-invite-template.csv" target="_blank" class="link action-with-icon">
        <lib-icon [type]="'download'"></lib-icon>{{ "import-invites.download-csv" | translate }}
      </a>
    </div>

    <div class="actions">
      <button libButton (click)="close()" [appearance]="'grey-outline'">
        {{ "actions.cancel" | translate }}
      </button>
      <button (click)="doSuccess()" [disabled]="(validItems$ | async).length === 0" libButton>
        {{ ((invalidItems$ | async).length > 0 ? "actions.import-valid" : "actions.import") | translate }}
      </button>
    </div>
  </lib-modal-footer>
</lib-modal-body>
