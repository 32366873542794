import { AgoraSettingsType } from './agora-settings.type';
import { LOCAL_VIDEO_CONTAINER_NAME, REMOTE_VIDEO_CONTAINER_NAME } from '../definitions';

export const AgoraSettings: AgoraSettingsType = {
  clientOptions: {
    mode: 'rtc',
    codec: 'vp8',
  },
  container: {
    localName: LOCAL_VIDEO_CONTAINER_NAME,
    remoteName: REMOTE_VIDEO_CONTAINER_NAME,
  },
  audioTrackerConfig: {
    AGC: true,
    AEC: true,
    ANS: true,
    encoderConfig: 'speech_standard',
  },
  videoConfig: {
    mirror: true,
    fit: 'contain',
  },
  videoTrackerConfig: {
    optimizationMode: 'motion', //https://docs.agora.io/en/video-calling/enhance-call-quality/video-transmission-optimization?platform=web
    encoderConfig: '480p_1',
  },
  denoiserPath: './assets/noiser', //https://docs.agora.io/en/video-calling/advanced-features/ai-noise-suppression?platform=web
};
