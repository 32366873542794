import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { TGuid } from '@core/helpers';

interface IConferenceTokens {
  access_token: string;
  refresh_token: string;
  expires_in: number;
}

@Injectable({
  providedIn: 'root',
})
export class ConferencesApiProviderService {
  constructor(private apiService: ApiService) {}

  getToken(): Observable<IConferenceTokens> {
    return this.apiService.get(`${environment.environmentVariables.apiCoreUrl}/Conferences/token`);
  }

  notifyCalling(chatId: TGuid): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/Conferences/${chatId}/calling`);
  }
}
