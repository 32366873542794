import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { IAuthorizedUserData } from '@project/view-models';
import { AuthorizationApiProvider } from '@project/data-providers';
import { LocalStorageService } from './local-storage.service';
import { tap } from 'rxjs/operators';
import { environment } from '@env';
import { HcService } from './hc-service';

@Injectable({
  providedIn: 'root',
})
export class AuthorisationStateService {
  private _authorisationData$ = new BehaviorSubject<IAuthorizedUserData | null>(null);

  private readonly AUTH_DATA_STORAGE_TOKEN = 'auth';

  constructor(
    private authorizationApiProvider: AuthorizationApiProvider,
    private localStorageService: LocalStorageService,

    private hcService: HcService,
  ) {
    this.loadAuthData();
  }

  isAuthorized(): boolean {
    return !!this._authorisationData$.value;
  }

  setAuthorisationData(data: IAuthorizedUserData | null) {
    this._authorisationData$.next(data);
    this.saveAuthData();

    if (data?.healthCenterId === environment.environmentVariables.healthCenterIdPiaui) {
      this.hcService.setPiauiHealthCenter();
    }
  }

  refreshToken(token: string) {
    this.setAuthorisationData({
      ...this.getAuthorisationData(),
      accessToken: token,
    });
  }

  getAuthorisationData(): IAuthorizedUserData | null {
    return this._authorisationData$.value;
  }

  getToken(): string {
    return this.getAuthorisationData()?.accessToken;
  }

  getRefreshToken(): string | undefined | null {
    return this.getAuthorisationData()?.refresh_token;
  }

  public getLiveQueueState(): boolean {
    return this.getAuthorisationData()?.is_live_queue_enabled ?? false;
  }

  private saveAuthData() {
    const authDataStr = JSON.stringify(this._authorisationData$.value);
    localStorage.setItem(this.AUTH_DATA_STORAGE_TOKEN, authDataStr);
  }

  private loadAuthData() {
    let authData;
    try {
      authData = JSON.parse(localStorage.getItem(this.AUTH_DATA_STORAGE_TOKEN));
    } catch {
      authData = null;
    }

    this._authorisationData$.next(authData);
  }

  impersonateHealthCenter(healthCenterId: string): Observable<any> {
    if (this._authorisationData$.value?.healthCenterId === healthCenterId) {
      return of(null);
    }

    return this.authorizationApiProvider.impersonateLogin(healthCenterId).pipe(
      tap((authData) => {
        this.setAuthorisationData(authData);
        this.localStorageService.store('logo-url', authData.health_center_logo_url || 'assets/logos/logo.svg');
      }),
    );
  }
}
