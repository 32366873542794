import { EGenderDTO, IHealthCenterAdminToInvite } from '@project/view-models';
import { LibValidators, TGuid } from '@core/helpers';
import { TranslateService } from '@project/translate';

export interface IHealthCenterAdminInviteFormModel {
  email: string;
  gender: EGenderDTO;
  first_name: string;
  last_name: string;
  healthCenterId?: TGuid;
  http_execute: boolean;
  access_token: boolean;
}

export class HealthCenterAdminInviteUtils {
  static getHealthCenterAdminInviteFormDeclaration() {
    return {
      email: ['', [LibValidators.email(TranslateService.localize('validations.invalid-email'))]],
      gender: [
        null,
        [
          LibValidators.required(TranslateService.localize('validations.required')),
          LibValidators.enumValue(EGenderDTO, TranslateService.localize('validations.not-allowed')),
        ],
      ],
      firstName: ['', [LibValidators.required(TranslateService.localize('validations.required'))]],
      lastName: ['', [LibValidators.required(TranslateService.localize('validations.required'))]],
      healthCenterId: [],
      accessToken: [],
    };
  }

  static createHealthCenterAdminInviteDto(form: IHealthCenterAdminInviteFormModel): IHealthCenterAdminToInvite {
    return {
      email: form.email,
      first_name: form.first_name,
      last_name: form.last_name,
      gender: form.gender,
      health_center_id: form.healthCenterId,
      http_execute: form.http_execute,
      access_token: form.access_token,
    };
  }
}
