import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Describe which api requests have to be bypassed by service worker
 * https://angular.io/guide/service-worker-devops#bypassing-the-service-worker
 */
@Injectable()
export class HttpNgswBypassInterceptorService implements HttpInterceptor {
  constructor(@Inject(PLATFORM_ID) private platformId) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = req.headers.set('ngsw-bypass', '');

    const newRequest = req.clone({
      headers,
    });

    return next.handle(newRequest);
  }
}
