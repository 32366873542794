import { ModalSaudeIdMarketplaceComponent } from './modal-saudeId-marketplace.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '../../translate/translate.module';
import { ModalDialogLayoutModule } from '@lib/ui';

@NgModule({
  declarations: [ModalSaudeIdMarketplaceComponent],
  imports: [CommonModule, TranslateModule, ModalDialogLayoutModule],
  exports: [ModalSaudeIdMarketplaceComponent],
})
export class ModalSaudeIdMarketplaceModule {}
