import { Injectable, NgZone } from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

const SCROLL_OFFSET_BEFORE_TARGET = 100;

/**
 * Service allows to scroll to elements with anchors and target ids.
 * Usage:
 * <a href="#some-section">Scroll to some section</a>
 * <section id='some-section'></section>
 */
@Injectable({
  providedIn: 'root',
})
export class ScrollToAnchorService {
  constructor(private ngZone: NgZone) {
    this.init();
  }

  static scrollTo(target: HTMLElement) {
    if (!target) {
      return;
    }

    const currentScreenPosition = window.pageYOffset;
    const targetTopPosition = target.getBoundingClientRect().top;
    const targetOffset = currentScreenPosition + targetTopPosition - SCROLL_OFFSET_BEFORE_TARGET;
    window.scroll({
      top: targetOffset,
      left: 0,
      behavior: 'smooth',
    });
  }

  static toTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  private init() {
    /**
     * For better performance, as it's possible, running outside angular zone
     */
    this.ngZone.runOutsideAngular(() => {
      /**
       * getting anchor href from elements
       */
      fromEvent(document, 'click')
        .pipe(
          map((event: Event) => {
            let target = event.target as HTMLElement;
            let deep = 3;
            while (deep >= 0 && target && target.tagName.toLowerCase() !== 'a') {
              target = target.parentElement;
              deep--;
            }
            return [event, target];
          }),
          filter(([, target]: [Event, HTMLElement]) => {
            return target && target.hasAttribute('href') && target.getAttribute('href').startsWith('#');
          }),
          tap(([event]) => {
            event.preventDefault();
            event.stopPropagation();
          }),
          map(([, target]) => {
            return target.getAttribute('href').substr(1);
          }),
        )
        .subscribe((anchor) => {
          ScrollToAnchorService.scrollTo(document.getElementById(anchor));
        });
    });
  }
}
