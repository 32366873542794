/**
 *  `ITaskShort` uses only for task model inside requisition and as base of `IFullTask`.
 *  If you use Task not as part of requisition, use `ITaskFull`.
 */

import { ETaskStatusDTO, ETaskTypeDTO } from './consts/task-type';
import { DateFormatter, TDateISOString, TGuid } from '@core/helpers';

export interface ITaskShortDTO {
  id: TGuid;
  type: ETaskTypeDTO;
  name: string | null;
  status: ETaskStatusDTO;
  description: string | null;
  created_at: TDateISOString;
}

export interface ITaskShort {
  id: TGuid;
  type: ETaskTypeDTO;
  status: ETaskStatusDTO;
  name: string | null;
  description: string | null;
  createdAt: Date;
}

export class TaskShortViewModelFactory {
  static createGetViewModel(dto: ITaskShortDTO): ITaskShort {
    return {
      id: dto.id,
      type: dto.type,
      status: dto.status,
      name: dto.name,
      description: dto.description,
      createdAt: DateFormatter.stringToDate(dto.created_at),
    };
  }
}
