<ng-container *ngIf="observationFull?.data?.length; else emptyMessage">
  <ng-container [ngSwitch]="observationFull?.method">
    <ng-container *ngSwitchCase="observationMethods.Binah">
      <app-observation-binah-data-table-preview
        [observationData]="observationFull.data"
      ></app-observation-binah-data-table-preview>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #emptyMessage>
  <p class="onboarding-message" *ngIf="emptyDataMessage">{{ emptyDataMessage }}</p>
</ng-template>
