import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { TabsControlService } from '../tabs-control.service';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'lib-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() url: string;
  @Input() itemClass?: string;

  @HostBinding('class.active') @Input() isActive: boolean;

  public showContent$: Observable<boolean> = this.tabsControlService.activeTab$.pipe(
    map((activeTab) => activeTab === this),
  );

  private sub: Subscription;

  constructor(private tabsControlService: TabsControlService) {}

  ngOnInit(): void {
    this.sub = this.showContent$.subscribe((show) => (this.isActive = show));

    this.tabsControlService.registerTab(this, this.isActive);
  }

  ngOnDestroy(): void {
    this.tabsControlService.unregisterTab(this);
    this.sub?.unsubscribe();
  }
}
