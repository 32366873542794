import { Directive, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { TInputAppearance, TInputSize } from './input/input.component';

@Directive({})
export abstract class InputBasicDirective<TValue> implements OnInit, OnChanges, ControlValueAccessor {
  @Input() formControlName: string;
  @Input() completed: boolean;
  @Input() name: string = null;
  @Input() appearance: TInputAppearance = 'default';
  @Input() size: TInputSize = 'l';
  @Input() disabled = false;

  @Output() blurInput = new EventEmitter<Event>();

  protected _value$ = new BehaviorSubject<TValue>(null);
  public value$ = this._value$.asObservable();

  protected _disabled$ = new BehaviorSubject<boolean>(false);
  public disabled$ = this._disabled$.asObservable();

  private selfControl: AbstractControl;

  protected constructor(@Optional() private controlContainer: ControlContainer) {}

  ngOnInit() {
    if (this.controlContainer?.control?.get) {
      this.selfControl = this.controlContainer.control.get(this.formControlName);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled) {
      this.setDisabledState(this.disabled);
    }
  }

  onBlur(e: Event) {
    this.onTouch();
    if (this.selfControl) {
      this.selfControl.updateValueAndValidity();
    }

    this.blurInput.emit(e);
  }

  abstract writeValue(value: any);

  setDisabledState(isDisabled: boolean): void {
    this._disabled$.next(isDisabled);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onInputHandler(value: TValue) {
    if (this._disabled$.getValue()) {
      return;
    }
    this._value$.next(value);
    this.onChange(value);
  }

  private onTouch: () => void = () => {};
  protected onChange: (value: TValue) => void = () => {};
}
