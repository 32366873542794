import { TranslateService } from '@project/translate';

export enum EProfileTypeDTO {
  Admin = 'Admin',
  Patient = 'Patient',
  Doctor = 'Doctor',
  HealthCenterAdmin = 'HealthCenterAdmin',
  Receptionist = 'Receptionist',
}

export function getProfileTypeLocalization(profileType: EProfileTypeDTO): string | undefined {
  const map: Record<EProfileTypeDTO, () => string> = {
    [EProfileTypeDTO.Patient]: () => TranslateService.localize('profile-type.patient'),
    [EProfileTypeDTO.Doctor]: () => TranslateService.localize('profile-type.doctor'),
    [EProfileTypeDTO.Admin]: () => TranslateService.localize('profile-type.admin'),
    [EProfileTypeDTO.HealthCenterAdmin]: () => TranslateService.localize('profile-type.hc-admin'),
    [EProfileTypeDTO.Receptionist]: () => TranslateService.localize('profile-type.receptionist'),
  };
  return map[profileType]?.();
}

export function isDoctor(type: EProfileTypeDTO): boolean {
  return type === EProfileTypeDTO.Doctor;
}

export function isPatient(type: EProfileTypeDTO): boolean {
  return type === EProfileTypeDTO.Patient;
}
