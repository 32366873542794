import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { forkJoin, Observable, of } from 'rxjs';
import {
  EObservationMethodDTO,
  IObservation,
  IObservationDataSnapshot,
  IObservationDataSnapshotDTO,
  IObservationDTO,
  IObservationFull,
  IObservationPatientsData,
  IObservationPatientsDataDTO,
  IObservationStatus,
  IObservationStatusDTO,
  ObservationDataSnapshotViewModelFactory,
  ObservationPatientsDataViewModelFactory,
  ObservationStatusViewModelFactory,
  ObservationViewModelFactory,
} from '@project/view-models';
import { environment } from '@env';
import { map, switchMap } from 'rxjs/operators';
import { sortByDecrementFn, TDateISOString, TGuid } from '@core/helpers';
import { MeasurementPreview } from '../../modules/session-details-shared/session-details-shared-patient-observations/session-details-shared-patient-observations.component';

interface ICreateObservationDataDTO {
  type: EObservationMethodDTO;
  patient_id: TGuid;
  date_from: TDateISOString;
  date_to: TDateISOString;
}

interface IUpdateObservationStatusDTO {
  type: EObservationMethodDTO;
  patient_id: TGuid;
  enable: boolean;
}

interface IUpdateObservationStatus {
  patientId: TGuid;
  method: EObservationMethodDTO;
  isEnabled: boolean;
}

export interface ICreateObservationData {
  patientId: TGuid;
  method: EObservationMethodDTO;
  dateFrom: TDateISOString;
  dateTo: TDateISOString;
}

@Injectable({
  providedIn: 'root',
})
export class ObservationsApiProviderService {
  constructor(private apiService: ApiService) {}

  getAllAsHcEmployer(): Observable<IObservation[]> {
    return this.apiService
      .get<IObservationDTO[]>(`${environment.environmentVariables.apiCoreUrl}/doctor/observations`)
      .pipe(map((observations) => observations.map((item) => ObservationViewModelFactory.createFromDTO(item))));
  }

  getByIdAsHcEmployer(id: TGuid): Observable<IObservation> {
    return this.apiService
      .get<IObservationDTO>(`${environment.environmentVariables.apiCoreUrl}/doctor/observations/${id}`)
      .pipe(map((observationDto) => ObservationViewModelFactory.createFromDTO(observationDto)));
  }

  getFullByIdAsHcEmployer(id: TGuid): Observable<IObservationFull> {
    return this.getByIdAsHcEmployer(id).pipe(
      switchMap((observation) =>
        this.getObservationDataSnapshots(observation.id, observation.method).pipe(
          map((data) => ObservationViewModelFactory.createFull(observation, data)),
        ),
      ),
    );
  }

  getFullObservationsByPatientIdAsHcEmployer(id: TGuid): Observable<IObservationFull[]> {
    return this.apiService
      .get<IObservationDTO[]>(`${environment.environmentVariables.apiCoreUrl}/doctor/observations/patients/${id}`)
      .pipe(
        map((observations) => observations.map((item) => ObservationViewModelFactory.createFromDTO(item))),
        switchMap((observations) =>
          observations.length
            ? forkJoin(
                observations.map((observation) =>
                  this.getObservationDataSnapshots(observation.id, observation.method).pipe(
                    map((data) => ObservationViewModelFactory.createFull(observation, data)),
                  ),
                ),
              )
            : of([]),
        ),
      );
  }

  getFullMeasurementByPatientIdAsHcEmployer(id: TGuid): Observable<MeasurementPreview> {
    return this.apiService.get<MeasurementPreview>(
      `${environment.environmentVariables.apiCoreUrl}/doctor/observations/measurements/patients/${id}`,
    );
  }

  getPatientsIdsWhoOnObservation(): Observable<IObservationPatientsData[]> {
    return this.apiService
      .get<IObservationPatientsDataDTO[]>(`${environment.environmentVariables.apiCoreUrl}/hc/observations/availables`)
      .pipe(
        map((observations) => observations.map((item) => ObservationPatientsDataViewModelFactory.createFromDto(item))),
      );
  }

  setPatientObservationStatus(dataArray: IUpdateObservationStatus[]): Observable<TGuid[]> {
    const dataToSend: IUpdateObservationStatusDTO[] = dataArray.map((item) => ({
      type: item.method,
      patient_id: item.patientId,
      enable: item.isEnabled,
    }));

    return this.apiService
      .post(`${environment.environmentVariables.apiCoreUrl}/hc/observations/availables`, dataToSend)
      .pipe(map(() => dataArray.map((item) => item.patientId)));
  }

  createObservationForPatients(dataArray: ICreateObservationData[]): Observable<TGuid[]> {
    const dataToSend: ICreateObservationDataDTO[] = dataArray.map((item) => ({
      type: item.method,
      patient_id: item.patientId,
      date_from: item.dateFrom,
      date_to: item.dateTo,
    }));

    return this.apiService
      .post(`${environment.environmentVariables.apiCoreUrl}/hc/observations`, dataToSend)
      .pipe(map(() => dataArray.map((item) => item.patientId)));
  }

  getObservationFeatureStatus(): Observable<IObservationStatus> {
    return this.apiService
      .get<IObservationStatusDTO>(`${environment.environmentVariables.apiCoreUrl}/hc/observations/config/status`)
      .pipe(map((dto) => ObservationStatusViewModelFactory.createFromDTO(dto)));
  }

  private getObservationDataSnapshots(
    observationId: TGuid,
    method: EObservationMethodDTO,
  ): Observable<IObservationDataSnapshot[]> {
    return this.apiService
      .get<IObservationDataSnapshotDTO[]>(
        `${environment.environmentVariables.apiCoreUrl}/doctor/observations/${observationId}/data`,
      )
      .pipe(
        map((dtoItems) => dtoItems.map((dto) => ObservationDataSnapshotViewModelFactory.createFromDTO(dto, method))),
        map((items) => items.sort((a, b) => sortByDecrementFn(a.createdDate, b.createdDate))),
      );
  }
}
