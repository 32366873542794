import { ISocketMessage } from './common';
import { EServiceSocketMessageTypeDTO } from '../consts/socket-message-type';

export interface IServiceMessageEventDTO extends ISocketMessage {
  type: EServiceSocketMessageTypeDTO;
  chat_id: string;
  user_id: string;
}

export interface IServiceMessageEvent {
  type: EServiceSocketMessageTypeDTO;
  chatId: string;
  userId: string;
}

export class ServiceMessageEventSocketMessageViewModelFactory {
  static createEventMessageFromDTO(dto: IServiceMessageEventDTO): IServiceMessageEvent {
    return {
      chatId: dto.chat_id,
      userId: dto.user_id,
      type: dto.type,
    };
  }
}
