import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TabComponent } from './tab/tab.component';

@Injectable()
export class TabsControlService {
  private _tabs$ = new BehaviorSubject<TabComponent[]>([]);
  private _activeTab$ = new BehaviorSubject<TabComponent>(null);

  public tabs$ = this._tabs$.asObservable();
  public activeTab$ = this._activeTab$.asObservable();

  public registerTab(tab: TabComponent, isActive: boolean) {
    this._tabs$.next(this._tabs$.value.concat([tab]));

    console.log({ isActive, value: this._activeTab$.value });

    if (isActive || !this._activeTab$.value) {
      this._activeTab$.next(tab);
    }
  }

  public unregisterTab(tab: TabComponent) {
    this._tabs$.next(this._tabs$.value.filter((t) => t !== tab));
  }

  public setTabActive(tab: TabComponent | null) {
    this._activeTab$.next(tab);
  }
}
