import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CloseRequisitionsService } from './close-requisitions.service';
import { AskToCloseRequisitionsOverlayComponent } from './ask-to-close-requisitions-overlay/ask-to-close-requisitions-overlay.component';
import { ButtonModule, CardModule, ModalDialogLayoutModule } from '@lib/ui';
import { TranslateModule } from '@project/translate';

@NgModule({
  declarations: [AskToCloseRequisitionsOverlayComponent],
  imports: [CommonModule, TranslateModule, ModalDialogLayoutModule, ButtonModule, CardModule],
  providers: [CloseRequisitionsService],
})
export class CloseRequisitionsModule {}
