import { AgoraResponse, RTCTokenResponse, VoxeetResponse, AgoraData, VoxeetData } from '../definitions';

export const VideoconferenceTransformer = {
  agoraTransformer,
  voxeetTransformer,
};

function agoraTransformer(response: RTCTokenResponse): AgoraData {
  const data = response.data as AgoraResponse;
  return {
    provider: response.provider,
    appId: data.app_id,
    channel: data.channel_name,
    uid: data.uid,
    token: data.rtc_token,
  };
}

function voxeetTransformer(response: RTCTokenResponse): VoxeetData {
  const data = response.data as VoxeetResponse;
  return {
    provider: response.provider,
    accessToken: data.access_token,
    refreshToken: data.refresh_token,
    expiresIn: data.expires_in,
  };
}
