import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ISelectOption } from '@lib/ui/form-elements';
import {
  EScheduleDayDTO,
  EScheduleRuleTypeDTO,
  getDayLocalizedLabel,
  getScheduleRuleTypeLocalizedLabel,
} from '@project/view-models';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { getEnumValues } from '@core/helpers';
import { Observable } from 'rxjs';
import { INPUT_MASKS, INPUT_PLACEHOLDERS } from '@project/shared';
import { ScheduleGroupFormModelService } from '../schedule-group-form-model.service';
import { DoctorSpecializationDataProvider, TimeslotSizesDataProvider } from '@project/data-providers';

@Component({
  selector: 'app-scheduler-rule-nested-form',
  templateUrl: './scheduler-rule-nested-form.component.html',
  styleUrls: ['./scheduler-rule-nested-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchedulerRuleNestedFormComponent implements OnInit {
  public readonly typeOptions: ISelectOption[] = [
    { value: EScheduleRuleTypeDTO.Weeks, label: getScheduleRuleTypeLocalizedLabel(EScheduleRuleTypeDTO.Weeks) },
    { value: EScheduleRuleTypeDTO.Days, label: getScheduleRuleTypeLocalizedLabel(EScheduleRuleTypeDTO.Days) },
  ];

  @Input() parentFormGroup: FormGroup;

  public daysOfWeekValue$: Observable<EScheduleDayDTO[]>;
  public isWeekSelected$: Observable<boolean>;
  public needToShowStartPoint$: Observable<boolean>;

  public daysOfWeek: { label: string; value: EScheduleDayDTO }[] = getEnumValues(EScheduleDayDTO).map((day) => ({
    label: getDayLocalizedLabel({ day, useFull: false }),
    value: day,
  }));

  public doctorSpecializationOptions: ISelectOption[] = this.doctorSpecializationDataProvider.getSelectOptions();
  public timeslotSizeOptions$: Observable<ISelectOption[]> = this.timeslotSizesDataProvider.sizesSelectOptions$;

  public readonly dateInputMask = INPUT_MASKS.date;
  public readonly dateInputPlaceholder = INPUT_PLACEHOLDERS.date;

  public readonly timeInputMask = INPUT_MASKS.timeAs24hours;
  public readonly timeInputPlaceholderFrom = INPUT_PLACEHOLDERS.timeAs24hoursMorning;
  public readonly timeInputPlaceholderTo = INPUT_PLACEHOLDERS.timeAs24hoursEvening;

  constructor(
    private doctorSpecializationDataProvider: DoctorSpecializationDataProvider,
    private timeslotSizesDataProvider: TimeslotSizesDataProvider,
    private scheduleGroupFormService: ScheduleGroupFormModelService,
  ) {}

  get daysOfWeekControl(): FormControl {
    return this.scheduleGroupFormService.getRuleDaysOfWeekControl(this.parentFormGroup);
  }

  get workingHoursControl(): FormArray {
    return this.scheduleGroupFormService.getRuleWorkingHoursControl(this.parentFormGroup);
  }

  get workingHoursControlFormGroups(): FormGroup[] {
    return this.workingHoursControl.controls as FormGroup[];
  }

  ngOnInit() {
    this.daysOfWeekValue$ = this.scheduleGroupFormService.daysOfWeekForRuleGroup$(this.parentFormGroup);
    this.isWeekSelected$ = this.scheduleGroupFormService.isWeekSelectedForRuleGroup$(this.parentFormGroup);
    this.needToShowStartPoint$ = this.scheduleGroupFormService.isSkipPeriodExistsForRuleGroup$(this.parentFormGroup);
  }

  public addWorkingHoursRuleTemplate() {
    this.scheduleGroupFormService.addWorkingHoursGroupForRule(this.parentFormGroup);
  }

  public removeWorkingHoursRuleTemplate(index: number) {
    this.scheduleGroupFormService.removeWorkingHoursGroupForRule(this.parentFormGroup, index);
  }

  onDayCheckedChange(day: EScheduleDayDTO, isChecked: boolean) {
    this.scheduleGroupFormService.setDayCheckedStateForRule(this.parentFormGroup, day, isChecked);
  }
}
