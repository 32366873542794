import { ISocketMessage } from './common';
import { ESocketMessageTypeDTO } from '../consts/socket-message-type';
import { ChatMessageViewModelFactory, IChatMessage, IChatMessageDTO } from '../chat-message.view-model';

export interface IChatMessageEventDTO extends ISocketMessage, IChatMessageDTO {
  type: ESocketMessageTypeDTO.ChatMessage;
}

export class ChatMessageEventSocketMessageViewModelFactory {
  static createMessageFromDTO(dto: IChatMessageEventDTO): IChatMessage {
    return ChatMessageViewModelFactory.createMessageFromDTO(dto);
  }
}
