import { DateFormatter, TDateISOString, TEmail, TGuid } from '@core/helpers';
import { ProfileHelper } from './helpers/profile.helper';
import { EProfileTypeDTO, getProfileTypeLocalization, isDoctor, isPatient } from './consts/profile-type';
import { EGenderDTO, getGenderLocalization } from './consts/gender';
import { EDoctorSpecializationDTO, getDoctorSpecializationLocalization } from './consts/doctor-specialization';
import { EDoctorStatusDTO } from './consts/doctor-status';
import { IProfileTag } from './profile-tag.view-model';

export interface IProfileShortDTO {
  id: TGuid;
  first_name: string;
  last_name: string;
  profile_type: EProfileTypeDTO;
  email?: TEmail;
  specialization?: EDoctorSpecializationDTO;
  license: string | null;
  tags: IProfileTag[];
}

export interface IProfileDTO extends IProfileShortDTO {
  mothers_name?: string;
  age: number;
  gender: EGenderDTO;
  license: string | null;
  cpf_number: string | null;
  photo_url: string | null;
  doctor_status?: EDoctorStatusDTO;
}

export interface IProfileFullDTO extends IProfileDTO {
  date_of_birth: TDateISOString | null;
  tags: IProfileTag[];
}

export interface IProfileShort {
  id: TGuid;
  firstName: string;
  lastName: string;
  fullName: string;
  profileType: EProfileTypeDTO;
  profileTypeLabel: string;
  email: TEmail | null;
  license: string;
  isDoctor: boolean;
  isPatient: boolean;
  specialization: EDoctorSpecializationDTO | null;
  specializationLabel: string | null;
  tags: IProfileTag[];
}

export interface IProfile extends IProfileShort {
  mothersName?: string;
  age: number;
  gender: EGenderDTO;
  genderLabel: string;
  isDoctor: boolean;
  isPatient: boolean;
  doctorStatus: EDoctorStatusDTO;
  cpfNumber?: string;
  photoUrl?: any;
  phone?: string;
  summaryInfo: string[];
}

export interface IProfileFull extends IProfile {
  dateOfBirth: Date | null;
  tags: IProfileTag[];
}

export class ProfileViewModelFactory {
  static createFull(dto: IProfileFullDTO): IProfileFull {
    return {
      ...this.create(dto),
      email: dto.email,
      dateOfBirth: dto.date_of_birth ? DateFormatter.stringToDate(dto.date_of_birth, { ignoreTimezones: true }) : null,
      tags: dto.tags,
    };
  }

  static create(dto: IProfileDTO): IProfile {
    let summaryInfo = [];

    if (dto.profile_type === EProfileTypeDTO.Patient) {
      summaryInfo = ProfileHelper.getPatientSummaryInfo({
        age: dto.age,
        gender: dto.gender,
      });
    }

    if (dto.profile_type === EProfileTypeDTO.Doctor) {
      summaryInfo = ProfileHelper.getDoctorSummaryInfo({
        specialization: dto.specialization,
      });
    }

    return {
      ...this.createShort(dto),
      mothersName: dto.mothers_name,
      age: dto.age,
      cpfNumber: dto.cpf_number,
      gender: dto.gender,
      genderLabel: getGenderLocalization(dto.gender),
      license: dto.license,
      photoUrl: dto.photo_url,
      doctorStatus: dto.doctor_status,
      summaryInfo,
    };
  }

  static createShort(dto: IProfileShortDTO): IProfileShort {
    const profileTypeLabel = getProfileTypeLocalization(dto.profile_type);

    return {
      firstName: dto.first_name,
      id: dto.id,
      lastName: dto.last_name,
      fullName: ProfileHelper.getFullName(dto.first_name, dto.last_name, profileTypeLabel),
      profileType: dto.profile_type,
      profileTypeLabel,
      email: dto.email || null,
      license: dto.license,
      specialization: dto.specialization,
      specializationLabel: getDoctorSpecializationLocalization(dto.specialization),
      isDoctor: isDoctor(dto.profile_type),
      isPatient: isPatient(dto.profile_type),
      tags: dto.tags,
    };
  }

  static createShortMock(id?: string): IProfileShort {
    return {
      profileType: EProfileTypeDTO.Patient,
      fullName: 'Test Patient',
      lastName: 'Patient',
      firstName: 'Test',
      id: id || Date.now() + '',
      email: 'test@test.com',
      license: '1111',
      specializationLabel: '',
      profileTypeLabel: 'Patient',
      isDoctor: false,
      isPatient: true,
      specialization: null,
      tags: [],
    };
  }
}
