import { Injectable } from '@angular/core';
import {
  IPagedItemsProceedResult,
  ServerSidePagedItemsProviderService,
  PagingApiMapperHelper,
} from '@core/data-providers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IRequisitionLoginLogs } from '@project/view-models';
import { RequisitionLoginLogsApiProviderService } from './api-providers/requisition-login-logs-api-provider.service';

export interface IRequisitionLoginLogsFiltersParams {}

@Injectable()
export class RequisitionLoginLogsServerSideItemsProviderService extends ServerSidePagedItemsProviderService<
  IRequisitionLoginLogs,
  IRequisitionLoginLogsFiltersParams,
  null
> {
  constructor(private requisitionLoginLogsApiProviderService: RequisitionLoginLogsApiProviderService) {
    super();
  }

  protected getDefaultFilters(): Partial<IRequisitionLoginLogsFiltersParams> {
    return {};
  }

  protected proceedPagedItemsLoading(page, itemsPerPage): Observable<IPagedItemsProceedResult<IRequisitionLoginLogs>> {
    return this.requisitionLoginLogsApiProviderService
      .getPaged(page, itemsPerPage, this._filtersState$.value)
      .pipe(
        map((result) => PagingApiMapperHelper.mapPagingApiResponseToProceedResult<IRequisitionLoginLogs>(page, result)),
      );
  }
}
