import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class PasswordApiProvider {
  constructor(private apiService: ApiService) {}

  setPassword(password: string): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiAuthUrl}/profile/password/set`, { password });
  }

  changePassword(oldPassword: string, newPassword: string): Observable<void> {
    const data = {
      old_password: oldPassword,
      password: newPassword,
    };

    return this.apiService.post(`${environment.environmentVariables.apiAuthUrl}/profile/password/change`, data);
  }
}
