<app-aside-page-section [fillSpace]="true" [scrollable]="true" [paddings]="true">
  <button
    type="button"
    class="aside-close-button"
    [title]="'actions.close' | translate"
    (click)="return()"
    *ngIf="showReturn"
  >
    <lib-icon type="arrow-angle-to-top-grey"></lib-icon>
  </button>
  <ng-container *ngIf="(medicalRecords$ | async)?.length; else emptyMedicalRecordsTemplate">
    <lib-card class="medical-reports">
      <lib-card-body class="section-top">
        <h2 class="h4 col">{{ "sidebar.menu-labels.encounter-history" | translate }}</h2>
      </lib-card-body>

      <ng-container *ngFor="let medicalRecord of medicalRecords$ | async">
        <lib-card-body>
          <div class="section btn-title-infos">
            <button
              libButton
              appearance="primary"
              *ngIf="canPrintMedicalRecord"
              [disabled]="!medicalRecord.pdf"
              (click)="onClickDownloadPDF(medicalRecord.pdf)"
            >
              Baixar
            </button>
            <div>
              <p class="section-title --no-margin">
                {{ medicalRecord.requisition.doctor.first_name }}
                {{ medicalRecord.requisition.doctor.last_name }}
              </p>

              <small class="text-color-muted">
                {{ medicalRecord.requisition.doctor.specialization }} -
                {{ medicalRecord.create_at | date: "medium" }}
              </small>
            </div>
          </div>

          <div class="section --notes">
            <p class="section-title">{{ "sessions-details.anamnesis" | translate }}</p>
            <div appFormattedText [rawText]="medicalRecord.anamnesis || 'Sem notas'"></div>
          </div>

          <div class="section" *ngIf="medicalRecord.medical_record_cid.length > 0">
            <p class="section-title --no-margin">CID</p>

            <table>
              <thead>
                <tr>
                  <th class="text-color-muted">{{ "nouns.code" | translate }}</th>
                  <th class="text-color-muted">{{ "nouns.description" | translate }}</th>
                  <th class="text-color-muted">{{ "nouns.status" | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let cid of medicalRecord.medical_record_cid">
                  <td>{{ cid.code }}</td>
                  <td>{{ cid.description }}</td>
                  <td>{{ cid.cid_status }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="section" *ngIf="medicalRecord.medical_record_tuss.length > 0">
            <p class="section-title --no-margin">TUSS</p>

            <table>
              <thead>
                <tr>
                  <th class="text-color-muted">{{ "nouns.code" | translate }}</th>
                  <th class="text-color-muted">{{ "nouns.description" | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let tuss of medicalRecord.medical_record_tuss">
                  <td>{{ tuss.code }}</td>
                  <td>{{ tuss.procedure }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="section">
            <span class="section-title">{{ "sessions-details.outcome" | translate }}: </span>
            {{ medicalRecord.out_comes }}
          </div>
        </lib-card-body>
      </ng-container>
    </lib-card>
  </ng-container>

  <ng-template #emptyMedicalRecordsTemplate>
    <div *ngIf="!!createMedicalRecordURL" class="empty-container">
      <p class="onboarding-message">
        {{ "empty-message.no-medical-records" | translate }}
      </p>

      <div class="empty-container-btn">
        <a libButton [routerLink]="doctorEncounterHistoryRoutes.asideMedicalRecord">
          {{ "empty-message.cta-medical-records" | translate }}
        </a>
      </div>
    </div>
  </ng-template>

  <ng-container *ngIf="(summaryNotes$ | async).length; else emptyMessageTemplate">
    <lib-card>
      <lib-card-body>
        <h2 class="h4">{{ "nouns.notes" | translate }}</h2>
      </lib-card-body>

      <ng-container *ngFor="let note of summaryNotes$ | async">
        <lib-card-body>
          <div class="doctor-info mb-6">
            <p *ngIf="note.authorProfile">
              <b>{{ note.authorProfile.fullName }}</b>
            </p>
            <small class="text-color-muted">
              <i>
                <span *ngIf="note.authorProfile">{{ note.authorProfile.specializationLabel }},</span>
                {{ note.updatedAt | date: "medium" }}
              </i>
            </small>

            <lib-icon
              *ngIf="editCreateNotes"
              class="iconEdit"
              (click)="editSummaryNote(note)"
              [type]="'edit'"
            ></lib-icon>
            <lib-icon
              *ngIf="showHistory"
              class="iconHistory"
              [type]="'history'"
              (click)="openHistory(note.id)"
            ></lib-icon>
          </div>
          <div appFormattedText [rawText]="note.note"></div>

          <ng-container *ngIf="(summaryNoteHistoryId | async) === note.id">
            <lib-card class="summary-note-history">
              <lib-card-body>
                <h2 class="h4">{{ "nouns.notes-history" | translate }}</h2>
              </lib-card-body>

              <ng-container *ngIf="(summaryNoteHistory$ | async).length === 0">
                <lib-card-body>
                  <div
                    class="text-color-muted"
                    appFormattedText
                    [rawText]="'nouns.notes-history-empty' | translate"
                  ></div>
                </lib-card-body>
              </ng-container>

              <ng-container *ngFor="let history of summaryNoteHistory$ | async">
                <lib-card-body>
                  <div class="doctor-info mb-6">
                    <small class="text-color-muted"
                      ><i> {{ history.createdAt | date: "medium" }}</i></small
                    >
                  </div>
                  <div appFormattedText [rawText]="history.note"></div>
                </lib-card-body>
              </ng-container>
            </lib-card>
          </ng-container>
        </lib-card-body>
      </ng-container>
    </lib-card>
  </ng-container>

  <ng-template #emptyMessageTemplate>
    <p class="onboarding-message">{{ "empty-message.no-summary-notes" | translate }}</p>
  </ng-template>
</app-aside-page-section>

<form *ngIf="canCreateNotes" [formGroup]="form" (ngSubmit)="doDirtySubmit()">
  <input type="hidden" formControlName="id" />
  <label>
    <textarea
      formControlName="message"
      autoSized
      [autoSizeValueToCheck]="form.value.message"
      [rows]="1"
      [placeholder]="'summary-notes.form.placeholder' | translate"
    ></textarea>
  </label>
  <button libButton appearance="primary" type="submit" [disabled]="form.disabled" [isPending]="submitting$ | async">
    {{ editNotes ? ("actions.edit" | translate) : ("actions.create" | translate) }}
  </button>
</form>
