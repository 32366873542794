export interface ITimeSlot {
  startTime: Date;
  endTime: Date;
  state: ETimeSlotState;
  content: string;
  actions?: ITimeSlotAction[];
}

export interface ITimeSlotProcessed extends ITimeSlot {
  showEndTime: boolean;
}

export enum ETimeSlotState {
  Free = 'free',
  Busy = 'busy',
  Unavailable = 'unavailable',
}

export interface ITimeSlotAction {
  name: string;
  onClick: () => void;
  disabled?: boolean;
}
