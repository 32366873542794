import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ISocialButton, TSocialButtonAppearance, TSocialButtonSize } from '../social-button';

@Component({
  selector: 'app-facebook-social-auth-button',
  templateUrl: './facebook-social-auth-button.component.html',
  styleUrls: ['./facebook-social-auth-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacebookSocialAuthButtonComponent implements OnInit, ISocialButton {
  @Output() clickSocial: EventEmitter<MouseEvent> = new EventEmitter();
  @Input() disabled: boolean;
  @Input() appearance: TSocialButtonAppearance = 'default';
  @Input() size: TSocialButtonSize = 'm';
  @HostBinding('class.muted') @Input() muted: boolean;

  constructor() {}

  ngOnInit(): void {}
}
