<div *ngFor="let invalidItemKeyValue of formErrors | keyvalue">
  <ng-container *ngIf="invalidItemKeyValue.value | keyvalue as errorsKeyValue">
    <ng-container *ngIf="errorsKeyValue.length === 1; else multiErrorField">
      {{ "nouns.field" | translate }} <b>{{ controlLabelsMap[invalidItemKeyValue.key] }}</b
      >: <span class="text-danger">{{ errorsKeyValue[0].value }}</span>
    </ng-container>
  </ng-container>
  <ng-template #multiErrorField>
    <p>
      {{ "nouns.field" | translate }} <b>{{ controlLabelsMap[invalidItemKeyValue.key] }}</b>
    </p>
    <ul>
      <li *ngFor="let errorKeyValue of invalidItemKeyValue.value | keyvalue">
        <b>{{ errorKeyValue.value }}</b>
      </li>
    </ul>
  </ng-template>
</div>
