export function toFixedIfDecimal(value: number, digits: number): number {
  if (Math.round(value) === value) {
    return value;
  }

  return parseFloat(value.toFixed(digits));
}

export function fillWithZeros(value: number, size: number): string {
  if (size < 1) {
    return value.toString();
  }
  return ('0'.repeat(size) + value).slice(-size);
}
