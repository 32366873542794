import { ComponentFactoryResolver, Inject, Injectable, Injector } from '@angular/core';
import { ComponentType, Overlay, OverlayContainer } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ModalBackdropComponent } from './modal-backdrop/modal-backdrop.component';
import { debounceTime, share, take, takeUntil } from 'rxjs/operators';
import { IModalComponent } from './modal-component.interface';
import { merge, Observable, Subject } from 'rxjs';
import { ConfirmComponent } from './confirm/confirm.component';
import { ModalWindowComponent } from './modal-window/modal-window.component';
import { CustomOverlayContainer } from './custom-overlay-container';
import { IModalWindowConfig } from './modal-window/modal-window-component.types';
import { TranslateService } from '@project/translate';

export interface IModalComponentRef<T> {
  instance: T;
  close$: Observable<any>;
  submit$: Observable<any>;
  close: () => void;
}

export interface IModalWindowComponentRef<T> extends IModalComponentRef<T> {
  requestClose: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class ModalOverlayService {
  private openedModals: IModalComponentRef<any>[] = [];
  private currentConfirmDialogRef: IModalComponentRef<ConfirmComponent>;

  constructor(
    private overlay: Overlay,
    private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(OverlayContainer) private overlayContainer: CustomOverlayContainer,
  ) {}

  confirm(message: string): Observable<any> {
    if (this.currentConfirmDialogRef) {
      this.currentConfirmDialogRef.close();
    }

    const ref: IModalComponentRef<ConfirmComponent> = this.openOverlay(ConfirmComponent, {
      message,
    });

    this.currentConfirmDialogRef = ref;
    return ref.submit$.pipe(takeUntil(ref.close$));
  }

  openOverlay<T extends IModalComponent<any>, Data extends object = object>(
    component: ComponentType<T>,
    data: Data = null,
    backdropParams?: {
      fullScreen?: boolean;
      closeByEsc?: boolean;
      closeByBackdropClick?: boolean;
    },
  ): IModalComponentRef<T> {
    const overlayRef = this.overlay.create({
      width: '100%',
      height: '100%',
    });

    this.overlayContainer.freezeBody();

    const backdropPortal = new ComponentPortal(ModalBackdropComponent, null, null, this.componentFactoryResolver);
    const backdropRef = overlayRef.attach(backdropPortal);
    const backdropInstance = backdropRef.instance;

    backdropInstance.fullScreen = backdropParams?.fullScreen ?? backdropInstance.fullScreen;
    backdropInstance.closeByEsc = backdropParams?.closeByEsc ?? backdropInstance.closeByEsc;
    backdropInstance.closeByBackdropClick =
      backdropParams?.closeByBackdropClick ?? backdropInstance.closeByBackdropClick;

    const factory = this.componentFactoryResolver.resolveComponentFactory(component);
    const componentRef = backdropInstance.viewContainerRef.createComponent(factory);
    const componentInstance = componentRef.instance;

    Object.keys(data || {}).forEach((propName) => {
      if (componentInstance.hasOwnProperty(propName)) {
        componentInstance[propName] = data[propName];
      }
    });

    const unsubscribe$ = new Subject();
    const destroy$ = new Subject();

    const modalRef: IModalComponentRef<T> = {
      instance: componentInstance,
      close$: merge(destroy$, backdropInstance.close$, componentInstance.close$).pipe(takeUntil(unsubscribe$), share()),
      submit$: componentInstance.submit$.pipe(takeUntil(unsubscribe$), share()),
      close() {
        destroy$.next();
      },
    };

    this.openedModals.push(modalRef);

    merge(modalRef.close$, modalRef.submit$)
      .pipe(
        debounceTime(0), // to close ref asynchronously only after external subscriptions were called
        take(1),
        takeUntil(unsubscribe$),
      )
      .subscribe(() => {
        unsubscribe$.next();
        unsubscribe$.complete();
        destroy$.complete();
        overlayRef.detach();

        this.openedModals = this.openedModals.filter((r) => r !== modalRef);

        if (!this.openedModals.length) {
          this.overlayContainer.unfreezeBody();
        }
      });

    return modalRef;
  }

  openWindow<C, Data extends object = object>(
    component: ComponentType<C>,
    config: IModalWindowConfig,
    data: Data = null,
    injector?: Injector,
    confirmClose?: boolean,
  ): IModalComponentRef<C> {
    const overlayRef = this.overlay.create();
    const windowPortal = new ComponentPortal(ModalWindowComponent, null, injector, this.componentFactoryResolver);
    const windowRef = overlayRef.attach(windowPortal);

    overlayRef.overlayElement.style.pointerEvents = 'none';
    overlayRef.overlayElement.style.zIndex = 'initial';

    const windowInstance = windowRef.instance;
    windowInstance.config = config;

    const factory = this.componentFactoryResolver.resolveComponentFactory(component);
    const componentRef = windowInstance.viewContainerRef.createComponent(factory);
    const componentInstance = componentRef.instance;
    Object.keys(data || {}).forEach((propName) => {
      if (componentInstance.hasOwnProperty(propName)) {
        componentInstance[propName] = data[propName];
      }
    });

    this.overlayContainer.freezeBody();

    const modalRef: IModalWindowComponentRef<C> = {
      close: () => windowInstance.close$.emit(),
      requestClose: () => windowInstance.closeRequestFn(),
      close$: windowInstance.close$,
      submit$: windowInstance.close$,
      instance: componentInstance,
    };
    this.openedModals.push(modalRef);

    const unsubscribe$ = new Subject();
    const destroy$ = new Subject();
    merge(windowInstance.close$)
      .pipe(
        debounceTime(0), // to close ref asynchronously only after external subscriptions were called
        takeUntil(unsubscribe$),
      )
      .subscribe(() => {
        if (confirmClose) {
          this.confirm(TranslateService.localize('questions.are-you-sure?')).subscribe(() => {
            unsubscribe$.next();
            unsubscribe$.complete();
            destroy$.complete();
            this.openedModals = this.openedModals.filter((ref) => ref !== modalRef);
            this.overlayContainer.unfreezeBody();
            overlayRef.detach();
            windowInstance.close$.unsubscribe();
          });
        } else {
          unsubscribe$.next();
          unsubscribe$.complete();
          destroy$.complete();
          this.openedModals = this.openedModals.filter((ref) => ref !== modalRef);
          this.overlayContainer.unfreezeBody();
          overlayRef.detach();
        }
      });

    return modalRef;
  }

  public closeAllOverlays() {
    this.openedModals.forEach((modal) => modal.close());
    this.openedModals = [];
  }
}
