import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export abstract class ActionsManagerService<TAction extends string> {
  private readonly _actionInProgress$ = new BehaviorSubject<TAction>(null);
  public readonly actionInProgress$: Observable<TAction> = this._actionInProgress$.asObservable();

  public readonly hasActionInProgress$: Observable<boolean> = this.actionInProgress$.pipe(map((action) => !!action));

  protected abstract readonly actionsHandlersMap: Record<TAction, (...args) => void>;

  protected constructor() {}

  get actionInProgress(): TAction | null {
    return this._actionInProgress$.value;
  }

  public dispatchAction(action: TAction, ...args) {
    const handler = this.actionsHandlersMap[action];

    if (!handler) {
      throw new Error('No handler for action: ' + action);
    } else {
      handler(...args);
    }
  }

  protected setActionInProgress(action: TAction) {
    this._actionInProgress$.next(action);
  }

  protected clearActionInProgress() {
    this._actionInProgress$.next(null);
  }
}
