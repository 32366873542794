import {
  IPagedItemsProceedResult,
  IPagedItemsSortingState,
  PagingApiMapperHelper,
  ServerSidePagedItemsProviderService,
} from '@core/data-providers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EProfileTypeDTO, IInvitationWithProfiles } from '@project/view-models';
import { UserInviteApiProviderService } from './api-providers/user-invite-api-provider.service';
import { Injectable } from '@angular/core';
import { EInvitationStatusDTO } from '@project/view-models';

export interface IInvitationsFilters {
  status?: EInvitationStatusDTO;
  role?: EProfileTypeDTO[];
  search?: string;
}

@Injectable()
export class InvitesServerSideItemsProvider extends ServerSidePagedItemsProviderService<
  IInvitationWithProfiles,
  IInvitationsFilters,
  null
> {
  constructor(private userInviteApiProviderService: UserInviteApiProviderService) {
    super();
  }

  protected getDefaultFilters(): IInvitationsFilters {
    return {};
  }

  protected proceedPagedItemsLoading(
    page: number,
    itemsPerPage: number,
    filters: IInvitationsFilters,
    sorting: IPagedItemsSortingState<null>,
  ): Observable<IPagedItemsProceedResult<IInvitationWithProfiles>> {
    return this.userInviteApiProviderService
      .getPagedWithProfiles(page, itemsPerPage, filters)
      .pipe(map((result) => PagingApiMapperHelper.mapPagingApiResponseToProceedResult(page, result)));
  }
}
