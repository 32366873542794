import { Injectable } from '@angular/core';
import { BroadcastEventsService } from '@core/services';
import { NotificationsService } from '@lib/notifications';
import { Observable, of, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { TGuid } from '@core/helpers';
import { concatMap, filter, map, takeUntil } from 'rxjs/operators';
import { ChatEnterEvent, ChatLeaveEvent, EUserPermission } from '@project/shared';
import { ETaskStatusDTO, IChatMessage, IRequisition } from '@project/view-models';
import { IFeatureService, RequisitionHelpersService, UserProfileDataService } from '@project/services';
import { TranslateService } from '@project/translate';
import { ChatApiProviderService, SocketMessagesDataProviderService } from '@project/data-providers';
import { RoutesBuilderService } from '@app/config';

@Injectable({
  providedIn: 'root',
})
export class InAppNotificationsService implements IFeatureService {
  private activeChatIds: TGuid[] = [];

  private destroy$ = new Subject();

  constructor(
    private broadcastEventsService: BroadcastEventsService,
    private notificationService: NotificationsService,
    private router: Router,
    private socketMessagesProviderService: SocketMessagesDataProviderService,
    private userProfileDataService: UserProfileDataService,
    private chatApiProviderService: ChatApiProviderService,
    private requisitionHelpersService: RequisitionHelpersService,
  ) {}

  public initialise(): Observable<void> {
    this.handleChatMessages();
    return of(null);
  }

  public destroy(): Observable<void> {
    this.destroy$.next();
    return of(null);
  }

  private handleChatMessages() {
    this.trackActiveChats();

    this.socketMessagesProviderService.chatMessage$
      .pipe(
        filter((message) => this.userProfileDataService.profile.id !== message.userId),
        filter((message) => !this.activeChatIds.includes(message.chatId)),
        concatMap(
          (message): Observable<[IChatMessage, IRequisition]> =>
            this.chatApiProviderService.getRequisitionByChatId(message.chatId).pipe(map((chat) => [message, chat])),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe(([message, requisition]) => {
        this.notificationService.info({
          title: this.requisitionHelpersService.getRequisitionParticipantForCurrentUser(requisition)?.fullName,
          message: message.text,
          actions: [
            {
              title: TranslateService.localize('actions.open-chat'),
              onClick: () => this.openChat(requisition),
            },
          ],
        });
      });
  }

  private trackActiveChats() {
    this.broadcastEventsService
      .eventsByType$(ChatEnterEvent)
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => this.activeChatIds.push(event.chatId));

    this.broadcastEventsService
      .eventsByType$(ChatLeaveEvent)
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        this.activeChatIds = this.activeChatIds.filter((chatId) => event.chatId !== chatId);
      });
  }

  private openChat(requisition: IRequisition) {
    if (this.userProfileDataService.hasPermissions([EUserPermission.haveReceptionistsSession])) {
      const taskId = requisition.tasks.filter((task) => task.status === ETaskStatusDTO.Active)[0]?.id;
      if (!taskId) {
        console.warn(`Task for requisition ${requisition.id} not found`);
        return;
      }

      const route = RoutesBuilderService.ASIDE_NAVIGATION.asideRoute({
        route: [taskId, RoutesBuilderService.RECEPTIONIST_SESSION_DETAILS.baseChat],
        relativeRoute: RoutesBuilderService.SESSION_BY_RECEPTIONIST.root(),
      });
      this.router.navigate(route);
      return;
    }

    if (
      this.userProfileDataService.hasAtLeastOnePermission([
        EUserPermission.havePatientSession,
        EUserPermission.haveDoctorSession,
      ])
    ) {
      this.router.navigate(RoutesBuilderService.ASIDE_NAVIGATION.asideChat(requisition.id));
      return;
    }
  }
}
