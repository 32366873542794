import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@project/translate';
import { DateFormatter, DEFAULT_DATE_FORMAT, LibValidators, TGuid } from '@core/helpers';
import {
  EDoctorSpecializationDTO,
  EGenderDTO,
  getDoctorSpecializationLocalization,
  IDoctorToInvite,
  License,
} from '@project/view-models';

export interface IDoctorInviteFormModel {
  firstName: string;
  lastName: string;
  email: string;
  gender: EGenderDTO;
  specialization: EDoctorSpecializationDTO;
  licenses?: License[];
  phone?: string;
  dateOfBirth?: string;
  tag?: TGuid;
  cpf: string;
}

export class DoctorInviteUtils {
  static getDoctorInviteFromDeclaration(fb: FormBuilder) {
    return {
      email: ['', [LibValidators.email(TranslateService.localize('validations.invalid-email'))]],
      gender: [
        null,
        [
          LibValidators.required(TranslateService.localize('validations.required')),
          LibValidators.enumValue(EGenderDTO, TranslateService.localize('validations.not-allowed')),
        ],
      ],
      firstName: ['', [LibValidators.required(TranslateService.localize('validations.required'))]],
      lastName: ['', [LibValidators.required(TranslateService.localize('validations.required'))]],
      cpf: ['', [LibValidators.required(TranslateService.localize('validations.required'))]],
      specialization: [
        '',
        [
          LibValidators.required(TranslateService.localize('validations.required')),
          LibValidators.enumValue(EDoctorSpecializationDTO, TranslateService.localize('validations.not-allowed')),
        ],
      ],
      dateOfBirth: ['', [LibValidators.date(DEFAULT_DATE_FORMAT, TranslateService.localize('validations.invalid'))]],
      phone: [],
      licenses: fb.array([]),
      tag: [],
    };
  }

  static getDoctorInviteFromDeclarationImport(fb: FormBuilder) {
    return {
      email: ['', [LibValidators.email(TranslateService.localize('validations.invalid-email'))]],
      gender: [
        null,
        [
          LibValidators.required(TranslateService.localize('validations.required')),
          LibValidators.enumValue(EGenderDTO, TranslateService.localize('validations.not-allowed')),
        ],
      ],
      firstName: ['', [LibValidators.required(TranslateService.localize('validations.required'))]],
      lastName: ['', [LibValidators.required(TranslateService.localize('validations.required'))]],
      specialization: [
        '',
        [
          LibValidators.required(TranslateService.localize('validations.required')),
          LibValidators.enumValue(EDoctorSpecializationDTO, TranslateService.localize('validations.not-allowed')),
        ],
      ],
      dateOfBirth: ['', [LibValidators.date(DEFAULT_DATE_FORMAT, TranslateService.localize('validations.invalid'))]],
      phone: [],
    };
  }

  static createDoctorInviteDto(formData: IDoctorInviteFormModel): IDoctorToInvite {
    return {
      email: formData.email ? formData.email.trim() : null,
      first_name: formData.firstName.trim(),
      last_name: formData.lastName.trim(),
      gender: formData.gender,
      phone: formData.phone ? formData.phone.trim().replace(/[)(-]/g, '') : null,
      date_birth: formData.dateOfBirth
        ? DateFormatter.stringToDate(formData.dateOfBirth.trim().replace(/[/-]/g, '.'), { format: DEFAULT_DATE_FORMAT })
        : null,
      specialization: formData.specialization || null,
      specializationLabel: formData.specialization
        ? getDoctorSpecializationLocalization(formData.specialization)
        : null,
      licenses: formData.licenses ? formData.licenses : null,
      tag: formData.tag,
      cpf: formData.cpf,
    };
  }
}
