import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { environment } from '@env';
import {
  EQuestionnaireTypeDTO,
  IQuestionnaire,
  QuestionnaireViewModelFactory,
  IQuestionnaireDTO,
} from '@project/view-models';
import { map } from 'rxjs/operators';
import { TGuid } from '@core/helpers';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireApiProviderService {
  constructor(private apiService: ApiService) {}

  getAll(): Observable<IQuestionnaire[]> {
    return this.apiService
      .get<IQuestionnaireDTO[]>(`${environment.environmentVariables.apiCoreUrl}/Questionnaire`)
      .pipe(map((items) => items.map((item) => QuestionnaireViewModelFactory.createFromDTO(item))));
  }

  getById(id: TGuid): Observable<IQuestionnaire> {
    return this.apiService
      .get<IQuestionnaireDTO>(`${environment.environmentVariables.apiCoreUrl}/Questionnaire/${id}`)
      .pipe(map((item) => QuestionnaireViewModelFactory.createFromDTO(item)));
  }

  getByType(type: EQuestionnaireTypeDTO): Observable<IQuestionnaire> {
    return this.apiService
      .get<IQuestionnaireDTO>(`${environment.environmentVariables.apiCoreUrl}/Questionnaire/actual/${type}`)
      .pipe(map((item) => QuestionnaireViewModelFactory.createFromDTO(item)));
  }
}
