import { TGuid } from '@core/helpers';

export interface IAddressDTO {
  id?: TGuid;
  country?: string | null;
  state?: string | null;
  is_o2?: string | null;
  city?: string;
  zip_code: string;
  address?: string | null;
  street: string;
  district: string;
  number: number | null;
  complement: string;
  country_id: TGuid;
  state_id: TGuid;
}

export interface IAddress {
  id?: TGuid;
  country?: string | null;
  state?: string | null;
  is_o2?: string | null;
  city?: string;
  zip_code: string;
  address?: string | null;
  street: string;
  district: string;
  number: number | null;
  complement: string;
  country_id: TGuid;
  state_id: TGuid;
}

export class AddressViewModelFactory {
  static createFromDTO(dto: IAddressDTO): IAddress {
    return {
      id: dto.id,
      country: dto.country,
      state: dto.state,
      is_o2: dto.is_o2,
      city: dto.city,
      zip_code: dto.zip_code,
      address: dto.address,
      street: dto.street,
      district: dto.district,
      number: dto.number,
      complement: dto.complement,
      country_id: dto.country_id,
      state_id: dto.state_id,
    };
  }
  static createDTO(model: IAddress): IAddressDTO {
    return {
      id: model.id,
      country: model.country,
      state: model.state,
      is_o2: model.is_o2,
      city: model.city,
      zip_code: model.zip_code,
      address: model.address,
      street: model.street,
      district: model.district,
      number: model.number,
      complement: model.complement,
      country_id: model.country_id,
      state_id: model.state_id,
    };
  }
}
