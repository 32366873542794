import { AbstractControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { OnInit, Directive } from '@angular/core';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { FormHelpers } from '@core/helpers';
import { HttpErrorResponse } from '@angular/common/http';
import { IFormSubmitHttpError, TFormFieldErrors } from './http-errors.types';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class FormComponent implements OnInit {
  public abstract form: FormGroup;

  protected _submitting$ = new BehaviorSubject<boolean>(false);
  public submitting$ = this._submitting$.asObservable();

  protected _completed$ = new BehaviorSubject<boolean>(false);
  public completed$ = this._completed$.asObservable();

  public formValid$: Observable<boolean>;

  public disableActions$: Observable<boolean>;

  protected abstract onFormInit();

  protected abstract doCleanSubmit();

  ngOnInit(): void {
    this.onFormInit();

    this.formValid$ = this.form.statusChanges.pipe(
      map(() => this.form.valid),
      distinctUntilChanged(),
      startWith(this.form.valid),
    );

    this.disableActions$ = combineLatest([this.submitting$, this.formValid$]).pipe(
      map(([submitting, valid]) => submitting || !valid),
    );
  }

  doDirtySubmit() {
    if (this._submitting$.getValue() || this._completed$.getValue()) {
      return;
    }

    // Manually validate every field in form to be sure all fields are valid
    FormHelpers.validateAllFields(this.form);

    if (!this.form.valid) {
      return;
    }

    this.doCleanSubmit();
  }

  protected setStartSubmitState() {
    this.form.disable({
      emitEvent: false, // To prevent field to be updated and remove http errors after enabling,
      onlySelf: true,
    });
    this._submitting$.next(true);
  }

  protected setEndSubmitState(error?: HttpErrorResponse) {
    this.form.enable({
      emitEvent: false, // To prevent field to be updated and remove http errors after enabling,
      onlySelf: true,
    });
    this._submitting$.next(false);

    if (error) {
      this.extractErrorsOperation(error);
    }
  }

  private extractErrorsOperation(error: HttpErrorResponse) {
    const formSubmitError: IFormSubmitHttpError = error?.error;

    if (formSubmitError && typeof formSubmitError.errors === 'object') {
      this.setFormErrors(formSubmitError.errors);
    }
  }

  private setFormErrors(errors: TFormFieldErrors) {
    Object.keys(errors).forEach((fieldName) => {
      const errorMessages = errors[fieldName] || [];
      const control: AbstractControl = this.form.controls[fieldName];

      if (!control || !errorMessages.length) {
        return;
      }

      errorMessages.forEach((message, index) => {
        const errorMessageName = `http_error_${index}`;
        control.setErrors({
          ...(control.errors || {}),
          [errorMessageName]: message,
        });
      });
    });
  }
}
