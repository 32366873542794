import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { SummaryApiProviderService } from '@project/data-providers';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService } from '@lib/notifications';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  IMedicalRecordCID,
  IMedicalRecordTUSS,
  INewMedicalRecord,
  IPatientMedicalRecord,
  IRequisitionWithQuestionsAnswers,
} from '@project/view-models';
import { takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { INPUT_MASKS, INPUT_PLACEHOLDERS } from '@project/shared';
import { TranslateService } from '@project/translate';
import { DateFormatter, DEFAULT_DATE_FORMAT, LibValidators } from '@core/helpers';

@Component({
  selector: 'app-session-details-shared-medical-record',
  templateUrl: './session-details-shared-medical-record.component.html',
  styleUrls: ['./session-details-shared-medical-record.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionDetailsSharedMedicalRecordComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() requisition = new BehaviorSubject<IRequisitionWithQuestionsAnswers>(null);

  @Output() readonly closeMedicalRecord = new EventEmitter();

  private readonly noMedicalEncounterOutcomeID = 'f92ee290-c5b9-44f7-8aa1-2c439fcaa3dd';
  private readonly destroy$ = new Subject();
  public readonly dateInputMask = INPUT_MASKS.date;
  public readonly dateInputPlaceholder = INPUT_PLACEHOLDERS.date;
  public readonly documentInputMask = INPUT_MASKS.cpf;
  public readonly documentInputPlaceholder = INPUT_PLACEHOLDERS.cpf;
  private readonly newEmptyCid: IMedicalRecordCID = { cid_id: null, cid_status_id: null };
  private readonly newEmptyTuss: IMedicalRecordTUSS = { tuss_id: null };
  private readonly mainPatientMedicalRecord: IPatientMedicalRecord = {
    medical_record_cid: [{ cid_id: null, cid_status_id: null }],
    medical_record_tuss: [{ tuss_id: null }],
    out_comes_id: null,
    anamnesis: null,
    birth_date: null,
    cpf: null,
    is_original_patient: true,
  };

  public patientsMedicalRecords: INewMedicalRecord = {
    requisition_id: null,
    medical_record: [{ ...this.mainPatientMedicalRecord }],
  };
  public cidStatusOptions: { label: string; value: string }[] = [];
  public outcomeOptions: { label: string; value: string }[] = [];
  public requisitionID: string;
  public isFormValid = true;
  public patientList = [`${TranslateService.localize('patients.main-tab-label')}`];
  public selected = new FormControl(0);
  public forms: FormGroup[] = [
    new FormGroup({
      cpf: new FormControl(null, [LibValidators.cpf(TranslateService.localize('validations.invalid'))]),
      dateBirth: new FormControl(null, [
        LibValidators.date(DEFAULT_DATE_FORMAT, TranslateService.localize('validations.invalid')),
      ]),
    }),
  ];

  public onClickAddTab(): void {
    this.patientList.push(`${TranslateService.localize('profile-type.patient')} ${this.patientList.length + 1}`);
    this.patientsMedicalRecords.medical_record.push({
      medical_record_cid: [{ cid_id: null, cid_status_id: null }],
      medical_record_tuss: [{ tuss_id: null }],
      out_comes_id: null,
      anamnesis: null,
      birth_date: null,
      cpf: null,
      is_original_patient: false,
    });
    this.forms.push(
      new FormGroup({
        cpf: new FormControl(null, [
          LibValidators.required(TranslateService.localize('validations.required')),
          LibValidators.cpf(TranslateService.localize('validations.invalid')),
        ]),
        dateBirth: new FormControl(null, [
          LibValidators.required(TranslateService.localize('validations.required')),
          LibValidators.date(DEFAULT_DATE_FORMAT, TranslateService.localize('validations.invalid')),
        ]),
      }),
    );
  }

  public onClickRemoveTab(patientIndex: number): void {
    this.patientList.splice(patientIndex, 1);
    this.patientsMedicalRecords.medical_record.splice(patientIndex, 1);
    this.selected.setValue(this.patientList.length - 1);
  }

  public onChangeDocument(patientIndex: number, patientDocument: string): void {
    this.patientsMedicalRecords.medical_record[patientIndex].cpf = patientDocument;
    this.isFormValid = true;
  }

  public onChangeBirthDate(patientIndex: number, birthDate: string): void {
    this.patientsMedicalRecords.medical_record[patientIndex].birth_date = birthDate;
    this.isFormValid = true;
  }

  private saveAnamnesisToSessionStorage(patientIndex: number, anamnesis: string): void {
    sessionStorage.setItem(`anamnesis_${patientIndex}`, anamnesis);
  }

  private loadAnamnesisFromSessionStorage(): void {
    this.patientsMedicalRecords.medical_record.forEach((record, index) => {
      const savedAnamnesis = sessionStorage.getItem(`anamnesis_${index}`);
      if (savedAnamnesis) {
        record.anamnesis = savedAnamnesis;
      }
    });
  }

  public onChangeAnamnesis(patientIndex: number, anamnesis: string): void {
    this.patientsMedicalRecords.medical_record[patientIndex].anamnesis = anamnesis;
    this.saveAnamnesisToSessionStorage(patientIndex, anamnesis);
  }

  public onClickAddCID(patientIndex: number): void {
    this.patientsMedicalRecords.medical_record[patientIndex].medical_record_cid.push({ ...this.newEmptyCid });
  }

  public onClickAddTuss(patientIndex: number): void {
    this.patientsMedicalRecords.medical_record[patientIndex].medical_record_tuss.push({ ...this.newEmptyTuss });
  }

  public onClickRemoveCID(patientIndex: number, cidIndex: number): void {
    this.patientsMedicalRecords.medical_record[patientIndex].medical_record_cid.splice(cidIndex, 1);
  }

  public onClickRemoveTuss(patientIndex: number, tussIndex: number): void {
    this.patientsMedicalRecords.medical_record[patientIndex].medical_record_tuss.splice(tussIndex, 1);
  }

  public onSelectCid(patientIndex: number, cidIndex: number, cidId: string): void {
    this.patientsMedicalRecords.medical_record[patientIndex].medical_record_cid[cidIndex].cid_id = cidId;
    this.isFormValid = true;
  }

  public onSelectCidStatus(patientIndex: number, cidIndex: number, cidStatusId: string): void {
    this.patientsMedicalRecords.medical_record[patientIndex].medical_record_cid[cidIndex].cid_status_id = cidStatusId;
    this.isFormValid = true;
  }

  public onSelectTuss(patientIndex: number, tussIndex: number, tussId: string): void {
    this.patientsMedicalRecords.medical_record[patientIndex].medical_record_tuss[tussIndex].tuss_id = tussId;
  }

  public onChangeOutcome(patientIndex: number, outcomeId: string): void {
    this.patientsMedicalRecords.medical_record[patientIndex].out_comes_id = outcomeId;
    this.isFormValid = true;
  }

  public isCidEmpty(patientIndex: number, cidIndex: number): boolean {
    return this.patientsMedicalRecords.medical_record[patientIndex].medical_record_cid[cidIndex].cid_id === null;
  }

  public isCidStatusEmpty(patientIndex: number, cidIndex: number): boolean {
    return this.patientsMedicalRecords.medical_record[patientIndex].medical_record_cid[cidIndex].cid_status_id === null;
  }

  public isTussEmpty(patientIndex: number, tussIndex: number): boolean {
    return this.patientsMedicalRecords.medical_record[patientIndex].medical_record_tuss[tussIndex].tuss_id === null;
  }

  public isPositionForAddButton(patientIndex: number, field: string, fieldIndex: number): boolean {
    if (field === 'cid') {
      return this.patientsMedicalRecords.medical_record[patientIndex].medical_record_cid.length - 1 === fieldIndex;
    } else if (field === 'tuss') {
      return this.patientsMedicalRecords.medical_record[patientIndex].medical_record_tuss.length - 1 === fieldIndex;
    }
  }

  public isPositionForRemoveButton(patientIndex: number, field: string, fieldIndex: number): boolean {
    if (field === 'cid') {
      return this.patientsMedicalRecords.medical_record[patientIndex].medical_record_cid.length - 1 !== fieldIndex;
    } else if (field === 'tuss') {
      return this.patientsMedicalRecords.medical_record[patientIndex].medical_record_tuss.length - 1 !== fieldIndex;
    }
  }

  private isNoMedicalEncounterOutcome(patientIndex: number): boolean {
    return this.patientsMedicalRecords.medical_record[patientIndex].out_comes_id === this.noMedicalEncounterOutcomeID;
  }

  private formatMedicalRecords(): IPatientMedicalRecord[] {
    const medicalRecords = this.patientsMedicalRecords.medical_record.map((medicalRecord: IPatientMedicalRecord) => {
      const medicalRecordCID = medicalRecord.medical_record_cid.filter((cid: IMedicalRecordCID) => {
        return cid.cid_id && cid.cid_status_id;
      });
      const medicalRecordTUSS = medicalRecord.medical_record_tuss.filter((tuss: IMedicalRecordTUSS) => {
        return tuss.tuss_id;
      });

      let formattedBirthDate: string;
      if (this.isValidBirthDate(medicalRecord.birth_date)) {
        const date = DateFormatter.stringToDate(medicalRecord.birth_date, { format: DEFAULT_DATE_FORMAT });
        formattedBirthDate = DateFormatter.dateToString(date, { ignoreTimezones: true }).substring(0, 10);
      }

      return {
        cpf: medicalRecord.cpf,
        birth_date: formattedBirthDate ? formattedBirthDate : medicalRecord.birth_date,
        is_original_patient: medicalRecord.is_original_patient,
        anamnesis: medicalRecord.anamnesis,
        medical_record_cid: medicalRecordCID,
        medical_record_tuss: medicalRecordTUSS,
        out_comes_id: medicalRecord.out_comes_id,
      };
    });
    return medicalRecords;
  }

  private removeEmptyCidAndTussFields(): void {
    this.patientsMedicalRecords.medical_record.forEach((medicalRecord: IPatientMedicalRecord) => {
      medicalRecord.medical_record_cid = medicalRecord.medical_record_cid.filter(
        (cid: IMedicalRecordCID, cidIndex: number) => {
          return cid.cid_id !== null || cidIndex === 0;
        },
      );

      medicalRecord.medical_record_tuss = medicalRecord.medical_record_tuss.filter(
        (tuss: IMedicalRecordTUSS, index: number) => {
          return tuss.tuss_id !== null || index === 0;
        },
      );
    });
  }

  private isValidCid(cidList: IMedicalRecordCID[]): boolean {
    let isValid = true;
    cidList.forEach((cid) => {
      if (cid.cid_id === null) {
        isValid = false;
        return;
      }
    });
    return isValid;
  }

  private isValidCidStatus(cidList: IMedicalRecordCID[]): boolean {
    let isValid = true;
    cidList.forEach((cid) => {
      if (cid.cid_status_id === null) {
        isValid = false;
        return;
      }
    });
    return isValid;
  }

  private isValidOutcome(outcome: string): boolean {
    return outcome !== null;
  }

  private isValidDocument(document: string): boolean {
    return document?.length === 14;
  }

  private isValidBirthDate(birthDate: string): boolean {
    return birthDate?.length === 10;
  }

  private showMessageError(message: string) {
    this.notificationsService.error({ message });
  }

  private sendMedicalRecord(medicalRecord: INewMedicalRecord | []): void {
    this.requisition.pipe(takeUntil(this.destroy$)).subscribe((requisition) => {
      this.closeMedicalRecord.emit({ medicalRecord, requisition });
    });
  }

  public onClickSubmit(): void {
    this.removeEmptyCidAndTussFields();

    if (this.validateAllPatients()) {
      this.sendMedicalRecord({
        requisition_id: this.requisitionID,
        medical_record: this.formatMedicalRecords(),
      });
      this.summaryApiProviderService.setMedicalRecordFilledState(true);
      this.clearAnamnesisInSessionStorage();
      return;
    }
    this.summaryApiProviderService.setMedicalRecordFilledState(false);
  }

  private clearAnamnesisInSessionStorage(): void {
    this.patientsMedicalRecords.medical_record.forEach((record, index) => {
      sessionStorage.removeItem(`anamnesis_${index}`);
    });
  }

  private validateAllPatients(): boolean {
    const haveMoreThanOnePatient = this.patientsMedicalRecords.medical_record.length > 1;

    for (let patientIndex = 0; patientIndex < this.patientsMedicalRecords.medical_record.length; patientIndex++) {
      const medicalRecord = this.patientsMedicalRecords.medical_record[patientIndex];

      if (!this.isNoMedicalEncounterOutcome(patientIndex)) {
        if (haveMoreThanOnePatient && !this.validateDocumentAndBirthDate(medicalRecord, patientIndex)) {
          return false;
        }

        if (!this.validateMedicalRecord(medicalRecord, patientIndex)) {
          return false;
        }
      }
    }
    return true;
  }

  private validateDocumentAndBirthDate(medicalRecord: IPatientMedicalRecord, patientIndex: number): boolean {
    if (!this.isValidDocument(medicalRecord.cpf)) {
      this.showMessageError(`${TranslateService.localize('sessions-details.missing-document')} ${patientIndex + 1}`);
      return false;
    } else if (!this.isValidBirthDate(medicalRecord.birth_date)) {
      this.showMessageError(`${TranslateService.localize('sessions-details.missing-birthdate')} ${patientIndex + 1}`);
      return false;
    }
    return true;
  }

  private validateMedicalRecord(medicalRecord: IPatientMedicalRecord, patientIndex: number): boolean {
    if (!this.isValidCid(medicalRecord.medical_record_cid)) {
      this.showMessageError(`${TranslateService.localize('sessions-details.missing-cid')} ${patientIndex + 1}`);
      return false;
    } else if (!this.isValidCidStatus(medicalRecord.medical_record_cid)) {
      this.showMessageError(`${TranslateService.localize('sessions-details.missing-status')} ${patientIndex + 1}`);
      return false;
    } else if (!this.isValidOutcome(medicalRecord.out_comes_id)) {
      this.showMessageError(`${TranslateService.localize('sessions-details.missing-outcome')} ${patientIndex + 1}`);
      return false;
    }
    return true;
  }

  constructor(
    private summaryApiProviderService: SummaryApiProviderService,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private el: ElementRef,
  ) {}

  ngOnInit(): void {
    this.summaryApiProviderService.getAllCIDStatus().subscribe((cids) => {
      this.cidStatusOptions = cids.map((status) => ({ value: status.id, label: status.status }));
    });

    this.summaryApiProviderService.getAllOutcomes().subscribe((outcomes) => {
      this.outcomeOptions = outcomes.map((outcome) => ({ value: outcome.id, label: outcome.out_come }));
    });

    this.route.parent.params.subscribe((params) => {
      this.requisitionID = params.requisitionId;
    });

    this.loadAnamnesisFromSessionStorage();

    window.localStorage.removeItem('isDoctorSaving');
  }

  ngAfterViewInit() {
    this.el.nativeElement.querySelector('textarea').focus();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
