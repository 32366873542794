<textarea
  #textarea
  [attr.data-appearance]="appearance"
  [attr.data-size]="size"
  [readOnly]="completed"
  [disabled]="disabled$ | async"
  [placeholder]="placeholder || ''"
  [attr.name]="name"
  [rows]="textareaRows"
  [value]="value$ | async"
  (input)="onInputHandler(textarea.value)"
  (blur)="onBlur($event)"
></textarea>

<lib-input-icon type="warning" class="icon-warning" [size]="size"></lib-input-icon>
<lib-input-icon type="completed" class="icon-completed" [size]="size"></lib-input-icon>
