<ng-container *ngIf="mask; else withoutMask">
  <input
    #input
    [trim]="trimValue"
    [trimOnWriteValue]="trim"
    [mask]="mask"
    [attr.data-appearance]="appearance"
    [attr.data-size]="size"
    [attr.autocomplete]="autocomplete"
    [attr.is-password]="showPasswordToggle"
    [type]="type"
    [readOnly]="completed"
    [attr.name]="name"
    [ngModel]="value$ | async"
    [disabled]="disabled$ | async"
    [placeholder]="placeholder || ''"
    (input)="onInputHandler(input.value)"
    (blur)="onBlur($event)"
  />
</ng-container>
<ng-template #withoutMask>
  <input
    #input
    [trim]="trimValue"
    [attr.data-appearance]="appearance"
    [attr.data-size]="size"
    [attr.autocomplete]="autocomplete"
    [attr.is-password]="showPasswordToggle"
    [type]="type"
    [readOnly]="completed"
    [attr.name]="name"
    [ngModel]="value$ | async"
    [disabled]="disabled$ | async"
    [placeholder]="placeholder || ''"
    (input)="onInputHandler(input.value)"
    (blur)="onBlur($event)"
  />
</ng-template>

<lib-input-icon
  *ngIf="showPasswordToggle"
  [size]="size"
  type="password"
  class="icon-password"
  [passwordOpened]="type !== 'password'"
  (click)="togglePasswordType()"
></lib-input-icon>
<lib-input-icon type="warning" class="icon-warning" [size]="size"></lib-input-icon>
<lib-input-icon type="completed" class="icon-completed" [size]="size"></lib-input-icon>
