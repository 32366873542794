<form [formGroup]="form" (ngSubmit)="doDirtySubmit()">
  <div class="row">
    <div class="col-6">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.first-name" | translate }}</span>
          <lib-input formControlName="firstName" name="firstName"></lib-input>
        </label>
      </lib-form-control>
    </div>
    <div class="col-6">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.last-name" | translate }}</span>
          <lib-input formControlName="lastName" name="lastName"></lib-input>
        </label>
      </lib-form-control>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.email" | translate }}</span>
          <lib-input formControlName="email" name="email" [trim]="true"></lib-input>
        </label>
      </lib-form-control>
    </div>
    <div class="col-12">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.gender" | translate }}</span>
          <lib-select [options]="genderOptions" formControlName="gender"></lib-select>
        </label>
      </lib-form-control>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.phone" | translate }}</span>
          <lib-input formControlName="phone" name="phone" [size]="'m'"></lib-input>
        </label>
      </lib-form-control>
    </div>
    <div class="col-6">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.date-of-birth" | translate }}</span>
          <lib-input
            formControlName="dateOfBirth"
            name="dateOfBirth"
            [mask]="dateInputMask"
            [placeholder]="dateInputPlaceholder"
            [size]="'m'"
          ></lib-input>
        </label>
      </lib-form-control>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.group" | translate }}</span>
        </label>
        <app-patient-invite-control></app-patient-invite-control>
      </lib-form-control>
    </div>
  </div>

  <button
    libButton
    [isPending]="isPending"
    type="submit"
    [disabled]="!(formValid$ | async)"
    [appearance]="'outline'"
    [size]="'m'"
    class="btnAction"
  >
    {{ patient == null ? ("actions.add-to-list" | translate) : ("patients.edit-button" | translate) }}
  </button>
</form>
