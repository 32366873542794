import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { AuthorisationStateService } from '@project/services';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { FeatureFlagService } from './feature-flag.service';
import { EFeatureFlags } from '@app/config';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ifFeatureEnabled]',
})
export class IfFeatureEnabledDirective implements OnInit, OnDestroy {
  @Input() set ifFeatureEnabled(feature: EFeatureFlags) {
    this.featureToCheck$.next(feature);
  }

  @Input() set ifFeatureEnabledElse(templateRef: TemplateRef<any>) {
    this.elseTemplateRef$.next(templateRef);
  }

  protected featureToCheck$ = new BehaviorSubject<EFeatureFlags>(null);
  protected elseTemplateRef$ = new BehaviorSubject<TemplateRef<any>>(null);

  private hasMainView = false;
  private hasElseView = false;
  private sub: Subscription;

  constructor(
    protected authorisationStateService: AuthorisationStateService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlagService: FeatureFlagService,
  ) {}

  ngOnInit(): void {
    this.sub = combineLatest([
      this.featureToCheck$.pipe(
        map((feature) => (feature ? this.featureFlagService.isFeatureOn(feature) : true)),
        distinctUntilChanged(),
      ),
      this.elseTemplateRef$.pipe(distinctUntilChanged()),
    ]).subscribe(([isFeatureOn, elseTemplate]) => {
      if (!this.hasMainView && isFeatureOn) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasMainView = true;
        this.hasElseView = false;
      }

      if (!this.hasElseView && !isFeatureOn) {
        this.viewContainer.clear();
        elseTemplate && this.viewContainer.createEmbeddedView(elseTemplate);
        this.hasMainView = false;
        this.hasElseView = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
