import { Injectable, InjectionToken, Injector } from '@angular/core';
import { ListItemsService } from '@modules';
import {
  IInvitationWithProfiles,
  EProfileTypeDTO,
  EInvitationStatusDTO,
  INVITATION_STATUSES_WITHOUT_ACCEPTED,
} from '@project/view-models';
import { IPatientFiltersParams, UserInviteApiProviderService } from '@project/data-providers';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { NotificationsService } from '@lib/notifications';
import { HttpHelperService } from './http-helper.service';
import { TGuid } from '@core/helpers';
import { TranslateService } from '@project/translate';

/**
 * @deprecated Use InvitationsManagerService
 * TODO: Migrate to InvitationsManagerService in all usages
 */
@Injectable()
export class InvitationsManagerService {
  private listService: ListItemsService<IInvitationWithProfiles>;
  private currentUserProfileTypes: EProfileTypeDTO[];
  private isInitialized$ = new BehaviorSubject<boolean>(false);

  public readonly invitations$ = this.isInitialized$.pipe(
    switchMap((isInitialized) => (isInitialized ? this.listService.currentPageItems$ : of([]))),
  );

  public readonly hasSelectedInvitations$ = this.isInitialized$.pipe(
    switchMap((isInitialized) => (isInitialized ? this.listService.hasSelectedIds$ : of(false))),
  );

  public readonly selectedInvitationsCount$ = this.isInitialized$.pipe(
    switchMap((isInitialized) => (isInitialized ? this.listService.selectedIdsCount$ : of(null))),
  );
  private currentInvitationsStatuses: Partial<IPatientFiltersParams>;

  constructor(
    private injector: Injector,
    private userInviteApiProviderService: UserInviteApiProviderService,
    private notificationsService: NotificationsService,
  ) {}

  public init(
    listServiceToken: InjectionToken<any>,
    userProfileTypesFilter: EProfileTypeDTO[] | undefined,
    invitationsStatusesFilter: Partial<IPatientFiltersParams> = null,
  ) {
    this.listService = this.injector.get(listServiceToken);
    this.currentUserProfileTypes = userProfileTypesFilter;
    this.currentInvitationsStatuses = invitationsStatusesFilter;
    this.isInitialized$.next(true);
  }

  public clearSelection() {
    this.listService.clearSelection();
  }

  public loadInvitations(search: string = null): Observable<IInvitationWithProfiles[]> {
    return this.userInviteApiProviderService.getAllWithProfiles(this.currentUserProfileTypes, null, search).pipe(
      catchError(() => {
        this.notificationsService.error({
          message: TranslateService.localize('invitations.load.fail.message'),
        });
        return of([]);
      }),
      tap((items) => {
        this.listService.setItems(items);
      }),
    );
  }

  public addItemsToCurrentPage(items: IInvitationWithProfiles[]) {
    this.listService.addItemsToCurrentPage(items);
  }

  public removeSelectedInvitations(): Observable<void> {
    return HttpHelperService.multipleRequests<TGuid>(
      this.listService.selectedIds.map((id) =>
        this.userInviteApiProviderService.removeInvitation(id).pipe(map(() => id)),
      ),
    ).pipe(
      tap((response) => {
        response.succeedResponseData.forEach((returnedId) => {
          this.listService.removeItemFromCurrentPage(returnedId);
        });

        if (response.failedCount || response.error) {
          this.notificationsService.error({
            message: response.error?.error || TranslateService.localize('errors.unable-remove'),
          });
        } else {
          this.notificationsService.success({
            message: TranslateService.localize('success-messages.removed'),
          });
        }
      }),
      map(() => null),
    );
  }

  public resendSelectedInvitations(): Observable<void> {
    return this.resendInvitations(this.listService.selectedIds);
  }

  public resendAllInvitations(): Observable<void> {
    return this.resendInvitations(this.listService.currentPageIds);
  }

  private resendInvitations(ids: TGuid[]): Observable<void> {
    return HttpHelperService.multipleRequests<TGuid>(
      ids.map((id) => this.userInviteApiProviderService.resendInvitation(id).pipe(map(() => id))),
    ).pipe(
      tap((response) => {
        if (response.failedCount || response.error) {
          this.notificationsService.error({
            message: response.error?.error || TranslateService.localize('invitations.resend.fail.message'),
          });
        } else {
          const successMessage =
            response.succeedCount > 1
              ? TranslateService.localize('invitations.resend-multiple.success.message')
              : TranslateService.localize('invitations.resend.success.message');

          this.notificationsService.success({
            message: successMessage,
          });
        }
      }),
      map(() => null),
    );
  }
}
