import { TranslateService } from '@project/translate';
import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NotificationsService } from '@lib/notifications';
import { SummaryApiProviderService } from '@project/data-providers';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, filter, map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-tuss-select',
  templateUrl: './tuss-select.component.html',
  styleUrls: ['./tuss-select.component.scss'],
})
export class TussSelectComponent implements OnInit, OnDestroy {
  public isSearching = false;

  public serverSideCtrl: FormControl = new FormControl();
  public serverSideFilteringCtrl: FormControl = new FormControl();

  public filteredOptions: ReplaySubject<{ name: string; value: string }[]> = new ReplaySubject<any[]>(1);

  protected _onDestroy = new Subject<void>();

  @Output() onTussSelected = new EventEmitter<string>();

  constructor(
    private summaryApiProviderService: SummaryApiProviderService,
    private notificationsService: NotificationsService,
  ) {}

  ngOnInit() {
    this.serverSideFilteringCtrl.valueChanges
      .pipe(
        map((value: string) => value.trim()),
        filter((search) => !!search && search.length > 2),
        tap(() => (this.isSearching = true)),
        takeUntil(this._onDestroy),
        debounceTime(500),
        map((search) => this.summaryApiProviderService.getTussBySearch(search)),
      )
      .subscribe((filteredOptions) => {
        filteredOptions.subscribe(
          (tusses) => {
            this.isSearching = false;

            const tussesOptions = tusses.map((option) => ({
              name: `${option.code} - ${option.procedure}`,
              value: option.id,
            }));
            this.filteredOptions.next(tussesOptions);
          },
          () => {
            this.isSearching = false;
            this.notificationsService.error({ message: 'Falha ao buscar TUSS' });
          },
        );
      });

    this.serverSideCtrl.valueChanges.subscribe(({ value }) => {
      this.onTussSelected.emit(value);
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
