import { Injectable } from '@angular/core';
import { environment } from '@env';

const NOT_DEFINED_YET = 'NOT DEFINED YET';

@Injectable({
  providedIn: 'root',
})
export class HcService {
  constructor() {}

  setHealthCenterId(healthCenterId: string) {
    if (healthCenterId) {
      localStorage.setItem('healthCenterId', healthCenterId);
    } else {
      localStorage.setItem('healthCenterId', NOT_DEFINED_YET);
    }
  }

  getHealthCenterId(): string {
    return localStorage.getItem('healthCenterId') || NOT_DEFINED_YET;
  }

  isPiaui(): boolean {
    const healthCenterId = this.getHealthCenterId();
    return healthCenterId === environment.environmentVariables.healthCenterIdPiaui;
  }

  setPiauiHealthCenter() {
    this.setHealthCenterId(environment.environmentVariables.healthCenterIdPiaui);
  }

  setNotDefinedYetHealthCenter() {
    this.setHealthCenterId(NOT_DEFINED_YET);
  }
}
