<div class="checkbox-content" [attr.disabled]="disabled$ | async">
  <button
    class="toggle"
    [class.active]="value$ | async"
    [title]="ariaTitle$ | async"
    [disabled]="disabled$ | async"
    type="button"
    (click)="toggleValue()"
  ></button>
  <span><ng-content></ng-content></span>
</div>
