<form [formGroup]="form" (ngSubmit)="submit()">
  <label>
    <textarea
      formControlName="message"
      autoSized
      [autoSizeValueToCheck]="form.value.message"
      [rows]="1"
      [placeholder]="'chat.message-input-placeholder' | translate"
      (keydown.enter)="onMessageEnter($event)"
    ></textarea>
  </label>

  <ul class="primary-actions">
    <li *ngIf="isUserPatient">
      <button (click)="uploadFile.click()" type="button" [disabled]="isShouldPreventMessageSending$ | async">
        <div class="button-attatch">
          <p class="hidden-mobile-only">{{ "chat.send-file" | translate }}</p>
          <lib-icon class="show-mobile-only" [type]="'upload-white'"></lib-icon>
        </div>
      </button>
    </li>
    <li>
      <button type="submit" [disabled]="isShouldPreventMessageSending$ | async">
        <lib-icon [type]="'send-blue'"></lib-icon>
      </button>
    </li>
  </ul>
  <input (change)="onFileChange($event)" #uploadFile multiple type="file" style="display: none;" />
</form>
