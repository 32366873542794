import { Component, EventEmitter, OnInit } from '@angular/core';
import { VideoconferenceImpl } from './videoconference.impl';
import { IModalComponent, ModalOverlayService, ModalWindowRef } from '@lib/modal';
import { EProfileTypeDTO, IRequisition, IRequisitionParticipant } from '@project/view-models';
import { VideoCallManagerService } from 'src/app/features/modules/video-call/video-call-manager.service';
import { TranslateService } from '@project/translate';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-videoconference',
  templateUrl: './videoconference.component.html',
  styleUrls: ['./videoconference.component.scss'],
})
export class VideoconferenceComponent implements OnInit, IModalComponent {
  public readonly mainProfileTypeDTO: IRequisitionParticipant = this.videoCallManagerService.participant;
  public readonly secundaryProfileTypeDTO: IRequisitionParticipant = this.videoCallManagerService
    .myProfile as IRequisitionParticipant;
  public readonly requisition: IRequisition = this.videoCallManagerService.requisition;

  public showChat = true;

  public readonly close$ = new EventEmitter();
  public readonly submit$ = new EventEmitter();

  private disableChatBehavior$ = new BehaviorSubject<boolean>(false);
  public disableChatObservable$ = this.disableChatBehavior$.asObservable();

  private disableRenderChat =
    this.secundaryProfileTypeDTO.profileType === EProfileTypeDTO.Doctor ||
    this.secundaryProfileTypeDTO.profileType === EProfileTypeDTO.Receptionist;

  constructor(
    public videoconference: VideoconferenceImpl,
    private modalOverlayService: ModalOverlayService,
    private videoCallManagerService: VideoCallManagerService,
    public modalWindowRef: ModalWindowRef,
  ) {}

  ngOnInit(): void {
    this.join();
    this.observerEvents();
    this.setupModal();
    this.renderChat();
  }

  public toggleChat(): void {
    this.showChat = !this.showChat;
  }

  public async join(): Promise<void> {
    await this.videoconference.join();
  }

  public async toggleVideo(): Promise<void> {
    await this.videoconference.toggleVideo();
  }

  public async toggleAudio(): Promise<void> {
    await this.videoconference.toggleSound();
  }

  public async left(): Promise<void> {
    this.modalOverlayService.confirm(TranslateService.localize('questions.are-you-sure?')).subscribe(async () => {
      await this.videoconference.left();
      await this.videoCallManagerService.endCurrentCall();
    });
  }

  public async exit() {
    await this.videoconference.left();
  }

  private observerEvents() {
    this.modalWindowRef.isFullscreenMode$.subscribe((isFullScreen: boolean) => {
      if (!this.disableRenderChat) {
        this.showChat = isFullScreen;
        this.disableChatBehavior$.next(isFullScreen);
      }
    });
  }

  private setupModal() {
    this.modalWindowRef.updateConfig({
      disableChangeWindowMode: this.mainProfileTypeDTO.profileType !== EProfileTypeDTO.Patient,
    });
  }

  private renderChat() {
    if (this.disableRenderChat) {
      this.disableChatBehavior$.next(false);
      this.showChat = false;
    }
  }
}
