import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '@env';
import { TGuid } from '@core/helpers';
import { IQueueInfo, ITaskFull, ITaskFullDTO, TaskFullViewModelFactory } from '@project/view-models';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TasksApiProviderService {
  constructor(private apiService: ApiService) {}

  getQueueInfo(): Observable<IQueueInfo> {
    return this.apiService.get(`${environment.environmentVariables.apiCoreUrl}/healthcenters/tasks/queue/info`);
  }

  close(id: TGuid): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/healthcenters/tasks/${id}/close`);
  }

  discard(id: TGuid): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/healthcenters/tasks/${id}/cancel`);
  }

  getActiveTasks(): Observable<ITaskFull[]> {
    return this.apiService
      .get<ITaskFullDTO[]>(`${environment.environmentVariables.apiCoreUrl}/healthcenters/tasks/active`)
      .pipe(map((tasks) => tasks.map((task) => TaskFullViewModelFactory.createGetViewModel(task))));
  }

  getNextTask(): Observable<ITaskFull | null> {
    return this.apiService
      .get<ITaskFullDTO>(`${environment.environmentVariables.apiCoreUrl}/healthcenters/tasks/next`)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 404) {
            return of(null);
          }

          return throwError(err);
        }),
        map((task) => (task ? TaskFullViewModelFactory.createGetViewModel(task) : null)),
      );
  }
}
