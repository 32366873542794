import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonDropdownComponent } from './button-dropdown/button-dropdown.component';
import { ButtonModule } from '../button/public-api';
import { DropdownModule } from '../dropdown/public-api';
import { ButtonDropdownMenuItemComponent } from './button-dropdown-menu-item/button-dropdown-menu-item.component';
import { FindDropdownPositionDirectiveModule } from '../find-dropdown-position-directive/public-api';

@NgModule({
  declarations: [ButtonDropdownComponent, ButtonDropdownMenuItemComponent],
  exports: [ButtonDropdownComponent, ButtonDropdownMenuItemComponent],
  imports: [CommonModule, ButtonModule, DropdownModule, FindDropdownPositionDirectiveModule],
})
export class ButtonDropdownModule {}
