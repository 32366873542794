import { TranslateService } from '@project/translate';

/**
 * @deps By editing keys of this enum, it is necessary to update the template files for csv import.
 */
export enum EDoctorSpecializationDTO {
  Infectologist = 'Infectologist',
  GeneralPhysician = 'GeneralPhysician',
  Pulmonologist = 'Pulmonologist',
  Cardiologist = 'Cardiologist',
  Intensivist = 'Intensivist',
  Dermatologist = 'Dermatologist',
  Physiotherapist = 'Physiotherapist',
  Psychologist = 'Psychologist',
  Psychiatrist = 'Psychiatrist',
  VascularSurgeon = 'VascularSurgeon',
  Endocrinologist = 'Endocrinologist',
  Gastroenterologist = 'Gastroenterologist',
  Gynecologist = 'Gynecologist',
  Obstetric = 'Obstetric',
  Hematologist = 'Hematologist',
  Nephrologist = 'Nephrologist',
  Neurologist = 'Neurologist',
  Nutritionist = 'Nutritionist',
  Ophthalmologist = 'Ophthalmologist',
  Orthopedist = 'Orthopedist',
  Otolaryngologist = 'Otolaryngologist',
  Pediatrician = 'Pediatrician',
  ChildPsychiatrist = 'ChildPsychiatrist',
  Rheumatologist = 'Rheumatologist',
  Traumatologist = 'Traumatologist',
  Urologist = 'Urologist',
  GeneralSurgeon = 'GeneralSurgeon',
}

export function getDoctorSpecializationLocalization(specialization: EDoctorSpecializationDTO): string | undefined {
  const map: Record<EDoctorSpecializationDTO, () => string> = {
    [EDoctorSpecializationDTO.Infectologist]: () => TranslateService.localize('doctors.specialization.infectologist'),
    [EDoctorSpecializationDTO.GeneralPhysician]: () =>
      TranslateService.localize('doctors.specialization.general-physician'),
    [EDoctorSpecializationDTO.Pulmonologist]: () => TranslateService.localize('doctors.specialization.pulmonologist'),
    [EDoctorSpecializationDTO.Cardiologist]: () => TranslateService.localize('doctors.specialization.cardiologist'),
    [EDoctorSpecializationDTO.Intensivist]: () => TranslateService.localize('doctors.specialization.intensivist'),
    [EDoctorSpecializationDTO.Dermatologist]: () => TranslateService.localize('doctors.specialization.dermatologist'),
    [EDoctorSpecializationDTO.Physiotherapist]: () =>
      TranslateService.localize('doctors.specialization.physiotherapist'),
    [EDoctorSpecializationDTO.Psychologist]: () => TranslateService.localize('doctors.specialization.psychologist'),
    [EDoctorSpecializationDTO.Psychiatrist]: () => TranslateService.localize('doctors.specialization.psychiatrist'),
    [EDoctorSpecializationDTO.VascularSurgeon]: () =>
      TranslateService.localize('doctors.specialization.vascular-surgeon'),
    [EDoctorSpecializationDTO.Endocrinologist]: () =>
      TranslateService.localize('doctors.specialization.endocrinologist'),
    [EDoctorSpecializationDTO.Gastroenterologist]: () =>
      TranslateService.localize('doctors.specialization.gastroenterologist'),
    [EDoctorSpecializationDTO.Gynecologist]: () => TranslateService.localize('doctors.specialization.gynecologist'),
    [EDoctorSpecializationDTO.Obstetric]: () => TranslateService.localize('doctors.specialization.obstetric'),
    [EDoctorSpecializationDTO.Hematologist]: () => TranslateService.localize('doctors.specialization.hematologist'),
    [EDoctorSpecializationDTO.Nephrologist]: () => TranslateService.localize('doctors.specialization.nephrologist'),
    [EDoctorSpecializationDTO.Neurologist]: () => TranslateService.localize('doctors.specialization.neurologist'),
    [EDoctorSpecializationDTO.Nutritionist]: () => TranslateService.localize('doctors.specialization.nutritionist'),
    [EDoctorSpecializationDTO.Ophthalmologist]: () =>
      TranslateService.localize('doctors.specialization.ophthalmologist'),
    [EDoctorSpecializationDTO.Orthopedist]: () => TranslateService.localize('doctors.specialization.orthopedist'),
    [EDoctorSpecializationDTO.Otolaryngologist]: () =>
      TranslateService.localize('doctors.specialization.otolaryngologist'),
    [EDoctorSpecializationDTO.Pediatrician]: () => TranslateService.localize('doctors.specialization.pediatrician'),
    [EDoctorSpecializationDTO.ChildPsychiatrist]: () =>
      TranslateService.localize('doctors.specialization.child-psychiatrist'),
    [EDoctorSpecializationDTO.Rheumatologist]: () => TranslateService.localize('doctors.specialization.rheumatologist'),
    [EDoctorSpecializationDTO.Traumatologist]: () => TranslateService.localize('doctors.specialization.traumatologist'),
    [EDoctorSpecializationDTO.Urologist]: () => TranslateService.localize('doctors.specialization.urologist'),
    [EDoctorSpecializationDTO.GeneralSurgeon]: () => TranslateService.localize('doctors.specialization.generalsurgeon'),
  };

  return map[specialization]?.();
}
