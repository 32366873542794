import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { BinahConfigViewModelFactory, IBinahConfig, IBinahConfigDTO } from '@project/view-models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BinahConfigApiProviderService {
  constructor(private apiService: ApiService) {}

  getBinahConfig(): Observable<IBinahConfig> {
    return this.apiService
      .get<IBinahConfigDTO>(`${environment.environmentVariables.apiCoreUrl}/hc/config/binah`)
      .pipe(map((dto) => BinahConfigViewModelFactory.createFromDTO(dto)));
  }

  setBinnahConfig(config: IBinahConfig): Observable<void> {
    return this.apiService.post(
      `${environment.environmentVariables.apiCoreUrl}/hc/config/binah`,
      BinahConfigViewModelFactory.createDTO(config),
    );
  }
}
