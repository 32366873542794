import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HcService } from '@project/services';

@Component({
  selector: 'app-authorization-layout',
  templateUrl: './authorization-layout.component.html',
  styleUrls: ['./authorization-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorizationLayoutComponent {
  constructor(private hcService: HcService) {}

  showLogo = !(new URL(window.location.href).searchParams.get('provider') === 'embedded');
  isPiaui = this.hcService.isPiaui();
}
