export enum EFeatureFlags {
  signUpEmail = 'sign-up-email',
  observations = 'observations',
  signInWithApple = 'sign-in-with-apple',
  signInWithGoogle = 'sign-in-with-google',
  signInWithFacebook = 'sign-in-with-facebook',
  patientsTags = 'patients-tags',
  doctorsTags = 'doctors-tags',
  receptionistsTags = 'receptionists-tags',
  chatPreviewForHcAdmin = 'chat-preview-for-hc-admin',
}

export const MANUALLY_ENABLED_FEATURE_FLAGS: EFeatureFlags[] = [
  EFeatureFlags.observations,
  EFeatureFlags.signInWithApple,
  EFeatureFlags.signInWithGoogle,
  EFeatureFlags.patientsTags,
  EFeatureFlags.doctorsTags,
  EFeatureFlags.receptionistsTags,
];
