export function firstLetterUppercase(value: string): string {
  if (!value || typeof value !== 'string') {
    return '';
  }

  return value[0].toUpperCase() + value.slice(1);
}

export function pascalCaseToSnakeCase(pascalCaseValue: string): string {
  return pascalCaseValue
    .split('')
    .map((c, index) => {
      if (c === c.toUpperCase()) {
        return `${index === 0 ? '' : '_'}${c.toLowerCase()}`;
      }
      return c;
    })
    .join('');
}

export function snakeCaseToPascalCase(snakeCaseValue: string): string {
  return snakeCaseValue
    .split('_')
    .map((word) => {
      return firstLetterUppercase(word);
    })
    .join('');
}

export function leftPad(value: string, maxLength: number, pad: string = '0') {
  while (value.length < maxLength) {
    value = `${pad}${value}`;
  }
  return value;
}
