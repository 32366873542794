import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TGuid } from '@core/helpers';
import { map } from 'rxjs/operators';
import { ListTableManagerService } from '../list-table-manager.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tr[appListTableItem]',
  templateUrl: './list-items-table-item.component.html',
  styleUrls: ['./list-items-table-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemsTableItemComponent {
  @Input() idToSelect: TGuid;

  public allowSelection$ = this.listTableManagerService.allowSelection$;

  public isSelected$ = this.listTableManagerService.selectedIds$.pipe(map((ids) => ids.includes(this.idToSelect)));

  constructor(private listTableManagerService: ListTableManagerService) {}

  onSelectionChange(isSelected: boolean) {
    this.listTableManagerService.changeItemSelection(this.idToSelect, isSelected);
  }
}
