import { Observable } from 'rxjs';

export abstract class VideoConferenceProvider {
  abstract name: string;

  abstract toggleVideo(): Promise<void>;

  abstract toggleSound(): Promise<void>;

  abstract join(): Promise<void>;

  abstract left(): Promise<void>;

  abstract features(): string[];

  abstract getRemoteParticipants(): (string | number)[];

  abstract remoteVideoObservable: Observable<boolean>;
  abstract remoteAudioObservable: Observable<boolean>;

  abstract localVideoObservable: Observable<boolean>;
  abstract localAudioObservable: Observable<boolean>;

  abstract remoteFallbackObservable: Observable<boolean>;
}
