import { Injectable } from '@angular/core';
import { ApiService, IPagingResponse, PagingApiService } from '@core/http';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { TGuid } from '@core/helpers';
import { map } from 'rxjs/operators';
import {
  IFullUpdateReceptionistDTO,
  IReceptionist,
  IReceptionistDTO,
  ReceptionistViewModelFactory,
} from '@project/view-models';

@Injectable({
  providedIn: 'root',
})
export class ReceptionistsApiProviderService {
  constructor(private apiService: ApiService, private pagingApiService: PagingApiService) {}

  getAll(): Observable<IReceptionist[]> {
    return this.apiService
      .get<IReceptionistDTO[]>(`${environment.environmentVariables.apiCoreUrl}/Receptionists`)
      .pipe(map((users) => users.map((user) => ReceptionistViewModelFactory.createFromDTO(user))));
  }

  getPaged(
    page: number,
    itemsPerPage: number,
    query?: Record<string, any>,
  ): Observable<IPagingResponse<IReceptionist>> {
    return this.pagingApiService.request<IReceptionistDTO, IReceptionist>({
      url: `${environment.environmentVariables.apiCoreUrl}/Receptionists`,
      additionalQueryParams: query,
      itemsPerPage,
      itemFactory: (item) => ReceptionistViewModelFactory.createFromDTO(item),
      pageNumber: page,
    });
  }

  getById(id: TGuid): Observable<IReceptionist> {
    return this.apiService
      .get<IReceptionistDTO>(`${environment.environmentVariables.apiCoreUrl}/Receptionists/${id}`)
      .pipe(map((user) => ReceptionistViewModelFactory.createFromDTO(user)));
  }

  remove(id: TGuid): Observable<void> {
    return this.apiService.delete(`${environment.environmentVariables.apiAuthUrl}/profile/receptionist/${id}/delete`);
  }

  update(id: TGuid, model: IFullUpdateReceptionistDTO): Observable<void> {
    return this.apiService.put(`${environment.environmentVariables.apiCoreUrl}/Receptionists/${id}`, model);
  }

  updateInvite(id: TGuid, model: IFullUpdateReceptionistDTO): Observable<void> {
    return this.apiService.put(`${environment.environmentVariables.apiAuthUrl}/invites/receptionist/${id}`, model);
  }
}
