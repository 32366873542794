import { TGuid } from '@core/helpers';

export interface ICountryDTO {
  id: TGuid;
  name: string;
  states: string | null;
}

export interface ICountry {
  countryId: TGuid;
  name: string;
  states: string | null;
}

export class CountryViewModelFactory {
  static createFromDTO(dto: ICountryDTO): ICountry {
    return {
      countryId: dto.id,
      name: dto.name,
      states: dto.states,
    };
  }
  static createDTO(model: ICountry): ICountryDTO {
    return {
      id: model.countryId,
      name: model.name,
      states: model.states,
    };
  }
}
