import { EDoctorSpecializationDTO, getDoctorSpecializationLocalization } from '../consts/doctor-specialization';
import { EGenderDTO, getGenderLocalization } from '../consts/gender';

interface IPatientProfileSummaryInfoData {
  age?: number;
  gender?: EGenderDTO;
  addressFields?: (string | undefined)[];
}

interface IDoctorProfileSummaryInfoData {
  specialization?: EDoctorSpecializationDTO;
}

export class ProfileHelper {
  static getFullName(firstName: string, lastName: string, fallbackName: string): string {
    const names: string[] = [firstName, lastName].filter((name) => !!name);

    if (!names.length) {
      names.push(fallbackName);
    }

    return names.join(' ');
  }

  static getDoctorSummaryInfo(data: IDoctorProfileSummaryInfoData): string[] {
    const summary: string[] = [];

    data.specialization && summary.push(getDoctorSpecializationLocalization(data.specialization));

    return summary.filter((item) => !!item);
  }

  static getPatientSummaryInfo(data: IPatientProfileSummaryInfoData): string[] {
    const summary: string[] = [];

    data.age && summary.push(data.age + '');
    data.gender && summary.push(getGenderLocalization(data.gender));
    data.addressFields &&
      data.addressFields.length &&
      summary.push(data.addressFields.filter((item) => !!item).join(', '));

    return summary.filter((item) => !!item);
  }
}
