import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env';
import {
  IRequisitionLoginLogs,
  IRequisitionLoginItemDTO,
  RequisitionLoginLogViewModelFactory,
} from '@project/view-models';
import { ApiService, IPagingResponse, PagingApiService } from '@core/http';
import { map } from 'rxjs/operators';
import { IRequisitionLoginLogsFiltersParams } from '@project/data-providers';

@Injectable({
  providedIn: 'root',
})
export class RequisitionLoginLogsApiProviderService {
  constructor(private apiService: ApiService, private pagingApiService: PagingApiService) {}

  getById(): Observable<IRequisitionLoginLogs> {
    return this.apiService
      .get<IRequisitionLoginItemDTO>(`${environment.environmentVariables.apiCoreUrl}/Profiles/accesses`)
      .pipe(
        map((dto) => {
          return RequisitionLoginLogViewModelFactory.createGetViewModel(dto);
        }),
      );
  }

  getPaged(
    page: number,
    itemsPerPage: number,
    query?: IRequisitionLoginLogsFiltersParams,
  ): Observable<IPagingResponse<IRequisitionLoginLogs>> {
    return this.pagingApiService
      .request<IRequisitionLoginItemDTO, IRequisitionLoginLogs>({
        url: `${environment.environmentVariables.apiCoreUrl}/Profiles/accesses`,
        additionalQueryParams: query,
        itemsPerPage,
        itemFactory: (item) => RequisitionLoginLogViewModelFactory.createGetViewModel(item),
        pageNumber: page,
      })
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }
}
