import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class BrokenCsvStructure extends Error {
  name: 'BrokenCsvStructure';
  constructor(public readonly message: string = 'Unable to parse content') {
    super();
    Object.setPrototypeOf(this, BrokenCsvStructure.prototype);
  }
}

export interface IParseCsvOptions {
  skipFirstRow: boolean;
  columnSeparatorPattern: RegExp | string;
  rowSeparatorPattern: RegExp | string;
}

const DEFAULT_PARSE_CSV_OPTIONS: IParseCsvOptions = {
  skipFirstRow: true,
  columnSeparatorPattern: ';',
  rowSeparatorPattern: /\r\n|\n|\r/,
};

@Injectable({
  providedIn: 'root',
})
export class CsvParserService {
  parseFile<Key extends string, TRecord extends { [K in Key]: string }>(
    file: File,
    columnNames: Key[],
    customOptions?: Partial<IParseCsvOptions>,
  ): Observable<TRecord[]> {
    const options = { ...DEFAULT_PARSE_CSV_OPTIONS, ...(customOptions ?? {}) };
    return defer(() => file.text()).pipe(map((fileContent) => this.parseText(fileContent, columnNames, options)));
  }

  parseText<Keys extends string, TRecord = { [K in Keys]: string }>(
    content: string,
    columnNames: Keys[],
    customOptions?: Partial<IParseCsvOptions>,
  ): TRecord[] {
    const options = { ...DEFAULT_PARSE_CSV_OPTIONS, ...(customOptions ?? {}) };
    const columnsLength = columnNames.length;

    let rows = content.split(options.rowSeparatorPattern);

    // To catch case with empty last line
    if (rows.length > 0 && rows[rows.length - 1] === '') {
      rows = rows.slice(0, -1);
    }

    if (options.skipFirstRow) {
      if (rows.length === 0) {
        throw new BrokenCsvStructure();
      }

      rows = rows.slice(1);
    }

    return rows.map(
      (rowText): TRecord => {
        const columns = rowText.split(options.columnSeparatorPattern);
        if (columns.length !== columnsLength) {
          throw new BrokenCsvStructure();
        }
        return columns.reduce((acc, value, index) => {
          acc[columnNames[index] as string] = value;
          return acc;
        }, {}) as TRecord;
      },
    );
  }
}
