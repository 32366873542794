import { Pipe, PipeTransform } from '@angular/core';

type IntervalValueType = 'seconds' | 'milliseconds';

@Pipe({
  name: 'libSecondsToTime',
})
export class SecondsToTimePipe implements PipeTransform {
  /**
   * Output format is '[[h*]hh]:[mm]:[ss]' or '[mm]:[ss]'
   */
  transform(value: number | null, valueType: IntervalValueType = 'seconds'): string {
    if (!value || value < 0) {
      return '';
    }

    const secondsAtAll = this.extractSeconds(value, valueType);

    const hours = Math.floor(secondsAtAll / 60 / 60);
    const minutes = Math.floor(secondsAtAll / 60) - hours * 60;
    const seconds = secondsAtAll - minutes * 60 - hours * 60 * 60;

    let formattedString = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    if (hours) {
      formattedString = `${hours.toString().padStart(2, '0')}:${formattedString}`;
    }

    return formattedString;
  }

  private extractSeconds(value: number, type: IntervalValueType): number {
    if (type === 'seconds') {
      return Math.floor(value);
    }
    if (type === 'milliseconds') {
      return Math.floor(value / 1000);
    }
  }
}
