import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountdownDirective } from './countdown.directive';

@NgModule({
  declarations: [CountdownDirective],
  exports: [CountdownDirective],
  imports: [CommonModule],
})
export class CountdownDirectiveModule {}
