import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AuthorizationApiProvider } from '@project/data-providers';
import { AuthorisationStateService } from '@project/services';
import { LocalStorageService } from 'src/app/project/services/local-storage.service';

enum EPeopleInQueueStatuses {
  Few = 'few',
  Medium = 'medium',
  Lot = 'lot',
}

@Component({
  selector: 'app-people-in-queue-card',
  templateUrl: './people-in-queue-card.component.html',
  styleUrls: ['./people-in-queue-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeopleInQueueCardComponent {
  @Input() peopleInQueueCount: number;
  @Input() healthCenter?: any;
  @Input() disabled?: boolean;

  constructor(private authorizationStateService: AuthorisationStateService) {}

  get queueStatus(): EPeopleInQueueStatuses {
    return this.calculateQueueStatus(this.peopleInQueueCount);
  }

  private calculateQueueStatus(peopleInQueue: number): EPeopleInQueueStatuses {
    if (typeof peopleInQueue !== 'number') {
      return null;
    }

    if (peopleInQueue < 5) {
      return EPeopleInQueueStatuses.Few;
    }

    if (peopleInQueue < 12) {
      return EPeopleInQueueStatuses.Medium;
    }

    return EPeopleInQueueStatuses.Lot;
  }

  public impersonate(id: string) {
    this.authorizationStateService.impersonateHealthCenter(id).subscribe();
  }
}
