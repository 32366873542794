import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TGuid } from '@core/helpers';
import { map } from 'rxjs/operators';

@Injectable()
export class ListItemsService<TItem extends { id: TGuid } = { id: TGuid }> {
  private readonly _currentPageItems$ = new BehaviorSubject<TItem[]>([]);
  private readonly _selectedIds$ = new BehaviorSubject<TGuid[]>([]);

  public readonly currentPageItems$: Observable<TItem[]> = this._currentPageItems$.asObservable();
  public readonly selectedIds$ = this._selectedIds$.asObservable();
  public readonly selectedIdsCount$: Observable<number> = this.selectedIds$.pipe(map((items) => items.length));
  public readonly hasSelectedIds$: Observable<boolean> = this.selectedIds$.pipe(map((items) => !!items.length));
  public readonly areAllItemsOnPageSelected$: Observable<boolean> = this.selectedIds$.pipe(
    map((items) => !!items.length && items.length === this._currentPageItems$.value.length),
  );

  get selectedIds(): TGuid[] {
    return this._selectedIds$.value.slice();
  }

  get currentPageIds(): TGuid[] {
    return this._currentPageItems$.value.map((item) => item.id);
  }

  public getItems(): TItem[] {
    return this._currentPageItems$.value.slice();
  }

  public getItem(id: TGuid): TItem | null {
    return this._currentPageItems$.value.find((item) => item.id === id);
  }

  public setItems(items: TItem[]) {
    this.clearSelection();
    this._currentPageItems$.next(items);
  }

  public selectCurrentPageItems() {
    const ids = this._currentPageItems$.value.map((item) => item.id);
    this._selectedIds$.next(ids);
  }

  public clearSelection() {
    this._selectedIds$.next([]);
  }

  public removeSelection(id: TGuid) {
    const currentIds = this._selectedIds$.value;

    if (currentIds.includes(id)) {
      this._selectedIds$.next(currentIds.filter((currentId) => currentId !== id));
    }
  }

  public addSelection(id: TGuid) {
    const currentIds = this._selectedIds$.value;

    if (!currentIds.includes(id)) {
      this._selectedIds$.next(currentIds.concat([id]));
    }
  }

  public addItemsToCurrentPage(items: TItem[]) {
    this._currentPageItems$.next(this._currentPageItems$.value.concat(items));
  }

  public removeItemFromCurrentPage(id: TGuid) {
    const currentItems = this._currentPageItems$.value;
    this._currentPageItems$.next(currentItems.filter((item) => item.id !== id));
    this.removeSelection(id);
  }

  public removeAllItemsFromCurrentPage() {
    const currentItems = this._currentPageItems$.value;
    const currentIds = this._selectedIds$.value;
    currentItems.pop();
    this._currentPageItems$.next(currentItems);
    currentIds.pop();
    this._selectedIds$.next(currentIds);
  }

  public removeLastSelection() {
    const currentIds = this._selectedIds$.value;

    currentIds.pop();
    this._selectedIds$.next(currentIds);
  }

  public updateItem(item: TItem) {
    const currentItems = this._currentPageItems$.value;
    this._currentPageItems$.next(currentItems.map((currentItem) => (currentItem.id === item.id ? item : currentItem)));
    this.removeSelection(item.id);
  }
}
