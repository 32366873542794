import { TGuid } from '@core/helpers';
import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { environment } from '@env';
import {
  AddressViewModelFactory,
  IAddress,
  IAddressDTO,
  CountryViewModelFactory,
  ICountry,
  ICountryDTO,
  StateViewModelFactory,
  IState,
  IStateDTO,
} from '@project/view-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AddressesApiProviderService {
  constructor(private apiService: ApiService) {}

  getMyCountry(): Observable<ICountry[]> {
    return this.apiService
      .get<ICountryDTO[]>(`${environment.environmentVariables.apiCoreUrl}/Country`)
      .pipe(map((dto) => dto.map((dto) => CountryViewModelFactory.createFromDTO(dto))));
  }

  getMyState(id: TGuid): Observable<IState[]> {
    return this.apiService
      .get<IStateDTO[]>(`${environment.environmentVariables.apiCoreUrl}/State/country/${id}`)
      .pipe(map((dto) => dto.map((dto) => StateViewModelFactory.createFromDTO(dto))));
  }

  getMyZipCode(zipCode: string): Observable<IAddress> {
    return this.apiService
      .get<IAddressDTO>(`${environment.environmentVariables.apiCoreUrl}/Addresses/zipcode/${zipCode}/Brazil`)
      .pipe(map((dto) => AddressViewModelFactory.createFromDTO(dto)));
  }

  setAddress(address: IAddress) {
    return this.apiService.post(
      `${environment.environmentVariables.apiCoreUrl}/Addresses/me`,
      AddressViewModelFactory.createDTO(address),
    );
  }

  getMyAddress(): Observable<IAddress> {
    return this.apiService
      .get(`${environment.environmentVariables.apiCoreUrl}/Addresses/me`)
      .pipe(map((dto) => AddressViewModelFactory.createFromDTO(dto)));
  }
}
