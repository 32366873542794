import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';

export interface IRecordValidationResult<T> {
  record: T;
  isValid: boolean;
  formErrors: IFormErrors;
}

export interface IRowValidationResult<T> extends IRecordValidationResult<T> {
  rowIndex: number;
}

export interface IFormErrors {
  [formControlNameOrIndex: string]: ValidationErrors | IFormErrors;
}

export class FormHelpers {
  static validateAllFields(control: AbstractControl) {
    if (control instanceof FormControl) {
      control.markAsTouched();
      control.updateValueAndValidity();
      return;
    }

    if (control instanceof FormGroup) {
      Object.keys(control.controls)
        .map((key) => control.controls[key])
        .forEach((nestedControl) => {
          FormHelpers.validateAllFields(nestedControl);
        });
      return;
    }

    if (control instanceof FormArray) {
      control.controls.forEach((nestedControl) => FormHelpers.validateAllFields(nestedControl));
      return;
    }
  }

  static mergeFormGroups(source: FormGroup, target: FormGroup) {
    Object.keys(source.controls).forEach((key) => {
      const sourceControl = source.get(key);

      if (!target.contains(key)) {
        target.addControl(key, source.get(key));
        return;
      }

      const targetControl = target.get(key);

      if (!targetControl.validator && sourceControl.validator) {
        targetControl.setValidators(sourceControl.validator);
      }

      function isEmpty(value: any): boolean {
        return [null, '', undefined].includes(value);
      }

      if (isEmpty(targetControl.value) && !isEmpty(sourceControl.value)) {
        targetControl.patchValue(sourceControl.value);
      }
    });
  }

  static validateRecord<T>(record: T, formGroup: FormGroup): IRecordValidationResult<T> {
    formGroup.reset();
    formGroup.setValue(record);
    // Manually validate every field in form to be sure all fields are valid
    this.validateAllFields(formGroup);

    return {
      formErrors: this.getFormControlsErrors(formGroup.controls),
      isValid: formGroup.valid,
      record,
    };
  }

  static validateRecords<T>(records: T[], formGroup: FormGroup): IRowValidationResult<T>[] {
    return records.map((item, index) => ({ ...this.validateRecord(item, formGroup), rowIndex: index }));
  }

  static getFormControlsErrors(formGroupControls: { [key: string]: AbstractControl }): IFormErrors {
    const errors: IFormErrors = {};

    for (const controlName in formGroupControls) {
      if (!formGroupControls.hasOwnProperty(controlName)) {
        continue;
      }

      const verifiableControl = formGroupControls[controlName];
      if (verifiableControl.valid) {
        continue;
      }

      if (verifiableControl instanceof FormControl) {
        errors[controlName] = verifiableControl.errors;
      }
      if (verifiableControl instanceof FormGroup) {
        errors[controlName] = this.getFormControlsErrors(verifiableControl.controls);
      }
      if (verifiableControl instanceof FormArray) {
        const controlsObject = verifiableControl.controls.reduce((acc, controlInArray, index) => {
          acc[index] = controlInArray;
          return acc;
        }, {});

        errors[controlName] = this.getFormControlsErrors(controlsObject);
      }
    }

    return errors;
  }
}
