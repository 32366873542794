import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarPreviewComponent } from './avatar-preview/avatar-preview.component';
import { BackgroundImageModule } from '@lib/ui';

@NgModule({
  declarations: [AvatarPreviewComponent],
  imports: [CommonModule, BackgroundImageModule],
  exports: [AvatarPreviewComponent],
})
export class AvatarModule {}
