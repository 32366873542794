<select
  #select
  [attr.data-appearance]="appearance"
  [attr.data-size]="size"
  [attr.readonly]="completed || null"
  [attr.is-placeholder]="!(value$ | async) || null"
  [disabled]="completed || (disabled$ | async)"
  (change)="onChangeHandler(select.value)"
  (blur)="onBlur($event)"
>
  <option *ngIf="placeholder" [value]="''" [selected]="true">{{ placeholder }}</option>
  <ng-container *ngFor="let option of options">
    <option *ngIf="option.value" [value]="option.value" [selected]="option.value === (value$ | async)">{{
      option.label
    }}</option>
  </ng-container>
</select>
<lib-input-icon
  type="select-arrow"
  class="overlapping-icon icon-arrow"
  [size]="size"
  [class.disabled]="completed || (disabled$ | async)"
></lib-input-icon>
<lib-input-icon type="warning" class="overlapping-icon icon-warning" [size]="size"></lib-input-icon>
<lib-input-icon type="completed" class="overlapping-icon icon-completed" [size]="size"></lib-input-icon>
