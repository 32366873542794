import { Injectable } from '@angular/core';

@Injectable()
export abstract class AbstractFeatureFlagService<TFeature extends string> {
  isFeatureOn(feature: TFeature): boolean {
    return this.checkFeature(feature);
  }

  everyFeatureOn(features: TFeature[]): boolean {
    return features.every((feature) => this.isFeatureOn(feature));
  }

  someFeatureOn(features: TFeature[]): boolean {
    return features.some((feature) => this.isFeatureOn(feature));
  }

  protected abstract checkFeature(feature: TFeature): boolean;
}
