import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { FormControlComponent } from './form-control/form-control.component';
import { FormLabelComponent } from './form-label/form-label.component';
import { InputComponent } from './input/input.component';
import { InputIconComponent } from './input-icon/input-icon.component';
import { SelectComponent } from './select/select.component';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { TextareaComponent } from './textarea/textarea.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { SpinnerModule } from '@lib/ui/spinner';
import { IconsModule } from '@lib/icons';

@NgModule({
  declarations: [
    CheckboxComponent,
    FormControlComponent,
    FormLabelComponent,
    InputComponent,
    InputIconComponent,
    SelectComponent,
    TextareaComponent,
    SearchInputComponent,
  ],
  exports: [
    CheckboxComponent,
    FormControlComponent,
    FormLabelComponent,
    InputComponent,
    SelectComponent,
    ReactiveFormsModule,
    FormsModule,
    TextareaComponent,
    SearchInputComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxTrimDirectiveModule,
    NgxMaskModule.forRoot(),
    SpinnerModule,
    IconsModule,
  ],
})
export class FormElementsModule {}
