import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { RoutesBuilderService, SITE_USER_MODULES, SITE_USER_SETTINGS_MODULES } from '@app/config';
import { EUserPermission } from '@project/shared';
import { TranslateService } from '@project/translate';
import { EFeatureFlags, ESidebarIconType, ISiteModule } from '@app/config';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { IProfileFull, EProfileTypeDTO, IDoctorHealthCenterDTO } from '@project/view-models';
import { UserProfileDataService } from '@project/services';
import { AuthorisationStateService } from '@project/services';
import { DoctorsApiProviderService } from '@project/data-providers';
import { commonEnvironment } from '../../../../../environments/common';

interface IMenuItem {
  title: string;
  routeLink: string;
  userPermissions: EUserPermission[];
  icon?: ESidebarIconType;
  featureFlag?: EFeatureFlags;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit, OnDestroy {
  public readonly RoutesBuilderService = RoutesBuilderService;
  private _healthCenters$ = new BehaviorSubject<IDoctorHealthCenterDTO[]>([]);
  public healthCenters$ = this._healthCenters$.asObservable();
  public selectedHC: string;
  public disabledSwitch = false;
  private listener: any;
  public version: string = commonEnvironment.appVersion;

  constructor(
    private router: Router,
    private userProfileDataService: UserProfileDataService,
    private authorizationStateService: AuthorisationStateService,
    private doctorsApiProviderService: DoctorsApiProviderService,
  ) {}

  ngOnInit(): void {
    this.userProfileDataService.isDoctor().subscribe((isDoctor) => {
      if (isDoctor) {
        this.doctorsApiProviderService
          .getAllHealthCenters(this.userProfileDataService.profile.id)
          .subscribe((healthCenters) => {
            this._healthCenters$.next(healthCenters);
          });

        this.selectedHC = this.authorizationStateService.getAuthorisationData().healthCenterId;

        this.listener = window.addEventListener('specialist-busy', (event) => {
          this.disabledSwitch = event['detail'];
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.listener) {
      window.removeEventListener('specialist-busy', this.listener);
    }
  }

  public userData$: Observable<IProfileFull | null> = this.userProfileDataService.profile$;

  public userType: string = JSON.parse(window.localStorage.getItem('auth')).userType;
  public isPatient: boolean = this.userType === EProfileTypeDTO.Patient;

  @Output() respToggled = new EventEmitter();

  public readonly navigationMenu = [
    {
      title: TranslateService.localize('sidebar.menu-labels.sessions'),
      routeLink: RoutesBuilderService.SESSIONS.root(),
      userPermissions: [EUserPermission.havePatientSession],
      icon: ESidebarIconType.Document,
    },
    {
      title: TranslateService.localize('sidebar.menu-labels.sessions'),
      routeLink: RoutesBuilderService.SESSIONS.root(),
      userPermissions: [EUserPermission.haveDoctorSession],
      icon: ESidebarIconType.Document,
    },
    ...this.mapSiteModulesToSidebarItems(SITE_USER_MODULES),
  ];

  public readonly settingsMenu: IMenuItem[] = [...this.mapSiteModulesToSidebarItems(SITE_USER_SETTINGS_MODULES)];

  public readonly logoutMenuItem: IMenuItem = {
    title: TranslateService.localize('actions.logout'),
    routeLink: RoutesBuilderService.LOGOUT.logout(),
    userPermissions: [],
    icon: ESidebarIconType.Logout,
  };

  hideMenu(): void {
    this.respToggled.emit(false);
  }

  private mapSiteModulesToSidebarItems(modules: ISiteModule[]): IMenuItem[] {
    return modules.map((module) => {
      return {
        title: module.sidebarLabelCreator(),
        routeLink: module.rootFullRoute,
        userPermissions: module.permissions ?? [],
        icon: module.sidebarIcon,
      };
    });
  }

  public onUserCardClick(): void {
    this.router.navigateByUrl(this.router.url + `(globalAside:information-center/${this.userType}`);
  }

  public impersonate(healthCenterId): void {
    if (this.selectedHC === healthCenterId) {
      return;
    }

    this.authorizationStateService.impersonateHealthCenter(healthCenterId).subscribe((hc) => {
      this.selectedHC = hc.healthCenterId;

      window.location.reload();
    });
  }
}
