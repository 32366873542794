import { DateFormatter, TDateISOString, TGuid } from '@core/helpers';
import {
  ERequisitionStageTypeDTO,
  ERequisitionStatusDTO,
  getRequisitionStageTypeLocalization,
  getRequisitionStatusLocalization,
} from './consts/requisition-consts';
import { EProfileTypeDTO, getProfileTypeLocalization } from './consts/profile-type';
import { IProfileShort, IProfileShortDTO, ProfileViewModelFactory } from './profile.view-model';
import { TranslateService } from '@project/translate';

export interface IDays {
  id: number;
  description: string;
}

export interface IRequisitionLogItemDTO {
  id: TGuid;
  status: ERequisitionStatusDTO;
  last_stage_type: ERequisitionStageTypeDTO;
  doctor_has_video_call: boolean;
  doctor_duration: number | null;
  receptionist_has_video_call: boolean;
  receptionist_duration: number | null;
  session_start_date: TDateISOString | null;
  date_started: TDateISOString;
  closing_author_role: EProfileTypeDTO;
  date_closing: TDateISOString | null;
  receptionist: IProfileShortDTO | null;
  doctor: IProfileShortDTO | null;
  patient: IProfileShortDTO | null;
}

interface ISessionDetails {
  hasVideoCall: boolean;
  hasVideoCallLabel: string;
  durationSeconds: number | null;
  specialistProfile: IProfileShort | null;
}

export interface IRequisitionLogsItem {
  id: TGuid;
  status: ERequisitionStatusDTO;
  statusLabel: string;
  lastStageType: ERequisitionStageTypeDTO;
  lastStageTypeLabel: string;
  sessionStartDate: Date;
  dateStart: Date;
  dateEnd: Date | null;
  closingAuthorRole: EProfileTypeDTO;
  closingAuthorRoleLabel: string;
  patient: IProfileShort | null;
  doctor: IProfileShort | null;
  withDoctorSessionDetails: ISessionDetails | null;
  withReceptionistSessionDetails: ISessionDetails | null;
}

export interface IRequisitionLogsPatientDTO {
  id: TGuid;
  doctor_name: string;
  patient_name: string;
  requested_at: TDateISOString;
  started_at: TDateISOString;
  closed_at: TDateISOString;
}
export interface IRequisitionLogsPatient {
  id: TGuid;
  doctor_name: string;
  patient_name: string;
  requested_at: Date;
  started_at: Date;
  closed_at: Date;
}

export interface IRequisitionLoginLogs {
  user_agent: string;
  role: string;
  first_name: string;
  last_name: string;
  email: string;
  ip_address: string;
  login_at: Date;
}

export interface IRequisitionLoginItemDTO {
  user_agent: string;
  role: string;
  first_name: string;
  last_name: string;
  email: string;
  ip_address: string;
  login_at: TDateISOString;
}

export class RequisitionLogPatientViewModelFactory {
  static createGetViewModel(dto: IRequisitionLogsPatientDTO): IRequisitionLogsPatient {
    return {
      id: dto.id,
      patient_name: dto.patient_name,
      doctor_name: dto.doctor_name,
      requested_at: DateFormatter.stringToDate(dto.requested_at),
      started_at: DateFormatter.stringToDate(dto.started_at),
      closed_at: DateFormatter.stringToDate(dto.closed_at),
    };
  }
}

export class RequisitionLoginLogViewModelFactory {
  static createGetViewModel(dto: IRequisitionLoginItemDTO): IRequisitionLoginLogs {
    return {
      user_agent: dto.user_agent,
      role: dto.role,
      first_name: dto.first_name,
      last_name: dto.last_name,
      email: dto.email,
      ip_address: dto.ip_address,
      login_at: DateFormatter.stringToDate(dto.login_at),
    };
  }
}
export class RequisitionLogItemViewModelFactory {
  static createGetViewModel(dto: IRequisitionLogItemDTO): IRequisitionLogsItem {
    return {
      id: dto.id,
      status: dto.status,
      statusLabel: getRequisitionStatusLocalization(dto.status),
      lastStageType: dto.last_stage_type,
      lastStageTypeLabel: getRequisitionStageTypeLocalization(dto.last_stage_type),
      sessionStartDate: DateFormatter.stringToDate(dto.session_start_date),
      dateStart: DateFormatter.stringToDate(dto.date_started),
      dateEnd: dto.date_closing ? DateFormatter.stringToDate(dto.date_closing) : null,
      closingAuthorRole: dto.closing_author_role,
      closingAuthorRoleLabel: getProfileTypeLocalization(dto.closing_author_role),
      patient: dto.patient ? ProfileViewModelFactory.createShort(dto.patient) : null,
      doctor: dto.doctor ? ProfileViewModelFactory.createShort(dto.doctor) : null,
      withDoctorSessionDetails: this.createSessionDetails(dto.doctor, dto.doctor_duration, dto.doctor_has_video_call),
      withReceptionistSessionDetails: this.createSessionDetails(
        dto.receptionist,
        dto.receptionist_duration,
        dto.receptionist_has_video_call,
      ),
    };
  }

  private static createSessionDetails(
    profile: IProfileShortDTO | null,
    duration: number | null,
    hasVideoCall: boolean | null,
  ): ISessionDetails | null {
    if (!profile) {
      return null;
    }

    return {
      specialistProfile: ProfileViewModelFactory.createShort(profile),
      durationSeconds: duration > 0 ? Math.floor(duration) : null,
      hasVideoCall,
      hasVideoCallLabel: hasVideoCall
        ? TranslateService.localize('modals.confirm.actions.yes')
        : TranslateService.localize('modals.confirm.actions.no'),
    };
  }
}
