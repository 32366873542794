import { TGuid } from '@core/helpers';

export interface IStateDTO {
  id: TGuid;
  name: string;
  country_id: TGuid;
}

export interface IState {
  state_id: TGuid;
  name: string;
  country_id: TGuid;
}

export class StateViewModelFactory {
  static createFromDTO(dto: IStateDTO): IState {
    return {
      state_id: dto.id,
      name: dto.name,
      country_id: dto.country_id,
    };
  }
  static createDTO(model: IState): IStateDTO {
    return {
      id: model.state_id,
      name: model.name,
      country_id: model.country_id,
    };
  }
}
