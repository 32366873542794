import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env';
import {
  IRequisitionLogsPatient,
  IRequisitionLogsPatientDTO,
  RequisitionLogPatientViewModelFactory,
} from '@project/view-models';
import { ApiService, IPagingResponse, PagingApiService } from '@core/http';
import { TGuid } from '@core/helpers';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DoctorEncounterHistoryApiProviderService {
  constructor(private apiService: ApiService, private pagingApiService: PagingApiService) {}

  getById(requisitionId: TGuid): Observable<IRequisitionLogsPatient> {
    return this.apiService
      .get<IRequisitionLogsPatientDTO>(
        `${environment.environmentVariables.apiCoreUrl}/Requisition/${requisitionId}/logs`,
      )
      .pipe(map((dto) => RequisitionLogPatientViewModelFactory.createGetViewModel(dto)));
  }

  getPaged(
    page: number,
    itemsPerPage: number,
    query?: Record<string, unknown>,
  ): Observable<IPagingResponse<IRequisitionLogsPatient>> {
    return this.pagingApiService.request<IRequisitionLogsPatientDTO, IRequisitionLogsPatient>({
      url: `${environment.environmentVariables.apiCoreUrl}/Requisition/logs/doctor`,
      additionalQueryParams: query,
      itemsPerPage,
      itemFactory: (item) => RequisitionLogPatientViewModelFactory.createGetViewModel(item),
      pageNumber: page,
    });
  }

  getPagedAside(
    id: TGuid,
    page: number,
    itemsPerPage: number,
    query?: Record<string, unknown>,
  ): Observable<IPagingResponse<IRequisitionLogsPatient>> {
    return this.pagingApiService.request<IRequisitionLogsPatientDTO, IRequisitionLogsPatient>({
      url: `${environment.environmentVariables.apiCoreUrl}/Requisition/logs/doctor/${id}`,
      additionalQueryParams: query,
      itemsPerPage,
      itemFactory: (item) => RequisitionLogPatientViewModelFactory.createGetViewModel(item),
      pageNumber: page,
    });
  }
}
