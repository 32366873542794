import { Injectable } from '@angular/core';
import { UserProfileDataService } from './user-profile-data.service';
import {
  EProfileTypeDTO,
  IDoctor,
  IPatient,
  IReceptionist,
  IRequisition,
  IRequisitionParticipant,
  RequisitionParticipantViewModel,
} from '@project/view-models';
import { TGuid } from '@core/helpers';

@Injectable({ providedIn: 'root' })
export class RequisitionHelpersService {
  constructor(private userProfileDataService: UserProfileDataService) {}

  getRequisitionParticipantForCurrentUser(requisition: IRequisition | null): IRequisitionParticipant | null {
    if (!requisition) {
      return null;
    }

    const currentUserProfile: EProfileTypeDTO = this.userProfileDataService.profile?.profileType;

    if (!currentUserProfile) {
      return null;
    }

    const requisitionParticipantExtractor: Partial<Record<
      EProfileTypeDTO,
      () => IDoctor | IPatient | IReceptionist | undefined
    >> = {
      [EProfileTypeDTO.Receptionist]: () => requisition.patient,
      [EProfileTypeDTO.Doctor]: () => requisition.patient,
      [EProfileTypeDTO.Patient]: () => requisition.doctors[0] || requisition.receptionist,
    };

    const requisitionProfileForParticipant = requisitionParticipantExtractor[currentUserProfile]?.();

    return requisitionProfileForParticipant
      ? RequisitionParticipantViewModel.create(requisitionProfileForParticipant)
      : null;
  }

  getParticipantById(requisition: IRequisition, id: TGuid): IRequisitionParticipant | null {
    return this.getParticipants(requisition).find((participant) => participant.id === id);
  }

  private getParticipants(requisition: IRequisition): IRequisitionParticipant[] {
    const raws: (IDoctor | IPatient | IReceptionist)[] = [
      requisition.patient,
      requisition.receptionist,
      ...requisition.doctors,
    ].filter((user) => !!user);
    return raws.map((user) => RequisitionParticipantViewModel.create(user));
  }
}
