import { Injectable } from '@angular/core';
import { delay, exhaustMap, filter, first, map, retryWhen, share, switchMap, takeUntil, tap } from 'rxjs/operators';
import { WebsocketProvider } from '@core/providers';
import { BehaviorSubject, empty, merge, Observable, of, OperatorFunction, Subject, throwError } from 'rxjs';
import {
  EServiceSocketMessageTypeDTO,
  ESocketMessageTypeDTO,
  IChatMessage,
  IUserConnectedEventDTO,
  IUserDisconnectedEventDTO,
  IServiceMessageEvent,
  IServiceMessageEventDTO,
  IQueueInfoUpdateEvent,
  IQueueInfoUpdateEventDTO,
  TChatSocketMessageType,
  IChatUpdateEvent,
  IChatUpdateEventDTO,
  IUserConnectedEvent,
  IUserDisconnectedEvent,
  UserConnectionEventSocketMessageViewModelFactory,
  ChatMessageEventSocketMessageViewModelFactory,
  QueueInfoUpdateEventSocketMessageViewModelFactory,
  ServiceMessageEventSocketMessageViewModelFactory,
  IChatMessageEventDTO,
  ISocketMessage,
  ChatUpdateEventSocketMessageViewModelFactory,
  IRequisitionUpdateEvent,
  IRequisitionUpdateEventDTO,
  RequisitionUpdateEventSocketMessageViewModelFactory,
  IDoctorStatusUpdateEvent,
  IDoctorStatusUpdateEventDTO,
  DoctorStatusUpdateEventSocketMessageViewModelFactory,
} from '@project/view-models';
import { WebsocketApiProviderService } from './api-providers/websocket-api-provider.service';
import { IFeatureService } from '@project/services';
import { environment } from '@env';
import { ConstWsSocketMessagesEnum } from '../shared/ws-socket.messages.enum';

const RECONNECT_TIMEOUT_MS = 3000;

/**
 * TODO:
 *  * Thinks about extract messages streams to different classes (by type maybe);
 */
@Injectable({
  providedIn: 'root',
})
export class SocketMessagesDataProviderService implements IFeatureService {
  private chatWebsocket = new WebsocketProvider();
  private _isInitialized$ = new BehaviorSubject<boolean>(false);

  private socketMessage$: Observable<ISocketMessage> = this.chatWebsocket.messages$.pipe(
    filter(() => this._isInitialized$.value),
    map((messageString: string) => {
      try {
        return JSON.parse(messageString);
      } catch (e) {
        return null;
      }
    }),
    filter((message) => !!message),
    map((message) => {
      try {
        if (message.attachment) {
          message.attachment = JSON.parse(message.attachment);

          message.text = message.attachment.original;
        }

        return message;
      } catch (e) {
        return message;
      }
    }),
    tap((message) => {
      if (!environment.production) {
        const now = new Date();
        console.log(`---> Socket Message :: ${now.toLocaleTimeString()}`, message);
      }
    }),
    share(),
  );

  public disconnectConflict$: Observable<void> = this.socketMessage$.pipe(
    this.filterByTypes([ConstWsSocketMessagesEnum.DisconnectThroughMultipleDevices]),
    map(() => null),
  );

  public chatMessage$: Observable<IChatMessage> = this.socketMessage$.pipe(
    this.filterByTypes([ESocketMessageTypeDTO.ChatMessage]),
    map((messageDto: IChatMessageEventDTO) =>
      ChatMessageEventSocketMessageViewModelFactory.createMessageFromDTO(messageDto),
    ),
  );

  public queueInfoUpdate$: Observable<IQueueInfoUpdateEvent> = this.socketMessage$.pipe(
    this.filterByTypes([
      ESocketMessageTypeDTO.ReceptionistQueueInformation,
      ESocketMessageTypeDTO.PatientsQueueInformation,
      ESocketMessageTypeDTO.DoctorsQueueInformation,
    ]),
    map((messageDto: IQueueInfoUpdateEventDTO) =>
      QueueInfoUpdateEventSocketMessageViewModelFactory.createMessageFromDto(messageDto),
    ),
  );

  public callStarted$: Observable<IServiceMessageEvent> = this.socketMessage$.pipe(
    this.filterByTypes([EServiceSocketMessageTypeDTO.CallStarted]),
    map((serviceMessageDto: IServiceMessageEventDTO) =>
      ServiceMessageEventSocketMessageViewModelFactory.createEventMessageFromDTO(serviceMessageDto),
    ),
  );

  public callEnded$: Observable<IServiceMessageEvent> = this.socketMessage$.pipe(
    this.filterByTypes([EServiceSocketMessageTypeDTO.CallEnded]),
    map((serviceMessageDto: IServiceMessageEventDTO) =>
      ServiceMessageEventSocketMessageViewModelFactory.createEventMessageFromDTO(serviceMessageDto),
    ),
  );

  public callDeclined$: Observable<IServiceMessageEvent> = this.socketMessage$.pipe(
    this.filterByTypes([EServiceSocketMessageTypeDTO.CallDeclined]),
    map((serviceMessageDto: IServiceMessageEventDTO) =>
      ServiceMessageEventSocketMessageViewModelFactory.createEventMessageFromDTO(serviceMessageDto),
    ),
  );

  /**
   * @deprecated
   * use requisitionUpdated$
   */
  public chatCreated$: Observable<IChatUpdateEvent> = this.socketMessage$.pipe(
    this.filterByTypes([ESocketMessageTypeDTO.ChatCreated]),
    map((serviceMessageDto: IChatUpdateEventDTO) =>
      ChatUpdateEventSocketMessageViewModelFactory.createChatEventFromDTO(serviceMessageDto),
    ),
  );

  /**
   * @deprecated
   * use requisitionUpdated$
   */
  public chatArchived$: Observable<IChatUpdateEvent> = this.socketMessage$.pipe(
    this.filterByTypes([ESocketMessageTypeDTO.ChatArchived]),
    map((serviceMessageDto: IChatUpdateEventDTO) =>
      ChatUpdateEventSocketMessageViewModelFactory.createChatEventFromDTO(serviceMessageDto),
    ),
  );

  public awake$: Observable<IServiceMessageEvent> = this.socketMessage$.pipe(
    this.filterByTypes([EServiceSocketMessageTypeDTO.Awake]),
    map((serviceMessageDto: IServiceMessageEventDTO) =>
      ServiceMessageEventSocketMessageViewModelFactory.createEventMessageFromDTO(serviceMessageDto),
    ),
  );

  public userConnected$: Observable<IUserConnectedEvent> = this.socketMessage$.pipe(
    this.filterByTypes([ESocketMessageTypeDTO.UserConnected]),
    map((messageDto: IUserConnectedEventDTO) =>
      UserConnectionEventSocketMessageViewModelFactory.createConnectedEventFromDTO(messageDto),
    ),
  );

  public userDisconnected$: Observable<IUserDisconnectedEvent> = this.socketMessage$.pipe(
    this.filterByTypes([ESocketMessageTypeDTO.UserDisconnected]),
    map((messageDto: IUserDisconnectedEventDTO) =>
      UserConnectionEventSocketMessageViewModelFactory.createDisconnectedEventFromDTO(messageDto),
    ),
  );

  public requisitionUpdated$: Observable<IRequisitionUpdateEvent> = this.socketMessage$.pipe(
    this.filterByTypes([ESocketMessageTypeDTO.RequisitionInformation]),
    map((dto: IRequisitionUpdateEventDTO) => RequisitionUpdateEventSocketMessageViewModelFactory.createFromDTO(dto)),
  );

  public doctorStatusUpdated$: Observable<IDoctorStatusUpdateEvent> = this.socketMessage$.pipe(
    this.filterByTypes([ESocketMessageTypeDTO.DoctorStatusUpdate]),
    map((dto: IDoctorStatusUpdateEventDTO) => DoctorStatusUpdateEventSocketMessageViewModelFactory.createFromDTO(dto)),
  );

  public isOnline$ = this.chatWebsocket.isOnline$.pipe(filter(() => this._isInitialized$.value));
  public isOffline$ = this.chatWebsocket.isDown$.pipe(filter(() => this._isInitialized$.value));

  private destroy$ = new Subject();

  private _reconnected$ = new Subject<void>();
  public readonly reconnected$ = this._reconnected$.asObservable();

  constructor(private websocketApiProviderService: WebsocketApiProviderService) {}

  public initialise(): Observable<void> {
    if (this._isInitialized$.value) {
      return;
    }

    this.initializeSocketConnection();

    this._isInitialized$.next(true);

    return of(null);
  }

  public destroy(): Observable<void> {
    this.destroy$.next();
    this.chatWebsocket.destroy();
    this._isInitialized$.next(false);
    return of(null);
  }

  private initializeSocketConnection() {
    let connectionsCount = 0;

    this.websocketApiProviderService
      .subscribeNotifications()
      .pipe(
        switchMap((response) => this.openWebSocketConnection(response.socket_url, response.session_id)),
        tap(() => {
          if (connectionsCount > 0) {
            this._reconnected$.next();
          }

          connectionsCount++;
        }),
        exhaustMap(() => this.awaitSocketDisconnectError()),
        retryWhen((errors$) =>
          errors$.pipe(
            tap(() => this.chatWebsocket.disconnect()),
            delay(RECONNECT_TIMEOUT_MS),
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe({
        error: () => {},
      });
  }

  private openWebSocketConnection(url: string, sessionId: string): Observable<never | void> {
    this.chatWebsocket.connect(url);

    const connectionError$: Observable<Error> = this.awaitSocketDisconnectError();
    const onlineSuccess$ = this.isOnline$.pipe(first((isOnline) => isOnline));

    return merge(onlineSuccess$, connectionError$).pipe(
      tap(() => {
        // Confirm connection
        this.chatWebsocket.send(`0 ${sessionId}`);
      }),
      map(() => null),
    );
  }

  /**
   * Always returns failed Observable
   */
  private awaitSocketDisconnectError(): Observable<never> {
    return this.chatWebsocket.isDown$.pipe(
      filter((isOffline) => isOffline),
      switchMap(() => throwError(new Error('Disconnected'))),
    );
  }

  private filterByTypes(types: TChatSocketMessageType[]): OperatorFunction<ISocketMessage, ISocketMessage> {
    return filter((message: ISocketMessage) => types.includes(message.type));
  }
}
