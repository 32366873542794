import { TGuid } from '@core/helpers';
import { flatArrayToMap } from '@core/helpers';
import { IQuestionnaireDTO, IQuestionnaireQuestionDTO, IQuestionnaireQuestionType } from './dto/questionnaire.dto';
import { EQuestionnaireAnswersTypeDTO, EQuestionnaireTypeDTO } from './consts/questionnaire';

export interface IQuestionnaireQuestionAnswerValue {
  id: TGuid;
  title: string;
}

export interface IQuestionnaireQuestionAnswer {
  id: TGuid;
  type: EQuestionnaireAnswersTypeDTO;
  values: IQuestionnaireQuestionAnswerValue[];
}

type TNextQuestionIdByAnswerValueIdMap<
  TAnswerValueId extends TGuid = TGuid,
  TNextQuestionId extends TGuid = TGuid
> = Record<TAnswerValueId, TNextQuestionId>;

export interface IQuestionnaireQuestion {
  id: TGuid;
  answer: IQuestionnaireQuestionAnswer;
  title: string;
  required: boolean;
  nextQuestionIdByAnswerValueIdMap: TNextQuestionIdByAnswerValueIdMap | null;
}

export interface IQuestionnaire {
  id: TGuid;
  entryQuestionId: TGuid;
  questionsMap: Record<TGuid, IQuestionnaireQuestion>;
  type: EQuestionnaireTypeDTO;
  version: number;
}

export class QuestionnaireViewModelFactory {
  static createFromDTO(dto: IQuestionnaireDTO): IQuestionnaire {
    const answersMap = this.createAnswersMapFromDTO(dto.questions.question_types);

    return {
      id: dto.id,
      type: dto.type,
      entryQuestionId: dto.questions.questionnaire.entry_point,
      questionsMap: this.createQuestionsMapFromDTO(dto.questions.questionnaire.questions, answersMap),
      version: dto.version,
    };
  }

  private static createAnswersMapFromDTO(
    dtoItems: IQuestionnaireQuestionType[],
  ): Record<TGuid, IQuestionnaireQuestionAnswer> {
    const answers = (dtoItems || []).map((item) => this.createAnswerFromDTO(item));
    return flatArrayToMap(answers);
  }

  private static createQuestionsMapFromDTO(
    dtoItems: IQuestionnaireQuestionDTO[],
    answersMap: Record<TGuid, IQuestionnaireQuestionAnswer>,
  ): Record<TGuid, IQuestionnaireQuestion> {
    const questions = (dtoItems || []).map((item) => this.createQuestionFromDTO(item, answersMap));
    return flatArrayToMap(questions);
  }

  private static createAnswerFromDTO(dto: IQuestionnaireQuestionType): IQuestionnaireQuestionAnswer {
    return {
      id: dto.id,
      type: dto.allow_type,
      values: dto.allowed_values,
    };
  }

  private static createQuestionFromDTO(
    dto: IQuestionnaireQuestionDTO,
    answersMap: Record<TGuid, IQuestionnaireQuestionAnswer>,
  ): IQuestionnaireQuestion {
    const answer = answersMap[dto.question_type];

    let required = true;

    /**
     * @TODO
     * Ugly hack to make last question optional if it's Text type;
     * Backend have to send us `required` property;
     */
    if (answer.type === EQuestionnaireAnswersTypeDTO.Text && (!dto.rules || dto.rules.length === 0)) {
      required = false;
    }

    return {
      id: dto.id,
      answer,
      title: dto.title,
      required,
      nextQuestionIdByAnswerValueIdMap: (dto.rules || []).reduce((acc, item) => {
        acc[item.allowed_value] = item.question;
        return acc;
      }, {}),
    };
  }
}
