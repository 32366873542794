import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormErrorsViewerComponent } from './form-errors-viewer/form-errors-viewer.component';
import { TranslateModule } from '@project/translate';

@NgModule({
  declarations: [FormErrorsViewerComponent],
  imports: [CommonModule, TranslateModule],
  exports: [FormErrorsViewerComponent],
})
export class FormErrorsViewerModule {}
