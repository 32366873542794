import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BroadcastEventsService } from '@core/services';
import { BehaviorSubject, from, of, Subject } from 'rxjs';
import { CloseAsidePageEvent, EAsideOutlets } from '@project/shared';
import { concatMap, delay, filter, switchMap, takeUntil } from 'rxjs/operators';
import { getEnumValues } from '@core/helpers';
import { ICONS_URLS } from '@lib/icons';
import { animate, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { FeatureService } from 'src/app/project/services/feature.service';

@Component({
  selector: 'app-sidebar-layout',
  templateUrl: './sidebar-layout.component.html',
  styleUrls: ['./sidebar-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class SidebarLayoutComponent implements OnInit, OnDestroy {
  public readonly asideOutlets = EAsideOutlets;

  public readonly urlMenu = ICONS_URLS.menu;
  isDesktop: boolean;
  toggled$ = new BehaviorSubject<boolean>(false);
  enabledLeftNav$ = new BehaviorSubject<boolean>(false);

  private destroyed$ = new Subject();

  constructor(
    private featureService: FeatureService,
    private broadcastEventsService: BroadcastEventsService,
    public breakpointObserver: BreakpointObserver,
  ) {}

  stateCheckbox(hide: boolean) {
    if (!this.isDesktop) {
      this.toggled$.next(hide);
    }
  }

  setToggle(value: boolean | null) {
    this.featureService.getConfig().subscribe((config) => {
      const disableLeftNav = config.disable_left_nav;
      this.enabledLeftNav$.next(!disableLeftNav);

      if (disableLeftNav) {
        this.broadcastEventsService.broadcast(new CloseAsidePageEvent(EAsideOutlets.SidebarAsideOutletPrimary));
        // Assuming you want to hide the sidebar
        this.toggled$.next(false);
      } else {
        // Logic for when disable_left_nav is false
        // You may have different behavior here based on your specific requirements
        this.broadcastEventsService.broadcast(new CloseAsidePageEvent(EAsideOutlets.SidebarAsideOutletPrimary));
        if (value == null) {
          this.toggled$.next(!this.toggled$.value);
        } else {
          this.toggled$.next(value);
        }
      }
    });
  }

  getToggled() {
    return this.toggled$.subscribe();
  }

  ngOnInit() {
    this.breakpointObserver.observe(['(min-width: 600px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isDesktop = true;
        this.setToggle(true);
      } else {
        this.isDesktop = false;
      }
    });
    /**
     * We should close one outlet route by another in async way to prevent multiple conflict navigation in same time.
     * That's why here is some delay between events broadcasting
     *
     * TODO scheduled([1,2,3], asapScheduler) instead of delay(1)
     */
    this.broadcastEventsService
      .eventsByType$(CloseAsidePageEvent)
      .pipe(
        filter((event) => !event.outlet),
        takeUntil(this.destroyed$),
        switchMap(() => {
          return from(getEnumValues(EAsideOutlets)).pipe(
            concatMap((val, index) => (index > 0 ? of(val).pipe(delay(1)) : of(val))),
          );
        }),
      )
      .subscribe((outlet) => {
        this.broadcastEventsService.broadcast(new CloseAsidePageEvent(outlet));
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}
