import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, defer, Observable, of } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ESidebarIconType } from '@app/config';

@Component({
  selector: 'app-sidebar-menu-icon',
  templateUrl: './sidebar-menu-icon.component.html',
  styleUrls: ['./sidebar-menu-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarMenuIconComponent implements OnInit {
  @Input() icon: ESidebarIconType;

  private _icon$ = new BehaviorSubject<ESidebarIconType | null>(null);

  /**
   * We load SVG as Text to have ability to change style in CSS
   */
  public icon$: Observable<SafeHtml> = this._icon$.pipe(
    switchMap((type) => (type ? defer(() => this.loadByType(type).catch(() => null)) : of(null))),
    map((content) => content?.default),
    map((content) => (content ? this.sanitizer.bypassSecurityTrustHtml(content) : null)),
  );

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this._icon$.next(this.icon);
  }

  private loadByType(type: ESidebarIconType): Promise<any> {
    return import(
      /* webpackChunkName: "icon-[request]" */ `!!raw-loader!src/assets/component-assets/sidebar-icons/${type}.svg`
    );
  }
}
