import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ExternalScriptLoaderService {
  private _loadedScripts: Record<string, HTMLScriptElement> = {};

  constructor(@Inject(PLATFORM_ID) private platformId: string, private ngZone: NgZone) {}

  load(name: string, url: string): Promise<void> {
    if (!isPlatformBrowser(this.platformId)) {
      return Promise.reject('Is not browser');
    }
    if (this._loadedScripts[name]) {
      return Promise.resolve();
    }
    return new Promise<any>((resolve, reject) => {
      this.ngZone.runOutsideAngular(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        this._loadedScripts[name] = script;
        script.onload = () => {
          resolve();
        };
        script.onerror = (e) => {
          reject(e);
        };
        document.head.appendChild(script);
      });
    });
  }

  reveal(name: string) {
    const script = this._loadedScripts[name];
    if (!script) {
      return;
    }
    script.parentElement.removeChild(script);
    delete this._loadedScripts[name];
  }
}
