import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class FirebaseProviderService {
  public getFirebaseMessaging(): firebase.messaging.Messaging {
    return firebase.messaging();
  }

  public isMessagingSupported(): boolean {
    return firebase.messaging.isSupported();
  }

  constructor() {
    firebase.initializeApp(environment.firebaseConfig);
  }
}
