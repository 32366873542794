<lib-modal-header (close)="close()">{{ headerTitle }}</lib-modal-header>
<lib-modal-body>
  <div class="container-rating">
    <bar-rating [(ngModel)]="rating.grade" [max]="5" [theme]="'stars'"></bar-rating>
  </div>
  <lib-form-control>
    <label>
      <span libLabel>{{ "modals.rating.feedback" | translate }}</span>
      <lib-input placeholder="{{ 'modals.rating.optional' | translate }}" [(ngModel)]="rating.message"></lib-input>
    </label>
  </lib-form-control>
</lib-modal-body>
<lib-modal-footer>
  <div class="actions">
    <button libButton (click)="close()" [appearance]="'grey-outline'">
      {{ "modals.confirm.actions.notnow" | translate }}
    </button>
    <button libButton (click)="sendRating()" [isPending]="isLoading" [disabled]="rating.grade < 1">
      {{ "actions.submit" | translate }}
    </button>
  </div>
</lib-modal-footer>
