import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { ITimeSlot } from '@lib/time-slots';
import { STATIC_DATE_FORMAT } from '@project/shared';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateFormatter, DateUtils } from '@core/helpers';
import { getCurrentLocale, TranslateService } from '@project/translate';

@Component({
  selector: 'app-timeslots-list-preview',
  templateUrl: './timeslots-list-preview.component.html',
  styleUrls: ['./timeslots-list-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeslotsListPreviewComponent implements OnInit, OnChanges {
  @Input() timeslotsToPreview: ITimeSlot[] = [];
  @Input() disableSlotActions = false;

  private startDate = new Date();

  private readonly _items$ = new BehaviorSubject<ITimeSlot[]>([]);

  private readonly _selectedDay$ = new BehaviorSubject<Date>(new Date());
  public readonly selectedDay$: Observable<Date> = this._selectedDay$.asObservable();

  public readonly selectedDayFormatted$: Observable<string> = this.selectedDay$.pipe(
    map((date) => {
      if (DateFormatter.isToday(date)) {
        return TranslateService.localize('nouns.today');
      }

      return DateFormatter.formatDateToPreviewShort(date, getCurrentLocale());
    }),
  );

  readonly itemsForSelectedDay$: Observable<ITimeSlot[]> = combineLatest([this._items$, this._selectedDay$]).pipe(
    map(([items, date]) => {
      const todayFormatted = DateFormatter.dateToString(date, { format: STATIC_DATE_FORMAT });
      return items.filter(
        (item) => DateFormatter.dateToString(item.startTime, { format: STATIC_DATE_FORMAT }) === todayFormatted,
      );
    }),
  );

  public readonly disablePrevButton$: Observable<boolean> = this.selectedDay$.pipe(
    map((date) => date <= this.startDate),
  );

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    this._items$.next(this.timeslotsToPreview || []);
  }

  prevDay() {
    const nextDate = DateUtils.shift(this._selectedDay$.value, { days: -1 });
    this._selectedDay$.next(nextDate);
  }

  nextDay() {
    const nextDate = DateUtils.shift(this._selectedDay$.value, { days: 1 });
    this._selectedDay$.next(nextDate);
  }
}
