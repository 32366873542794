import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { TasksApiProviderService, SocketMessagesDataProviderService } from '@project/data-providers';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { IFeatureService } from '@project/services';

@Injectable()
export class TasksInQueueService implements Resolve<void>, IFeatureService {
  private readonly _tasksInQueue$ = new BehaviorSubject<number>(null);
  public readonly tasksInQueue$ = this._tasksInQueue$.asObservable();

  private destroy$ = new Subject();

  constructor(
    private socketMessagesProviderService: SocketMessagesDataProviderService,
    private tasksApiProviderService: TasksApiProviderService,
  ) {}

  initialise(): Observable<void> {
    this.initInfoUpdateBySocketMessages();
    return of(null);
  }

  destroy(): Observable<void> {
    this.destroy$.next();
    return of(null);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<void> {
    return this.updateValue();
  }

  private initInfoUpdateBySocketMessages() {
    this.socketMessagesProviderService.queueInfoUpdate$.pipe(takeUntil(this.destroy$)).subscribe((info) => {
      this._tasksInQueue$.next(info.length);
    });
  }

  private updateValue(): Observable<void> {
    return this.tasksApiProviderService.getQueueInfo().pipe(
      catchError(() => of(null)),
      // TODO Ask UI team about what value should be showed if some error occurs
      tap((info) => this._tasksInQueue$.next(info?.size ?? null)),
      map(() => null),
    );
  }
}
