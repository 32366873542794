<table>
  <thead *ngIf="binahDataFirstRow as headerData">
    <tr>
      <th class="date-column">{{ "nouns.date" | translate }}</th>
      <th *ngFor="let param of headerData.data">{{ param.label }} ({{ param.measurementUnitLabelShort }})</th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let data of observationData">
      <td style="white-space: nowrap;">{{ data.createdDate | date: "medium" }}</td>
      <td
        *ngFor="let param of data.data"
        class="value-cell"
        [ngClass]="{
          danger: param.valueCategory === categories.Bad,
          success: param.valueCategory === categories.Good
        }"
      >
        {{ param.valueToDisplay }}
      </td>
    </tr>
  </tbody>
</table>
