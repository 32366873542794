import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ApiHelper {
  static convertObjectToHttpParams(obj: Record<string, any>): HttpParams {
    return Object.keys(obj).reduce((params: HttpParams, key) => {
      /**
       * Ignoring empty values in resulting params
       */
      if ([undefined, null, ''].includes(obj[key])) {
        return params;
      }
      if (obj[key] instanceof Array) {
        obj[key].forEach((v) => {
          params = params.append(key + '', v);
        });
        return params;
      }

      return params.set(key, obj[key]);
    }, new HttpParams());
  }
}
