import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IVideoCallSession } from '../consts';

@Component({
  selector: 'app-participant-video',
  templateUrl: './participant-video.component.html',
  styleUrls: ['./participant-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantVideoComponent {
  @Input() isMe: boolean;
  @Input() session: IVideoCallSession;
  @Input() showCompactUI = false;
}
