<div class="modal-header">
  <div class="logos-container">
    <img [src]="logoPortal" alt="Portal Telemedicina" class="logo-portal" />
    <img [src]="logoSaudeId" alt="Saúde iD" class="logo-saudeid" />
  </div>
  <lib-modal-header (close)="close()" class="close-container"> </lib-modal-header>
</div>

<lib-modal-body class="modal-body">
  <div class="cards-section">
    <div class="card">
      <h4 class="modal-title">{{ "saude-id-modal.title-exams" | translate }}</h4>
      <p class="modal-content">{{ "saude-id-modal.text-exams" | translate }}</p>
      <div class="flexible"></div>
      <a class="cta-button" [href]="scheduleAppointmentURL" target="_blank" rel="noopener">{{
        "saude-id-modal.cta-exams" | translate
      }}</a>
    </div>
    <div class="card">
      <h4 class="modal-title">{{ "saude-id-modal.title-covid" | translate }}</h4>
      <p class="modal-content">{{ "saude-id-modal.text-covid" | translate }}</p>
      <div class="flexible"></div>
      <a class="cta-button" [href]="scheduleCovidExamURL" target="_blank" rel="noopener">{{
        "saude-id-modal.cta-covid" | translate
      }}</a>
    </div>
  </div>
</lib-modal-body>
