import {
  ESortDirection,
  IPagedItemsProceedResult,
  IPagedItemsSortingState,
} from '../generic/server-side-paged-items-provider.service';
import { IPagingResponse } from '@core/http';

export class PagingApiMapperHelper {
  static mapPagingApiResponseToProceedResult<T>(
    page: number,
    response: IPagingResponse<T>,
  ): IPagedItemsProceedResult<T> {
    return {
      items: response.items,
      state: {
        currentPageNumber: page,
        totalItemsCount: response.totalItemsCount,
        totalFilteredItemsCount: response.requestTotalItemsCount,
        totalPagesCount: response.requestTotalPages,
      },
    };
  }

  static convertSortingStateToQueryParamsObject(sortingState: IPagedItemsSortingState<string>) {
    if (!sortingState?.sortByKey) {
      return {};
    }

    return {
      sort: `${sortingState.sortDirection === ESortDirection.Descend ? '-' : ''}${sortingState.sortByKey}`,
    };
  }
}
