import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { environment } from '@env';
import {
  IRequisitionNotification,
  IRequisitionNotificationDTO,
  RequisitionNotificationItemViewModelFactory,
} from '@project/view-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RequisitionNotificationCenterApiProviderService {
  constructor(private apiService: ApiService) {}

  public getLatest(): Observable<IRequisitionNotification[]> {
    return this.apiService
      .get<IRequisitionNotificationDTO[]>(
        `${environment.environmentVariables.apiCoreUrl}/NotificationCenter?offset=0&limit=20`,
      )
      .pipe(
        map((dto) => {
          return dto.map(RequisitionNotificationItemViewModelFactory.createGetViewModel);
        }),
      );
  }

  public markNotificationAsRead(id) {
    return this.apiService.post<IRequisitionNotificationDTO[]>(
      `${environment.environmentVariables.apiCoreUrl}/NotificationCenter/${id}/read`,
    );
  }
}
