import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { EUserPermission, EUserPermissionsCheckAs, IRouteData } from '@project/shared';
import { UserProfileDataService } from '../user-profile-data.service';

interface IParams {
  checkAs: EUserPermissionsCheckAs;
  permissions: EUserPermission[];
  failRedirect: string;
}

@Injectable({
  providedIn: 'root',
})
export class HasAccessGuard implements CanActivate, CanLoad {
  constructor(private userProfileDataService: UserProfileDataService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkWithRedirect(this.extractParamsFromRouteData(route.data));
  }

  canLoad(route: Route): boolean {
    return this.checkWithRedirect(this.extractParamsFromRouteData(route.data));
  }

  private extractParamsFromRouteData(data: IRouteData): IParams {
    return {
      checkAs: data.userPermissionsCheckAs || EUserPermissionsCheckAs.Every,
      permissions: data.userPermissionsToCheck || [],
      failRedirect: data.userPermissionsFailRedirect || '/',
    };
  }

  private checkWithRedirect({ permissions, checkAs, failRedirect }: IParams): boolean {
    const hasPermissions =
      checkAs === EUserPermissionsCheckAs.Every
        ? this.userProfileDataService.hasPermissions(permissions)
        : this.userProfileDataService.hasAtLeastOnePermission(permissions);

    if (!hasPermissions) {
      this.router.navigateByUrl(failRedirect);
      return false;
    }
    return true;
  }
}
