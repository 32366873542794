import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class DropdownService implements OnDestroy {
  private _active$ = new BehaviorSubject<boolean>(false);

  public active$ = this._active$.pipe(distinctUntilChanged());

  toggleActive() {
    this.setActive(!this.active);
  }

  get active(): boolean {
    return this._active$.getValue();
  }

  setActive(status: boolean) {
    this._active$.next(status);
  }

  ngOnDestroy() {
    this._active$.complete();
  }
}
