import { TGuid } from '@core/helpers';

export interface IReasonDTO {
  id: TGuid;
  reason: string;
}

export interface IReason {
  id: TGuid;
  reason: string;
}

export interface IRequisitionReasonDTO {
  requisition_id: TGuid;
  reason_id: TGuid;
  description?: string;
}

export interface IRequisitionReason {
  requisition_id: TGuid;
  reason_id: TGuid;
  description?: string;
}

export class ReasonsViewModelFactory {
  static createFromDTO(dto: IReasonDTO): IReason {
    return {
      id: dto.id,
      reason: dto.reason,
    };
  }
  static createDTO(model: IReason): IReasonDTO {
    return {
      id: model.id,
      reason: model.reason,
    };
  }
}

export class RequisitionReasonsViewModelFactory {
  static createFromDTO(dto: IRequisitionReasonDTO): IRequisitionReason {
    return {
      requisition_id: dto.reason_id,
      reason_id: dto.reason_id,
      description: dto.description,
    };
  }
  static createDTO(model: IRequisitionReason): IRequisitionReasonDTO {
    return {
      requisition_id: model.requisition_id,
      reason_id: model.reason_id,
      description: model.description,
    };
  }
}
