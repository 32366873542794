import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { DropdownDirective } from '../../dropdown/public-api';
import { TDropdownPosition } from '@lib/ui/find-dropdown-position-directive';

@Component({
  selector: 'lib-actions-dropdown',
  templateUrl: './actions-dropdown.component.html',
  styleUrls: ['./actions-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsDropdownComponent implements OnInit {
  @ViewChild('dropdown') private dropdownDirective: DropdownDirective;

  @Input() position: TDropdownPosition;
  @Input() toggleOpacity = true;

  public hideDropdown() {
    this.dropdownDirective.hideDropdown();
  }

  ngOnInit(): void {}
}
