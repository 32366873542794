import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { IModalComponent } from '@lib/modal';
import { ERequisitionStageTypeDTO, IRequisition, IRequisitionParticipant } from '@project/view-models';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpHelperService, RequisitionHelpersService } from '@project/services';
import { RequisitionApiProviderService } from '@project/data-providers';
import { TranslateService } from '@project/translate';

export interface IAskToCloseRequisitionsOverlayComponentInputs {
  requisitions: IRequisition[];
}

interface IRequisitionPreviewData {
  title: string;
  description?: string;
}

@Component({
  selector: 'app-ask-to-close-requisitions-overlay',
  templateUrl: './ask-to-close-requisitions-overlay.component.html',
  styleUrls: ['./ask-to-close-requisitions-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AskToCloseRequisitionsOverlayComponent
  implements IModalComponent, OnDestroy, IAskToCloseRequisitionsOverlayComponentInputs {
  submit$ = new EventEmitter();
  close$ = new EventEmitter();

  @Input() requisitions: IRequisition[] = [];
  private destroy$ = new Subject();

  private _inProgress$ = new BehaviorSubject<boolean>(false);
  public inProgress$ = this._inProgress$.asObservable();

  constructor(
    private requisitionApiProviderService: RequisitionApiProviderService,
    private requisitionHelpersService: RequisitionHelpersService,
  ) {}

  get requisitionsPreview(): IRequisitionPreviewData[] {
    return (this.requisitions || []).map((requisition) => {
      const participant = this.getRequisitionParticipant(requisition);

      if (participant) {
        return {
          title: participant.fullName,
          description: participant.summaryInfo ? participant.summaryInfo.join(', ') : null,
        };
      }

      return {
        title: TranslateService.localize('user-feature.close-chats.requested-specialist-message'),
      };
    });
  }

  isRecepcionist() {
    return this.requisitions[0].activeStage.type === ERequisitionStageTypeDTO.Receptionist;
  }

  isLivequeue() {
    return this.requisitions[0].activeStage.type === ERequisitionStageTypeDTO.LiveQueue;
  }

  closeRequisitions() {
    if (this._inProgress$.value) {
      return;
    }

    this._inProgress$.next(true);

    HttpHelperService.multipleRequests(
      this.requisitions.map((requisition) => this.requisitionApiProviderService.close(requisition.id)),
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => this.submit$.emit(),
        error: () => this.close$.emit(),
      });
  }

  close() {
    this.close$.emit();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getRequisitionParticipant(requisition: IRequisition): IRequisitionParticipant | null {
    return this.requisitionHelpersService.getRequisitionParticipantForCurrentUser(requisition);
  }
}
