import { Injectable } from '@angular/core';
import { EProfileTypeDTO, IRequisitionNotification } from '@project/view-models';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequisitionNotificationCenterApiProviderService } from 'src/app/project/data-providers/api-providers/requisition-notification-center-api-provider.service';

@Injectable({
  providedIn: 'root',
})
export class UserInformationCenterManagerService {
  public notifications = new BehaviorSubject<IRequisitionNotification[]>([]);
  public notifications$ = this.notifications.asObservable();
  public isPatient = JSON.parse(window.localStorage.getItem('auth')).userType === EProfileTypeDTO.Patient;

  public unreadNotifications$ = this.notifications$.pipe(
    map((notifications) => {
      if (notifications.some((notification) => notification.read_at === null)) {
        return notifications;
      } else {
        return [];
      }
    }),
  );

  constructor(
    private requisitionNotificationCenterApiProviderService: RequisitionNotificationCenterApiProviderService,
  ) {
    if (this.isPatient) {
      this.loadNotifications();
    }
  }

  public loadNotifications() {
    this.requisitionNotificationCenterApiProviderService.getLatest().subscribe((notifications) => {
      this.notifications.next(notifications);
    });
  }

  public markNotificationAsRead(notification: IRequisitionNotification) {
    return this.requisitionNotificationCenterApiProviderService.markNotificationAsRead(notification.id);
  }
}
