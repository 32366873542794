import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

const NEW_LINE_SYMBOL = '\n';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[appFormattedText]',
  templateUrl: './formatted-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormattedTextComponent implements OnChanges {
  @Input() rawText: string;

  public lineMessages: string[] = [];

  constructor() {}

  ngOnChanges(): void {
    this.lineMessages = (this.rawText || '').split(NEW_LINE_SYMBOL);
  }
}
