/**
 * Documentation is here
 * https://www.dolby.io/developers/interactivity-apis/client-sdk/reference-javascript/voxeetsdk
 *
 * WARNING!!!
 * This types just a short declarations of documentation (above) because VoxeetSDK has no types definitions
 * and this values are used to improve developing experience;
 *
 */

/**
 * Constants
 */

export enum EVoxeetParticipantStatus {
  Connected = 'Connected',
  Connecting = 'Connecting',
  Decline = 'Decline',
  Error = 'Error',
  Inactive = 'Inactive',
  Left = 'Left',
  Warning = 'Warning',
}

enum EVoxeetConferenceStatus {
  Created = 'created',
  Destroyed = 'destroyed',
  Ended = 'ended',
  Error = 'error',
  Joined = 'joined',
  Left = 'left',
}

export enum EVoxeetConferenceServiceEvent {
  Ended = 'ended',
  Joined = 'joined',
  Left = 'left',
  ParticipantAdded = 'participantAdded',
  ParticipantUpdated = 'participantUpdated',
  StreamAdded = 'streamAdded',
  StreamRemoved = 'streamRemoved',
  StreamUpdated = 'streamUpdated',
}

/**
 * Models
 */

export interface IVoxeetParticipantInfo {
  avatarUrl?: string;
  externalId?: string;
  name: string;
}

export interface IVoxeetParticipant {
  id: string;
  info: IVoxeetParticipantInfo;
  status: EVoxeetParticipantStatus;
  streams: MediaStream[];
}

export interface IVoxeetConferenceParameters {
  /**
   * Fill free to specify it if need
   * https://www.dolby.io/developers/interactivity-apis/client-sdk/reference-javascript/model/conferenceparameters
   */
  [key: string]: any;
}

export interface IVoxeetConference {
  readonly id: string; // Getter
  readonly alias: string; // Getter
  readonly isNew: boolean; // Getter
  readonly params: IVoxeetConferenceParameters; // Getter
  readonly pinCode: string; // Getter
  readonly status: EVoxeetConferenceStatus; // Getter
}

export interface IVoxeetConferenceOptions {
  alias?: string;
  params?: IVoxeetConferenceParameters;
  pinCode?: number;
}

/**
 * Services
 */

export interface IVoxeetSessionService {
  readonly participant: IVoxeetParticipant; // Getter
  close(): any;
  open(info: IVoxeetParticipantInfo): Promise<void>;
}

export interface IVoxeetConferenceService {
  readonly current: IVoxeetConference; // Getter
  readonly participants: Record<string, IVoxeetParticipant>; // Getter
  on(eventName: string, callback: (participant: IVoxeetParticipant, stream?: MediaStream) => void): void;
  create(options: IVoxeetConferenceOptions): Promise<IVoxeetConference>;
  fetch(conferenceId: string): Promise<IVoxeetConference>;
  join(conference: IVoxeetConference, options: { constraints?: any; simulcast?: boolean }): Promise<IVoxeetConference>;
  leave(leaveRoom?: boolean): Promise<void>;
  startAudio(participant: IVoxeetParticipant): Promise<any>;
  stopAudio(participant: IVoxeetParticipant): Promise<any>;
  startVideo(participant: IVoxeetParticipant, constraints: any): Promise<any>;
  stopVideo(participant: IVoxeetParticipant): Promise<any>;
}

/**
 * Whole SDK
 */

export interface IVoxeetSDK {
  readonly session: IVoxeetSessionService; // Getter
  readonly conference: IVoxeetConferenceService; // Getter
  initializeToken: (token: string, callback: () => Promise<string>) => void;
}
