import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ISocialButton, TSocialButtonAppearance, TSocialButtonSize } from '../social-button';
import { GoogleAuthProviderService } from '../../services/google-auth-provider.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-google-social-auth-button',
  templateUrl: './google-social-auth-button.component.html',
  styleUrls: ['./google-social-auth-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleSocialAuthButtonComponent implements OnInit, ISocialButton {
  @Output() clickSocial: EventEmitter<MouseEvent> = new EventEmitter();
  @Input() disabled: boolean;
  @Input() appearance: TSocialButtonAppearance = 'default';
  @Input() size: TSocialButtonSize = 'm';
  @HostBinding('class.muted') @Input() muted: boolean;

  public readonly authEnabled$: Observable<boolean> = this.googleAuthProviderService.enabled$;
  public readonly authLoading$: Observable<boolean> = this.googleAuthProviderService.loading$;

  constructor(private googleAuthProviderService: GoogleAuthProviderService) {}

  ngOnInit(): void {
    this.googleAuthProviderService.initialize();
  }
}
