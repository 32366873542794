import { ChangeDetectionStrategy, Component, EventEmitter, Input } from '@angular/core';
import { IModalComponent } from '@lib/modal';

@Component({
  selector: 'app-status-message',
  templateUrl: './offline-status-message.component.html',
  styleUrls: ['./offline-status-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfflineStatusMessageComponent implements IModalComponent {
  @Input() message: string = null;

  submit$ = new EventEmitter();
  close$ = new EventEmitter();
}
