import { ModalRating } from './../../components/modal-rating/modal-rating.model';
import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { environment } from '@env';
import {
  ChatMessageViewModelFactory,
  IChatMessage,
  IChatMessageDTO,
  IRequisition,
  RequisitionViewModelFactory,
} from '@project/view-models';
import { map } from 'rxjs/operators';
import { DateFormatter, TGuid } from '@core/helpers';

@Injectable({
  providedIn: 'root',
})
export class ChatApiProviderService {
  constructor(private apiService: ApiService) {}

  sendMessage(chatId: TGuid, message: string): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiChatUrl}/chat/message`, {
      message,
      chat_id: chatId,
    });
  }

  sendServiceMessage(chatId: TGuid, serviceMessage: string, ...additionalParams: string[]): Observable<void> {
    return this.apiService.post(
      `${environment.environmentVariables.apiChatUrl}/chat/service`,
      JSON.stringify({
        type: serviceMessage,
        chat_id: chatId,
        ...additionalParams,
      }),
    );
  }

  getChatMessagesPage(chatId: TGuid, count: number, from: Date = new Date()): Observable<IChatMessage[]> {
    return this.apiService
      .get<IChatMessageDTO[]>(
        `${
          environment.environmentVariables.apiChatUrl
        }/chat/messages/${chatId}/${count}/${DateFormatter.dateIncludeHours(from, 4)}`,
      )
      .pipe(
        map((messages) => {
          return messages.map((message: IChatMessageDTO) => {
            try {
              if (message.attachment) {
                message.attachment = JSON.parse((message as any).attachment);
                message.text = message.attachment.original;
              }
              return message;
            } catch (e) {
              return message;
            }
          });
        }),
        map((messages) => messages.map((message) => ChatMessageViewModelFactory.createMessageFromDTO(message))),
      );
  }

  getRequisitionByChatId(chatId: TGuid): Observable<IRequisition> {
    return this.apiService
      .get(`${environment.environmentVariables.apiCoreUrl}/Chats/${chatId}/requisitions`)
      .pipe(map((requisition) => RequisitionViewModelFactory.createGetViewModel(requisition)));
  }

  uploadFile(chatId: TGuid, file: File): Observable<void> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.apiService.postWithForm(
      `${environment.environmentVariables.apiChatUrl}/chat/attach/${chatId}`,
      formData,
    );
  }

  sendRating(modalRating: ModalRating): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/Chats/video/rating`, modalRating);
  }
}
