import { Injectable, Type } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

export abstract class BroadcastEvent {}

@Injectable({
  providedIn: 'root',
})
export class BroadcastEventsService {
  private _events$ = new Subject<BroadcastEvent>();
  public events$ = this._events$.asObservable();

  public eventsByType$<T extends BroadcastEvent>(type: Type<T>): Observable<T> {
    return this.events$.pipe(filter((event: T) => event instanceof type));
  }

  public broadcast(event: BroadcastEvent) {
    this._events$.next(event);
  }
}
