import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IRequisitionWithQuestionsAnswers } from '@project/view-models';

@Component({
  selector: 'app-session-details-shared-questionnaire',
  templateUrl: './session-details-shared-questionnaire.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionDetailsSharedQuestionnaireComponent {
  @Input() requisition: IRequisitionWithQuestionsAnswers;
}
