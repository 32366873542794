import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';
import { ExternalScriptLoaderService } from '@core/services';
import { environment } from '@env';

declare const AppleID: any;

export interface IAppleAuthResult {
  authorization: {
    code: string;
    id_token: string;
    state: string;
  };
}

@Injectable()
export class AppleAuthProviderService {
  constructor(private externalScriptLoaderService: ExternalScriptLoaderService) {}

  public signIn(): Observable<IAppleAuthResult> {
    return defer(() => this.getSDK().then(() => AppleID.auth.signIn()));
  }

  private getSDK(): Promise<void> {
    return this.externalScriptLoaderService
      .load('appleauth', 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js')
      .then(() => {
        AppleID.auth.init({
          clientId: environment.appIds.appleClientId,
          scope: 'email',
          redirectURI: location.origin,
          state: Date.now().toString(16),
          usePopup: true,
        });
      });
  }
}
