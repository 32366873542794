import { EObservationMethodDTO } from './consts/observation-consts';

interface IMethodAvailabilityDTO {
  type: EObservationMethodDTO;
  enabled: boolean;
}

export interface IObservationStatusDTO {
  enabled: boolean;
  methods: IMethodAvailabilityDTO[];
}

export interface IObservationStatus {
  enabled: boolean;
  methods: Record<EObservationMethodDTO, boolean>;
}

export class ObservationStatusViewModelFactory {
  static createFromDTO(dto: IObservationStatusDTO): IObservationStatus {
    return {
      enabled: dto.enabled,
      methods: {
        [EObservationMethodDTO.Binah]: this.isMethodEnabled(EObservationMethodDTO.Binah, dto.methods || []),
      },
    };
  }

  private static isMethodEnabled(method: EObservationMethodDTO, methods: IMethodAvailabilityDTO[]): boolean {
    return methods.find((m) => m.type === method)?.enabled ?? false;
  }
}
