import { Injectable, InjectionToken, Injector } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TGuid } from '@core/helpers';
import { ListItemsService } from './list-items.service';

@Injectable()
export class ListTableManagerService {
  private listItemsService: ListItemsService;

  private _allowSelection$ = new BehaviorSubject<boolean>(false);
  public allowSelection$ = this._allowSelection$.asObservable();

  private isInitialized$ = new BehaviorSubject<boolean>(false);

  public isCurrentPageSelected$: Observable<boolean> = this.isInitialized$.pipe(
    switchMap((isInitialized) => (isInitialized ? this.listItemsService.areAllItemsOnPageSelected$ : of(false))),
  );

  public selectedIds$: Observable<TGuid[]> = this.isInitialized$.pipe(
    switchMap((isInitialized) => (isInitialized ? this.listItemsService.selectedIds$ : of([]))),
  );

  constructor(private injector: Injector) {}

  init(serviceToken: InjectionToken<any>) {
    this.listItemsService = this.injector.get(serviceToken);
    this.isInitialized$.next(true);
  }

  destroy() {
    this.isInitialized$.next(false);
  }

  setAllowedSelection(isAllowed: boolean) {
    this._allowSelection$.next(isAllowed);
  }

  setCurrentPageSelection(isSelected: boolean) {
    if (isSelected) {
      this.listItemsService.selectCurrentPageItems();
    } else {
      this.listItemsService.clearSelection();
    }
  }

  changeItemSelection(id: TGuid, isSelected: boolean) {
    if (isSelected) {
      this.listItemsService.addSelection(id);
    } else {
      this.listItemsService.removeSelection(id);
    }
  }
}
