<div libDropdown #dpd="libDropdown" [hideByOutsideClick]="true">
  <button
    libButton
    libDropdownToggle
    [appearance]="appearance"
    [size]="size"
    [disabled]="disabled"
    [isPending]="isPending"
  >
    {{ label }}
    <span class="arrow" [attr.button-appearance]="appearance">
      <svg viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.371603 1.86173L3.6179 4.68155C4.10673 5.10615 4.89637 5.10615 5.38519 4.68155L8.63149 1.86173C9.42113 1.17583 8.8571 0 7.74157 0H1.24898C0.133458 0 -0.418036 1.17583 0.371603 1.86173Z"
        />
      </svg>
    </span>
  </button>
  <ul
    *libDropdownContent
    [libFindDropdownPosition]="'right-bottom'"
    (click)="hideOnItemClick ? dpd.hideDropdown() : null"
  >
    <ng-content select="lib-button-dropdown-menu-item"></ng-content>
  </ul>
</div>
