import { ChangeDetectionStrategy, Component, HostListener, Optional } from '@angular/core';
import { ActionsDropdownComponent } from '../actions-dropdown/actions-dropdown.component';

@Component({
  selector: '[lib-dropdown-action]',
  templateUrl: './dropdown-action.component.html',
  styleUrls: ['./dropdown-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownActionComponent {
  constructor(@Optional() private actionsDropdownComponent: ActionsDropdownComponent) {
    if (!this.actionsDropdownComponent) {
      throw new Error('ActionsDropdownComponent not resolved');
    }
  }

  @HostListener('click')
  clickListener() {
    this.actionsDropdownComponent.hideDropdown();
  }
}
