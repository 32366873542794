<div class="slots">
  <div class="space"></div>
  <lib-time-slot
    *ngFor="let slot of sortedTimeSlots"
    [timeSlot]="slot"
    [allActionsDisabled]="allActionsDisabled"
    [showEndTime]="slot.showEndTime"
  ></lib-time-slot>
  <div class="space"></div>
</div>
