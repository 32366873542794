import { DateFormatter, TGuid } from '@core/helpers';
import {
  IScheduleDailyRule,
  IScheduleDailyRuleCreateData,
  IScheduleDailyRuleCreateDTO,
  IScheduleDailyRuleDTO,
  ScheduleDailyRuleViewModelFactory,
} from './schedule-daily-rule.view-model';
import {
  IScheduleWeeklyRule,
  IScheduleWeeklyRuleCreateData,
  IScheduleWeeklyRuleCreateDTO,
  IScheduleWeeklyRuleDTO,
  ScheduleWeeklyRuleViewModelFactory,
} from './schedule-weekly-rule.view-model';
import { STATIC_DATE_FORMAT, TStaticDateFormat, TStaticDateTimeFormat } from '@project/shared';
import { EScheduleStatusDTO } from './consts/schedule-consts';

// TODO Add status for schedule - Active, Future, Past (see swagger for more details)

export interface IScheduleGroupDTO {
  id: TGuid;
  effective_from: TStaticDateFormat;
  daily_availability_rules: IScheduleDailyRuleDTO[] | null;
  weekly_availability_rules: IScheduleWeeklyRuleDTO[] | null;
  status: EScheduleStatusDTO;
}

export interface IScheduleGroupCreateDTO {
  effective_from: TStaticDateFormat;
  daily_availability_rules: IScheduleDailyRuleCreateDTO[];
  weekly_availability_rules: IScheduleWeeklyRuleCreateDTO[];
}

export interface IScheduleGroup {
  id: TGuid;
  effectiveFrom: Date;
  daysRules: IScheduleDailyRule[];
  weeksRules: IScheduleWeeklyRule[];
  allRules: (IScheduleDailyRule | IScheduleWeeklyRule)[];
  status: EScheduleStatusDTO;
}

export interface IScheduleGroupCreateData {
  effectiveFrom: Date;
  daysRules: IScheduleDailyRuleCreateData[];
  weeksRules: IScheduleWeeklyRuleCreateData[];
}

export interface IScheduleGroupPostErrorDTO {
  intersecting_intervals: {
    start: TStaticDateTimeFormat;
    end: TStaticDateTimeFormat;
    sources: { rule_id: number; working_hour_id: number }[];
  }[];
  is_valid: boolean;
}

export interface IIntersectedInterval {
  start: Date;
  end: Date;
  sources: IIntersectedIntervalSource[];
}

export interface IIntersectedIntervalSource {
  ruleId: number;
  workingHourId: number;
}

export class ScheduleGroupViewModelFactory {
  static createGetViewModel(dto: IScheduleGroupDTO): IScheduleGroup {
    const daysRules = (dto.daily_availability_rules || []).map((rule) =>
      ScheduleDailyRuleViewModelFactory.createGetViewModel(rule),
    );

    const weeksRules = (dto.weekly_availability_rules || []).map((rule) =>
      ScheduleWeeklyRuleViewModelFactory.createGetViewModel(rule),
    );
    return {
      id: dto.id,
      effectiveFrom: DateFormatter.stringToDate(dto.effective_from, { format: STATIC_DATE_FORMAT }),
      daysRules,
      weeksRules,
      allRules: [...weeksRules, ...daysRules],
      status: dto.status,
    };
  }

  static createPostDTO(data: IScheduleGroupCreateData): IScheduleGroupCreateDTO {
    return {
      effective_from: DateFormatter.dateToString(data.effectiveFrom, { format: STATIC_DATE_FORMAT }),
      daily_availability_rules: data.daysRules.map((rule) => ScheduleDailyRuleViewModelFactory.createPostDTO(rule)),
      weekly_availability_rules: data.weeksRules.map((rule) => ScheduleWeeklyRuleViewModelFactory.createPostDTO(rule)),
    };
  }
}

export class ScheduleRulesIntersectingError extends Error {
  public intersectedIntervals: IIntersectedInterval[];

  constructor(dto: IScheduleGroupPostErrorDTO) {
    super();
    this.processFromDto(dto);
  }

  private processFromDto(dto: IScheduleGroupPostErrorDTO) {
    this.intersectedIntervals = dto.intersecting_intervals.map(
      (interval): IIntersectedInterval => {
        return {
          end: DateFormatter.stringToDate(interval.end),
          start: DateFormatter.stringToDate(interval.start),
          sources: interval.sources.map(
            (source): IIntersectedIntervalSource => ({
              ruleId: source.rule_id,
              workingHourId: source.working_hour_id,
            }),
          ),
        };
      },
    );
  }
}
