export enum ESidebarIconType {
  Bell = 'bell',
  Calendar = 'calendar',
  Dashboard = 'dashboard',
  Document = 'document',
  Eye = 'eye',
  Logout = 'logout',
  MedicalSign = 'medical-sign',
  People = 'people',
  PersonCard = 'person-card',
  Settings = 'settings',
}
