import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router } from '@angular/router';
import { AbstractFeatureFlagService } from './abstract-feature-flag.service';

@Injectable({
  providedIn: 'root',
})
export class AbstractFeatureFlagsGuard implements CanActivate, CanLoad {
  constructor(private featureFlagService: AbstractFeatureFlagService<any>, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    return this.checkWithRedirect(route.data.featureFlags || []);
  }

  canLoad(route: Route): boolean {
    return this.checkWithRedirect(route.data.featureFlags || []);
  }

  private checkWithRedirect(flags: string[]): boolean {
    const featuresEnabled = this.featureFlagService.everyFeatureOn(flags);

    if (!featuresEnabled) {
      this.router.navigateByUrl('/');
      return false;
    }
    return true;
  }
}
