import { TranslateService } from '@project/translate';

export enum ERequisitionStatusDTO {
  Created = 'Created',
  InProcessing = 'InProcessing',
  Closed = 'Closed',
}

export enum ERequisitionStageTypeDTO {
  None = 'None',
  Receptionist = 'Receptionist',
  Appointment = 'Appointment',
  LiveQueue = 'LiveQueue',
}

export enum ERequisitionStageStatusDTO {
  WaitingForStart = 'WaitingForStart',
  AwaitingForDoctor = 'AwaitingForDoctor',
  SessionStarted = 'SessionStarted',
}

export enum ERequisitionFilterType {
  All = 'All',
  Active = 'Active',
  Closed = 'Closed',
}

export enum ENotificationCenterTypeDTO {
  Calling = 'Calling',
  Ongoing = 'Ongoing',
  Finished = 'Finished',
  Declined = 'Declined',
  Lost = 'Lost',
}

export function getRequisitionStatusLocalization(type: ERequisitionStatusDTO | null): string {
  const map: Record<ERequisitionStatusDTO, () => string> = {
    [ERequisitionStatusDTO.Created]: () => TranslateService.localize('enums.requisition-status.Created'),
    [ERequisitionStatusDTO.InProcessing]: () => TranslateService.localize('enums.requisition-status.InProcessing'),
    [ERequisitionStatusDTO.Closed]: () => TranslateService.localize('enums.requisition-status.Closed'),
  };

  return map[type]?.() || '';
}

export function getRequisitionStageTypeLocalization(type: ERequisitionStageTypeDTO): string {
  const map: Record<ERequisitionStageTypeDTO, () => string> = {
    [ERequisitionStageTypeDTO.Appointment]: () => TranslateService.localize('enums.requisition-stage-type.Appointment'),
    [ERequisitionStageTypeDTO.LiveQueue]: () => TranslateService.localize('enums.requisition-stage-type.LiveQueue'),
    [ERequisitionStageTypeDTO.None]: () => TranslateService.localize('enums.requisition-stage-type.None'),
    [ERequisitionStageTypeDTO.Receptionist]: () =>
      TranslateService.localize('enums.requisition-stage-type.Receptionist'),
  };

  return map[type]?.() || '';
}
