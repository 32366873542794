import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'lib-spinner',
  template: '<span [attr.data-color]="color"></span>',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  @Input() color: 'light' | 'theme' = 'theme';
  @HostBinding('style.height.em') @Input() heightEm: number = 1;
}
