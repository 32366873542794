<app-aside-page-header>
  <app-avatar-with-content>
    <h2 class="h1">
      {{ pageTitle }}
    </h2>
  </app-avatar-with-content>
</app-aside-page-header>
<app-aside-page-section [paddings]="true" [fillSpace]="true">
  <lib-card class="mb-2">
    <lib-card-body>
      <ng-container [ngSwitch]="userType">
        <ng-container *ngSwitchCase="profileTypes.HealthCenterAdmin">
          <app-health-center-admin-invite-details-form
            [hcAdmin]="editableUser"
            [isPending]="isPending"
            (dataSubmit)="addUser($event)"
          ></app-health-center-admin-invite-details-form>
        </ng-container>
        <ng-container *ngSwitchCase="profileTypes.Admin">
          <app-admin-invite-details-form
            [admin]="editableUser"
            (dataSubmit)="addUser($event)"
          ></app-admin-invite-details-form>
        </ng-container>
        <ng-container *ngSwitchCase="profileTypes.Doctor">
          <app-doctor-invite-details-form
            [doctor]="editableUser"
            [isPending]="isPending"
            (dataSubmit)="addUser($event)"
          ></app-doctor-invite-details-form>
        </ng-container>
        <ng-container *ngSwitchCase="profileTypes.Patient">
          <app-patient-invite-details-form
            [patient]="editableUser"
            [isPending]="isPending"
            [fatherPatientId]="fatherPatientId"
            [fatherTagId]="fatherTagId"
            (dataSubmit)="addUser($event)"
          ></app-patient-invite-details-form>
        </ng-container>
        <ng-container *ngSwitchCase="profileTypes.Receptionist">
          <app-receptionist-invite-details-form
            [receptionist]="editableUser"
            [isPending]="isPending"
            (dataSubmit)="addUser($event)"
          ></app-receptionist-invite-details-form>
        </ng-container>
      </ng-container>
    </lib-card-body>
  </lib-card>
  <div *ngIf="canImportFromFile && isEditing != 'Invite'" class="mb-8" [ngSwitch]="userType">
    <button
      (click)="downloadInvalidsCsv()"
      [hidden]="(inviteError$ | async).length == 0"
      class="import-btn button-link invalidErrorLink"
    >
      {{ "import-invites-errors.download-csv" | translate }}
    </button>
    <button class="import-btn button-link" (click)="importUsers()" *ngIf="!importDesable">
      {{ "import-invites.import-action" | translate }}
    </button>
  </div>

  <h2 *ngIf="(usersList$ | async)?.length" class="h3 mb-4">
    {{ "phrases.following-invites-will-be-sent" | translate }}
  </h2>

  <div class="user-wraper">
    <table class="list-table">
      <tr *ngFor="let user of usersList$ | async">
        <td>
          <p class="mb-2">
            <b class="text-color-default">{{ user.first_name }} {{ user.last_name }}</b>
          </p>
          <p>
            <span class="inline-info">{{ user.email }}</span>
            <span class="inline-info" *ngIf="user.phone">{{ user.phone }}</span>
            <span class="inline-info" *ngIf="user.license">{{ user.license }}</span>
            <span class="inline-info" *ngIf="user.date_birth">{{ user.date_birth | date }}</span>
          </p>
        </td>

        <td class="min-width-column" *ngIf="user.specializationLabel">
          <b class="text-color-default">{{ user.specializationLabel }}</b>
        </td>
        <td class="min-width-column">
          <button class="button-ghost" [disabled]="inProgress$ | async" (click)="removeUserFromList(user)">
            <lib-icon type="cross"></lib-icon>
          </button>
        </td>
      </tr>
    </table>
  </div>
</app-aside-page-section>

<app-aside-page-section [paddings]="true" *ngIf="hasUsersToInvite$ | async" class="footer">
  <button
    libButton
    [disabled]="inProgress$ | async"
    [isPending]="inProgress$ | async"
    [size]="'l'"
    (click)="sendInvitations()"
    class="btnUsersToInvite"
  >
    {{ "actions.invite" | translate }}
  </button>
</app-aside-page-section>
