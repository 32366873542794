import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { RequisitionApiProviderService, SocketMessagesDataProviderService } from '@project/data-providers';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { IFeatureService } from '@project/services';

@Injectable()
export class PeopleInQueueService implements Resolve<void>, IFeatureService {
  private readonly _peopleInQueue$ = new BehaviorSubject<number>(null);
  public readonly peopleInQueue$ = this._peopleInQueue$.asObservable();

  private readonly _peopleInQueueByHealthCenter$ = new BehaviorSubject<Record<string, number>>(null);
  public readonly peopleInQueueByHealthCenter$ = this._peopleInQueueByHealthCenter$.asObservable();

  private readonly _waitingTimeMinutes$ = new BehaviorSubject<number>(null);
  public readonly waitingTimeMinutes$ = this._waitingTimeMinutes$.asObservable();

  private destroy$ = new Subject();

  constructor(
    private socketMessagesProviderService: SocketMessagesDataProviderService,
    private requisitionApiProviderService: RequisitionApiProviderService,
  ) {}

  initialise(): Observable<void> {
    this.initInfoUpdateBySocketMessages();
    return of(null);
  }

  destroy(): Observable<void> {
    this.destroy$.next();
    return of(null);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<void> {
    return this.updateValue();
  }

  private initInfoUpdateBySocketMessages() {
    this.socketMessagesProviderService.queueInfoUpdate$.pipe(takeUntil(this.destroy$)).subscribe((info) => {
      this._peopleInQueue$.next(info.length);
      this._waitingTimeMinutes$.next(info.waitingTimeMinutes);

      if (info.healthCenterId) {
        this._peopleInQueueByHealthCenter$.next({
          ...this._peopleInQueueByHealthCenter$.value,
          [info.healthCenterId]: info.length,
        });
      }
    });
  }

  private updateValue(): Observable<void> {
    return this.requisitionApiProviderService.getQueueInfo().pipe(
      catchError(() => of(null)),
      tap((info) => this._peopleInQueue$.next(info?.size ?? null)),
      map(() => null),
    );
  }
}
