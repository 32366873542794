import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TabsControlService } from '../tabs-control.service';
import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'lib-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TabsControlService],
})
export class TabsComponent implements OnInit {
  @Input() hideBorder: boolean;

  public tabs$ = this.tabsControlService.tabs$;
  public activeTab$ = this.tabsControlService.activeTab$;

  constructor(private tabsControlService: TabsControlService) {}

  ngOnInit(): void {}

  setTabActive(tab: TabComponent) {
    this.tabsControlService.setTabActive(tab);
  }
}
