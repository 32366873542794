import { TGuid } from '@core/helpers';

export const DEFAULT_QUESTIONNAIRE_ANSWER_ID_DTO: TGuid = '00000000-0000-0000-0000-000000000000';

export enum EQuestionnaireTypeDTO {
  Main = 'Main',
  Empty = 'Empty',
}

export enum EQuestionnaireAnswersTypeDTO {
  Select = 'select',
  MultiSelect = 'multi_select',
  Text = 'text',
}
