<main>
  <div class="content">
    <h1 class="h1">
      {{ participant?.fullName }} {{ "session.conference-overlay.user-is-ready-to-begin" | translate }}
    </h1>
    <p>
      {{ "session.conference-overlay.call-to-action" | translate }} <br />
      {{ "session.conference-overlay.description" | translate }}
    </p>
  </div>

  <div class="buttons">
    <button libButton [appearance]="'outline'" [size]="'l'" (click)="onDeclineClick()">
      {{ "actions.cancel" | translate }}
    </button>
    <button libButton (click)="onJoinClick()" [size]="'l'">
      {{ "actions.join" | translate }}
    </button>
  </div>
</main>
