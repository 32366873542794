<lib-modal-header (close)="close()">
  <span>{{ "nouns.attention" | translate }}</span
  >!
</lib-modal-header>
<lib-modal-body>
  <p class="message mb-4" *ngIf="isRecepcionist()">
    {{ "user-feature.close-chats.message" | translate }}
  </p>
  <p class="message mb-4" *ngIf="isLivequeue()">
    {{ "user-feature.close-chats.message.with.doctor" | translate }}
  </p>

  <p class="message mb-4" *ngIf="isLivequeue()">
    {{ "patients-unfinished-consult" | translate }}
  </p>

  <ul>
    <li *ngFor="let requisitionPreview of requisitionsPreview">
      <lib-card>
        <lib-card-body>
          <b>{{ requisitionPreview.title }}&nbsp;</b>
          <i *ngIf="requisitionPreview.description?.length">({{ requisitionPreview.description }})</i>
        </lib-card-body>
      </lib-card>
    </li>
  </ul>

  <p class="question" *ngIf="isRecepcionist()">
    {{ "user-feature.close-chats.question" | translate }}
  </p>
</lib-modal-body>
<lib-modal-footer class="buttons-container" *ngIf="isRecepcionist()">
  <button libButton appearance="outline" [disabled]="inProgress$ | async" (click)="close()" data-testid="close-button">
    {{ "modals.confirm.actions.no" | translate }}
  </button>

  <button
    libButton
    appearance="primary"
    [disabled]="inProgress$ | async"
    [isPending]="inProgress$ | async"
    (click)="closeRequisitions()"
    data-testid="dont-close-button"
  >
    {{ "modals.confirm.actions.yes" | translate }}
  </button>
</lib-modal-footer>
