import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { map } from 'rxjs/operators';
import { TGuid } from '@core/helpers';
import {
  ISummaryNote,
  ISummaryNoteDTO,
  SummaryNoteViewModelFactory,
  ISummaryNoteHistoryDTO,
  SummaryNoteHistoryViewModeFactory,
  ISummaryNoteHistory,
} from '@project/view-models';

interface ICheckNotesQueryParams {
  doctorId?: TGuid;
  requisitionId?: TGuid;
}

@Injectable({
  providedIn: 'root',
})
export class SummaryNotesApiProviderService {
  constructor(private apiService: ApiService) {}

  public createByRequisitionId(requisitionId: TGuid, note: string): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/notes/requisitions/${requisitionId}`, {
      note,
    });
  }

  public getSummaryNoteHistoryById(id: TGuid): Observable<ISummaryNoteHistory[]> {
    return this.apiService
      .get<ISummaryNoteHistoryDTO[]>(`${environment.environmentVariables.apiCoreUrl}/notes/history/${id}`)
      .pipe(map((notes) => notes.map((chat) => SummaryNoteHistoryViewModeFactory.createFromDTO(chat))));
  }

  public getAllRelatedToPatient(patientId: TGuid): Observable<ISummaryNote[]> {
    return this.apiService
      .get<ISummaryNoteDTO[]>(`${environment.environmentVariables.apiCoreUrl}/patients/${patientId}/notes`)
      .pipe(map((notes) => notes.map((chat) => SummaryNoteViewModelFactory.createFromDTO(chat))));
  }

  public getAllRelatedToRequisition(requisitionId: TGuid): Observable<ISummaryNote[]> {
    return this.apiService
      .get<ISummaryNoteDTO[]>(`${environment.environmentVariables.apiCoreUrl}/requisition/${requisitionId}/notes`)
      .pipe(map((notes) => notes.map((chat) => SummaryNoteViewModelFactory.createFromDTO(chat))));
  }

  public checkExistence(queryParams: ICheckNotesQueryParams = {}): Observable<boolean> {
    return this.apiService.get<boolean>(`${environment.environmentVariables.apiCoreUrl}/notes/exists`, queryParams);
  }

  public updateSummaryNoteId(id: TGuid, note: string): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/notes/${id}`, {
      note,
    });
  }
}
