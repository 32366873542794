import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasAccessDirective } from './has-access.directive';
import { HasOneOfAccessDirective } from './has-one-of-access.directive';

@NgModule({
  declarations: [HasAccessDirective, HasOneOfAccessDirective],
  exports: [HasAccessDirective, HasOneOfAccessDirective],
  imports: [CommonModule],
})
export class HasAccessDirectiveModule {}
