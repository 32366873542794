import { DateFormatter, TDateISOString, TGuid } from '@core/helpers';
import { IProfileShort } from './profile.view-model';
import { EInvitationStatusDTO, getInvitationStatusLocalization } from './consts/invitation-status';

export interface IInvitationDTO {
  id: TGuid;
  date_created: TDateISOString;
  status: EInvitationStatusDTO;
  author_profile_id: TGuid | null;
  person_profile_id: TGuid;
}

export interface IInvitation {
  id: TGuid;
  dateCreated: Date;
  status: EInvitationStatusDTO;
  statusLabel: string;
  authorProfileId: TGuid | null;
  personProfileId: TGuid;
}

export interface IInvitationWithProfiles {
  id: TGuid;
  dateCreated: Date;
  status: EInvitationStatusDTO;
  statusLabel: string;
  author: IProfileShort;
  person: IProfileShort;
}

export interface IInvitationCreateResponseDTO {
  profile_id: TGuid;
  invitation_id: TGuid;
}

export interface IInvitationCreateResponse {
  profileId: TGuid;
  invitationId: TGuid;
}

export interface IInvitationsCreateResponseDTO {
  success: IInvitationCreateResponseDTO[];
}

export class InvitationViewModelFactory {
  static createFromDTO(dto: IInvitationDTO): IInvitation {
    return {
      id: dto.id,
      dateCreated: DateFormatter.stringToDate(dto.date_created),
      status: dto.status,
      statusLabel: getInvitationStatusLocalization(dto.status),
      authorProfileId: dto.author_profile_id,
      personProfileId: dto.person_profile_id,
    };
  }

  static createFromDtoWithProfiles(
    dto: IInvitationDTO,
    profiles: Record<TGuid, IProfileShort>,
  ): IInvitationWithProfiles {
    return {
      id: dto.id,
      dateCreated: DateFormatter.stringToDate(dto.date_created),
      status: dto.status,
      statusLabel: getInvitationStatusLocalization(dto.status),
      author: profiles[dto.author_profile_id] ?? null,
      person: profiles[dto.person_profile_id],
    };
  }

  static createResponseFromDTO(dto: IInvitationCreateResponseDTO): IInvitationCreateResponse {
    return {
      invitationId: dto.invitation_id,
      profileId: dto.profile_id,
    };
  }

  static createResponseAllFromDTO(dto: IInvitationsCreateResponseDTO): IInvitationCreateResponse[] {
    return dto.success.map((invites: IInvitationCreateResponseDTO) => {
      return {
        invitationId: invites.invitation_id,
        profileId: invites.profile_id,
      };
    });
  }
}
