import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EProfileTypeDTO } from '@project/view-models';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-avatar-preview',
  templateUrl: './avatar-preview.component.html',
  styleUrls: ['./avatar-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarPreviewComponent implements OnChanges {
  @Input() url: string;
  @Input() fallbackTheme: 'blue' | 'white' = 'blue';
  @Input() profileType: EProfileTypeDTO;

  private _fallbackAvatar$ = new BehaviorSubject<EProfileTypeDTO>(null);
  public fallbackAvatar$: Observable<SafeHtml> = this._fallbackAvatar$.pipe(
    distinctUntilChanged(),
    switchMap((profileType) => this.getFallbackAvatarIcon(profileType)),
    map((module) => module.default),
    map((rawIcon) => this.domSanitizer.bypassSecurityTrustHtml(rawIcon)),
    shareReplay(1),
  );

  constructor(private domSanitizer: DomSanitizer) {}

  @HostBinding('class') get theme(): string {
    return `fallback-theme-${this.fallbackTheme}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.profileType) {
      this._fallbackAvatar$.next(this.profileType);
    }
  }

  private getFallbackAvatarIcon(profileType: EProfileTypeDTO | null): Promise<{ default: string }> {
    const defaultValue = () =>
      import(
        /* webpackChunkName: "avatar-user" */
        `!!raw-loader!src/assets/component-assets/avatar-preview/user-avatar-placeholder.svg`
      );

    const profileTypeToIconMap: { [K in EProfileTypeDTO]?: any } = {
      [EProfileTypeDTO.Doctor]: () =>
        import(
          /* webpackChunkName: "avatar-doctor" */
          `!!raw-loader!src/assets/component-assets/avatar-preview/doctor-avatar-placeholder.svg`
        ),
      [EProfileTypeDTO.Patient]: () =>
        import(
          /* webpackChunkName: "avatar-user" */
          `!!raw-loader!src/assets/component-assets/avatar-preview/user-avatar-placeholder.svg`
        ),
      [EProfileTypeDTO.Receptionist]: () =>
        import(
          /* webpackChunkName: "avatar-receptionist" */
          `!!raw-loader!src/assets/component-assets/avatar-preview/receptionist-avatar-placeholder.svg`
        ),
    };

    return profileTypeToIconMap[profileType]?.() ?? defaultValue();
  }
}
