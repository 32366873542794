import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

export interface IMultipleHttpRequestsResponse<D = any> {
  error: HttpErrorResponse | null;
  succeedCount: number;
  failedCount: number;
  succeedResponseData: D[];
}

@Injectable({
  providedIn: 'root',
})
export class HttpHelperService {
  static multipleRequests<TDataToReturn>(
    rawRequests: Observable<TDataToReturn>[],
  ): Observable<IMultipleHttpRequestsResponse<TDataToReturn>> {
    const response: IMultipleHttpRequestsResponse<TDataToReturn> = {
      error: null,
      failedCount: 0,
      succeedCount: 0,
      succeedResponseData: [],
    };

    const requests = rawRequests.map((req) =>
      req.pipe(
        tap((responseData) => {
          response.succeedResponseData.push(responseData);
          response.succeedCount++;
        }),
        catchError((err: HttpErrorResponse) => {
          response.error = err;
          response.failedCount++;
          return of(null);
        }),
      ),
    );

    if (!requests.length) {
      return of(response);
    }

    return forkJoin(requests).pipe(map(() => response));
  }
}
