<app-aside-page-section [paddings]="true">
  <ng-container *ngIf="measurement.group_row.length; else onboardingTemplate">
    <ng-container>
      <section class="mb-6">
        <h2 class="h2 mb-1">
          <span>{{ "nouns.method" | translate }}</span
          >: {{ "nouns.methodName" | translate }}
        </h2>
        <table>
          <thead>
            <tr>
              <th class="date-column">{{ "nouns.date" | translate }}</th>
              <th *ngFor="let colum of measurement.columns_name">{{ colum }}</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let row of measurement.group_row">
              <td style="white-space: nowrap;">{{ row.created_date | date: "medium" }}</td>
              <td class="value-cell" [ngClass]="{ success: true }" *ngFor="let item of row.columns">
                {{ item.value }}{{ item.unity }}
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </ng-container>
  </ng-container>

  <ng-template #onboardingTemplate>
    <p class="onboarding-message">{{ "observations.details.no-data-message" | translate }}</p>
  </ng-template>
</app-aside-page-section>
