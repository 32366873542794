export enum ELocale {
  En = 'en',
  Pt = 'pt',
  Es = 'es',
}

const defaultLocale = ELocale.Pt;
const possibleLocales: string[] = [ELocale.Pt, ELocale.En, ELocale.Es];

export function getCurrentLocale(): ELocale {
  const userLocale = localStorage.getItem('locale') || navigator.language.slice(0, 2);
  if (possibleLocales.includes(userLocale?.toLowerCase())) {
    return userLocale as ELocale;
  }
  return defaultLocale;
}
