import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarLayoutComponent } from './sidebar-layout/sidebar-layout.component';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar/sidebar.component';
import { WelcomeLayoutComponent } from './welcome-layout/welcome-layout.component';
import { IconsModule } from '@lib/icons';
import { HasAccessDirectiveModule } from '../has-access-directive/public-api';
import { SidebarUserCardComponent } from './sidebar-user-card/sidebar-user-card.component';
import { AuthorizationLayoutComponent } from './authorization-layout/authorization-layout.component';
import { AsideOutletModule } from '../aside-outlet/public-api';
import { FeatureFlagModule } from '@project/modules/feature-flag';
import { TranslateModule } from '@project/translate';
import { SidebarMenuIconComponent } from './sidebar-menu-icon/sidebar-menu-icon.component';
import { AvatarModule } from '../avatar/public-api';
import { SidebarLogoComponent } from './sidebar-logo/sidebar-logo.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';

@NgModule({
  declarations: [
    SidebarLayoutComponent,
    SidebarComponent,
    SidebarLogoComponent,
    WelcomeLayoutComponent,
    SidebarUserCardComponent,
    AuthorizationLayoutComponent,
    SidebarMenuIconComponent,
  ],
  exports: [SidebarLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    AsideOutletModule,
    IconsModule,
    HasAccessDirectiveModule,
    AvatarModule,
    AsideOutletModule,
    FeatureFlagModule,
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
  ],
})
export class LayoutModule {}
