import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IDoctor, IPatient, ITimeSlot } from '@project/view-models';

@Component({
  selector: 'app-appointment-card',
  templateUrl: './appointment-card.component.html',
  styleUrls: ['./appointment-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentCardComponent {
  @Input() appointment: ITimeSlot;
  @Input() patientToPreview: IPatient;
  @Input() doctorToPreview: IDoctor;
}
