import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IfFeatureEnabledDirective } from './if-feature-enabled.directive';

@NgModule({
  declarations: [IfFeatureEnabledDirective],
  exports: [IfFeatureEnabledDirective],
  imports: [CommonModule],
})
export class FeatureFlagModule {}
