import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CloseRequisitionWithSummaryNoteOverlayComponent } from './close-requisition-with-summary-note-overlay/close-requisition-with-summary-note-overlay.component';
import { ModalModule } from '@lib/modal';
import { CloseRequisitionWithSummaryNoteService } from './close-requisition-with-summary-note.service';
import { ButtonModule, FormElementsModule, ModalDialogLayoutModule } from '@lib/ui';
import { CloseRequisitionWithConfirmService } from './close-requisition-with-confirm.service';
import { TranslateModule } from '@project/translate';

@NgModule({
  declarations: [CloseRequisitionWithSummaryNoteOverlayComponent],
  imports: [CommonModule, ModalModule, FormElementsModule, TranslateModule, ButtonModule, ModalDialogLayoutModule],
  providers: [CloseRequisitionWithSummaryNoteService, CloseRequisitionWithConfirmService],
})
export class CloseRequisitionWithConfirmModule {}
