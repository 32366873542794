import { ApplicationRef, Injectable, NgZone } from '@angular/core';
import { runInZoneOperator } from '@core/helpers';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApplicationRuntimeService {
  constructor(private applicationRef: ApplicationRef, private ngZone: NgZone) {}

  /**
   * We have to return to zone after checking `isStable` state
   */
  public isStable$ = this.applicationRef.isStable.pipe(runInZoneOperator(this.ngZone));
  public readonly signInReferer = new BehaviorSubject<string>('');
}
