import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { IModalComponent } from '@lib/modal';
import { ModalRating } from './modal-rating.model';
@Component({
  selector: 'app-modal-rating',
  templateUrl: './modal-rating.component.html',
  styleUrls: ['./modal-rating.component.scss'],
})
export class RatingComponent implements IModalComponent<ModalRating>, OnDestroy {
  rating: ModalRating = {
    requisition_id: '',
    grade: 0,
    message: '',
  };

  public close$: EventEmitter<any>;
  public submit$: EventEmitter<ModalRating>;

  @Output() submitRating$: EventEmitter<ModalRating> = new EventEmitter();
  @Input() headerTitle: string;

  public isLoading = false;

  constructor() {
    this.close$ = new EventEmitter();
    this.submit$ = new EventEmitter();
  }
  ngOnDestroy(): void {}

  public close() {
    this.close$.emit();
  }

  public sendRating() {
    this.submitRating$.emit(this.rating);
    this.isLoading = true;
  }
}
