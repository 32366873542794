import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import { ELocale, getCurrentLocale } from './locale-getter';
import { loadTranslations } from '@angular/localize';

export function initLocale() {
  type TLocaleData = any;
  const map: Record<ELocale, TLocaleData> = {
    [ELocale.En]: localeEn,
    [ELocale.Pt]: localePt,
    [ELocale.Es]: localeEs,
  };

  const locale = getCurrentLocale();
  registerLocaleData(map[locale], locale);
}

export function loadLocaleTranslations(locale: ELocale): Promise<void> {
  return (
    import(
      /* webpackInclude: /\.ts$/ */
      /* webpackChunkName: "locale-" */
      './locale/' + locale
    )
      // Load default locale
      .catch(() => import('./locale/pt'))
      .then((module) => {
        loadTranslations(module.default);
      })
  );
}
