import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IRequisition } from '@project/view-models';

export class MeasurementPreview {
  public columns_name: string[] = [];
  public group_row: Row[] = [];
}

export class Row {
  public created_date: Date;
  public columns: Column[] = [];
}

export class Column {
  public group: number;
  public name: string;
  public unity: string;
  public value: string;
}

@Component({
  selector: 'app-session-details-shared-patient-observations',
  templateUrl: './session-details-shared-patient-observations.component.html',
  styleUrls: ['./session-details-shared-patient-observations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionDetailsSharedPatientObservationsComponent {
  @Input() requisition: IRequisition;
  @Input() measurement: MeasurementPreview;
}
