import { Injectable } from '@angular/core';
import { bindCallback, defer, Observable, of, Subject } from 'rxjs';
import { ServiceWorkerProviderService, FirebaseProviderService } from '@project/providers';
import { finalize, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { NotificationsApiProviderService } from '@project/data-providers';
import { messaging } from 'firebase/app';
import { IFeatureService } from '@project/services';

const NOTIFICATIONS_STORAGE_TOKEN = 'notifications';

class NotificationsNotAllowedError extends Error {}

@Injectable({
  providedIn: 'root',
})
export class WebNotificationsFeatureService implements IFeatureService {
  private readonly firebaseMessaging?: messaging.Messaging;
  private readonly destroy$ = new Subject();
  private readonly messagingInitialisation$ = this.serviceWorkerProviderService.getServiceWorkerRegistration().pipe(
    tap((registration) => this.firebaseMessaging.useServiceWorker(registration)),
    map(() => null),
    shareReplay(1),
  );

  constructor(
    private serviceWorkerProviderService: ServiceWorkerProviderService,
    private notificationsApiProviderService: NotificationsApiProviderService,
    firebaseProviderService: FirebaseProviderService,
  ) {
    if (firebaseProviderService.isMessagingSupported()) {
      this.firebaseMessaging = firebaseProviderService.getFirebaseMessaging();
    }
  }

  public initialise(): Observable<void> {
    if (!this.serviceWorkerProviderService.isServiceWorkerEnabled()) {
      return of(null);
    }

    if (!this.firebaseMessaging) {
      return of(null);
    }

    if (!Notification || typeof Notification.requestPermission !== 'function') {
      return of(null);
    }

    // If already have token to receive notifications
    if (this.getTokenFromStorage()) {
      return this.useExistingSubscription();
    }

    return this.messagingInitialisation$.pipe(
      tap(() => this.subscribeNotifications()),
      map(() => null),
    );
  }

  public destroy(): Observable<void> {
    this.destroy$.next();
    return defer(() => this.unsubscribeNotifications());
  }

  /**
   * @important must be called on logout
   */
  private unsubscribeNotifications(): Promise<void> {
    const token = this.getTokenFromStorage();
    if (!token) {
      return Promise.resolve();
    }

    return defer(() => this.firebaseMessaging.deleteToken(token))
      .pipe(
        switchMap(() => this.notificationsApiProviderService.removeToken(token)),
        finalize(() => this.removeTokenInStorage()),
      )
      .toPromise();
  }

  private useExistingSubscription(): Observable<void> {
    return this.messagingInitialisation$.pipe(tap(() => this.subscribeRefreshToken()));
  }

  private requestNotificationsPermission(): Observable<void> {
    return defer(() => Notification.requestPermission()).pipe(
      map((result: NotificationPermission) => {
        if (result === 'denied') {
          throw new NotificationsNotAllowedError();
        }

        return null;
      }),
    );
  }

  private subscribeNotifications() {
    this.requestNotificationsPermission()
      .pipe(
        switchMap(() => this.firebaseMessaging.getToken()),
        switchMap((token) => this.notificationsApiProviderService.registerToken(token).pipe(map(() => token))),
        tap((token) => this.saveTokenInStorage(token)),
        tap(() => this.subscribeRefreshToken()),
        takeUntil(this.destroy$),
      )
      .subscribe({
        error: (err) => {
          if (err instanceof NotificationsNotAllowedError) {
            console.log('Notifications not allowed');
            return;
          }

          console.error(err);
        },
      });
  }

  private subscribeRefreshToken() {
    bindCallback(this.firebaseMessaging.onTokenRefresh)()
      .pipe(
        switchMap(() => this.firebaseMessaging.getToken()),
        switchMap((token) =>
          this.notificationsApiProviderService.removeToken(this.getTokenFromStorage()).pipe(map(() => token)),
        ),
        switchMap((token) => this.notificationsApiProviderService.registerToken(token).pipe(map(() => token))),
        tap((token) => this.saveTokenInStorage(token)),
        takeUntil(this.destroy$),
      )
      .subscribe({
        error: (err) => console.error(err),
      });
  }

  private saveTokenInStorage(token: string) {
    localStorage.setItem(NOTIFICATIONS_STORAGE_TOKEN, token);
  }

  private removeTokenInStorage() {
    localStorage.removeItem(NOTIFICATIONS_STORAGE_TOKEN);
  }

  private getTokenFromStorage(): string {
    return localStorage.getItem(NOTIFICATIONS_STORAGE_TOKEN);
  }
}
