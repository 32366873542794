import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ExternalScriptLoaderService } from '@core/services';
import { environment } from '@env';
import GoogleAuth = gapi.auth2.GoogleAuth;
import { first, switchMap } from 'rxjs/operators';

export interface IGoogleAuthResult {
  code: string;
}

export enum EGoogleAuthErrorType {
  PopupClosedByUser = 'popup_closed_by_user',
  IframeInitializationFailed = 'idpiframe_initialization_failed',
}

@Injectable()
export class GoogleAuthProviderService {
  private _enabled$ = new BehaviorSubject<boolean>(false);
  public enabled$ = this._enabled$.asObservable();

  private _loading$ = new BehaviorSubject<boolean>(true);
  public loading$ = this._loading$.asObservable();

  private googleAuth$ = new BehaviorSubject<GoogleAuth | null>(null);

  constructor(private externalScriptLoaderService: ExternalScriptLoaderService) {}

  public signIn(): Observable<IGoogleAuthResult> {
    return this.googleAuth$.pipe(
      first((auth) => !!auth),
      switchMap((auth) => {
        /* We use `new Promise` wrapper to keep this observable inside NgZone  */
        return new Promise<IGoogleAuthResult>((res, rej) => {
          auth.grantOfflineAccess().then(res).catch(rej);
        });
      }),
    );
  }

  private getSDK(): Promise<GoogleAuth> {
    return this.externalScriptLoaderService.load('gapi', 'https://apis.google.com/js/platform.js').then(() => {
      if (this.googleAuth$.value) {
        return Promise.resolve(this.googleAuth$.value);
      }

      return new Promise<GoogleAuth>((resolve, reject) =>
        gapi.load('auth2', () => {
          const googleAuth = gapi.auth2.init({
            client_id: environment.appIds.googleClientId,
            scope: 'profile',
            redirect_uri: 'postmessage',
            cookie_policy: 'none',
            fetch_basic_profile: false,
          });

          googleAuth.then(
            () => {
              resolve(googleAuth);
            },
            (reason) => {
              reject(reason);
            },
          );
        }),
      );
    });
  }

  public initialize() {
    if (this.googleAuth$.value) {
      return;
    }

    this.getSDK()
      .then((auth) => {
        this.googleAuth$.next(auth);
        this._enabled$.next(true);
      })
      .catch(() => this._enabled$.next(false))
      .finally(() => this._loading$.next(false));
  }
}
