import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateFormatter, DateUtils } from '@core/helpers';
import { TranslateService } from '@project/translate';
import { STATIC_DATE_FORMAT } from './date-formats';
import { IDayPaginator } from './day-paginator';
import { getCurrentLocale } from '@project/translate';

export class FullListStreamDayPaginator<T> implements IDayPaginator<T> {
  private readonly _selectedDay$ = new BehaviorSubject<Date>(new Date());
  public readonly selectedDay$: Observable<Date> = this._selectedDay$.asObservable();

  readonly itemsForSelectedDay$: Observable<T[]> = combineLatest([this.items$, this._selectedDay$]).pipe(
    map(([items, date]) => {
      return items.filter(
        (item) =>
          DateFormatter.dateToString(this.dateExtractor(item), { format: STATIC_DATE_FORMAT }) ===
          DateFormatter.dateToString(date, { format: STATIC_DATE_FORMAT }),
      );
    }),
  );

  // This implementation of paginator hasn't async operations
  public readonly inProgress$ = of(false);

  public readonly selectedDayFormatted$: Observable<string> = this.selectedDay$.pipe(
    map((date) => {
      if (DateFormatter.isToday(date)) {
        return TranslateService.localize('nouns.today');
      }

      return DateFormatter.formatDateToPreviewShort(date, getCurrentLocale());
    }),
  );

  constructor(private items$: Observable<T[]>, private dateExtractor: (element: T) => Date) {}

  previousDay() {
    const nextDate = DateUtils.shift(this._selectedDay$.value, { days: -1 });
    this._selectedDay$.next(nextDate);
  }

  nextDay() {
    const nextDate = DateUtils.shift(this._selectedDay$.value, { days: 1 });
    this._selectedDay$.next(nextDate);
  }

  selectDay(day: Date) {
    this._selectedDay$.next(day);
  }
}
