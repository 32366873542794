import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalHeaderComponent {
  @Input() backButtonLabel: string;

  @Output() close = new EventEmitter();
  @Output() back = new EventEmitter();

  closeHandler() {
    this.close.emit();
  }

  backHandler() {
    this.back.emit();
  }
}
