import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'lib-button-dropdown-menu-item',
  template: '<ng-content></ng-content>',
  styleUrls: ['./button-dropdown-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonDropdownMenuItemComponent {
  @HostBinding('attr.tabindex') private tabindex = 0;
}
