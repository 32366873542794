import { Injectable, InjectionToken, Injector } from '@angular/core';
import { ListItemsService } from '@modules';
import {
  EInvitationStatusDTO,
  EProfileTypeDTO,
  IInvitationWithProfiles,
  INVITATION_STATUSES_WITHOUT_ACCEPTED,
} from '@project/view-models';
import {
  InvitesServerSideItemsProvider,
  IPatientFiltersParams,
  UserInviteApiProviderService,
} from '@project/data-providers';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { NotificationsService } from '@lib/notifications';
import { HttpHelperService } from './http-helper.service';
import { TGuid } from '@core/helpers';
import { TranslateService } from '@project/translate';

@Injectable()
export class InvitationsPagedManagerService {
  private listService: ListItemsService<IInvitationWithProfiles>;
  private isInitialized$ = new BehaviorSubject<boolean>(false);

  public readonly invitations$ = this.isInitialized$.pipe(
    switchMap((isInitialized) => (isInitialized ? this.listService.currentPageItems$ : of([]))),
  );

  public readonly hasSelectedInvitations$ = this.isInitialized$.pipe(
    switchMap((isInitialized) => (isInitialized ? this.listService.hasSelectedIds$ : of(false))),
  );

  public readonly selectedInvitationsCount$ = this.isInitialized$.pipe(
    switchMap((isInitialized) => (isInitialized ? this.listService.selectedIdsCount$ : of(null))),
  );

  private INVITES_PER_PAGE = 15;

  constructor(
    private injector: Injector,
    private userInviteApiProviderService: UserInviteApiProviderService,
    private notificationsService: NotificationsService,
    private invitesServerSideItemsProvider: InvitesServerSideItemsProvider,
  ) {}

  public init(
    listServiceToken: InjectionToken<any>,
    userProfileTypesFilter: EProfileTypeDTO[] | undefined,
    invitationStatusesFilter: EInvitationStatusDTO | undefined = EInvitationStatusDTO.Null,
  ) {
    this.listService = this.injector.get(listServiceToken);

    this.invitesServerSideItemsProvider.setFilters({
      status: invitationStatusesFilter,
      role: userProfileTypesFilter,
      search: null,
    });
    this.isInitialized$.next(true);
  }

  public clearSelection() {
    this.listService.clearSelection();
  }

  public loadInvitationsByPage(page: number, filters: Partial<any>): Observable<void> {
    const filtersState = this.invitesServerSideItemsProvider.filtersState;

    this.invitesServerSideItemsProvider.setFilters({ ...filtersState, ...filters });

    return this.invitesServerSideItemsProvider.updateItems(page, this.INVITES_PER_PAGE).pipe(
      catchError(() => {
        this.notificationsService.error({
          message: TranslateService.localize('invitations.load.fail.message'),
          durationMs: Infinity,
        });
        return of([]);
      }),
      tap((invites) => this.listService.setItems(invites)),
      map(() => null),
    );
  }

  public addItemsToCurrentPage(items: IInvitationWithProfiles[]) {
    this.listService.addItemsToCurrentPage(items);
  }

  public removeSelectedInvitations(): Observable<void> {
    return HttpHelperService.multipleRequests<TGuid>(
      this.listService.selectedIds.map((id) =>
        this.userInviteApiProviderService.removeInvitation(id).pipe(map(() => id)),
      ),
    ).pipe(
      switchMap((response) =>
        this.loadInvitationsByPage(this.invitesServerSideItemsProvider.pagingState.currentPageNumber, null).pipe(
          switchMap(() =>
            this.listService.getItems().length === 0 &&
            this.invitesServerSideItemsProvider.pagingState.currentPageNumber !== 1
              ? this.loadInvitationsByPage(this.invitesServerSideItemsProvider.pagingState.currentPageNumber - 1, null)
              : of(null),
          ),
          map(() => response),
        ),
      ),
      tap((response) => {
        if (response.failedCount || response.error) {
          this.notificationsService.error({
            message: response.error?.error || TranslateService.localize('errors.unable-remove'),
          });
        } else {
          this.notificationsService.success({
            message: TranslateService.localize('success-messages.removed'),
          });
        }
      }),
      map(() => null),
    );
  }

  public resendSelectedInvitations(): Observable<void> {
    return this.resendInvitations(this.listService.selectedIds);
  }

  public resendAllInvitations(): Observable<void> {
    return this.resendInvitations(this.listService.currentPageIds);
  }

  private resendInvitations(ids: TGuid[]): Observable<void> {
    return HttpHelperService.multipleRequests<TGuid>(
      ids.map((id) => this.userInviteApiProviderService.resendInvitation(id).pipe(map(() => id))),
    ).pipe(
      tap((response) => {
        if (response.failedCount || response.error) {
          this.notificationsService.error({
            message: response.error?.error || TranslateService.localize('invitations.resend.fail.message'),
          });
        } else {
          const successMessage =
            response.succeedCount > 1
              ? TranslateService.localize('invitations.resend-multiple.success.message')
              : TranslateService.localize('invitations.resend.success.message');

          this.notificationsService.success({
            message: successMessage,
          });
        }
      }),
      map(() => null),
    );
  }
}
