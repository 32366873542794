import { NgModule } from '@angular/core';
import { ConfirmComponent } from './confirm/confirm.component';
import { ModalBackdropComponent } from './modal-backdrop/modal-backdrop.component';
import { OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ButtonModule, ModalDialogLayoutModule } from '@lib/ui';
import { ModalWindowComponent } from './modal-window/modal-window.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CustomOverlayContainer } from './custom-overlay-container';
import { ResizeDirective } from './resize/resize.directive';
import { ResizeTriggerDirective } from './resize/resize-trigger.directive';

@NgModule({
  declarations: [
    ConfirmComponent,
    ModalBackdropComponent,
    ModalWindowComponent,
    ResizeDirective,
    ResizeTriggerDirective,
  ],
  imports: [CommonModule, OverlayModule, DragDropModule, ModalDialogLayoutModule, ButtonModule],
  exports: [],
  providers: [
    {
      provide: OverlayContainer,
      useClass: CustomOverlayContainer,
    },
  ],
})
export class ModalModule {}
