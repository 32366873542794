import { Directive, HostListener, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { ResizeDirective } from './resize.directive';

@Directive({
  selector: '[libResizeTrigger]',
})
export class ResizeTriggerDirective implements OnDestroy {
  private movementSubscription = Subscription.EMPTY;

  constructor(private resizeDirective: ResizeDirective) {}

  @HostListener('document:mouseup', ['$event'])
  onMouseUp() {
    this.movementSubscription.unsubscribe();
    this.resizeDirective.endMovement();
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(e: MouseEvent) {
    e.preventDefault();
    if (!this.movementSubscription.closed) {
      return;
    }

    this.initMouseMoveWatcher();
  }

  private initMouseMoveWatcher() {
    this.movementSubscription = fromEvent(document, 'mousemove')
      .pipe(throttleTime(this.resizeDirective.libResizeThrottleMs))
      .subscribe((e: MouseEvent) => this.resizeDirective.handleMove(e.pageX, e.pageY));
  }

  ngOnDestroy() {
    this.movementSubscription.unsubscribe();
  }
}
