import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionComponent } from './accordion/accordion.component';
import { IconsModule } from '@lib/icons';
import { DropdownModule } from '../dropdown/public-api';

@NgModule({
  declarations: [AccordionComponent],
  exports: [AccordionComponent],
  imports: [CommonModule, DropdownModule, IconsModule],
})
export class AccordionModule {}
