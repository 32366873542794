<app-aside-page-section [paddings]="true">
  <ng-container *ngIf="requisition; else emptyMessageTemplate">
    <ng-container *ngIf="requisition?.questions?.length; else emptyMessageTemplate">
      <lib-card *ngFor="let question of requisition.questions" class="card mb-4">
        <lib-card-body>
          <h2 class="h4 mb-5">{{ question.title }}</h2>
          <p class="fz-m-1" [class.mb-3]="!last" *ngFor="let answer of question.answers; let last = last">
            {{ answer.title }}
          </p>
        </lib-card-body>
      </lib-card>
    </ng-container>
  </ng-container>

  <ng-template #emptyMessageTemplate>
    <p class="onboarding-message">
      {{ "empty-message.no-questionnaire" | translate }}
    </p>
  </ng-template>
</app-aside-page-section>
