import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleSocialAuthButtonComponent } from './social-buttons/google-social-auth-button/google-social-auth-button.component';
import { FacebookSocialAuthButtonComponent } from './social-buttons/facebook-social-auth-button/facebook-social-auth-button.component';
import { IconsModule } from '@lib/icons';
import { SocialAuthorizationService } from './services/social-authorization.service';
import { GoogleAuthProviderService } from './services/google-auth-provider.service';
import { FacebookAuthProviderService } from './services/facebook-auth-provider.service';
import { AppleAuthProviderService } from './services/apple-auth-provider.service';
import { AppleSocialAuthButtonComponent } from './social-buttons/apple-social-auth-button/apple-social-auth-button.component';

@NgModule({
  declarations: [GoogleSocialAuthButtonComponent, FacebookSocialAuthButtonComponent, AppleSocialAuthButtonComponent],
  imports: [CommonModule, IconsModule],
  exports: [GoogleSocialAuthButtonComponent, FacebookSocialAuthButtonComponent, AppleSocialAuthButtonComponent],
})
export class SocialAuthorizationModule {
  public static forRoot(): ModuleWithProviders<SocialAuthorizationModule> {
    return {
      ngModule: SocialAuthorizationModule,
      providers: [
        SocialAuthorizationService,
        GoogleAuthProviderService,
        FacebookAuthProviderService,
        AppleAuthProviderService,
      ],
    };
  }
}
