<ng-container>
  <lib-card class="card">
    <div class="left">
      <lib-icon class="icon mb-2" [type]="'people'" [ngClass]="patientQueueStatus"></lib-icon>
      <span *ngIf="patientQueuePosition != null" class="counter">{{ patientQueuePosition }}</span>
      <mat-progress-spinner
        *ngIf="patientQueuePosition == null"
        value="75"
        mode="indeterminate"
        diameter="30"
        class="custom-spinner"
      ></mat-progress-spinner>
      <span class="counter-label text-color-muted m-0"> {{ "session.patient-queue-position" | translate }}</span>
    </div>
    <div class="right">
      <p class="description text-color-muted">
        <ng-content></ng-content>
      </p>
    </div>
  </lib-card>
</ng-container>
