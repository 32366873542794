import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoSizedTextareaDirective } from './auto-sized-textarea.directive';

@NgModule({
  declarations: [AutoSizedTextareaDirective],
  imports: [CommonModule],
  exports: [AutoSizedTextareaDirective],
})
export class AutoSizedTextareaModule {}
