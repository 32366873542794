import { ISocketMessage } from './common';
import { TGuid } from '@core/helpers';
import { ESocketMessageTypeDTO } from '../consts/socket-message-type';
import { EDoctorStatusDTO } from '../consts/doctor-status';

export enum EDoctorStatusUpdateReasonDTO {
  Custom = 'custom',
  System = 'system',
}

export interface IDoctorStatusUpdateEventDTO extends ISocketMessage {
  doctor_id: TGuid;
  status: string; // TODO Backend should return enum value instead lowerCase value of enum and we shouldn't manually map this values
  type: ESocketMessageTypeDTO.DoctorStatusUpdate;
  reason: EDoctorStatusUpdateReasonDTO;
}

export interface IDoctorStatusUpdateEvent {
  doctorId: TGuid;
  status: EDoctorStatusDTO;
  reason: EDoctorStatusUpdateReasonDTO;
}

export class DoctorStatusUpdateEventSocketMessageViewModelFactory {
  static createFromDTO(dto: IDoctorStatusUpdateEventDTO): IDoctorStatusUpdateEvent {
    const statusMap: Record<string, EDoctorStatusDTO> = {
      away: EDoctorStatusDTO.Away,
      active: EDoctorStatusDTO.Active,
    };

    return {
      doctorId: dto.doctor_id,
      status: statusMap[dto.status],
      reason: dto.reason,
    };
  }
}
