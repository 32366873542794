import { EProfileTypeDTO } from './../../view-models/consts/profile-type';
import { ProfileRoleType } from './../../view-models/user-to-invite.view-model';
import { Injectable } from '@angular/core';
import { IProfileTag } from '@project/view-models';
import { merge, Observable } from 'rxjs';
import { ModalOverlayService } from '@lib/modal';
import { ProfileTagSettingsOverlayComponent } from './profile-tag-settings-overlay/profile-tag-settings-overlay.component';
import { first, map, tap } from 'rxjs/operators';

export enum EProfileTagSettingsResult {
  NothingChanged = 'NothingChanged',
  Updated = 'Updated',
  Removed = 'Removed',
}

export interface IProfileTagSettingsResult {
  type: EProfileTagSettingsResult;
  tag: IProfileTag | null;
}

@Injectable({
  providedIn: 'root',
})
export class ProfileTagManagerService {
  constructor(private modalOverlayService: ModalOverlayService) {}

  openSettings(tag?: IProfileTag, profileType?: EProfileTypeDTO): Observable<IProfileTagSettingsResult> {
    const ref = this.modalOverlayService.openOverlay(
      ProfileTagSettingsOverlayComponent,
      {
        tag,
        profileType,
      },
      {
        closeByBackdropClick: false,
        closeByEsc: false,
      },
    );

    const remove$: Observable<IProfileTagSettingsResult> = ref.instance.remove$.pipe(
      map((targetTag) => ({
        type: EProfileTagSettingsResult.Removed,
        tag: targetTag,
      })),
    );

    const update$: Observable<IProfileTagSettingsResult> = ref.instance.submit$.pipe(
      map((targetTag) => ({
        type: EProfileTagSettingsResult.Updated,
        tag: targetTag,
      })),
    );

    const close$: Observable<IProfileTagSettingsResult> = ref.instance.close$.pipe(
      map((targetTag) => ({
        type: EProfileTagSettingsResult.NothingChanged,
        tag: targetTag,
      })),
    );

    return merge(remove$, close$, update$).pipe(
      first(),
      tap(() => ref?.close()),
    );
  }
}
