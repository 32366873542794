<div class="date-controls">
  <button libButton [onlyIcon]="true" appearance="outline" [disabled]="disablePrevButton$ | async" (click)="prevDay()">
    <<
  </button>
  <p class="h3">
    {{ selectedDayFormatted$ | async }}
  </p>
  <button libButton [onlyIcon]="true" appearance="outline" (click)="nextDay()">>></button>
</div>

<div class="body">
  <ng-container *ngIf="itemsForSelectedDay$ | async as slotsToShow">
    <lib-time-slots
      *ngIf="slotsToShow.length > 0; else noTimeSlots"
      [timeSlots]="slotsToShow"
      [allActionsDisabled]="disableSlotActions"
    ></lib-time-slots>
    <ng-template #noTimeSlots>
      <p class="h3 text-align-center pt-4">{{ "time-slots.no-time-slots" | translate }}</p>
    </ng-template>
  </ng-container>
</div>
