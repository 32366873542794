import { Injectable } from '@angular/core';
import { ApiService, IPagingResponse, PagingApiService } from '@core/http';
import { iif, Observable, of } from 'rxjs';
import { environment } from '@env';
import {
  EInvitationStatusDTO,
  EProfileTypeDTO,
  IDoctorToInvite,
  IGeneralAdminToInvite,
  IHealthCenterAdminToInvite,
  IInvitationCreateResponse,
  IInvitationCreateResponseDTO,
  IInvitationDTO,
  IInvitationsCreateResponseDTO,
  IInvitationWithProfiles,
  InvitationViewModelFactory,
  IPatientToInvite,
  IReceptionistToInvite,
  ProfileRoleType,
  TUserToInvite,
  UserInviteViewModelFactory,
} from '@project/view-models';
import { keepUniqueOnly, TGuid } from '@core/helpers';
import { map, switchMap } from 'rxjs/operators';
import { ProfileApiProviderService } from './profile-api-provider.service';

@Injectable({
  providedIn: 'root',
})
export class UserInviteApiProviderService {
  constructor(
    private apiService: ApiService,
    private pagingApiService: PagingApiService,
    private profileApiProviderService: ProfileApiProviderService,
  ) {}

  getDTOProfiles(profileType: ProfileRoleType, users: TUserToInvite[]) {
    if (profileType === ProfileRoleType.Patient) {
      return { patients: users, profile_type: profileType };
    } else if (profileType === ProfileRoleType.Doctor) {
      return { doctors: users, profile_type: profileType };
    } else if (profileType === ProfileRoleType.Receptionist) {
      return { receptionists: users, profile_type: profileType };
    } else if (profileType === ProfileRoleType.Admin) {
      return { generals: users, profile_type: profileType };
    } else if (profileType === ProfileRoleType.HealthCenterAdmin) {
      return { hc_admin: users, profile_type: profileType };
    }
  }

  getAllWithProfiles(
    profileTypes?: EProfileTypeDTO[],
    invitationStatuses?: EInvitationStatusDTO,
    search: string = null,
  ): Observable<IInvitationWithProfiles[]> {
    return this.apiService
      .get<IInvitationDTO[]>(`${environment.environmentVariables.apiAuthUrl}/invites`, {
        role: profileTypes,
        status: invitationStatuses,
        search,
      })
      .pipe(
        switchMap((invites) => {
          const profileIds = keepUniqueOnly(
            invites
              .map((invite) => [invite.author_profile_id, invite.person_profile_id])
              .flat()
              .filter((v) => !!v),
          );

          return iif(
            () => profileIds.length > 0,
            this.profileApiProviderService.getShortProfiles(profileIds),
            of({}),
          ).pipe(
            map((profiles) =>
              invites.map((invite) => InvitationViewModelFactory.createFromDtoWithProfiles(invite, profiles)),
            ),
          );
        }),
      );
  }

  getByIdWithProfiles(id: TGuid): Observable<IInvitationWithProfiles> {
    return this.apiService.get<IInvitationDTO>(`${environment.environmentVariables.apiAuthUrl}/invites/${id}`).pipe(
      switchMap((invite) => {
        const ids = [invite.person_profile_id, invite.author_profile_id].filter((v) => !!v);
        return iif(() => ids.length > 0, this.profileApiProviderService.getShortProfiles(ids), of({})).pipe(
          map((profiles) => InvitationViewModelFactory.createFromDtoWithProfiles(invite, profiles)),
        );
      }),
    );
  }

  getPagedWithProfiles(
    page: number,
    itemsPerPage: number,
    queryParams?: Record<string, any>,
  ): Observable<IPagingResponse<IInvitationWithProfiles>> {
    return this.pagingApiService
      .request<IInvitationDTO, IInvitationDTO>({
        url: `${environment.environmentVariables.apiAuthUrl}/invites`,
        additionalQueryParams: queryParams,
        itemsPerPage,
        itemFactory: (item) => item,
        pageNumber: page,
      })
      .pipe(
        switchMap((pagingResponse) => {
          const profileIds = keepUniqueOnly(
            pagingResponse.items
              .map((invite) => [invite.author_profile_id, invite.person_profile_id])
              .flat()
              .filter((v) => !!v),
          );

          return iif(
            () => profileIds.length > 0,
            this.profileApiProviderService.getShortProfiles(profileIds),
            of({}),
          ).pipe(
            map(
              (profiles): IPagingResponse<IInvitationWithProfiles> => ({
                ...pagingResponse,
                items: pagingResponse.items.map((invite) =>
                  InvitationViewModelFactory.createFromDtoWithProfiles(invite, profiles),
                ),
              }),
            ),
          );
        }),
      );
  }

  resendInvitation(id: TGuid): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiAuthUrl}/invites/${id}/resend`);
  }

  removeInvitation(id: TGuid): Observable<void> {
    return this.apiService.delete(`${environment.environmentVariables.apiAuthUrl}/invites/${id}`);
  }

  invites(users: TUserToInvite[], profileRoleType: ProfileRoleType): Observable<IInvitationCreateResponse[]> {
    console.log(users);
    return this.apiService
      .post<IInvitationsCreateResponseDTO>(
        `${environment.environmentVariables.apiAuthUrl}/invites/imports`,
        this.getDTOProfiles(profileRoleType, users),
      )
      .pipe(
        map((data) => {
          return InvitationViewModelFactory.createResponseAllFromDTO(data);
        }),
      );
  }

  inviteGeneralAdmin(user: IGeneralAdminToInvite): Observable<IInvitationCreateResponse> {
    return this.apiService
      .post<IInvitationCreateResponseDTO>(
        `${environment.environmentVariables.apiAuthUrl}/invites/admin/general`,
        UserInviteViewModelFactory.createGeneralAdminDTO(user),
      )
      .pipe(map((data) => InvitationViewModelFactory.createResponseFromDTO(data)));
  }

  inviteHealthCenterAdmin(user: IHealthCenterAdminToInvite): Observable<IInvitationCreateResponse> {
    return this.apiService
      .post<IInvitationCreateResponseDTO>(
        `${environment.environmentVariables.apiAuthUrl}/invites/admin/healthcenter`,
        UserInviteViewModelFactory.createHealthCenterAdminDTO(user),
      )
      .pipe(map((data) => InvitationViewModelFactory.createResponseFromDTO(data)));
  }

  inviteDoctor(user: IDoctorToInvite): Observable<IInvitationCreateResponse> {
    return this.apiService
      .post<IInvitationCreateResponseDTO>(
        `${environment.environmentVariables.apiAuthUrl}/invites/doctor`,
        UserInviteViewModelFactory.createDoctorDTO(user),
      )
      .pipe(map((data) => InvitationViewModelFactory.createResponseFromDTO(data)));
  }

  invitePatient(user: IPatientToInvite): Observable<IInvitationCreateResponse> {
    return this.apiService
      .post<IInvitationCreateResponseDTO>(
        `${environment.environmentVariables.apiAuthUrl}/invites/patient`,
        UserInviteViewModelFactory.createPatientDTO(user),
      )
      .pipe(map((data) => InvitationViewModelFactory.createResponseFromDTO(data)));
  }

  inviteReceptionist(user: IReceptionistToInvite): Observable<IInvitationCreateResponse> {
    return this.apiService
      .post<IInvitationCreateResponseDTO>(
        `${environment.environmentVariables.apiAuthUrl}/invites/receptionist`,
        UserInviteViewModelFactory.createReceptionistDTO(user),
      )
      .pipe(map((data) => InvitationViewModelFactory.createResponseFromDTO(data)));
  }
}
