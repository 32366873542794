import { DateFormatter, TGuid, TPositiveIntNumber } from '@core/helpers';
import {
  IScheduleRuleWorkingHours,
  IScheduleRuleWorkingHoursCreateData,
  IScheduleRuleWorkingHoursCreateDTO,
  IScheduleRuleWorkingHoursDTO,
  ScheduleRuleWorkingHoursViewModelFactory,
} from './schedule-rule-working-hours.view-model';
import { EScheduleDayDTO, getDayLocalizedLabel } from './consts/schedule-consts';
import { TranslateService } from '@project/translate';
import { STATIC_DATE_FORMAT, TStaticDateFormat } from '@project/shared';

export interface IScheduleWeeklyRuleDTO {
  id: TGuid;
  starting_from: TStaticDateFormat | null;
  working_weeks: TPositiveIntNumber;
  weeks_off: TPositiveIntNumber;
  days_of_week: EScheduleDayDTO[];
  working_hours: IScheduleRuleWorkingHoursDTO[];
}

export interface IScheduleWeeklyRuleCreateDTO {
  starting_from: TStaticDateFormat | null;
  working_weeks: TPositiveIntNumber;
  weeks_off: TPositiveIntNumber;
  days_of_week: EScheduleDayDTO[];
  working_hours: IScheduleRuleWorkingHoursCreateDTO[];
}

export interface IScheduleWeeklyRule {
  id: TGuid;
  startingFrom: Date | null;
  workingWeeks: TPositiveIntNumber;
  weeksOff: TPositiveIntNumber;
  daysOfWeek: EScheduleDayDTO[];
  summaryLabel: string;
  isStartingFromDateMatter: boolean;
  workingHours: IScheduleRuleWorkingHours[];
}

export interface IScheduleWeeklyRuleCreateData {
  startingFrom: Date | null;
  workingWeeks: TPositiveIntNumber;
  weeksOff: TPositiveIntNumber;
  daysOfWeek: EScheduleDayDTO[];
  workingHours: IScheduleRuleWorkingHoursCreateData[];
}

export class ScheduleWeeklyRuleViewModelFactory {
  static createGetViewModel(dto: IScheduleWeeklyRuleDTO): IScheduleWeeklyRule {
    return {
      id: dto.id,
      startingFrom: dto.starting_from
        ? DateFormatter.stringToDate(dto.starting_from, { format: STATIC_DATE_FORMAT })
        : null,
      workingWeeks: dto.working_weeks,
      weeksOff: dto.weeks_off,
      daysOfWeek: dto.days_of_week,
      summaryLabel: this.getSummaryLabel(dto),
      isStartingFromDateMatter: dto.weeks_off > 0,
      workingHours: dto.working_hours.map((item) => ScheduleRuleWorkingHoursViewModelFactory.createGetViewModel(item)),
    };
  }

  static createPostDTO(data: IScheduleWeeklyRuleCreateData): IScheduleWeeklyRuleCreateDTO {
    return {
      starting_from: data.startingFrom
        ? DateFormatter.dateToString(data.startingFrom, { format: STATIC_DATE_FORMAT })
        : null,
      working_weeks: data.workingWeeks,
      weeks_off: data.weeksOff,
      days_of_week: data.daysOfWeek,
      working_hours: data.workingHours.map((item) => ScheduleRuleWorkingHoursViewModelFactory.createPostDTO(item)),
    };
  }

  private static getSummaryLabel(dto: IScheduleWeeklyRuleDTO): string {
    if (dto.weeks_off === 0) {
      const daysLabelsFull = dto.days_of_week.map((day) => getDayLocalizedLabel({ day, useFull: true }));
      return TranslateService.localize('working-hours.schedule.weekly-summary-label#days', {
        days: daysLabelsFull.join(', '),
      });
    } else {
      const daysLabelsShort = dto.days_of_week.map((day) => getDayLocalizedLabel({ day, useFull: false }));
      return TranslateService.localize(
        'working-hours.schedule.weekly-summary-label-with-off#workingWeeksCount#days#offWeeksCount',
        {
          workingWeeksCount: dto.working_weeks,
          days: daysLabelsShort.join(', '),
          offWeeksCount: dto.weeks_off,
        },
      );
    }
  }
}
