import { Injectable } from '@angular/core';
import { ApiService, IPagingResponse, PagingApiService } from '@core/http';
import { environment } from '@env';
import {
  ISendGridResponse,
  SendGridResponseViewModelFactory,
  ISendGridResponseDTO,
  ISendGridResponseSpam,
  ISendGridResponseSpamDTO,
} from '@project/view-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SendGridApiProviderService {
  constructor(private apiService: ApiService, private pagingApiService: PagingApiService) {}

  getAllBouncesPaged(page: number, itemsPerPage: number): Observable<IPagingResponse<ISendGridResponse>> {
    return this.pagingApiService.request<ISendGridResponseDTO, ISendGridResponse>({
      url: `${environment.environmentVariables.apiAuthUrl}/email/bounces`,
      itemsPerPage,
      itemFactory: (dto) => dto,
      pageNumber: page,
    });
  }

  getAllSpamsPaged(page: number, itemsPerPage: number): Observable<IPagingResponse<ISendGridResponseSpam>> {
    return this.pagingApiService.request<ISendGridResponseSpamDTO, ISendGridResponseSpam>({
      url: `${environment.environmentVariables.apiAuthUrl}/email/spam_reports`,
      itemsPerPage,
      itemFactory: (dto) => dto,
      pageNumber: page,
    });
  }

  getAllInvalidsPaged(page: number, itemsPerPage: number): Observable<IPagingResponse<ISendGridResponse>> {
    return this.pagingApiService.request<ISendGridResponseDTO, ISendGridResponse>({
      url: `${environment.environmentVariables.apiAuthUrl}/email/invalid_reports`,
      itemsPerPage,
      itemFactory: (dto) => dto,
      pageNumber: page,
    });
  }

  getAllBlocksPaged(page: number, itemsPerPage: number): Observable<IPagingResponse<ISendGridResponse>> {
    return this.pagingApiService.request<ISendGridResponseDTO, ISendGridResponse>({
      url: `${environment.environmentVariables.apiAuthUrl}/email/blocks`,
      itemsPerPage,
      itemFactory: (dto) => dto,
      pageNumber: page,
    });
  }
}
