import { ComponentRef } from '@angular/core';
import { ENotificationStatus, INotificationAction, INotificationParams } from './consts';
import { Subject, Observable } from 'rxjs';

const DEFAULT_DURATION_MS = 5000;
const DEFAULT_SUCCESS_DURATION_MS = 3000;

export class NotificationRef {
  public readonly message: string;
  public readonly title: string;
  public readonly actions: INotificationAction[];
  public readonly unclosable: boolean;
  public durationMs: number;
  public close$: Observable<any>;

  private closeSubject: Subject<any>;

  constructor(
    params: INotificationParams,
    public readonly status: ENotificationStatus,
    private componentRef: ComponentRef<any>,
  ) {
    this.message = params.message;
    this.title = params.title || null;
    this.actions = params.actions || [];
    this.unclosable = params.unclosable || false;

    this.setDuration(params.durationMs);

    this.closeSubject = new Subject();
    this.close$ = this.closeSubject.asObservable();

    this.componentRef.onDestroy(() => {
      this.closeSubject.next();
    });
  }

  close() {
    this.componentRef.destroy();
  }

  private setDuration(durationValue: number | undefined) {
    if ((!durationValue || durationValue < 500) && !this.unclosable) {
      this.durationMs = this.status === ENotificationStatus.Success ? DEFAULT_SUCCESS_DURATION_MS : DEFAULT_DURATION_MS;
      return;
    }

    if (isFinite(durationValue) && durationValue <= 60000 && !this.unclosable) {
      this.durationMs = durationValue;
      return;
    }

    this.durationMs = null;
  }
}
