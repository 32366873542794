import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchedulerRuleNestedFormComponent } from './scheduler-rule-nested-form/scheduler-rule-nested-form.component';
import { SchedulerGroupFormComponent } from './scheduler-group-form/scheduler-group-form.component';
import { AccordionModule, ButtonModule, FormElementsModule } from '@lib/ui';
import { TranslateModule } from '@project/translate';
import { IconsModule } from '@lib/icons';

@NgModule({
  declarations: [SchedulerRuleNestedFormComponent, SchedulerGroupFormComponent],
  exports: [SchedulerRuleNestedFormComponent, SchedulerGroupFormComponent],
  imports: [CommonModule, FormElementsModule, AccordionModule, ButtonModule, TranslateModule, IconsModule],
})
export class WorkingHoursFormsModule {}
