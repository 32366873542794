import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-bullet',
  template: '',
  styleUrls: ['./bullet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulletComponent {
  @HostBinding('attr.theme') @Input() theme: 'success' | 'warning' | 'danger' | 'muted' | 'default' = 'default';
}
