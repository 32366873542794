<div class="wraper">
  <table class="list-table">
    <thead>
      <ng-content select="tr[appListTableHead]"></ng-content>
    </thead>
    <tbody>
      <ng-content select="tr[appListTableItem]"></ng-content>
    </tbody>
    <ng-content select="tbody[appListTableBody]"></ng-content>
  </table>
</div>
