import { TranslateModule } from './../../translate/translate.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfilePreviewComponent } from './profile-preview/profile-preview.component';
import { AvatarModule } from '../avatar/public-api';
import { ButtonModule } from '@lib/ui';

@NgModule({
  declarations: [ProfilePreviewComponent],
  exports: [ProfilePreviewComponent],
  imports: [CommonModule, AvatarModule, AvatarModule, ButtonModule, TranslateModule],
})
export class ProfilePreviewModule {}
