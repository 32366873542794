import { Injectable } from '@angular/core';
import {
  IPatientFiltersParams,
  PatientsApiProviderService,
  ProfilesTagsApiProviderService,
} from '@project/data-providers';
import { EProfileTypeDTO, IProfileTag } from '@project/view-models';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { EFeatureFlags } from '@app/config';
import { ActionsManagerService } from '@project/shared';
import { FeatureFlagService } from '../../feature-flag/feature-flag.service';
import {
  EProfileTagSettingsResult,
  IProfileTagSettingsResult,
  ProfileTagManagerService,
} from '../../profile-tags/profile-tag-manager.service';
import { TGuid } from '@core/helpers';
import { PatientServerSideItemsProviderService } from 'src/app/project/data-providers/patient-server-side-items-provider.service';

export enum EPatientsModuleAction {
  OpenTagCreationSettings = 'OpenTagCreationSettings',
  PatchFilters = 'PatchFilters',
  SetTagSelected = 'SetTagSelected',
  ClearTagSelected = 'ClearTagSelected',
  LoadProfileTags = 'LoadProfileTags',
  SetTagById = 'SetTagById',
}

@Injectable({
  providedIn: 'root',
})
export class PatientInviteManagerService extends ActionsManagerService<EPatientsModuleAction> {
  protected actionsHandlersMap = {
    [EPatientsModuleAction.OpenTagCreationSettings]: () => this.createTagAndAddToPatients(),
    [EPatientsModuleAction.PatchFilters]: (filters: IPatientFiltersParams) => this.patchFilters(filters),
    [EPatientsModuleAction.SetTagSelected]: () => this.setTagSelected(),
    [EPatientsModuleAction.ClearTagSelected]: () => this.clearTagSelected(),
    [EPatientsModuleAction.LoadProfileTags]: () => this.loadProfileTags().subscribe(),
    [EPatientsModuleAction.SetTagById]: (tagId: TGuid) => this.setTagById(tagId),
  };

  constructor(
    private featureFlagService: FeatureFlagService,
    private profilesTagsApiProviderService: ProfilesTagsApiProviderService,
    private profileTagManagerService: ProfileTagManagerService,
    private patientServerSideItemsProviderService: PatientServerSideItemsProviderService,
  ) {
    super();
  }

  public readonly _profileTags$ = new BehaviorSubject<IProfileTag[]>([]);
  public readonly profileTags$ = this._profileTags$
    .asObservable()
    .pipe(map((tags) => tags.sort((a, b) => a.name.localeCompare(b.name))));
  public readonly filtersState$ = this.patientServerSideItemsProviderService.filtersState$;
  public readonly tagFilter$ = new BehaviorSubject<TGuid>(null);

  private loadProfileTags(): Observable<IProfileTag[]> {
    if (this.featureFlagService.isFeatureOn(EFeatureFlags.patientsTags)) {
      return this.profilesTagsApiProviderService
        .getAll(EProfileTypeDTO.Patient)
        .pipe(tap((tags) => this._profileTags$.next(tags)));
    } else {
      return of([]);
    }
  }

  private createTagAndAddToPatients() {
    this.waitForTagOverlayResult().subscribe((res) => {
      if (res.type !== EProfileTagSettingsResult.Updated) {
        return;
      }
      this._profileTags$.next(this._profileTags$.value.concat([res.tag]));
    });
  }

  private waitForTagOverlayResult(tag?: IProfileTag): Observable<IProfileTagSettingsResult> {
    return this.profileTagManagerService.openSettings(tag, EProfileTypeDTO.Patient);
  }

  private patchFilters(filters: Partial<IPatientFiltersParams>) {
    this.patientServerSideItemsProviderService.patchFilters(filters);
  }

  private setTagSelected(): void {
    this.tagFilter$.next(this.patientServerSideItemsProviderService.filtersState.tag);
  }

  private clearTagSelected(): void {
    this.patientServerSideItemsProviderService.resetFilters();
  }

  private setTagById(tagId: TGuid) {
    const filters: Partial<IPatientFiltersParams> = { tag: tagId };
    this.patientServerSideItemsProviderService.patchFilters(filters);
  }
}
