import { DateFormatter, TDateISOString, TEmail, TGuid } from '@core/helpers';
import { ProfileHelper } from './helpers/profile.helper';
import { EGenderDTO, getGenderLocalization } from './consts/gender';
import { EProfileTypeDTO } from './consts/profile-type';
import { IProfileTag, IProfileTagDTO, ProfileTagViewModelFactory } from './profile-tag.view-model';

export interface IFullUpdatePatientDTO {
  first_name: string;
  last_name: string;
  gender: EGenderDTO;
  phone: string;
  date_birth?: Date;
  email: TEmail;
}

export interface IPatientAddressDTO {
  id: TGuid;
  country_id?: TGuid;
  state_id?: TGuid;
  country?: string;
  state?: string;
  city?: string;
  zip_code?: string;
  address?: string;
}

export interface IPatientDTO {
  id: TGuid;
  birth_date: TDateISOString;
  gender: EGenderDTO;
  first_name: string | null;
  last_name: string | null;
  photo_url: string | null;
  email: TEmail;
  tags: IProfileTagDTO[] | null;
  address?: IPatientAddressDTO;
  phone?: string;
  cpf_number?: string;
  father_patient_id?: TGuid;
  hc_disabled: boolean;
}

export interface IPatientAddress {
  id: TGuid;
  country: string;
  city: string;
  state?: string;
  zipCode?: string;
  address?: string;
}

export interface IPatient {
  id: TGuid;
  gender: EGenderDTO;
  genderLabel: string;
  fullName: string;
  firstName: string | null;
  lastName: string | null;
  photoUrl: string | null;
  summaryInfo: string[];
  profileType: EProfileTypeDTO.Patient;
  birthDate: Date | null;
  tags: IProfileTag[];
  address?: IPatientAddress;
  age?: number;
  email?: string;
  phone?: string;
  cpfNumber?: string;
  fatherPatientId?: TGuid;
  hc_disabled: boolean;
}

export interface IPatientTag extends IPatient {
  tag?: TGuid;
}

export interface IDependents {
  id: TGuid;
  name: string;
}

export interface IPrescriptionDocument {
  data: {
    success: boolean;
    results: Array<{
      id: TGuid;
      date: TDateISOString;
      url: string;
    }>;
  };
}

export class PatientViewModelFactory {
  static createFromDTO(dto: IPatientDTO): IPatient {
    const dateOfBirth = dto.birth_date ? DateFormatter.stringToDate(dto.birth_date, { ignoreTimezones: true }) : null;

    const age = dateOfBirth ? DateFormatter.getYearsSince(dateOfBirth) : null;
    const genderLabel = getGenderLocalization(dto.gender);
    const address = dto.address ? this.createAddressFromDTO(dto.address) : null;

    const summary: string[] = ProfileHelper.getPatientSummaryInfo({
      age,
      gender: dto.gender,
      addressFields: [address?.city, address?.country],
    });

    return {
      id: dto.id,
      gender: dto.gender,
      genderLabel,
      age,
      birthDate: dateOfBirth,
      fullName: ProfileHelper.getFullName(dto.first_name, dto.last_name, dto.email),
      address,
      summaryInfo: summary,
      firstName: dto.first_name,
      lastName: dto.last_name,
      photoUrl: dto.photo_url,
      email: dto.email,
      tags: (dto.tags || []).map((tagDto) => ProfileTagViewModelFactory.createGetViewModel(tagDto)),
      phone: dto.phone,
      cpfNumber: dto.cpf_number,
      profileType: EProfileTypeDTO.Patient,
      fatherPatientId: dto.father_patient_id,
      hc_disabled: dto.hc_disabled,
    };
  }

  static createAddressFromDTO(dto: IPatientAddressDTO): IPatientAddress {
    return {
      id: dto.id,
      country: dto.country,
      city: dto.city,
    };
  }
}
