import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: '[libLabel]',
  templateUrl: './form-label.component.html',
  styleUrls: ['./form-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormLabelComponent {
  /**
   * Use 'true' to hide input but keep it in html markup
   */
  @HostBinding('attr.data-visibility-hidden') @Input() hidden = false;
  @HostBinding('attr.data-muted') @Input() muted = false;
}
