<div class="notification" [attr.data-status]="notification.status">
  <ng-container [ngSwitch]="notification.status">
    <img class="status-icon" *ngSwitchCase="statuses.Success" src="/shared-assets/notifications/check.svg" alt="" />
    <img class="status-icon" *ngSwitchCase="statuses.Info" src="/shared-assets/notifications/info.svg" alt="" />
    <img class="status-icon" *ngSwitchCase="statuses.Error" src="/shared-assets/notifications/warning.svg" alt="" />
    <img class="status-icon" *ngSwitchCase="statuses.Warning" src="/shared-assets/notifications/warning.svg" alt="" />
  </ng-container>

  <div class="content">
    <h3 class="title" *ngIf="notification.title">{{ notification.title }}</h3>
    <div class="message" [innerHtml]="notification.message"></div>
  </div>

  <div *ngIf="notification.actions.length !== 0" class="actions">
    <button *ngFor="let action of notification.actions" (click)="onActionClick(action)" class="action-btn">
      {{ action.title }}
    </button>
  </div>

  <button *ngIf="!notification.unclosable" title="Close" class="close-btn" (click)="close()">
    <img class="close-btn-icon" src="/shared-assets/notifications/cross.svg" alt="" />
  </button>

  <div
    *ngIf="notification.durationMs"
    class="countdown"
    [attr.data-status]="notification.status"
    [@countdown]="{ value: 'down', params: { time: notification.durationMs } }"
    (@countdown.done)="onCountdownEnd()"
  ></div>
</div>
