<ng-container [ngSwitch]="type">
  <img *ngSwitchCase="types.Success" src="assets/component-assets/session-queue-item-simple-info/check.svg" alt="" />
  <img *ngSwitchCase="types.Warning" src="assets/component-assets/session-queue-item-simple-info/warning.svg" alt="" />
  <img *ngSwitchCase="types.Waiting" src="assets/component-assets/session-queue-item-simple-info/clock.svg" alt="" />
  <img *ngSwitchCase="types.Matching" src="assets/component-assets/session-queue-item-simple-info/track.svg" alt="" />
  <img
    *ngSwitchCase="types.Calendar"
    src="assets/component-assets/session-queue-item-simple-info/date_range.svg"
    alt=""
  />
</ng-container>

<div class="content-container">
  <p *ngIf="mutedTopMessage" class="muted-message">{{ mutedTopMessage }}</p>
  <p class="main-message"><ng-content></ng-content></p>
</div>
