import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormComponent, INPUT_MASKS, INPUT_PLACEHOLDERS } from '@project/shared';
import { FormArray } from '@angular/forms';
import { IIntersectedInterval, IScheduleGroupCreateData, ScheduleRulesIntersectingError } from '@project/view-models';
import { ScheduleGroupFormModelService } from '../schedule-group-form-model.service';
import { ScheduleApiProviderService } from '@project/data-providers';
import { TGuid } from '@core/helpers';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationsService } from '@lib/notifications';
import { TranslateService } from '@project/translate';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-scheduler-group-form',
  templateUrl: './scheduler-group-form.component.html',
  styleUrls: ['./scheduler-group-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ScheduleGroupFormModelService],
})
export class SchedulerGroupFormComponent extends FormComponent implements OnChanges {
  @Input() doctorId: TGuid;

  @Output() created = new EventEmitter();

  public readonly form = this.scheduleGroupFormService.form;

  public dateFieldMask = INPUT_MASKS.date;
  public dateFieldPlaceholder = INPUT_PLACEHOLDERS.date;

  private _intersectionsErrors$ = new BehaviorSubject<IIntersectedInterval[] | null>([]);
  public intersectionsErrors$ = this._intersectionsErrors$.asObservable();

  constructor(
    private scheduleGroupFormService: ScheduleGroupFormModelService,
    private scheduleApiProviderService: ScheduleApiProviderService,
    private notificationsService: NotificationsService,
  ) {
    super();
  }

  get rulesControl(): FormArray {
    return this.scheduleGroupFormService.rulesControl;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.scheduleGroup?.firstChange === false) {
      this.onFormInit();
    }
  }

  public addRuleTemplate() {
    this.scheduleGroupFormService.addRuleGroup();
  }

  public removeRule(index: number) {
    this.scheduleGroupFormService.removeRuleGroup(index);
  }

  protected onFormInit() {
    this.addRuleTemplate();
    this.scheduleGroupFormService.init();
  }

  protected doCleanSubmit() {
    const data: IScheduleGroupCreateData = this.scheduleGroupFormService.getCurrentDataModelToCreateScheduler();

    this._intersectionsErrors$.next(null);
    this.setStartSubmitState();

    this.scheduleApiProviderService.createScheduleForDoctor(this.doctorId, data).subscribe({
      next: () => {
        this.created.emit();
        this.setEndSubmitState();

        this.notificationsService.success({
          message: TranslateService.localize('working-hours.schedule-submit.success.new-schedule-created'),
        });
      },
      error: (err: HttpErrorResponse | ScheduleRulesIntersectingError) => {
        if (err instanceof ScheduleRulesIntersectingError) {
          this.setEndSubmitState();
          const intersectedIntervals = err.intersectedIntervals;
          /**
           * To show only only one error for Rule&WorkingHour intersection.
           * Currently, we receiving error in every day on the week.
           */
          const filteredIntersectionInterval = intersectedIntervals.filter(
            (intervalToFilter, index) =>
              intersectedIntervals.findIndex((interval) =>
                intervalToFilter.sources.every((sourceFromIntervalToFilter) =>
                  interval.sources.find(
                    (source) =>
                      source.ruleId === sourceFromIntervalToFilter.ruleId &&
                      source.workingHourId === sourceFromIntervalToFilter.workingHourId,
                  ),
                ),
              ) === index,
          );

          this._intersectionsErrors$.next(filteredIntersectionInterval);
        } else {
          this.setEndSubmitState(err);

          this.notificationsService.error({
            message:
              typeof err?.error === 'string'
                ? err.error
                : TranslateService.localize('working-hours.schedule-submit.error.unable-to-save-schedule'),
          });
        }
      },
    });
  }
}
