import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionQueueItemComponent } from './session-queue-item/session-queue-item.component';
import { SessionQueueItemSimpleInfoComponent } from './session-queue-item-simple-info/session-queue-item-simple-info.component';
import { SessionQueueItemActionsComponent } from './session-queue-item-actions/session-queue-item-actions.component';

@NgModule({
  declarations: [SessionQueueItemComponent, SessionQueueItemSimpleInfoComponent, SessionQueueItemActionsComponent],
  exports: [SessionQueueItemComponent, SessionQueueItemSimpleInfoComponent, SessionQueueItemActionsComponent],
  imports: [CommonModule],
})
export class SessionQueueItemModule {}
