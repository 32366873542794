import { Injectable } from '@angular/core';
import { Observable, of, Subject, timer } from 'rxjs';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { OfflineStatusMessageComponent } from './offline-status-message/offline-status-message.component';
import { SocketMessagesDataProviderService } from '@project/data-providers';
import { TranslateService } from '@project/translate';
import { IFeatureService } from '@project/services';

const OVERLAY_DEBOUNCE_TIME = 3000;

@Injectable({
  providedIn: 'root',
})
export class ConnectionStatusFeatureService implements IFeatureService {
  private overlayRef: OverlayRef;

  private destroyed$ = new Subject();

  constructor(private overlay: Overlay, private socketMessagesProviderService: SocketMessagesDataProviderService) {}

  public initialise(): Observable<void> {
    this.socketMessagesProviderService.isOffline$
      .pipe(
        switchMap((isOffline) => (isOffline ? timer(OVERLAY_DEBOUNCE_TIME).pipe(map(() => isOffline)) : of(isOffline))),
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
      )
      .subscribe((isOffline) => {
        if (isOffline) {
          this.show();
        } else {
          this.hide();
        }
      });

    return of(null);
  }

  public destroy(): Observable<void> {
    this.destroyed$.next();
    this.hide();
    return of(null);
  }

  private show() {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create();
    }

    this.overlayRef.detach();

    const itemPortal = new ComponentPortal(OfflineStatusMessageComponent);
    const componentRef = this.overlayRef.attach(itemPortal);
    componentRef.instance.message = TranslateService.localize('phrases.you-are-offline-message');
  }

  private hide() {
    this.overlayRef?.detach();
  }
}
