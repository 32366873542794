import { ISocketMessage } from './common';
import { TGuid } from '@core/helpers';
import { ESocketMessageTypeDTO } from '../consts/socket-message-type';

export interface IUserConnectedEventDTO extends ISocketMessage {
  user_id: TGuid;
  type: ESocketMessageTypeDTO.UserConnected;
}

export interface IUserDisconnectedEventDTO extends ISocketMessage {
  user_id: TGuid;
  type: ESocketMessageTypeDTO.UserDisconnected;
}

export interface IUserConnectedEvent {
  userId: TGuid;
}

export interface IUserDisconnectedEvent {
  userId: TGuid;
}

export class UserConnectionEventSocketMessageViewModelFactory {
  static createConnectedEventFromDTO(dto: IUserConnectedEventDTO): IUserConnectedEvent {
    return {
      userId: dto.user_id,
    };
  }

  static createDisconnectedEventFromDTO(dto: IUserDisconnectedEventDTO): IUserDisconnectedEvent {
    return {
      userId: dto.user_id,
    };
  }
}
