import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';

interface ICookieOptions {
  expires?: any;
  path?: string;
  'max-age'?: any;
}

@Injectable({ providedIn: 'root' })
export class CookieService {
  constructor(@Inject(PLATFORM_ID) private platformId, @Inject('req') private readonly req: any) {}

  /**
   * https://javascript.info/cookie#setcookie-name-value-options
   */
  setCookie(name: string, value: string, options: ICookieOptions = {}) {
    if (isPlatformServer(this.platformId)) {
      /* If you need to set cookie via SSR, implement it */
    } else {
      this.setCookieViaBrowser(name, value, options);
    }
  }

  /**
   * https://javascript.info/cookie#getcookie-name
   */
  getCookie(name: string) {
    const matches = this.cookieStore.match(
      new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'),
    );
    return matches ? decodeURIComponent(matches[1]) : '';
  }

  deleteCookie(name) {
    this.setCookie(name, '', {
      'max-age': -1,
    });
  }

  private setCookieViaBrowser(name: string, value: string, options: ICookieOptions = {}) {
    options = {
      path: '/',
      ...options,
    };

    if (options.expires && options.expires.toUTCString) {
      options.expires = options.expires.toUTCString();
    }

    const currentCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);
    let newCookieValue = '';

    Object.keys(options).forEach((key) => {
      newCookieValue += ';' + key;
      const optionValue = options[key];
      if (optionValue !== true) {
        newCookieValue += '=' + optionValue;
      }
    });

    newCookieValue += `;SameSite=Lax`;

    document.cookie = currentCookie + newCookieValue;
  }

  /**
   * Different cookies for browser and server side rendering
   */
  private get cookieStore(): string {
    return this.req !== null ? this.req.cookies || '' : document.cookie;
  }
}
