import { TranslateService } from '@project/translate';

/**
 * @deps By editing keys of this enum, it is necessary to update the template files for csv import.
 */
export enum EGenderDTO {
  Unknown = 'Unknown',
  Male = 'Male',
  Female = 'Female',
}

export function getGenderLocalization(gender: EGenderDTO): string | undefined {
  const map: Record<EGenderDTO, () => string> = {
    [EGenderDTO.Female]: () => TranslateService.localize('gender.female'),
    [EGenderDTO.Male]: () => TranslateService.localize('gender.male'),
    [EGenderDTO.Unknown]: () => TranslateService.localize('gender.unknown'),
  };
  return map[gender]?.();
}
