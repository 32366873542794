import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IPagingButton, PaginationLayoutBuilderService } from './pagination-layout-builder.service';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'lib-paging-control',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PaginationLayoutBuilderService],
  styleUrls: ['paging-control.component.scss'],
  templateUrl: 'paging-control.component.html',
})
export class PagingControlComponent implements OnChanges {
  @Input() pagesTotal = 1;
  @Input() maxVisiblePages = 10;
  @Input() alwaysShowStartAndEnd = true;
  @Input() disabled = false;
  @Input() set page(page: number) {
    this._page$.next(page);
  }

  @Output() pageChange = new EventEmitter<number>();

  private _page$ = new BehaviorSubject<number>(1);
  public page$ = this._page$.asObservable();

  public disablePrev$ = this.page$.pipe(map((page) => page <= 1));
  public disableNext$ = this.page$.pipe(map((page) => page >= this.pagesTotal));

  private _buttons$ = new BehaviorSubject<IPagingButton[]>([]);
  public buttons$ = this._buttons$.asObservable();

  private MINIMAL_VISIBLE_PAGES_COUNT = 5; // Has to always be >= 5

  constructor(private paginationUiService: PaginationLayoutBuilderService) {}

  get page(): number {
    return this._page$.getValue();
  }

  get isCornerButtonsVisible(): boolean {
    return !this.alwaysShowStartAndEnd && this.pagesTotal > this.maxVisiblePages;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.maxVisiblePages || this.maxVisiblePages < this.MINIMAL_VISIBLE_PAGES_COUNT) {
      this.maxVisiblePages = this.MINIMAL_VISIBLE_PAGES_COUNT;
    }

    this.buildButtons();
  }

  first() {
    this.emitPageChose(1);
  }

  last() {
    this.emitPageChose(this.pagesTotal);
  }

  prev() {
    if (this.page <= 1) {
      this.first();
      return;
    }

    if (this.page > this.pagesTotal) {
      this.last();
    } else {
      this.emitPageChose(this.page - 1);
    }
  }

  next() {
    if (this.page >= this.pagesTotal) {
      this.last();
      return;
    }

    if (this.page < 1) {
      this.first();
    } else {
      this.emitPageChose(this.page + 1);
    }
  }

  onPageClick(page: number) {
    if (page === this.page) {
      return;
    }
    this.emitPageChose(page);
  }

  public trackByNumber(index: number, button: IPagingButton): number {
    return button.page;
  }

  private buildButtons() {
    const buttons: IPagingButton[] = this.alwaysShowStartAndEnd
      ? this.paginationUiService.getPagesButtonsWithEdges(this.pagesTotal, this.page, this.maxVisiblePages)
      : this.paginationUiService.getPagesButtonsWithoutEdges(this.pagesTotal, this.page, this.maxVisiblePages);

    this._buttons$.next(buttons);
  }

  private emitPageChose(page: number) {
    this.pageChange.emit(page);
  }
}
