import { EFeatureFlags } from '@app/config';
import { Data, Route } from '@angular/router';
import { EUserPermission } from './permissions';

export enum EUserPermissionsCheckAs {
  Every = 'Every',
  Some = 'Some',
}

export interface IRouteData extends Data {
  titleCreator?: () => string;
  featureFlags?: EFeatureFlags[];
  userPermissionsToCheck?: EUserPermission[];
  userPermissionsCheckAs?: EUserPermissionsCheckAs;
  userPermissionsFailRedirect?: string;
}

export interface IRouteWithData extends Route {
  data?: IRouteData;
  children?: IRouteWithData[];
}

export type TRouteWithOutlet = any[];
