<ul class="tabs" [class.-bordered]="!hideBorder">
  <li
    *ngFor="let tab of tabs$ | async"
    (click)="setTabActive(tab)"
    class="tab"
    [class.active]="tab === (activeTab$ | async)"
  >
    {{tab.label}}
  </li>
</ul>
<div>
  <ng-content select="lib-tab"></ng-content>
</div>
