<div class="buttons-container">
  <button
    type="button"
    *ngIf="isCornerButtonsVisible"
    [title]="1"
    [disabled]="disabled || (disablePrev$ | async)"
    (click)="first()"
  >
    «
  </button>

  <button type="button" [disabled]="disabled || (disablePrev$ | async)" (click)="prev()">
    ‹
  </button>

  <ng-container *ngFor="let item of buttons$ | async; trackBy: trackByNumber">
    <button
      [attr.aria-selected]="item.current"
      type="button"
      [title]="item.page"
      (click)="onPageClick(item.page)"
      [disabled]="disabled"
    >
      {{ item.title }}
    </button>
  </ng-container>

  <button type="button" [disabled]="disabled || (disableNext$ | async)" (click)="next()">
    ›
  </button>

  <button
    type="button"
    *ngIf="isCornerButtonsVisible"
    [disabled]="disabled || (disableNext$ | async)"
    [title]="pagesTotal"
    (click)="last()"
  >
    »
  </button>
</div>
