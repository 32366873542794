import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { IModalComponent, ModalOverlayService, ModalWindowRef } from '@lib/modal';
import { ECurrentVideoSessionState, ESessionState, IVideoCallSession } from '../consts';
import { TranslateService } from '@project/translate';
import { VideoCallManagerService } from '../video-call-manager.service';
import { of, Subject } from 'rxjs';
import { first, take, takeUntil } from 'rxjs/operators';
import { SocketMessagesDataProviderService } from '@project/data-providers';
import { IRequisition } from '@project/view-models';

@Component({
  selector: 'app-video-calls-overlay',
  templateUrl: './video-calls-overlay.component.html',
  styleUrls: ['./video-calls-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoCallsOverlayComponent implements IModalComponent, OnInit, OnDestroy {
  close$ = new EventEmitter();
  submit$ = new EventEmitter();

  public sessions$ = this.videoCallManagerService.participantsSessions$;
  public mySession$ = this.videoCallManagerService.mySession$;
  public videoSessionState$ = this.videoCallManagerService.videoSessionState$;
  public sessionState$ = this.videoCallManagerService.sessionState$;

  public videoSessionStates = ECurrentVideoSessionState;
  public sessionStates = ESessionState;

  public isAudioEnabled$ = this.videoCallManagerService.isAudioEnabled$;
  public isVideoEnabled$ = this.videoCallManagerService.isVideoEnabled$;
  public isAudioToggleInProgress$ = this.videoCallManagerService.isAudioToggleInProgress$;
  public isVideoToggleInProgress$ = this.videoCallManagerService.isVideoToggleInProgress$;

  public isFullscreenMode$ = this.windowRef.isFullscreenMode$;

  private userRole;

  private destroy$ = new Subject();

  public readonly requisition: IRequisition = this.videoCallManagerService.requisition;

  constructor(
    private videoCallManagerService: VideoCallManagerService,
    private modalOverlayService: ModalOverlayService,
    private windowRef: ModalWindowRef,
    private socketMessagesProviderService: SocketMessagesDataProviderService,
  ) {}

  ngOnInit(): void {
    this.windowRef.setCloseHandler(() => {
      this.close();
      return of(false);
    });

    this.videoSessionState$
      .pipe(
        first((state) => state === ECurrentVideoSessionState.Online),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.windowRef.updateConfig({ disableChangeWindowMode: false, hideCloseButton: false }));

    this.socketMessagesProviderService.callStarted$.pipe().subscribe(() =>
      this.sessions$.pipe(take(1)).subscribe((session) => {
        this.userRole = session[0]?.profile.profileType;
        this.videoCallManagerService._hasCallHappenned.next(false);
      }),
    );
  }

  close(): void {
    this.modalOverlayService.confirm(TranslateService.localize('questions.are-you-sure?')).subscribe(() => {
      this.videoCallManagerService.endCurrentCallLegacy();

      if (this.userRole === 'Doctor') {
        this.videoCallManagerService._hasCallHappenned.next(true);
      }
    });
  }

  toggleAudioLegacy() {
    this.videoCallManagerService.toggleAudioLegacy();
  }

  toggleVideoLegacy() {
    this.videoCallManagerService.toggleVideoLegacy();
  }

  trackSession(index, session: IVideoCallSession): string {
    return session.participantId;
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
