<div class="container">
  <div class="video">
    <header>
      <h1>{{ "video-calls.conference-title" | translate }}</h1>
    </header>

    <main class="conference">
      <div class="others">
        <ng-container *ngIf="!(sessions$ | async).length">
          <ng-container *ngTemplateOutlet="conferenceStateTemplate"></ng-container>
        </ng-container>
        <app-participant-video
          class="video"
          *ngFor="let session of sessions$ | async; trackBy: trackSession"
          [showCompactUI]="!(isFullscreenMode$ | async)"
          [session]="session"
        ></app-participant-video>
      </div>
    </main>

    <ul class="controls">
      <li>
        <button
          class="control sound"
          [attr.force-small]="!(isFullscreenMode$ | async)"
          [class.active]="isAudioEnabled$ | async"
          [disabled]="(isAudioToggleInProgress$ | async) || (videoSessionState$ | async) !== videoSessionStates.Online"
          (click)="toggleAudioLegacy()"
        >
          <lib-icon *ngIf="isAudioEnabled$ | async; else micOff" type="mic-white"></lib-icon>
          <ng-template #micOff>
            <lib-icon type="mic-off-white"></lib-icon>
          </ng-template>
        </button>
      </li>
      <li>
        <button
          class="control video"
          [attr.force-small]="!(isFullscreenMode$ | async)"
          [class.active]="isVideoEnabled$ | async"
          [disabled]="(isVideoToggleInProgress$ | async) || (videoSessionState$ | async) !== videoSessionStates.Online"
          (click)="toggleVideoLegacy()"
        >
          <lib-icon *ngIf="isVideoEnabled$ | async; else videoOff" type="videocam-white"></lib-icon>
          <ng-template #videoOff>
            <lib-icon type="videocam-off-white"></lib-icon>
          </ng-template>
        </button>
      </li>
      <li>
        <button
          class="control end-call"
          [attr.force-small]="!(isFullscreenMode$ | async)"
          [class.active]="false"
          [disabled]="
            (videoSessionState$ | async) === videoSessionStates.Offline ||
            (videoSessionState$ | async) === videoSessionStates.Connecting
          "
          (click)="close()"
        >
          <lib-icon type="call-end-white"></lib-icon>
        </button>
      </li>
    </ul>
  </div>

  <ng-container *ngIf="isFullscreenMode$ | async">
    <div>
      <app-chat-room [requisition]="requisition"></app-chat-room>
    </div>
  </ng-container>

  <ng-template #conferenceStateTemplate>
    <h2 class="h3 video-session-state" [ngSwitch]="videoSessionState$ | async">
      <ng-template [ngSwitchCase]="videoSessionStates.Offline">{{ "nouns.loading" | translate }}...</ng-template>
      <ng-template [ngSwitchCase]="videoSessionStates.Connecting">{{ "nouns.loading" | translate }}...</ng-template>
      <ng-template [ngSwitchCase]="videoSessionStates.Online">
        {{ "video-calls.status-messages.waiting-participant" | translate }}...
      </ng-template>
      <ng-template [ngSwitchCase]="videoSessionStates.Error">{{ "nouns.error" | translate }}</ng-template>
    </h2>
  </ng-template>
</div>
