import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

enum EPatientQueueStatus {
  Few = 'few',
  Medium = 'medium',
  Lot = 'lot',
}

@Component({
  selector: 'app-patient-queue-position-card',
  templateUrl: './patient-queue-position-card.component.html',
  styleUrls: ['./patient-queue-position-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientQueuePositionCardComponent {
  @Input() patientQueuePosition: number;

  get patientQueueStatus(): EPatientQueueStatus {
    return this.calculatePatientQueueStatus(this.patientQueuePosition);
  }

  private calculatePatientQueueStatus(patientPosition: number): EPatientQueueStatus {
    if (typeof patientPosition !== 'number') {
      return null;
    }

    if (patientPosition < 5) {
      return EPatientQueueStatus.Few;
    }

    if (patientPosition < 12) {
      return EPatientQueueStatus.Medium;
    }

    return EPatientQueueStatus.Lot;
  }
}
