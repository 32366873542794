import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from '../dropdown/public-api';
import { ActionsDropdownComponent } from './actions-dropdown/actions-dropdown.component';
import { DropdownActionComponent } from './dropdown-action/dropdown-action.component';
import { FindDropdownPositionDirectiveModule } from '../find-dropdown-position-directive/public-api';

@NgModule({
  declarations: [ActionsDropdownComponent, DropdownActionComponent],
  imports: [CommonModule, DropdownModule, FindDropdownPositionDirectiveModule],
  exports: [ActionsDropdownComponent, DropdownActionComponent],
})
export class ActionsDropdownModule {}
