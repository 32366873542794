import { TDateISOString, TEmail, TGuid } from '@core/helpers';
import { ProfileHelper } from './helpers/profile.helper';
import { EProfileTypeDTO } from './consts/profile-type';
import { EGenderDTO } from './consts/gender';

export interface IFullUpdateReceptionistDTO {
  first_name: string;
  last_name: string;
  gender: EGenderDTO;
  phone: string;
  date_birth?: Date;
  email: TEmail;
}

export interface IReceptionistDTO {
  id: TGuid;
  first_name: string | null;
  last_name: string | null;
  email: TEmail;
  phone: string | null;
  photo_url: string | null;
  gender: EGenderDTO;
  birth_date: TDateISOString;
}

export interface IReceptionist {
  id: TGuid;
  fullName: string | null;
  firstName: string;
  lastName: string;
  email: TEmail | null;
  gender: EGenderDTO;
  birthDate: Date | null;
  photoUrl: string | null;
  phone: string;
  profileType: EProfileTypeDTO.Receptionist;
}

export class ReceptionistViewModelFactory {
  static createFromDTO(dto: IReceptionistDTO): IReceptionist {
    return {
      id: dto.id,
      fullName: ProfileHelper.getFullName(dto.first_name, dto.last_name, dto.email),
      firstName: dto.first_name,
      lastName: dto.last_name,
      email: dto.email,
      phone: dto.phone,
      photoUrl: dto.photo_url,
      profileType: EProfileTypeDTO.Receptionist,
      gender: dto.gender,
      birthDate: dto.birth_date != null ? new Date(dto.birth_date) : null,
    };
  }
}
