<ng-container *ngIf="userData$ | async; let userData">
  <app-avatar-preview
    class="avatar haveNewNotification"
    *ngIf="unreadNotifications?.length"
    [profileType]="userData.profileType"
    [url]="userData.photoUrl"
    [fallbackTheme]="'white'"
  ></app-avatar-preview>
  <app-avatar-preview
    class="avatar"
    *ngIf="!unreadNotifications?.length"
    [profileType]="userData.profileType"
    [url]="userData.photoUrl"
    [fallbackTheme]="'white'"
  ></app-avatar-preview>
  <div class="content">
    <p class="name" [title]="userData.fullName">
      <span *ngIf="userData.firstName">{{ userData.firstName }}</span>
      <span *ngIf="userData.lastName">{{ userData.lastName }}</span>
    </p>
    <p data-testid="user-role" class="type">{{ userData.profileTypeLabel }}</p>
  </div>
</ng-container>
