import { IconsModule } from './../../../../../projects/icons/src/lib/icons.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionDetailsSharedPatientObservationsComponent } from './session-details-shared-patient-observations/session-details-shared-patient-observations.component';
import { SessionDetailsSharedQuestionnaireComponent } from './session-details-shared-questionnaire/session-details-shared-questionnaire.component';
import { SessionDetailsSharedMedicalRecordComponent } from './session-details-shared-medical-record/session-details-shared-medical-record.component';
import { TussSelectComponent } from './session-details-shared-medical-record/tuss-select/tuss-select.component';
import { CidSelectComponent } from './session-details-shared-medical-record/cid-select/cid-select.component';
import { LayoutElementsModule } from '../layout-elements/public-api';
import { AutoSizedTextareaModule, ButtonModule, CardModule, FormElementsModule } from '@lib/ui';
import { SessionDetailsSharedSummaryComponent } from './session-details-shared-summary/session-details-shared-summary.component';
import { TranslateModule } from '@project/translate';
import { ObservationDataPreviewModule } from '../observation-data-preview/public-api';
import { FormattedTextViewerModule } from '../formatted-text-viewer/public-api';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatRadioModule } from '@angular/material/radio';
import { RouterModule } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [
    SessionDetailsSharedPatientObservationsComponent,
    SessionDetailsSharedMedicalRecordComponent,
    SessionDetailsSharedQuestionnaireComponent,
    SessionDetailsSharedSummaryComponent,
    CidSelectComponent,
    TussSelectComponent,
  ],
  exports: [
    SessionDetailsSharedPatientObservationsComponent,
    SessionDetailsSharedMedicalRecordComponent,
    SessionDetailsSharedQuestionnaireComponent,
    SessionDetailsSharedSummaryComponent,
    CidSelectComponent,
  ],
  imports: [
    CommonModule,
    LayoutElementsModule,
    CardModule,
    ButtonModule,
    AutoSizedTextareaModule,
    TranslateModule,
    ObservationDataPreviewModule,
    FormattedTextViewerModule,
    IconsModule,
    FormElementsModule,
    NgxMatSelectSearchModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatToolbarModule,
    MatRadioModule,
    RouterModule,
    MatTabsModule,
  ],
})
export class SessionDetailsSharedModule {}
