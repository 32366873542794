import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';

export interface IPagedItemsProceedResult<Item> {
  state: IPagedItemsState;
  items: Item[];
}

export interface IPagedItemsState {
  totalItemsCount: number;
  totalFilteredItemsCount: number;
  totalPagesCount: number;
  currentPageNumber: number;
}

export enum ESortDirection {
  Ascend = 1,
  Descend = -1,
}

export interface IPagedItemsSortingState<Key extends string> {
  sortByKey: Key;
  sortDirection: ESortDirection;
}

@Injectable()
export abstract class ServerSidePagedItemsProviderService<Item, Filters, SortKey extends string> {
  private readonly _pagingState$ = new BehaviorSubject<IPagedItemsState | null>(null);
  public readonly pagingState$ = this._pagingState$.asObservable();

  private readonly _sortingState$ = new BehaviorSubject<IPagedItemsSortingState<SortKey> | null>(null);
  public readonly sortingState$ = this._sortingState$.asObservable();

  protected readonly _filtersState$ = new BehaviorSubject<Filters>(this.getDefaultFilters());
  public readonly filtersState$: Observable<Filters> = this._filtersState$.asObservable();

  private readonly _items$ = new BehaviorSubject<Item[]>([]);
  public readonly items$ = this._items$.asObservable();

  private readonly _isFetching$ = new BehaviorSubject<boolean>(false);
  public readonly isFetching$ = this._isFetching$.asObservable();

  protected defaultItemsPerPage = 30;
  private itemsPerPage: number;

  get pagingState(): IPagedItemsState | null {
    return this._pagingState$.value;
  }

  get filtersState(): Partial<Filters> {
    return this._filtersState$.value;
  }

  public setItemsPerPageCount(count: number) {
    this.defaultItemsPerPage = count;
  }

  public setFilters(filters: Filters) {
    this._filtersState$.next(filters);
  }

  public patchFilters(filters: Partial<Filters>) {
    const currentFilters = this._filtersState$.value;
    this._filtersState$.next({ ...currentFilters, ...filters });
  }

  public setSorting(sortBy: SortKey, direction: ESortDirection) {
    this._sortingState$.next({
      sortByKey: sortBy,
      sortDirection: direction,
    });
  }

  public resetFilters() {
    this._filtersState$.next(this.getDefaultFilters());
  }

  public resetSorting() {
    this._sortingState$.next(null);
  }

  public updateItems(page: number, itemsPerPage: number = this.defaultItemsPerPage): Observable<Item[]> {
    this.itemsPerPage = itemsPerPage;

    if (!this.itemsPerPage || this.itemsPerPage < 0) {
      this.itemsPerPage = this.defaultItemsPerPage;
    }

    this._isFetching$.next(true);

    return this.proceedPagedItemsLoading(
      page,
      this.itemsPerPage,
      this._filtersState$.value,
      this._sortingState$.value,
    ).pipe(
      tap((result) => this._pagingState$.next(result.state)),
      map((result) => result.items),
      tap((items) => this._items$.next(items)),
      catchError((err) => {
        this._items$.next([]);
        this._pagingState$.next(null);
        return throwError(err);
      }),
      finalize(() => this._isFetching$.next(false)),
    );
  }

  protected abstract getDefaultFilters(): Filters;

  protected abstract proceedPagedItemsLoading(
    page: number,
    itemsPerPage: number,
    filters: Partial<Filters>,
    sorting: IPagedItemsSortingState<SortKey>,
  ): Observable<IPagedItemsProceedResult<Item>>;
}
