import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObservationBinahDataTablePreviewComponent } from './observation-binah-data-table-preview/observation-binah-data-table-preview.component';
import { ObservationDataTablePreviewComponent } from './observation-data-table-preview/observation-data-table-preview.component';
import { TranslateModule } from '@project/translate';

@NgModule({
  declarations: [ObservationBinahDataTablePreviewComponent, ObservationDataTablePreviewComponent],
  exports: [ObservationDataTablePreviewComponent],
  imports: [CommonModule, TranslateModule],
})
export class ObservationDataPreviewModule {}
