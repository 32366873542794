<p *ngIf="!hideHeader" class="header">
  <span *ngIf="isMyMessage" class="me">{{ "nouns.me-as-sender" | translate }}</span>
  <span *ngIf="!isMyMessage" class="participant">{{ user.fullName }}</span>
</p>
<ng-container *ngIf="attachment == null; else withAttachment">
  <p class="message" [class.my]="isMyMessage">{{ message.text }}</p>
</ng-container>

<ng-template #withAttachment>
  <p class="message" [class.my]="isMyMessage">
    {{ "chat.attachment.title" | translate }}:
    <a class="attachment" [class.my]="isMyMessage" href="{{ attachment }}" target="_blank">{{ message.text }}</a>
    <img class="attachment-image" [src]="attachment" *ngIf="isImage(attachment)" />
    <img class="pdf-image" *ngIf="isPdf(attachment)" src="assets/icons/pdf.png" />
    <img class="audio-image" src="assets/icons/audio.png" *ngIf="isAudio(attachment)" />
  </p>
</ng-template>
