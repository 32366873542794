import { Inject, Injectable, NgZone } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { asapScheduler, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, observeOn } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';

@Injectable()
export class CustomOverlayContainer extends OverlayContainer {
  public bodyIsFrozen = false;
  private freezeScrollPosition: number;
  private freezeCounter: number = 0;

  private _focusedOverlay = new Subject<Element>();

  constructor(private ngZone: NgZone, @Inject(DOCUMENT) document: Document, platform: Platform) {
    super(document, platform);
  }

  isOverlayFocused(anchorInOverlay: Element): Observable<boolean> {
    return this._focusedOverlay.pipe(
      map((focused) => focused.contains(anchorInOverlay)),
      distinctUntilChanged(),
      observeOn(asapScheduler),
    );
  }

  focusOverlay(anchorInOverlay: Element) {
    const container = this.getContainerElement();
    const targetChild = Array.from(container.children).find((child) => child.contains(anchorInOverlay));
    this._focusedOverlay.next(targetChild);

    if (container.childElementCount === 1) {
      return;
    }

    for (let prevRaisedElement = targetChild; container.lastChild !== targetChild; ) {
      const elementToRaise = container.lastElementChild;
      this.raiseElementHigherThan(elementToRaise, prevRaisedElement);
      prevRaisedElement = elementToRaise;
    }
  }

  public freezeBody() {
    this.freezeCounter--;
    if (this.freezeCounter !== 0) {
      return;
    }

    if (this.bodyIsFrozen) {
      return;
    }
    this.freezeScrollPosition = window.pageYOffset || window.scrollY;
    document.body.style.position = `fixed`;
    document.body.style.top = `-${this.freezeScrollPosition}px`;
    document.body.style.overflow = `hidden`;
    document.body.style.width = `100%`;
    document.body.style.minHeight = `100vh`;
    this.bodyIsFrozen = true;
    this.freezeCounter++;
  }

  public unfreezeBody() {
    document.body.style.overflow = ``;
    document.body.style.position = ``;
    document.body.style.top = ``;
    document.body.style.width = ``;
    document.body.style.minHeight = ``;
    window.scroll({
      top: this.freezeScrollPosition,
    });
    this.bodyIsFrozen = false;
  }

  private raiseElementHigherThan(elementToRaise: Element, higherThanElement: Element) {
    const container = elementToRaise.parentElement;
    const containerChildren: Element[] = Array.from(container.children);
    const higherThanIndex = containerChildren.indexOf(higherThanElement);
    let elementIndex = containerChildren.indexOf(elementToRaise);

    for (; higherThanIndex < elementIndex; elementIndex--) {
      container.insertBefore(container.children[elementIndex], container.children[elementIndex - 1]);
    }
  }
}
