import { BehaviorSubject } from 'rxjs';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { EUserPermission } from '@project/shared';
import { EFeatureFlags, ESidebarIconType } from '@app/config';
import { LocalStorageService } from 'src/app/project/services/local-storage.service';

interface IMenuItem {
  title: string;
  routeLink: string;
  userPermissions: EUserPermission[];
  icon?: ESidebarIconType;
  featureFlag?: EFeatureFlags;
}

@Component({
  selector: 'app-sidebar-logo',
  templateUrl: './sidebar-logo.component.html',
  styleUrls: ['./sidebar-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarLogoComponent implements OnInit {
  public logo: BehaviorSubject<string> = new BehaviorSubject<string>('assets/logos/logo.svg');

  constructor(private localStorageService: LocalStorageService) {
    this.logo.next(localStorage.getItem('logo-url'));
  }

  ngOnInit(): void {
    this.localStorageService.changes.subscribe((localStorage) => {
      if (localStorage.key === 'logo-url') {
        this.logo.next(localStorage.value);
      }
    });
  }
}
