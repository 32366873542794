import { Directive, ElementRef, Inject, Input, OnChanges, PLATFORM_ID, Renderer2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Directive({ selector: 'textarea[autoSized]' })
export class AutoSizedTextareaDirective implements OnChanges {
  /**
   * This @Input() is used instead of @HostListener('input') or 'change' to handle check if value changed programmatically
   */
  @Input() autoSizeValueToCheck: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private renderer: Renderer2,
    private elementRef: ElementRef<HTMLTextAreaElement>,
  ) {}

  ngOnChanges() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    // Reset height before setting new one to get valid content height
    this.renderer.setStyle(this.elementRef.nativeElement, 'height', 'auto');
    this.renderer.setStyle(this.elementRef.nativeElement, 'height', this.elementRef.nativeElement.scrollHeight + 'px');
  }
}
