import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputBasicDirective } from '../input-basic.directive';

export type TInputAppearance = 'default' | 'white';
export type TInputSize = 'l' | 'm';

@Component({
  selector: 'lib-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
})
export class TextareaComponent extends InputBasicDirective<string> implements OnInit, ControlValueAccessor {
  @Input() textareaRows = 5;
  @Input() placeholder: string;
  @Input() maxlength: number;

  constructor(@Optional() controlContainer: ControlContainer) {
    super(controlContainer);
  }

  writeValue(value: string) {
    this._value$.next(value ? value + '' : '');
  }
}
