import { EGenderDTO, IReceptionistToInvite } from '@project/view-models';
import { TranslateService } from '@project/translate';
import { DateFormatter, DEFAULT_DATE_FORMAT, LibValidators } from '@core/helpers';

export interface IReceptionistInviteFormModel {
  email: string;
  gender: EGenderDTO;
  firstName: string;
  lastName: string;
  dateOfBirth?: string;
  phone?: string;
}

export class ReceptionistInviteUtils {
  static getReceptionistInviteFormDeclaration(): Record<string, unknown> {
    return {
      email: ['', [LibValidators.email(TranslateService.localize('validations.invalid-email'))]],
      gender: [
        null,
        [
          LibValidators.required(TranslateService.localize('validations.required')),
          LibValidators.enumValue(EGenderDTO, TranslateService.localize('validations.not-allowed')),
        ],
      ],
      firstName: ['', [LibValidators.required(TranslateService.localize('validations.required'))]],
      lastName: ['', [LibValidators.required(TranslateService.localize('validations.required'))]],
      dateOfBirth: [
        '',
        [
          LibValidators.date(DEFAULT_DATE_FORMAT, TranslateService.localize('validations.invalid')),
          LibValidators.required(TranslateService.localize('validations.required')),
        ],
      ],
      phone: ['', [LibValidators.required(TranslateService.localize('validations.required'))]],
    };
  }

  static createReceptionistInviteDto(form: IReceptionistInviteFormModel): IReceptionistToInvite {
    return {
      email: form.email ? form.email.trim() : null,
      first_name: form.firstName.trim(),
      last_name: form.lastName.trim(),
      gender: form.gender,
      phone: form.phone ? form.phone.trim().replace(/[)(-]/g, '') : null,
      date_birth: form.dateOfBirth
        ? DateFormatter.stringToDate(form.dateOfBirth.trim().replace(/[/-]/g, '.'), { format: DEFAULT_DATE_FORMAT })
        : null,
    };
  }
}
