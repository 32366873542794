export class Cpf {
  private static CPF_BLACKLIST = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999',
  ];

  private static CPF_REGEX = /^[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}$/;
  private static CPF_ONLY_NUMBERS_REGEX = /^[0-9]{3}[0-9]{3}[0-9]{3}[0-9]{2}$/;

  public static isValid(cpf: string, checkOnlyNumber: boolean = false): boolean {
    // Validation algorithm: https://docs.allpago.com/compliance/customer-id-number#validation-algorithm-cpf
    // Initial implementation: https://github.com/benhurott/react-native-masked-text/blob/master/lib/masks/cpf.mask.js
    if (checkOnlyNumber) {
      cpf = cpf.replace(/[.-]/gi, '');
      if (!cpf.match(this.CPF_ONLY_NUMBERS_REGEX)) {
        return false;
      }
    } else {
      if (!cpf.match(this.CPF_REGEX)) {
        return false;
      }
      cpf = cpf.replace(/[.-]/gi, '');
    }

    if (cpf.length !== 11 || this.CPF_BLACKLIST.includes(cpf)) {
      return false;
    }

    // Verification digits checks
    let v1 = 0;
    for (let i = 0; i <= 8; i++) {
      v1 += parseInt(cpf.charAt(i), 10) * (10 - i);
    }

    v1 = 11 - (v1 % 11);

    if (v1 >= 10) {
      v1 = 0;
    }

    if (v1 !== parseInt(cpf.charAt(9), 10)) {
      return false;
    }

    let v2 = 0;
    for (let i = 0; i <= 9; i++) {
      v2 += parseInt(cpf.charAt(i), 10) * (11 - i);
    }

    v2 = 11 - (v2 % 11);

    if (v2 >= 10) {
      v2 = 0;
    }
    if (v2 !== parseInt(cpf.charAt(10), 10)) {
      return false;
    }

    return true;
  }
}
