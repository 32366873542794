import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { ProfileApiProviderService } from '@project/data-providers';
import { UserProfileDataService } from '../user-profile-data.service';
import { catchError, map, tap } from 'rxjs/operators';
import { NotificationsService } from '@lib/notifications';
import { TranslateService } from '@project/translate';
import { LogoutService } from '../logout.service';
import { EProfileTypeDTO } from '@project/view-models';
import { AuthorisationStateService } from '../authorisation-state.service';

/**
 * user's profile is loading via Guard instead of resolver to keep guards/resolvers orders
 * https://github.com/angular/angular/issues/24187
 */

@Injectable({
  providedIn: 'root',
})
export class UserProfileDataLoaderGuard implements CanActivate {
  constructor(
    private userProfileDataService: UserProfileDataService,
    private profileApiProviderService: ProfileApiProviderService,
    private notificationsService: NotificationsService,
    private logoutService: LogoutService,
    private authorisationStateService: AuthorisationStateService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    if (!!this.userProfileDataService.profile) {
      return of(true);
    }

    return forkJoin([
      this.profileApiProviderService.getMyProfileInformation(),
      this.isProfileDataFilled(this.authorisationStateService.getAuthorisationData().userType),
    ]).pipe(
      tap(([profile, isProfileDataFilled]) => {
        this.userProfileDataService.setProfileData(profile);
        this.userProfileDataService.setIsProfileDataFillRequired(!isProfileDataFilled);
      }),
      map(() => true),
      catchError(() => {
        this.notificationsService.error({
          message: TranslateService.localize('errors.unable-load-user-data'),
        });
        return this.logoutService.logoutAsGuard();
      }),
    );
  }

  private isProfileDataFilled(profileType: EProfileTypeDTO): Observable<boolean> {
    const flagResolverByProfile: { [profile in EProfileTypeDTO]?: Observable<boolean> } = {
      [EProfileTypeDTO.Patient]: this.profileApiProviderService.isPatientProfileFilled(),
    };

    return flagResolverByProfile[profileType] ?? of(true);
  }
}
