import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoConferenceManagerService } from './services/video-conference.manager.service';
import { CallIsReadyToBeginOverlayComponent } from './call-is-ready-to-begin-overlay/call-is-ready-to-begin-overlay.component';
import { ButtonModule, CardModule } from '@lib/ui';
import { ModalModule } from '@lib/modal';
import { ReactiveFormsModule } from '@angular/forms';
import { LayoutElementsModule } from '../layout-elements/public-api';
import { ChatRoomModule } from '../chat-room/public-api';
import { FormattedTextViewerModule } from '../formatted-text-viewer/public-api';
import { HasAccessDirectiveModule } from '../has-access-directive/public-api';
import { RouterModule } from '@angular/router';
import { IconsModule } from '@lib/icons';
import { PeopleInQueueService } from './services/people-in-queue.service';
import { PatientQueuePositionService } from './services/patient-queue-position.service';
import { TranslateModule } from '@project/translate';
import { PeopleInQueueCardComponent } from './people-in-queue-card/people-in-queue-card.component';
import { PatientQueuePositionCardComponent } from './patient-queue-position-card/patient-queue-position-card.component';
import { TasksInQueueService } from './services/tasks-in-queue.service';
import { AppointmentCardComponent } from './appointment-card/appointment-card.component';
import { ProfilePreviewModule } from '../profile-preview/public-api';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    CallIsReadyToBeginOverlayComponent,
    PeopleInQueueCardComponent,
    PatientQueuePositionCardComponent,
    AppointmentCardComponent,
  ],
  exports: [PeopleInQueueCardComponent, PatientQueuePositionCardComponent, AppointmentCardComponent],
  imports: [
    CommonModule,
    RouterModule,
    CardModule,
    ButtonModule,
    ModalModule,
    IconsModule,
    LayoutElementsModule,
    ChatRoomModule,
    ReactiveFormsModule,
    FormattedTextViewerModule,
    HasAccessDirectiveModule,
    TranslateModule,
    ProfilePreviewModule,
    MatProgressSpinnerModule,
  ],
})
export class SessionsSharedModule {
  static forRoot(): ModuleWithProviders<SessionsSharedModule> {
    return {
      ngModule: SessionsSharedModule,
      providers: [
        VideoConferenceManagerService,
        PeopleInQueueService,
        PatientQueuePositionService,
        TasksInQueueService,
      ],
    };
  }
}
