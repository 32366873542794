import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

interface ICoords {
  x: number;
  y: number;
}

@Directive({
  selector: '[libBackgroundImage]',
})
export class BackgroundImageDirective implements OnInit, OnChanges {
  @Input('libBackgroundImage') src: string;
  @Input() centerCoords: ICoords = { x: 50, y: 50 };
  @Input() bgSize: 'cover' | 'contain' = 'cover';

  constructor(private renderer: Renderer2, private elementRef: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'background-size', this.bgSize);
    this.renderer.setStyle(this.elementRef.nativeElement, 'background-repeat', `no-repeat`);
    this.setCenter(this.centerCoords);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('src' in changes) {
      this.setBgImage(this.src);
    }

    if ('centerCoords' in changes) {
      this.setCenter(this.centerCoords);
    }
  }

  private normalize(v: number) {
    return v < 0 ? 0 : v > 100 ? 100 : v;
  }

  private setBgImage(url: string | null | undefined) {
    this.renderer.setStyle(this.elementRef.nativeElement, 'background-image', url ? `url('${url}')` : 'none');
  }

  private setCenter(coords: ICoords) {
    const x = this.normalize(coords.x);
    const y = this.normalize(coords.y);
    this.renderer.setStyle(this.elementRef.nativeElement, 'background-position', `${x}% ${y}%`);
  }
}
