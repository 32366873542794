import { DateFormatter, TGuid } from '@core/helpers';
import { IPatient, IPatientDTO, PatientViewModelFactory } from './patient.view-model';
import { DoctorViewModelFactory, IDoctor, IDoctorDTO } from './doctor.view-model';
import { STATIC_DATE_TIME_FORMAT, TStaticDateFormat, TStaticDateTimeFormat } from '@project/shared';
import { TranslateService } from '@project/translate';
import { ERequisitionStatusDTO } from './consts/requisition-consts';

export interface ITimeSlotDTO {
  id: TGuid;
  start: TStaticDateTimeFormat;
  finish: TStaticDateTimeFormat;
  available: boolean;
  patient: IPatientDTO | null;
  doctor: IDoctorDTO | null;
  requisition_id: TGuid | null;
  requisition_status: ERequisitionStatusDTO | null;
}

export interface ITimeSlot {
  id: TGuid;
  start: Date;
  finish: Date;
  title: string;
  available: boolean;
  patient: IPatient | null;
  doctor: IDoctor | null;
  requisitionId: TGuid | null;
  requisitionStatus: ERequisitionStatusDTO | null;
  color?: string;
}

export interface IAppontmentSelectedTimeSlot {
  date?: Date;
  navHide: boolean;
  doctorId?: TGuid;
  closed?: boolean;
}

export interface IDoctorAppointmentTimeSlot {
  ids: TGuid[];
  from?: TStaticDateFormat;
  to?: TStaticDateFormat;
}

export interface ITimeSlotAssign {
  timeslote_id: string;
  requisition_id: TGuid;
}

export class TimeSlotViewModelFactory {
  static createFromDto(dto: ITimeSlotDTO): ITimeSlot {
    const patient = dto.patient ? PatientViewModelFactory.createFromDTO(dto.patient) : null;
    const doctor = dto.doctor ? DoctorViewModelFactory.createFromDTO(dto.doctor) : null;
    return {
      id: dto.id,
      start: DateFormatter.stringToDate(dto.start, { format: STATIC_DATE_TIME_FORMAT }),
      finish: DateFormatter.stringToDate(dto.finish, { format: STATIC_DATE_TIME_FORMAT }),
      available: dto.available,
      patient,
      doctor,
      requisitionId: dto.requisition_id,
      requisitionStatus: dto.requisition_status,
      title: this.getTimeSlotTitle(dto.available, patient, doctor),
      color: dto.doctor?.color,
    };
  }

  private static getTimeSlotTitle(available: boolean, patient: IPatient, doctor: IDoctor): string {
    if (patient) {
      return TranslateService.localize(`time-slots.appointment-with#participant`, {
        participant: patient.fullName,
      });
    }

    if (doctor) {
      return TranslateService.localize(`time-slots.appointment-with#participant`, {
        participant: `${doctor.fullName} (${doctor.specializationLabel})`,
      });
    }

    return TranslateService.localize(available ? `time-slots.available-status` : `time-slots.unavailable-status`);
  }
}
