import { License } from '@project/view-models';
import { DateFormatter, DEFAULT_DATE_FORMAT, TDateISOString, TEmail, TGuid } from '@core/helpers';
import { EGenderDTO } from './consts/gender';
import { EDoctorSpecializationDTO } from './consts/doctor-specialization';
import { IInvitedUserData } from '@modules';
import { EProfileTypeDTO } from './consts/profile-type';

interface IUserToInviteDTO {
  email: TEmail;
  gender: EGenderDTO;
  first_name: string;
  last_name: string;
  phone?: string | null;
  date_birth?: TDateISOString | null;
  tag?: TGuid;
  http_execute?: boolean;
  access_token?: boolean;
}

interface IHealthCenterAdminInviteDTO extends IUserToInviteDTO {
  health_center_id: TGuid;
  access_token: boolean;
}

interface IGeneralAdminInviteDTO extends IUserToInviteDTO {}

interface IPatientToInviteDTO extends IUserToInviteDTO {}

interface IDoctorToInviteDTO extends IUserToInviteDTO {
  specialization: EDoctorSpecializationDTO;
  licenses: License[];
  cpf: string;
}

interface IUserToInvite {
  email: TEmail;
  gender: EGenderDTO;
  first_name: string;
  last_name: string;
  phone?: string;
  date_birth?: Date;
  tag?: TGuid;
  http_execute?: boolean;
  access_token?: boolean;
}

export interface IDoctorToInvite extends IUserToInvite {
  specialization: EDoctorSpecializationDTO;
  specializationLabel: string;
  licenses: License[];
  cpf: string;
}

export interface IHealthCenterAdminToInvite extends IUserToInvite {
  health_center_id?: TGuid;
  access_token: boolean;
}

export interface IGeneralAdminToInvite extends IUserToInvite {}

export interface IReceptionistToInvite extends IUserToInvite {}

export interface IPatientToInvite extends IUserToInvite {
  tag?: TGuid;
  father_patient_id?: TGuid;
}

export enum ProfileRoleType {
  Unknown = 0,
  Admin = 1,
  Patient = 2,
  Doctor = 3,
  HealthCenterAdmin = 4,
  Receptionist = 5,
}

export enum InviteStatusType {
  Queue = 0,
  Sent = 1,
  Error = 2,
  Accepted = 3,
  Resend = 4,
  All = 5,
}

export interface IUsersInvitationResult {
  isSuccess: boolean;
  invitedUsersData: IInvitedUserData[];
  failedUsersFormData: TUserToInviteDTO[];
  profileType: EProfileTypeDTO;
  errorMessages: string[];
  count_success: number;
  count_failed: number;
}

export type TUserToInvite =
  | IGeneralAdminToInvite
  | IHealthCenterAdminToInvite
  | IPatientToInvite
  | IDoctorToInvite
  | IReceptionistToInvite;

export type TUserToInviteDTO =
  | IGeneralAdminInviteDTO
  | IHealthCenterAdminInviteDTO
  | IPatientToInviteDTO
  | IDoctorToInviteDTO
  | IPatientToInviteDTO;

export class UserInviteViewModelFactory {
  public static createDoctorDTO(doc: IDoctorToInvite): IDoctorToInviteDTO {
    return {
      specialization: doc.specialization,
      licenses: doc.licenses,
      ...this.createBasicDTO(doc),
      cpf: doc.cpf,
    };
  }

  public static createPatientDTO(pat: IPatientToInvite): IPatientToInviteDTO {
    return {
      ...this.createBasicDTO(pat),
    };
  }

  public static createReceptionistDTO(pat: IReceptionistToInvite): IPatientToInviteDTO {
    return {
      ...this.createBasicDTO(pat),
    };
  }

  public static createGeneralAdminDTO(admin: IGeneralAdminToInvite): IGeneralAdminInviteDTO {
    return {
      ...this.createBasicDTO(admin),
    };
  }

  public static createHealthCenterAdminDTO(admin: IHealthCenterAdminToInvite): IHealthCenterAdminInviteDTO {
    return {
      health_center_id: admin.health_center_id,
      access_token: admin.access_token,
      ...this.createBasicDTO(admin),
    };
  }

  private static createBasicDTO(user: TUserToInvite): IUserToInviteDTO {
    return {
      email: user.email,
      gender: user.gender,
      first_name: user.first_name,
      last_name: user.last_name,
      phone: user.phone,
      date_birth: user.date_birth
        ? DateFormatter.dateToString(new Date(user.date_birth), { format: DEFAULT_DATE_FORMAT })
        : null,
      tag: user.tag,
      http_execute: user.http_execute,
      access_token: user.access_token,
    };
  }
}
