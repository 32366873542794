<mat-form-field appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [(ngModel)]="value"
    [disabled]="disabled"
    (ngModelChange)="onChangeDatepicker()"
    (focus)="onTouchDatepicker()"
    [matDatepicker]="picker"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>

  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
