import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DateFormatter, DateUtils, DEFAULT_DATE_FORMAT, LibValidators } from '@core/helpers';
import { TranslateService } from '@project/translate';
import {
  EScheduleDayDTO,
  EDoctorSpecializationDTO,
  EScheduleRuleTypeDTO,
  IScheduleDailyRuleCreateData,
  IScheduleGroupCreateData,
  IScheduleRuleWorkingHoursCreateData,
  IScheduleWeeklyRuleCreateData,
} from '@project/view-models';
import { filter, map, shareReplay, startWith, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

type TRuleFormGroup = FormGroup;

interface IWorkingHoursFormGroupValue {
  timeFrom: string;
  timeTo: string;
  timeSlotSize: string;
  doctorSpeciality: EDoctorSpecializationDTO;
}

interface IRuleFormGroupValue {
  type: EScheduleRuleTypeDTO;
  workingPeriod: string;
  skipPeriod: string;
  daysOfWeek: EScheduleDayDTO[];
  startingFrom: string;
  workingHours: IWorkingHoursFormGroupValue[];
}

@Injectable()
export class ScheduleGroupFormModelService {
  public readonly form = this.fb.group({
    effective_from: [
      '',
      [
        LibValidators.required(TranslateService.localize('validations.required')),
        LibValidators.date(DEFAULT_DATE_FORMAT, TranslateService.localize('validations.invalid')),
      ],
    ],
    rules: this.fb.array(
      [],
      [
        LibValidators.minLength(
          1,
          TranslateService.localize('working-hours.schedule-form.validators.have-to-add-rule'),
        ),
      ],
    ),
  });

  private readonly CONFIG = {
    WORKING_PERIOD_FROM: 1,
    WORKING_PERIOD_TO: 10,
    SKIP_PERIOD_FROM: 0,
    SKIP_PERIOD_TO: 10,
  };

  private ruleFormGroupRemoved$ = new Subject<FormGroup>();

  constructor(private fb: FormBuilder) {}

  get rulesControl(): FormArray {
    return this.form.get('rules') as FormArray;
  }

  getRuleDaysOfWeekControl(ruleFormGroup: TRuleFormGroup): FormControl {
    return ruleFormGroup.get('daysOfWeek') as FormControl;
  }

  getRuleWorkingHoursControl(ruleFormGroup: TRuleFormGroup): FormArray {
    return ruleFormGroup.get('workingHours') as FormArray;
  }

  init() {
    const tomorrow = DateUtils.shift(new Date(), { days: 1 });
    this.form.get('effective_from').patchValue(DateFormatter.dateToString(tomorrow, { format: DEFAULT_DATE_FORMAT }));
  }

  addRuleGroup() {
    this.rulesControl.markAsTouched();
    this.rulesControl.push(this.getNewRuleFormGroup());
  }

  removeRuleGroup(index: number) {
    this.rulesControl.markAsTouched();
    this.rulesControl.removeAt(index);
  }

  addWorkingHoursGroupForRule(ruleFormGroup: TRuleFormGroup) {
    const workingHoursControl = ruleFormGroup.get('workingHours') as FormArray;
    workingHoursControl.markAsTouched();
    workingHoursControl.push(this.getNewWorkingHoursTemplateGroup());
  }

  removeWorkingHoursGroupForRule(ruleFormGroup: TRuleFormGroup, index: number) {
    const workingHoursControl = ruleFormGroup.get('workingHours') as FormArray;
    workingHoursControl.markAsTouched();
    workingHoursControl.removeAt(index);
  }

  public isSkipPeriodExistsForRuleGroup$(ruleFormGroup: TRuleFormGroup): Observable<boolean> {
    return ruleFormGroup.get('skipPeriod').valueChanges.pipe(
      startWith<number, number>(ruleFormGroup.get('skipPeriod').value),
      map((value) => {
        return +value >= 1;
      }),
      shareReplay(1),
    );
  }

  public isWeekSelectedForRuleGroup$(ruleFormGroup: TRuleFormGroup): Observable<boolean> {
    return ruleFormGroup.get('type').valueChanges.pipe(
      startWith<EScheduleRuleTypeDTO, EScheduleRuleTypeDTO>(ruleFormGroup.get('type').value),
      map((value) => value === EScheduleRuleTypeDTO.Weeks),
      shareReplay(1),
    );
  }

  public daysOfWeekForRuleGroup$(ruleFormGroup: TRuleFormGroup): Observable<EScheduleDayDTO[]> {
    return ruleFormGroup
      .get('daysOfWeek')
      .valueChanges.pipe(
        startWith<EScheduleDayDTO[], EScheduleDayDTO[]>(ruleFormGroup.get('daysOfWeek').value),
        shareReplay(1),
      );
  }

  public setDayCheckedStateForRule(ruleFormGroup: TRuleFormGroup, day: EScheduleDayDTO, isChecked: boolean) {
    const daysControl = ruleFormGroup.get('daysOfWeek');
    daysControl.markAsTouched();
    const current: EScheduleDayDTO[] = daysControl.value;
    if (current.includes(day) && !isChecked) {
      daysControl.patchValue(current.filter((item) => item !== day));
    }

    if (!current.includes(day) && isChecked) {
      daysControl.patchValue(current.concat([day]));
    }
  }

  public getCurrentDataModelToCreateScheduler(): IScheduleGroupCreateData {
    const { value } = this.form;

    const rules: IRuleFormGroupValue[] = value.rules;

    const effectiveFromDate: Date = DateFormatter.stringToDate(value.effective_from, { format: DEFAULT_DATE_FORMAT });

    return {
      weeksRules: rules
        .filter((rule) => rule.type === EScheduleRuleTypeDTO.Weeks)
        .map((rule) => this.createWeeksRule(rule, effectiveFromDate)),
      daysRules: rules
        .filter((rule) => rule.type === EScheduleRuleTypeDTO.Days)
        .map((rule) => this.createDaysRule(rule, effectiveFromDate)),
      effectiveFrom: effectiveFromDate,
    };
  }

  private createWeeksRule(
    formValue: IRuleFormGroupValue,
    fallbackStartingFromDate: Date,
  ): IScheduleWeeklyRuleCreateData {
    return {
      daysOfWeek: formValue.daysOfWeek,
      weeksOff: +formValue.skipPeriod,
      workingWeeks: +formValue.workingPeriod,
      startingFrom: this.getStartingFromDate(
        DateFormatter.stringToDate(formValue.startingFrom, { format: DEFAULT_DATE_FORMAT }),
        +formValue.skipPeriod,
        fallbackStartingFromDate,
      ),
      workingHours: formValue.workingHours.map((item) => this.createWorkingHoursRule(item)),
    };
  }

  private createDaysRule(formValue: IRuleFormGroupValue, fallbackStartingFromDate: Date): IScheduleDailyRuleCreateData {
    return {
      daysOff: +formValue.skipPeriod,
      workingDays: +formValue.workingPeriod,
      startingFrom: this.getStartingFromDate(
        DateFormatter.stringToDate(formValue.startingFrom, { format: DEFAULT_DATE_FORMAT }),
        +formValue.skipPeriod,
        fallbackStartingFromDate,
      ),
      workingHours: formValue.workingHours.map((item) => this.createWorkingHoursRule(item)),
    };
  }

  private getStartingFromDate(target: Date | null, daysOfCount: number, fallbackDate: Date): Date {
    return daysOfCount === 0 ? fallbackDate : target;
  }

  private createWorkingHoursRule(formValue: IWorkingHoursFormGroupValue): IScheduleRuleWorkingHoursCreateData {
    return {
      workingHoursStart: formValue.timeFrom,
      doctorSpecialization: formValue.doctorSpeciality,
      timeSlotSize: +formValue.timeSlotSize,
      workingHoursEnd: formValue.timeTo,
    };
  }

  private getNewRuleFormGroup(): TRuleFormGroup {
    const ruleFormGroup = this.fb.group({
      type: [EScheduleRuleTypeDTO.Weeks, LibValidators.required(TranslateService.localize('validations.required'))],
      workingPeriod: [
        this.CONFIG.WORKING_PERIOD_FROM.toString(),
        [
          LibValidators.required(TranslateService.localize('validations.required')),
          LibValidators.integer(TranslateService.localize('validations.should-be-integer')),
          LibValidators.rangeNumber(
            this.CONFIG.WORKING_PERIOD_FROM,
            this.CONFIG.WORKING_PERIOD_TO,
            TranslateService.localize('validations.invalid-number-range#from#to', {
              from: this.CONFIG.WORKING_PERIOD_FROM,
              to: this.CONFIG.WORKING_PERIOD_TO,
            }),
          ),
        ],
      ],
      skipPeriod: [
        this.CONFIG.SKIP_PERIOD_FROM.toString(),
        [
          LibValidators.required(TranslateService.localize('validations.required')),
          LibValidators.integer(TranslateService.localize('validations.should-be-integer')),
          LibValidators.rangeNumber(
            this.CONFIG.SKIP_PERIOD_FROM,
            this.CONFIG.SKIP_PERIOD_TO,
            TranslateService.localize('validations.invalid-number-range#from#to', {
              from: this.CONFIG.SKIP_PERIOD_FROM,
              to: this.CONFIG.SKIP_PERIOD_TO,
            }),
          ),
        ],
      ],
      daysOfWeek: [[]],
      startingFrom: [''],
      workingHours: this.fb.array(
        [],
        [
          LibValidators.minLength(
            1,
            TranslateService.localize('working-hours.schedule-form.validators.have-to-add-rule'), // TODO new message
          ),
        ],
      ),
    });

    this.addWorkingHoursGroupForRule(ruleFormGroup);
    this.initRuleRuntimeValidators(ruleFormGroup);
    return ruleFormGroup;
  }

  private getNewWorkingHoursTemplateGroup(): FormGroup {
    return this.fb.group({
      timeFrom: [
        '',
        [
          LibValidators.required(TranslateService.localize('validations.required')),
          LibValidators.timeIn24HoursFormat('HH:MM', TranslateService.localize('validations.invalid')),
        ],
      ],
      timeTo: [
        '',
        [
          LibValidators.required(TranslateService.localize('validations.required')),
          LibValidators.timeIn24HoursFormat('HH:MM', TranslateService.localize('validations.invalid')),
        ],
      ],
      timeSlotSize: ['', LibValidators.required(TranslateService.localize('validations.required'))],
      doctorSpeciality: ['', LibValidators.required(TranslateService.localize('validations.required'))],
    });
  }

  private initRuleRuntimeValidators(ruleFormGroup: TRuleFormGroup) {
    const typeControl = ruleFormGroup.get('type');
    const daysOfWeekControl = ruleFormGroup.get('daysOfWeek');
    const startingFromControl = ruleFormGroup.get('startingFrom');
    const currentGroupRemoved$ = this.ruleFormGroupRemoved$.pipe(filter((group) => group === ruleFormGroup));

    typeControl.valueChanges
      .pipe(startWith<EScheduleRuleTypeDTO, EScheduleRuleTypeDTO>(typeControl.value), takeUntil(currentGroupRemoved$))
      .subscribe((type) => {
        if (type === EScheduleRuleTypeDTO.Weeks) {
          daysOfWeekControl.setValidators([
            LibValidators.minLength(
              1,
              TranslateService.localize('working-hours.schedule-form.validators.have-to-select-days'),
            ),
          ]);
        } else {
          daysOfWeekControl.clearValidators();
        }
        daysOfWeekControl.updateValueAndValidity();
      });

    this.isSkipPeriodExistsForRuleGroup$(ruleFormGroup)
      .pipe(takeUntil(currentGroupRemoved$))
      .subscribe((needToShowStartPoint) => {
        if (needToShowStartPoint) {
          startingFromControl.setValidators([
            LibValidators.required(TranslateService.localize('validations.required')),
            LibValidators.date(DEFAULT_DATE_FORMAT, TranslateService.localize('validations.invalid')),
          ]);
        } else {
          startingFromControl.clearValidators();
        }
        startingFromControl.updateValueAndValidity();
      });
  }
}
