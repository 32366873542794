import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormComponent, SharedFormOptions } from '@project/shared';
import { ISelectOption } from '@lib/ui/form-elements';
import { FormBuilder } from '@angular/forms';
import { IGeneralAdminToInvite } from '@project/view-models';
import { AdminInviteUtils, IAdminInviteFormModel } from '../admin-invite.common';

@Component({
  selector: 'app-admin-invite-details-form',
  templateUrl: './admin-invite-details-form.component.html',
  styleUrls: ['./admin-invite-details-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminInviteDetailsFormComponent extends FormComponent implements OnInit {
  @Output() dataSubmit = new EventEmitter<IGeneralAdminToInvite>();
  @Input() admin: IAdminInviteFormModel;

  public form = this.fb.group(AdminInviteUtils.getAdminInviteFormDeclaration());

  public genderOptions: ISelectOption[] = SharedFormOptions.genderOptions();

  constructor(private fb: FormBuilder) {
    super();
  }

  protected onFormInit() {
    this.form.get('gender').setValue(this.genderOptions[0].value);
    if (this.admin != null) {
      this.form.get('firstName').setValue(this.admin.firstName);
      this.form.get('lastName').setValue(this.admin.lastName);
      this.form.get('gender').setValue(this.admin.gender);
      this.form.get('email').setValue(this.admin.email);
    }
  }

  protected doCleanSubmit() {
    const userData = AdminInviteUtils.createAdminInviteDto(this.form.value);
    this.dataSubmit.emit(userData);

    const { gender } = this.form.value;
    this.form.reset();
    this.form.get('gender').patchValue(gender);
  }
}
