import { debounceTime, filter, takeUntil, tap, map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { SummaryApiProviderService } from '@project/data-providers';
import { NotificationsService } from '@lib/notifications';

@Component({
  selector: 'app-cid-select',
  templateUrl: './cid-select.component.html',
  styleUrls: ['./cid-select.component.scss'],
})
export class CidSelectComponent implements OnInit, OnDestroy {
  public isSearching = false;

  public serverSideCtrl: FormControl = new FormControl();
  public serverSideFilteringCtrl: FormControl = new FormControl();

  public filteredOptions: ReplaySubject<{ name: string; value: string }[]> = new ReplaySubject<any[]>(1);

  protected _onDestroy = new Subject<void>();

  @Output() onCidSelected = new EventEmitter<string>();

  constructor(
    private summaryApiProviderService: SummaryApiProviderService,
    private notificationsService: NotificationsService,
  ) {}

  ngOnInit() {
    this.serverSideFilteringCtrl.valueChanges
      .pipe(
        map((value: string) => {
          const reDots = /\./g;

          return value.replace(reDots, '').trim();
        }),
        filter((search) => !!search && search.length > 2),
        tap(() => (this.isSearching = true)),
        takeUntil(this._onDestroy),
        debounceTime(500),
        map((search) => this.summaryApiProviderService.getCIDBySearch(search)),
      )
      .subscribe((filteredOptions) => {
        filteredOptions.subscribe(
          (cids) => {
            this.isSearching = false;

            const cidsOptions = cids.map((option) => ({
              name: `${option.code} - ${option.description}`,
              value: option.id,
            }));
            this.filteredOptions.next(cidsOptions);
          },
          () => {
            this.isSearching = false;
            this.notificationsService.error({ message: 'Falha ao buscar CID' });
          },
        );
      });

    this.serverSideCtrl.valueChanges.subscribe(({ value }) => {
      this.onCidSelected.emit(value);
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
