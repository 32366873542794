import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import {
  DoctorSessionStatusFeatureService,
  IFeatureService,
  LogoutService,
  RequisitionIsUpdatedEventFeatureService,
  UserProfileDataService,
} from '@project/services';
import { catchError, mapTo } from 'rxjs/operators';
import {
  InAppNotificationsService,
  TechnicalSupportChatFeatureService,
  WebNotificationsFeatureService,
} from '@features/services';
import { SocketMessagesDataProviderService } from '@project/data-providers';
import {
  ConnectionStatusFeatureService,
  PatientQueuePositionService,
  PeopleInQueueService,
  TasksInQueueService,
  VideoConferenceManagerService,
} from '@modules';
import { environment } from '@env';
import { EUserPermission } from '@project/shared';
import { ConflictResolverService } from 'src/app/project/services/conflict-resolver.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizedUserFeaturesInitializerService implements CanActivate {
  private isAlreadyInitialized = false;

  constructor(
    private logoutService: LogoutService,
    private userProfileDataService: UserProfileDataService,
    private webNotificationsFeatureService: WebNotificationsFeatureService,
    private technicalSupportChatFeatureService: TechnicalSupportChatFeatureService,
    private requisitionIsUpdatedEventFeatureService: RequisitionIsUpdatedEventFeatureService,
    private socketMessagesProviderService: SocketMessagesDataProviderService,
    private connectionStatusFeatureService: ConnectionStatusFeatureService,
    private inAppNotificationsService: InAppNotificationsService,
    private videoConferenceManagerService: VideoConferenceManagerService,
    private peopleInQueueService: PeopleInQueueService,
    private tasksInQueueService: TasksInQueueService,
    private doctorSessionStatusFeatureService: DoctorSessionStatusFeatureService,
    private patientQueuePositionService: PatientQueuePositionService,
    private conflictResolverService: ConflictResolverService,
  ) {}

  canActivate(): Observable<true> {
    if (this.isAlreadyInitialized) {
      return of(true);
    }

    this.isAlreadyInitialized = true;

    this.logoutService.addLogoutOperation(() => (this.isAlreadyInitialized = false));

    return this.initializeStart().pipe(mapTo(true));
  }

  private initializeStart(): Observable<void> {
    const featuresToInit: IFeatureService[] = [this.webNotificationsFeatureService];

    if (environment.production && this.hasPermissions([EUserPermission.haveChatWithTechnicalSupport])) {
      featuresToInit.push(this.technicalSupportChatFeatureService);
    }

    if (
      this.hasPermissions(
        [
          EUserPermission.havePatientSession,
          EUserPermission.haveDoctorSession,
          EUserPermission.haveReceptionistsSession,
        ],
        // eslint-disable-next-line id-blacklist
        { any: true },
      )
    ) {
      featuresToInit.push(
        this.patientQueuePositionService,
        this.requisitionIsUpdatedEventFeatureService,
        this.socketMessagesProviderService,
        this.connectionStatusFeatureService,
        this.videoConferenceManagerService,
        this.inAppNotificationsService,
        this.peopleInQueueService,
        this.tasksInQueueService,
        this.conflictResolverService,
      );
    }

    if (this.hasPermissions([EUserPermission.haveDoctorSession])) {
      featuresToInit.push(this.doctorSessionStatusFeatureService);
    }

    this.registerLogoutOperation(featuresToInit);
    return this.initializeFeatures(featuresToInit).pipe(catchError(() => of(null)));
  }

  // eslint-disable-next-line id-blacklist
  private hasPermissions(permissions: EUserPermission[], options?: { any?: boolean }): boolean {
    return options?.any
      ? this.userProfileDataService.hasAtLeastOnePermission(permissions)
      : this.userProfileDataService.hasPermissions(permissions);
  }

  private initializeFeatures(featuresToInit: IFeatureService[]): Observable<void> {
    return forkJoin(featuresToInit.map((feature) => feature.initialise())).pipe(mapTo(null));
  }

  private registerLogoutOperation(featuresToInit: IFeatureService[]) {
    featuresToInit.forEach((feature) => {
      this.logoutService.addLogoutOperation(() => feature.destroy().toPromise());
    });
  }
}
