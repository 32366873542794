<app-sidebar-logo></app-sidebar-logo>

<mat-select *ngIf="(healthCenters$ | async)?.length > 1" class="hc" [value]="selectedHC" [disabled]="disabledSwitch">
  <mat-option
    *ngFor="let hc of healthCenters$ | async"
    [value]="hc.health_center_id"
    (click)="impersonate(hc.health_center_id)"
  >
    {{ hc.health_center_name }}
  </mat-option>
</mat-select>

<hr />
<p class="version">{{ "nouns.version" | translate }} {{ version }}</p>
<main>
  <ul class="menu">
    <ng-container *ngFor="let item of navigationMenu">
      <ng-container *ifFeatureEnabled="item.featureFlag || null">
        <li *hasAccess="item.userPermissions">
          <a [routerLink]="item.routeLink" routerLinkActive="active" (click)="hideMenu()">
            <app-sidebar-menu-icon class="icon" *ngIf="item.icon" [icon]="item.icon"></app-sidebar-menu-icon>
            {{ item.title }}
          </a>
        </li>
      </ng-container>
    </ng-container>
  </ul>
</main>

<section
  class="section-vertical-paddings section-horizontal-paddings"
  [ngClass]="isPatient && 'user-card-container'"
  (click)="isPatient && onUserCardClick()"
>
  <app-sidebar-user-card></app-sidebar-user-card>
</section>

<hr />

<section class="section-vertical-paddings section-horizontal-paddings">
  <h4>{{ "sidebar.section-titles.settings" | translate }}</h4>
</section>

<section class="section-vertical-paddings">
  <ul class="menu">
    <ng-container *ngFor="let item of settingsMenu">
      <li *hasAccess="item.userPermissions">
        <a [routerLink]="item.routeLink" routerLinkActive="active" (click)="hideMenu()">
          <app-sidebar-menu-icon class="icon" *ngIf="item.icon" [icon]="item.icon"></app-sidebar-menu-icon>
          {{ item.title }}
        </a>
      </li>
    </ng-container>
  </ul>
</section>

<hr />

<footer class="section-vertical-paddings">
  <ul class="menu">
    <li>
      <a [routerLink]="logoutMenuItem.routeLink" routerLinkActive="active">
        <app-sidebar-menu-icon
          class="icon"
          *ngIf="logoutMenuItem.icon"
          [icon]="logoutMenuItem.icon"
        ></app-sidebar-menu-icon>
        {{ logoutMenuItem.title }}
      </a>
    </li>
  </ul>
</footer>
