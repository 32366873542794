import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

export type TDropdownPosition = 'left-top' | 'right-top' | 'right-bottom' | 'left-bottom';

type TPositionValue = string | '100%' | 'auto';

interface IPosition {
  top: TPositionValue;
  bottom: TPositionValue;
  left: TPositionValue;
  right: TPositionValue;
}

@Directive({
  selector: '[libFindDropdownPosition]',
})
export class FindDropdownPositionDirective implements AfterViewInit {
  @Input('libFindDropdownPosition') position: TDropdownPosition = 'right-bottom';

  @HostBinding('style.opacity') private opacity = 0;
  @HostBinding('style.top') private topPosition = 'auto';
  @HostBinding('style.bottom') private bottomPosition = 'auto';
  @HostBinding('style.left') private leftPosition = 'auto';
  @HostBinding('style.right') private rightPosition = 'auto';

  private native: HTMLElement;

  constructor(er: ElementRef, private cd: ChangeDetectorRef, @Inject(PLATFORM_ID) private platformId) {
    this.native = er.nativeElement;
  }

  /**
   * TODO: Rework dropdown via portals (or maybe there a better solution) to avoid problem with scrolling
   *  parent containers.
   */
  ngAfterViewInit() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const { height, width, y } = this.native.getBoundingClientRect();

    const targetHorizontallyRight = document.body.clientWidth;
    const targetVerticallyBottom = window.innerHeight;

    const position: IPosition = this.getDefaultPosition();

    if (y + height >= targetVerticallyBottom) {
      position.top = 'auto';
      position.bottom = '100%';
    }

    if (width >= targetHorizontallyRight) {
      position.left = 'auto';
      position.right = '0';
    }

    this.opacity = 1;

    this.topPosition = position.top;
    this.bottomPosition = position.bottom;
    this.leftPosition = position.left;
    this.rightPosition = position.right;

    this.cd.detectChanges();
  }

  private getDefaultPosition(): IPosition {
    const dictionary: Record<TDropdownPosition, Partial<IPosition>> = {
      'left-top': { bottom: '100%', right: '0' },
      'right-top': { bottom: '100%', left: '0' },
      'right-bottom': { top: '100%', left: '0' },
      'left-bottom': { top: '100%', right: '0' },
    };

    return {
      bottom: 'auto',
      left: 'auto',
      right: 'auto',
      top: 'auto',
      ...dictionary[this.position],
    };
  }
}
