export enum ENotificationStatus {
  Info = 'Info',
  Success = 'Success',
  Warning = 'Warning',
  Error = 'Error',
}

export interface INotificationAction {
  title: string;
  onClick: () => void;
}

export interface INotificationParams {
  message: string;
  title?: string;
  durationMs?: number;
  actions?: INotificationAction[];
  unclosable?: boolean;
}
