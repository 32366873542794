import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthorisationStateService } from './authorisation-state.service';
import { RoutesBuilderService } from '@app/config';
import { concatSequentialObservableResults, sortByIncrementFn } from '@core/helpers';
import { defer, forkJoin, Observable, of } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';
import { UserProfileDataService } from './user-profile-data.service';

type TOperationOnLogoutCreator = () => Promise<void> | any;

/**
 * @description In common cases, preferred to use logout redirect instead of using service directly.
 */
@Injectable({
  providedIn: 'root',
})
export class LogoutService implements CanActivate {
  private operationsOnLogout: Record<number, TOperationOnLogoutCreator[]> = {};

  constructor(
    private router: Router,
    private authorisationStateService: AuthorisationStateService,
    private userProfileDataService: UserProfileDataService,
  ) {}

  public addLogoutOperation(operation: TOperationOnLogoutCreator, wave = 0) {
    if (!this.operationsOnLogout[wave]) {
      this.operationsOnLogout[wave] = [];
    }

    this.operationsOnLogout[wave].push(operation);
  }

  logout() {
    this.doLogout()
      .pipe(first())
      .subscribe(() => {
        this.router.navigateByUrl(RoutesBuilderService.AUTH.login());
      });
  }

  canActivate(): Observable<UrlTree> {
    return this.logoutAsGuard();
  }

  logoutAsGuard(): Observable<UrlTree> {
    return this.doLogout().pipe(
      first(),
      map(() => this.router.parseUrl(RoutesBuilderService.AUTH.login())),
    );
  }

  private doLogout(): Observable<void> {
    const waves = this.getWaves();

    const observablesChain: Observable<void>[] = waves.map((operations) => {
      if (operations?.length) {
        return forkJoin(operations.map((creator) => defer(() => Promise.resolve(creator()))));
      } else {
        return of(null);
      }
    });

    const logout$: Observable<void> = observablesChain?.length
      ? concatSequentialObservableResults(observablesChain)
      : of(null);

    return logout$.pipe(
      catchError(() => of(null)),
      tap(() => {
        this.userProfileDataService.clear();
        this.authorisationStateService.setAuthorisationData(null);
        this.operationsOnLogout = {};
      }),
    );
  }

  private getWaves(): TOperationOnLogoutCreator[][] {
    const waves: string[] = Object.keys(this.operationsOnLogout).sort(sortByIncrementFn);
    return waves.map((wave) => this.operationsOnLogout[wave]);
  }
}
