import { Injectable } from '@angular/core';
import { ApiService, IPagingResponse, PagingApiService } from '@core/http';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { TGuid } from '@core/helpers';
import { map } from 'rxjs/operators';
import {
  HealthCenterAdminViewModelFactory,
  IFullUpdateHealthCenterAdminDTO,
  IHealthCenterAdmin,
  IHealthCenterAdminDTO,
} from '@project/view-models';

@Injectable({
  providedIn: 'root',
})
export class HealthCenterAdminsApiProviderService {
  constructor(private apiService: ApiService, private pagingApiService: PagingApiService) {}

  getAll(): Observable<IHealthCenterAdmin[]> {
    return this.apiService
      .get<IHealthCenterAdminDTO[]>(`${environment.environmentVariables.apiCoreUrl}/admins/healthcenters`)
      .pipe(map((admins) => admins.map((admin) => HealthCenterAdminViewModelFactory.createFromDTO(admin))));
  }

  getPaged(
    page: number,
    itemsPerPage: number,
    query?: Record<string, any>,
  ): Observable<IPagingResponse<IHealthCenterAdmin>> {
    return this.pagingApiService.request<IHealthCenterAdminDTO, IHealthCenterAdmin>({
      url: `${environment.environmentVariables.apiCoreUrl}/admins/healthcenters`,
      additionalQueryParams: query,
      itemsPerPage,
      itemFactory: (item) => HealthCenterAdminViewModelFactory.createFromDTO(item),
      pageNumber: page,
    });
  }

  getById(id: TGuid): Observable<IHealthCenterAdmin> {
    return this.apiService
      .get<IHealthCenterAdminDTO>(`${environment.environmentVariables.apiCoreUrl}/admins/healthcenters/${id}`)
      .pipe(map((admin) => HealthCenterAdminViewModelFactory.createFromDTO(admin)));
  }

  remove(id: TGuid): Observable<void> {
    return this.apiService.delete(
      `${environment.environmentVariables.apiAuthUrl}/profile/healthcenteradmin/${id}/delete`,
    );
  }

  update(id: TGuid, model: IFullUpdateHealthCenterAdminDTO): Observable<void> {
    return this.apiService.put(`${environment.environmentVariables.apiCoreUrl}/admins/healthcenters/${id}`, model);
  }

  updateInvite(id: TGuid, model: IFullUpdateHealthCenterAdminDTO): Observable<void> {
    return this.apiService.put(
      `${environment.environmentVariables.apiAuthUrl}/invites/admin/healthcenter/${id}`,
      model,
    );
  }
}
