import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { environment } from '@env';
import {
  ESocialLoginProvider,
  ILinkedSocialPlatforms,
  SocialLoginProvidersViewModelFactory,
} from '@project/view-models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SocialLoginProvidersApiProviderService {
  constructor(private apiService: ApiService) {}

  public link(provider: ESocialLoginProvider, idToken: string): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiAuthUrl}/signup/account/link`, {
      provider,
      id_token: idToken,
    });
  }

  public unlink(provider: ESocialLoginProvider): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiAuthUrl}/signup/account/unlink/${provider}`);
  }

  public getLinkedPlatforms(): Observable<ILinkedSocialPlatforms> {
    return this.apiService
      .get<ESocialLoginProvider[]>(`${environment.environmentVariables.apiAuthUrl}/signup/account/links`)
      .pipe(map((dto) => SocialLoginProvidersViewModelFactory.createLinkedSocialPlatformsFromDto(dto)));
  }
}
