import { Injectable } from '@angular/core';
import {
  IPagedItemsProceedResult,
  ServerSidePagedItemsProviderService,
  PagingApiMapperHelper,
} from '@core/data-providers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IRequisitionLogsPatient } from '@project/view-models';
import { RequisitionDoctorEncounterHistoryApiProviderService } from './api-providers/requisition-doctor-encounter-history-api-provider.service';
import { IRequisitionDoctorEncounterHistoryFiltersParams } from './requisition-doctor-encounter-history-server-side-items-provider.service';

@Injectable()
export class RequisitionDoctorsEncounterHistoryServerSideItemsProviderService extends ServerSidePagedItemsProviderService<
  IRequisitionLogsPatient,
  IRequisitionDoctorEncounterHistoryFiltersParams,
  null
> {
  constructor(
    private requisitionDoctorEncounterHistoryApiProviderService: RequisitionDoctorEncounterHistoryApiProviderService,
  ) {
    super();
  }

  protected getDefaultFilters(): Partial<IRequisitionDoctorEncounterHistoryFiltersParams> {
    return {};
  }

  protected proceedPagedItemsLoading(
    page,
    itemsPerPage,
    filtersState,
    sortingState,
  ): Observable<IPagedItemsProceedResult<IRequisitionLogsPatient>> {
    return this.requisitionDoctorEncounterHistoryApiProviderService
      .getPagedDoctors(page, itemsPerPage, this._filtersState$.value)
      .pipe(
        map((result) =>
          PagingApiMapperHelper.mapPagingApiResponseToProceedResult<IRequisitionLogsPatient>(page, result),
        ),
      );
  }
}
