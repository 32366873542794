import { TEmail, TGuid } from '@core/helpers';

export interface IGeneralAdminDTO {
  id: TGuid;
  first_name: string | null;
  last_name: string | null;
  email: TEmail | null;
  phone: string | null;
  photo_url: string | null;
}

export interface IGeneralAdmin {
  id: TGuid;
  fullName: string | null;
  email: TEmail | null;
  photoUrl: string | null;
}

export class GeneralAdminViewModelFactory {
  static createFromDTO(dto: IGeneralAdminDTO): IGeneralAdmin {
    return {
      id: dto.id,
      fullName: dto.first_name && dto.last_name ? dto.first_name + ' ' + dto.last_name : dto.email,
      email: dto.email,
      photoUrl: dto.photo_url,
    };
  }
}
