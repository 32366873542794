import { ApplicationRef, Injectable, NgZone } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concatMap, first, switchMap } from 'rxjs/operators';
import { interval, Observable } from 'rxjs';
import { runInZoneOperator } from '@core/helpers';

const CHECK_UPDATE_INTERVAL = 20 * 60 * 1000; // 20 minutes

@Injectable({
  providedIn: 'root',
})
export class ServiceWorkerProviderService {
  constructor(private update: SwUpdate, private appRef: ApplicationRef, private ngZone: NgZone) {
    const enabled = update.isEnabled;
    console.log('ServiceWorker enabled: ', enabled);
    if (!enabled) {
      return;
    }

    this.initUpdater();
    this.update.checkForUpdate();
    this.checkUpcomingUpdates();
  }

  isServiceWorkerEnabled(): boolean {
    return this.update.isEnabled;
  }

  getServiceWorkerRegistration(): Observable<ServiceWorkerRegistration> {
    /**
     * `this.appRef.isStable` works outside zone
     */
    return this.appRef.isStable.pipe(
      runInZoneOperator(this.ngZone),
      first((isStable) => isStable),
      concatMap(() => navigator.serviceWorker.getRegistration()),
    );
  }

  private checkUpcomingUpdates() {
    this.appRef.isStable
      .pipe(
        first((isStable) => isStable),
        concatMap(() => interval(CHECK_UPDATE_INTERVAL)), // Every 20 minutes
      )
      .subscribe(() => this.update.checkForUpdate());
  }

  private initUpdater() {
    this.update.available
      .pipe(switchMap(() => this.update.activateUpdate()))
      // eslint-disable-next-line no-console
      .subscribe(() => console.info('ServiceWorker was updated (need page reload)'));
  }
}
