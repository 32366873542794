// Environment variables
declare const API_CORE_URL: string;
declare const API_CHAT_URL: string;
declare const API_AUTH_URL: string;
declare const API_MEMED_URL: string;
declare const FEATURE_FLAGS: string;
declare const MEMED_SCRIPT_URL: string;
declare const HEALTH_CENTER_ID_PIAUI: string;
declare const DATA_DELETION_REDIRECTION: string;

import { version } from '../../package.json';

console.log(`APP_VERSION = ${version}`);

export const commonEnvironment = {
  appVersion: version,
  environmentVariables: {
    apiCoreUrl: (API_CORE_URL || '') + '/api/core/v1',
    apiChatUrl: (API_CHAT_URL || '') + '/api/chat/v3',
    apiAuthUrl: (API_AUTH_URL || '') + '/api/auth/v1',
    apiMemedUrl: (API_MEMED_URL || '') + '/api/memed/v1',
    featureFlags: (FEATURE_FLAGS && FEATURE_FLAGS.split(',')) || [],
    memedScriptUrl:
      MEMED_SCRIPT_URL ||
      'https://integrations.memed.com.br/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js',
    healthCenterIdPiaui: HEALTH_CENTER_ID_PIAUI || '',
    dataDeletionRedirection: DATA_DELETION_REDIRECTION || '',
    GATrackers: {
      GA_TRACKING_ID_PIAUI: 'G-V435Q9PLDX',
      GA_TRACKING_ID_SOS: 'G-WKPQ4FKW6D',
    },
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCtpBmdHgip4ARD68-hQtcdDXtn-AgGWvc',
    authDomain: 'ptmp-d43bd.firebaseapp.com',
    databaseURL: 'https://ptmp-d43bd.firebaseio.com',
    projectId: 'ptmp-d43bd',
    storageBucket: 'ptmp-d43bd.appspot.com',
    messagingSenderId: '247949406192',
    appId: '1:247949406192:web:80d3609eea69d844707735',
    measurementId: 'G-MFFMY6KYEB',
  },
  appIds: {
    googleClientId: '247949406192-sho92o0suqhj3o0o13kkt3jnbqeromuk.apps.googleusercontent.com',
    facebookAppId: '636763470513155',
    appleClientId: 'com.ptm.iSalut.service',
  },
  appKeys: {},
  privacyPolicyUrl: 'https://sos.portaltelemedicina.com.br/privacidade/',
  userAgreementUrl: 'https://sos.portaltelemedicina.com.br/termos-e-condicoes-gerais-de-uso/',
};
