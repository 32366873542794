import { Injectable } from '@angular/core';
import {
  IPagedItemsProceedResult,
  ServerSidePagedItemsProviderService,
  PagingApiMapperHelper,
} from '@core/data-providers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IRequisitionLogsItem } from '@project/view-models';
import { RequisitionEncounterHistoryApiProviderService } from './api-providers/requisition-encounter-history-api-provider.service';

export interface IRequisitionEncounterHistoryFiltersParams {
  search?: string;
  filter?: number;
}

@Injectable()
export class RequisitionEncounterHistoryServerSideItemsProviderService extends ServerSidePagedItemsProviderService<
  IRequisitionLogsItem,
  IRequisitionEncounterHistoryFiltersParams,
  null
> {
  constructor(private requisitionLogsApiProviderService: RequisitionEncounterHistoryApiProviderService) {
    super();
  }

  protected getDefaultFilters(): Partial<IRequisitionEncounterHistoryFiltersParams> {
    return {};
  }

  protected proceedPagedItemsLoading(
    page,
    itemsPerPage,
    filtersState,
    sortingState,
  ): Observable<IPagedItemsProceedResult<IRequisitionLogsItem>> {
    return this.requisitionLogsApiProviderService
      .getPaged(page, itemsPerPage, this._filtersState$.value)
      .pipe(
        map((result) => PagingApiMapperHelper.mapPagingApiResponseToProceedResult<IRequisitionLogsItem>(page, result)),
      );
  }
}
