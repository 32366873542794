import { TEmail, TGuid } from '@core/helpers';
import { EGenderDTO } from './consts/gender';
import { ProfileHelper } from './helpers/profile.helper';

export interface IFullUpdateHealthCenterAdminDTO {
  first_name: string;
  last_name: string;
  gender: EGenderDTO;
  email: TEmail;
  access_token: boolean;
}

export interface IHealthCenterAdminDTO {
  id: TGuid;
  first_name: string | null;
  last_name: string | null;
  email: TEmail | null;
  photo_url: string | null;
  gender: EGenderDTO;
  access_token: boolean;
}

export interface IHealthCenterAdmin {
  id: TGuid;
  fullName: string | null;
  firstName: string;
  lastName: string;
  gender: EGenderDTO;
  email: TEmail | null;
  photoUrl: string | null;
  accessToken: boolean;
}

export class HealthCenterAdminViewModelFactory {
  static createFromDTO(dto: IHealthCenterAdminDTO): IHealthCenterAdmin {
    return {
      id: dto.id,
      fullName: ProfileHelper.getFullName(dto.first_name, dto.last_name, dto.email),
      firstName: dto.first_name,
      lastName: dto.last_name,
      email: dto.email,
      photoUrl: dto.photo_url,
      gender: dto.gender,
      accessToken: dto.access_token,
    };
  }
}
