import { Injectable } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { SocketMessagesDataProviderService } from '../data-providers/socket-messages.data-provider.service';
import { IFeatureService } from './_index';
import { LogoutService } from './logout.service';

@Injectable({
  providedIn: 'root',
})
export class ConflictResolverService implements IFeatureService {
  private socketConflictSubscription: Subscription;

  constructor(
    private readonly socketService: SocketMessagesDataProviderService,
    private readonly logoutService: LogoutService,
  ) {}

  initialise(): Observable<void> {
    this.socketConflictSubscription = this.socketService.disconnectConflict$.subscribe(() => {
      this.logoutService.logout();
    });
    return of(null);
  }

  destroy(): Observable<void> {
    if (this.socketConflictSubscription) {
      this.socketConflictSubscription.unsubscribe();
    }
    return of(null);
  }
}
