import { BehaviorSubject } from 'rxjs';

export abstract class Store<T> {
  protected data$ = new BehaviorSubject<T | null>(null);

  protected constructor(private defaultValueFn: () => T | null = () => null) {
    this.data$.next(defaultValueFn());
  }

  protected get data(): T {
    return this.data$.value;
  }

  public reset() {
    this.data$.next(this.defaultValueFn());
  }
}
