import { Injectable } from '@angular/core';
import { ISelectOption } from '@lib/ui/form-elements';
import { ScheduleApiProviderService } from './api-providers/schedule-api-provider.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TimeslotSizesDataProvider {
  private readonly _sizes$ = new BehaviorSubject<number[]>(null);
  public readonly sizes$ = this._sizes$.pipe(filter((value) => value !== null));

  public readonly sizesSelectOptions$: Observable<ISelectOption[]> = this.sizes$.pipe(
    map((sizes) =>
      sizes.map((size) => ({
        value: size.toString(),
        label: size.toString(),
      })),
    ),
  );

  constructor(private scheduleApiProviderService: ScheduleApiProviderService) {
    this.loadSizes();
  }

  loadSizes() {
    this.scheduleApiProviderService.getTimeSlotSizes().subscribe(
      (sizes) => this._sizes$.next(sizes),
      () => this._sizes$.next([]),
    );
  }
}
