import { Injectable } from '@angular/core';
import {
  IRequisitionEncounterHistoryFiltersParams,
  RequisitionApiProviderService,
  SummaryApiProviderService,
  SummaryNotesApiProviderService,
  RequisitionDoctorEncounterHistoryServerSideItemsProviderService,
} from '@project/data-providers';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  IDays,
  IMedicalRecord,
  IObservationFull,
  IRequisitionLogsPatient,
  IRequisitionWithQuestionsAnswers,
  ISummaryNote,
} from '@project/view-models';
import { catchError, map, switchMap, tap, mergeAll } from 'rxjs/operators';
import { NotificationsService } from '@lib/notifications';
import { TranslateService } from '@project/translate';
import { IPagedItemsState } from '@core/data-providers';
import { TGuid } from '@core/helpers';
import { ActionsManagerService } from '@project/shared';

export enum ESessionLogModuleAction {
  PatchFilters = 'PatchFilters',
  LoadSessionLogByPage = 'LoadSessionLogByPage',
}

@Injectable()
export class DoctorEncounterHistoryManagerService extends ActionsManagerService<ESessionLogModuleAction> {
  protected actionsHandlersMap = {
    [ESessionLogModuleAction.PatchFilters]: (filters: IRequisitionEncounterHistoryFiltersParams) =>
      this.setFilter(filters),
    [ESessionLogModuleAction.LoadSessionLogByPage]: (page: number) => this.loadItems(page).subscribe(),
  };

  public pageItems$: Observable<IRequisitionLogsPatient[]> = this.encounterHistoryServerSideItemsProviderService.items$;
  public pagingState$: Observable<IPagedItemsState> = this.encounterHistoryServerSideItemsProviderService.pagingState$;
  public isFetching$: Observable<boolean> = this.encounterHistoryServerSideItemsProviderService.isFetching$;

  private _selectedRequisition$ = new BehaviorSubject<IRequisitionWithQuestionsAnswers | null>(null);
  public selectedRequisition$ = this._selectedRequisition$.asObservable();

  private _notes$ = new BehaviorSubject<ISummaryNote[]>([]);
  public notes$ = this._notes$.asObservable();

  private readonly _medicalRecords$ = new BehaviorSubject<IMedicalRecord[]>([]);
  public readonly medicalRecords$ = this._medicalRecords$;
  public readonly medicalRecords = this._medicalRecords$.asObservable();

  private _selectedLogObservations$ = new BehaviorSubject<IObservationFull[]>([]);
  public selectedLogObservations$ = this._selectedLogObservations$.asObservable();

  private LOGS_PER_PAGE = 15;

  private readonly _days$ = new BehaviorSubject<IDays[]>([]);
  public readonly days$ = this._days$.asObservable();
  public readonly filtersState$ = this.encounterHistoryServerSideItemsProviderService.filtersState$;

  public readonly _requisition$ = new BehaviorSubject<IRequisitionLogsPatient>(null);
  public readonly requisition$ = this._requisition$.asObservable();

  constructor(
    private encounterHistoryServerSideItemsProviderService: RequisitionDoctorEncounterHistoryServerSideItemsProviderService,
    private notificationService: NotificationsService,
    private summaryNotesApiProviderService: SummaryNotesApiProviderService,
    private requisitionApiProviderService: RequisitionApiProviderService,
    private summaryApiProviderService: SummaryApiProviderService,
  ) {
    super();

    this._days$.next([
      { description: TranslateService.localize('session.logs.day'), id: 1 },
      { description: TranslateService.localize('session.logs.week'), id: 2 },
      { description: TranslateService.localize('session.logs.month'), id: 3 },
    ]);
  }

  setFilter(filter: Partial<IRequisitionEncounterHistoryFiltersParams>) {
    this.encounterHistoryServerSideItemsProviderService.patchFilters(filter);

    this.loadItems(1).subscribe({
      error: () => null,
    });
  }

  loadItems(page: number, setLoadingAction: boolean = true): Observable<void> {
    if (setLoadingAction) {
      this.setActionInProgress(ESessionLogModuleAction.LoadSessionLogByPage);
    }

    return this.encounterHistoryServerSideItemsProviderService.updateItems(page, this.LOGS_PER_PAGE).pipe(
      catchError(() => {
        this.notificationService.error({
          message: TranslateService.localize('nouns.error'),
        });
        return of(null);
      }),
      tap((log) => {
        if (setLoadingAction) {
          this.clearActionInProgress();
        }
      }),
      map(() => null),
    );
  }

  loadMedicalRecords(requisitionID: string): Observable<void> {
    const requisition = this._requisition$.value;
    if (!requisition) {
      return null;
    }

    return this.requisitionApiProviderService.getById(requisitionID).pipe(
      map(({ doctors: [{ id }] }) => id),
      map((doctorID) =>
        this.summaryApiProviderService.getMedicalRecordsById(requisition.id, doctorID).pipe(
          map((medicalRecords) => {
            const currentRequisition = medicalRecords.filter((record) => record.requisition_id === requisitionID);

            this._medicalRecords$.next(currentRequisition);
          }),
        ),
      ),
      mergeAll(),
    );
  }

  loadLogDetails(requisitionId: TGuid): Observable<void> {
    return this.requisitionApiProviderService.getWithAnswersById(requisitionId).pipe(
      catchError(() => {
        this.notificationService.error({
          message: TranslateService.localize('nouns.error'),
        });
        return of(null);
      }),
      tap((item) => this._selectedRequisition$.next(item)),
      map(() => null),
    );
  }

  loadLogSummaryNotes(requisitionId: TGuid): Observable<void> {
    return this.summaryNotesApiProviderService.getAllRelatedToRequisition(requisitionId).pipe(
      catchError(() => {
        this.notificationService.error({
          message: TranslateService.localize('nouns.error'),
        });
        return of([]);
      }),
      tap((notes) => this._notes$.next(notes)),
      map(() => null),
    );
  }

  selectRequisition(requisition: IRequisitionLogsPatient) {
    this._requisition$.next(requisition);
  }

  createNote(note: string): Observable<void> {
    return this.summaryNotesApiProviderService
      .createByRequisitionId(this._requisition$.value.id, note)
      .pipe(switchMap(() => this.loadLogSummaryNotes(this._requisition$.value.id)));
  }

  updateNote(id: TGuid, note: string): Observable<void> {
    return this.summaryNotesApiProviderService
      .updateSummaryNoteId(id, note)
      .pipe(switchMap(() => this.loadLogSummaryNotes(this._requisition$.value.id)));
  }
}
