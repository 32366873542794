<video
  *ngIf="session.stream"
  [class.hidden]="!session.hasVideo"
  autoplay
  [muted]="isMe"
  [srcObject]="session.stream"
  playsinline
></video>

<ng-container *ngIf="session.hasVideo">
  <p class="participant-status participant-info" [class.small]="showCompactUI">
    <span class="icon-bg">
      <lib-icon *ngIf="!session.hasAudio" type="mic-off-white"></lib-icon>
      <lib-icon *ngIf="session.hasAudio" type="mic-white"></lib-icon>
    </span>
    &nbsp;&nbsp;<span
      ><b>{{ session.profile.fullName }}</b></span
    >
  </p>
</ng-container>

<div *ngIf="!session.hasVideo" class="participant-no-video-info">
  <app-avatar-preview
    class="mb-4 avatar"
    [profileType]="session.profile?.profileType"
    [url]="session.profile?.photoUrl"
  ></app-avatar-preview>
  <p class="participant-status fz-m-2 mb-2">
    <lib-icon *ngIf="!session.hasAudio" type="mic-off-white"></lib-icon>
    <lib-icon *ngIf="session.hasAudio" type="mic-white"></lib-icon>
    &nbsp;{{ session.profile?.fullName }}
  </p>
  <p>{{ session.profile?.summaryInfo.join(", ") }}</p>
</div>
