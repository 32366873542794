import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ITimeSlot, ITimeSlotProcessed } from '../../models/time-slots.model';

@Component({
  selector: 'lib-time-slots',
  templateUrl: './time-slots.component.html',
  styleUrls: ['./time-slots.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSlotsComponent implements OnChanges {
  @Input() timeSlots: ITimeSlot[] = [];
  @Input() allActionsDisabled: boolean = false;

  public sortedTimeSlots: ITimeSlotProcessed[];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.timeSlots) {
      this.sortedTimeSlots = this.timeSlots
        .sort((a, b) => a.startTime.getTime() - b.startTime.getTime())
        .map(
          (slot, index): ITimeSlotProcessed => ({
            ...slot,
            showEndTime:
              !this.timeSlots[index + 1] || this.timeSlots[index + 1].startTime.getTime() !== slot.endTime.getTime(),
          }),
        );
    }
  }
}
