import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card/card.component';
import { CardBodyComponent } from './card-body/card-body.component';

@NgModule({
  declarations: [CardComponent, CardBodyComponent],
  exports: [CardComponent, CardBodyComponent],
  imports: [CommonModule],
})
export class CardModule {}
