import { LibValidators, TGuid } from '@core/helpers';
import { TranslateService } from '@project/translate';
import { EGenderDTO, IGeneralAdminToInvite } from '@project/view-models';

export interface IAdminInviteFormModel {
  email: string;
  gender: EGenderDTO;
  firstName: string;
  lastName: string;
}

export class AdminInviteUtils {
  static getAdminInviteFormDeclaration() {
    return {
      email: ['', [LibValidators.email(TranslateService.localize('validations.invalid-email'))]],
      gender: [
        null,
        [
          LibValidators.required(TranslateService.localize('validations.required')),
          LibValidators.enumValue(EGenderDTO, TranslateService.localize('validations.not-allowed')),
        ],
      ],
      firstName: ['', [LibValidators.required(TranslateService.localize('validations.required'))]],
      lastName: ['', [LibValidators.required(TranslateService.localize('validations.required'))]],
    };
  }

  static createAdminInviteDto(form: IAdminInviteFormModel): IGeneralAdminToInvite {
    return {
      email: form.email,
      first_name: form.firstName,
      last_name: form.lastName,
      gender: form.gender,
    };
  }
}
