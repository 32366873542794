import { Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { NotificationItemComponent } from './notification-item/notification-item.component';
import { NotificationRef } from './notification-ref';
import { ENotificationStatus, INotificationParams } from './consts';

@Injectable()
export class NotificationsService {
  private overlayRef: OverlayRef;

  constructor(private overlay: Overlay) {}

  info(params: INotificationParams): NotificationRef {
    return this.open(ENotificationStatus.Info, params);
  }

  error(params: INotificationParams): NotificationRef {
    return this.open(ENotificationStatus.Error, params);
  }

  warning(params: INotificationParams): NotificationRef {
    return this.open(ENotificationStatus.Warning, params);
  }

  success(params: INotificationParams): NotificationRef {
    return this.open(ENotificationStatus.Success, params);
  }

  private open(status: ENotificationStatus, params: INotificationParams): NotificationRef {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create({
        width: '100%',
      });
    }

    this.overlayRef.detach();

    const itemPortal = new ComponentPortal(NotificationItemComponent);
    const componentRef = this.overlayRef.attach(itemPortal);
    const ref = new NotificationRef(params, status, componentRef);

    componentRef.instance.notification = ref;

    return ref;
  }
}
