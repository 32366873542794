import { Directive, ElementRef, Inject, Input, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

const DEFAULT_THROTTLE_TIME = 45;

export interface IResizeConstraintPx {
  width: number;
  height: number;
}

@Directive({
  selector: '[libResize]',
})
export class ResizeDirective {
  @Input() libResizeMaxSize: IResizeConstraintPx = { width: Infinity, height: Infinity };
  @Input() libResizeMinSize: IResizeConstraintPx = { width: 0, height: 0 };
  @Input() libResizeThrottleMs: number = DEFAULT_THROTTLE_TIME;

  private oldX: number = null;
  private oldY: number = null;

  private readonly element: HTMLDivElement;

  constructor(elementRef: ElementRef, @Inject(DOCUMENT) private document: Document, private renderer2: Renderer2) {
    this.element = elementRef.nativeElement;
  }

  public handleMove(x: number, y: number) {
    if (!this.oldX || !this.oldY) {
      this.oldX = x;
      this.oldY = y;
      return;
    }

    const { width, height, bottom, right } = this.element.getBoundingClientRect();
    const calculatedWidth = width + (x - this.oldX);
    const calculatedHeight = height + (y - this.oldY);

    const maxWidthAllowedByScreen = this.document.documentElement.clientWidth - right + width;
    const maxHeightAllowedByScreen = this.document.documentElement.clientHeight - bottom + height;

    const newWidth = this.getSizeWithConstrains(
      calculatedWidth,
      this.libResizeMaxSize.width > maxWidthAllowedByScreen ? maxWidthAllowedByScreen : this.libResizeMaxSize.width,
      this.libResizeMinSize.width,
    );
    const newHeight = this.getSizeWithConstrains(
      calculatedHeight,
      this.libResizeMaxSize.height > maxHeightAllowedByScreen ? maxHeightAllowedByScreen : this.libResizeMaxSize.height,
      this.libResizeMinSize.height,
    );

    this.setSize(newWidth, newHeight);

    this.oldX = x;
    this.oldY = y;
  }

  public endMovement() {
    this.oldX = null;
    this.oldY = null;
  }

  private setSize(width: number, height: number) {
    this.renderer2.setStyle(this.element, 'width', `${width}px`);
    this.renderer2.setStyle(this.element, 'height', `${height}px`);
  }

  private getSizeWithConstrains(targetValue: number, max: number, min: number): number {
    if (targetValue > max) {
      return max;
    }
    if (targetValue < min) {
      return min;
    }

    return targetValue;
  }
}
