import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { map, share, take } from 'rxjs/operators';
import {
  IDoctorAppointmentTimeSlot,
  ITimeSlot,
  ITimeSlotAssign,
  ITimeSlotDTO,
  TimeSlotViewModelFactory,
  ERequisitionFilterType,
} from '@project/view-models';
import { TGuid } from '@core/helpers';
import { TStaticDateFormat } from '@project/shared';

@Injectable({
  providedIn: 'root',
})
export class TimeSlotsApiProviderService {
  constructor(private apiService: ApiService) {}

  getDoctorAppointmentTimeSlotsById(doctorAppointmentTimeSlot: IDoctorAppointmentTimeSlot): Observable<ITimeSlot[]> {
    return this.apiService
      .get(
        `${environment.environmentVariables.apiCoreUrl}/timeslots/doctor/appointments/ids`,
        doctorAppointmentTimeSlot,
      )
      .pipe(
        map((slots: ITimeSlotDTO[]) => slots.map((slot: ITimeSlotDTO) => TimeSlotViewModelFactory.createFromDto(slot))),
      );
  }

  getDoctorAppointmentTimeSlots(): Observable<ITimeSlot[]> {
    return this.apiService
      .get(`${environment.environmentVariables.apiCoreUrl}/timeslots/doctor/appointments`)
      .pipe(
        map((slots: ITimeSlotDTO[]) => slots.map((slot: ITimeSlotDTO) => TimeSlotViewModelFactory.createFromDto(slot))),
      );
  }

  getDoctorScheduleTimeSlotsById(doctorId: TGuid, from: TStaticDateFormat): Observable<ITimeSlot[]> {
    return this.apiService
      .get(`${environment.environmentVariables.apiCoreUrl}/timeslots/doctor/${doctorId}/slots`, {
        from,
      })
      .pipe(
        map((slots: ITimeSlotDTO[]) => slots.map((slot: ITimeSlotDTO) => TimeSlotViewModelFactory.createFromDto(slot))),
      );
  }

  getDoctorScheduleTimeSlots(from: TStaticDateFormat): Observable<ITimeSlot[]> {
    return this.apiService
      .get(`${environment.environmentVariables.apiCoreUrl}/timeslots/doctor/slots`, {
        from,
      })
      .pipe(
        map((slots: ITimeSlotDTO[]) => slots.map((slot: ITimeSlotDTO) => TimeSlotViewModelFactory.createFromDto(slot))),
      );
  }

  getPatientAppointmentTimeSlots(from?: TStaticDateFormat, filter?: ERequisitionFilterType): Observable<ITimeSlot[]> {
    return this.apiService
      .get(`${environment.environmentVariables.apiCoreUrl}/timeslots/patient/appointments`, {
        from,
        filter,
      })
      .pipe(
        map((slots: ITimeSlotDTO[]) => slots.map((slot: ITimeSlotDTO) => TimeSlotViewModelFactory.createFromDto(slot))),
      );
  }
}
