import { Component, HostBinding, Input } from '@angular/core';

export type TButtonAppearance =
  | 'primary'
  | 'outline'
  | 'outline-muted'
  | 'danger'
  | 'light-muted-outline'
  | 'grey-outline'
  | 'text-placeholder'
  | 'none';
export type TButtonSize = 'l' | 'm' | 's';

export interface IButtonProps {
  appearance: TButtonAppearance;
  size: TButtonSize;
  isPending: boolean;
}

@Component({
  selector: 'button[libButton],a[libButton]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements IButtonProps {
  @HostBinding('attr.type') @Input() type: 'submit' | 'button' = 'button';
  @HostBinding('attr.appearance') @Input() appearance: TButtonAppearance = 'primary';
  @HostBinding('attr.data-size') @Input() size: TButtonSize = 'm';
  @HostBinding('attr.isPending') @Input() isPending = false;
  @HostBinding('attr.is-only-icon') @Input() onlyIcon = false;
  @HostBinding('attr.with-icon') @Input() withIcon = false;
  @HostBinding('attr.disabled') private _disabled: true | null;

  @Input() set disabled(value: boolean) {
    this._disabled = value || null;
  }

  get disabled(): boolean {
    return this._disabled || false;
  }
}
