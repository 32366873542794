import { Injectable } from '@angular/core';

export enum EMediaDevices {
  Audio = 'audio',
  Video = 'video',
  Both = 'both',
}

@Injectable({
  providedIn: 'root',
})
export class MediaDevicesService {
  public checkMediaDevice(mediaType: EMediaDevices): Promise<void> {
    if (mediaType === EMediaDevices.Both) {
      return this.checkMediaDevices({
        [EMediaDevices.Audio]: true,
        [EMediaDevices.Video]: true,
      });
    } else {
      return this.checkMediaDevices({ [mediaType]: true });
    }
  }

  private checkMediaDevices(constraints: MediaStreamConstraints): Promise<void> {
    return this.getUserMedia(constraints).then((stream) => {
      stream.getTracks().forEach((track) => track.stop());
    });
  }

  private getUserMedia(constraints: MediaStreamConstraints): Promise<MediaStream> {
    if (
      typeof navigator !== 'undefined' &&
      typeof navigator?.mediaDevices !== 'undefined' &&
      typeof navigator?.mediaDevices?.getUserMedia === 'function'
    ) {
      return navigator.mediaDevices.getUserMedia(constraints);
    }

    if (typeof navigator !== 'undefined' && typeof navigator?.getUserMedia === 'function') {
      return new Promise((resolve, reject) => {
        try {
          navigator.getUserMedia(
            constraints,
            (stream) => resolve(stream),
            (err) => reject(err),
          );
        } catch (e) {
          reject(e);
        }
      });
    }

    return Promise.reject(new Error('Not supported'));
  }
}
