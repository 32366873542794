<lib-modal-header i18n="@@modals.confirm.title" (close)="close$.emit()">
  Confirm
</lib-modal-header>
<lib-modal-body>
  <main>
    {{ message }}
  </main>
</lib-modal-body>
<lib-modal-footer class="footer">
  <button #closeButton (click)="close$.emit()" libButton [appearance]="'outline'" i18n="@@modals.confirm.actions.no">
    NO
  </button>
  <button (click)="submit$.emit()" libButton [appearance]="'primary'" i18n="@@modals.confirm.actions.yes">YES</button>
</lib-modal-footer>
