import { IRouteWithData } from '@project/shared';
import { RoutesBuilderService } from '@app/config';
import { SessionsTypeGuard } from './sessions-type-guard';

export const SESSIONS_SPECIALIST_ROUTES: IRouteWithData[] = [
  {
    path: RoutesBuilderService.SESSION_BY_DOCTOR.baseLiveSessions,
    canActivate: [SessionsTypeGuard],
    loadChildren: () =>
      import('./live-sessions-specialist/live-sessions-specialist.module').then((m) => m.LiveSessionsSpecialistModule),
  },
  {
    path: RoutesBuilderService.SESSION_BY_DOCTOR.baseAppointmentsSessions,
    canActivate: [SessionsTypeGuard],
    loadChildren: () =>
      import('./appointments-sessions-specialist/appointments-sessions-specialist.module').then(
        (m) => m.AppointmentsSessionsSpecialistModule,
      ),
  },
  {
    path: '',
    redirectTo: RoutesBuilderService.SESSION_BY_DOCTOR.baseLiveSessions,
  },
];
