import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormComponent, INPUT_MASKS, INPUT_PLACEHOLDERS, SharedFormOptions } from '@project/shared';
import { ISelectOption } from '@lib/ui/form-elements';
import { FormBuilder } from '@angular/forms';
import { IReceptionist, IReceptionistToInvite } from '@project/view-models';
import { ReceptionistInviteUtils } from '../receptionist-invite.common';
import { fillWithZeros } from '@core/helpers';

@Component({
  selector: 'app-receptionist-invite-details-form',
  templateUrl: './receptionist-invite-details-form.component.html',
  styleUrls: ['./receptionist-invite-details-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceptionistInviteDetailsFormComponent extends FormComponent implements OnInit {
  @Output() dataSubmit = new EventEmitter<IReceptionistToInvite>();
  @Input() receptionist: IReceptionist;
  @Input() isPending = false;

  public form = this.fb.group(ReceptionistInviteUtils.getReceptionistInviteFormDeclaration());

  public genderOptions: ISelectOption[] = SharedFormOptions.genderOptions();
  public readonly dateInputMask = INPUT_MASKS.date;
  public readonly dateInputPlaceholder = INPUT_PLACEHOLDERS.date;

  constructor(private fb: FormBuilder) {
    super();
  }

  protected onFormInit() {
    this.form.get('gender').setValue(this.genderOptions[0].value);

    if (this.receptionist != null) {
      this.form.get('firstName').setValue(this.receptionist.firstName);
      this.form.get('lastName').setValue(this.receptionist.lastName);

      this.form.get('email').setValue(this.receptionist.email);

      this.form.get('gender').setValue(this.receptionist.gender);

      this.form.get('phone').setValue(this.receptionist.phone);

      if (this.receptionist.birthDate != null) {
        this.form
          .get('dateOfBirth')
          .setValue(
            `${fillWithZeros(this.receptionist.birthDate.getUTCDate(), 2)}.${fillWithZeros(
              this.receptionist.birthDate.getUTCMonth() + 1,
              2,
            )}.${this.receptionist.birthDate.getUTCFullYear()}`,
          );
      }
    }
  }

  protected doCleanSubmit() {
    const userData = ReceptionistInviteUtils.createReceptionistInviteDto(this.form.value);
    this.dataSubmit.emit(userData);

    if (this.receptionist != null) {
      return;
    }

    const { gender } = this.form.value;
    this.form.reset();
    this.form.get('gender').patchValue(gender);
  }
}
