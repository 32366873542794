<form [formGroup]="form" (ngSubmit)="doDirtySubmit()">
  <lib-modal-header (close)="close()">{{ "profile-tags.settings-overlay.title" | translate }}</lib-modal-header>
  <lib-modal-body class="body">
    <lib-form-control class="mb-1" *ngIf="profileType == 'Patient'">
      <label>
        <span libLabel>Id</span>
        <lib-input formControlName="id"></lib-input>
      </label>
    </lib-form-control>
    <lib-form-control class="mb-1">
      <label>
        <span libLabel>{{ "nouns.name" | translate }}</span>
        <lib-input formControlName="name"></lib-input>
      </label>
    </lib-form-control>
    <lib-form-control class="mb-1" *ngIf="profileType == 'Patient'">
      <label>
        <span libLabel>{{ "nouns.public_name" | translate }}</span>
        <lib-input formControlName="public_name"></lib-input>
      </label>
    </lib-form-control>
    <lib-form-control class="mb-0" *ngIf="profileType == 'Patient'">
      <label>
        <span class="slide-toggle_title" libLabel>{{ "patients.tag-public" | translate }}</span>
        <mat-slide-toggle color="primary" formControlName="public"></mat-slide-toggle>
      </label>
    </lib-form-control>
    <lib-form-control class="mb-0" *ngIf="profileType == 'Patient'">
      <label>
        <span class="slide-toggle_title" libLabel>{{ "patients.tag-disable-scheduling" | translate }}</span>
        <mat-slide-toggle color="primary" formControlName="disable_scheduling"></mat-slide-toggle>
      </label>
    </lib-form-control>
    <lib-form-control class="mb-0" *ngIf="profileType == 'Patient'">
      <label>
        <span class="slide-toggle_title" libLabel>{{ "patients.tag-disable-live-queue" | translate }}</span>
        <mat-slide-toggle color="primary" formControlName="disable_live_queue"></mat-slide-toggle>
      </label>
    </lib-form-control>
  </lib-modal-body>
  <lib-modal-footer class="footer">
    <button
      *ngIf="tag"
      [disabled]="disableActions$ | async"
      [isPending]="isRemoving$ | async"
      libButton
      [appearance]="'danger'"
      (click)="remove()"
    >
      {{ "actions.remove" | translate }}
    </button>
    <span class="divider"></span>
    <button [disabled]="disableActions$ | async" libButton [appearance]="'grey-outline'" (click)="close()">
      {{ "actions.close" | translate }}
    </button>
    <button
      [disabled]="disableActions$ | async"
      [isPending]="submitting$ | async"
      libButton
      type="submit"
      [appearance]="'primary'"
    >
      {{ (tag ? "actions.update" : "actions.create") | translate }}
    </button>
  </lib-modal-footer>
</form>
