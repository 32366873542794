import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-aside-page-section',
  templateUrl: './aside-page-section.component.html',
  styleUrls: ['./aside-page-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsidePageSectionComponent {
  @HostBinding('attr.fill-space') @Input() fillSpace = false;
  @HostBinding('attr.with-paddings') @Input() paddings = false;
  @HostBinding('attr.scrollable') @Input() scrollable = false;
}
