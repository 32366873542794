import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthorizationApiProvider } from '../../data-providers/api-providers/authorization-api-provider.service';
import { ISignInRefreshTokenResponseDTO } from '../../view-models/auth.view-model';
import { AuthorisationStateService } from '../authorisation-state.service';
import { LogoutService } from '../logout.service';

@Injectable()
export class HttpAuthInterceptorService implements HttpInterceptor {
  constructor(
    private authorisationStateService: AuthorisationStateService,
    private authorisationApiService: AuthorizationApiProvider,
    private logoutService: LogoutService,
  ) {}

  private cloneRequest(req: HttpRequest<any>, token: string) {
    const headers = req.headers.set('Authorization', `Bearer ${token}`);
    return req.clone({
      headers,
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.authorisationStateService.getToken();
    const refreshToken = this.authorisationStateService.getRefreshToken();

    return next.handle(this.cloneRequest(req, token)).pipe(
      catchError((error: HttpErrorResponse) => {
        const { status } = error;
        if (status === 401 && !req.url.includes(this.authorisationApiService.refreshTokePath)) {
          if (refreshToken) {
            //try refresh
            return this.handleUnauthorized(req, next, token);
          } else {
            this.logoutService.logout();
          }
        }
        throw error;
      }),
    );
  }

  private handleUnauthorized(req: HttpRequest<any>, next: HttpHandler, expiredToken: string) {
    const refreshToken = this.authorisationStateService.getRefreshToken();
    if (refreshToken) {
      return this.authorisationApiService
        .refreshToken({
          token: expiredToken,
          refresh_token: refreshToken,
        })
        .pipe(
          switchMap((response: ISignInRefreshTokenResponseDTO) => {
            this.authorisationStateService.refreshToken(response.token);
            return next.handle(this.cloneRequest(req, response.token)).pipe(
              catchError((innerError: HttpErrorResponse) => {
                if (innerError.status === 401) {
                  this.logoutService.logout();
                }
                throw innerError;
              }),
            );
          }),
          catchError((err: any) => {
            this.logoutService.logout();
            return throwError(err);
          }),
        );
    }
    return throwError('Refresh token not found');
  }
}
