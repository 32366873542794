import {
  IPagedItemsProceedResult,
  IPagedItemsSortingState,
  PagingApiMapperHelper,
  ServerSidePagedItemsProviderService,
} from '@core/data-providers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IReceptionist } from '@project/view-models';
import { Injectable } from '@angular/core';
import { ReceptionistsApiProviderService } from './api-providers/_index';

export interface IReceptionistFiltersParams {
  search?: string;
}

@Injectable()
export class ReceptionistsServerSideItemsProvider extends ServerSidePagedItemsProviderService<
  IReceptionist,
  IReceptionistFiltersParams,
  null
> {
  constructor(private receptionistApiProviderService: ReceptionistsApiProviderService) {
    super();
  }

  protected getDefaultFilters(): IReceptionistFiltersParams {
    return {};
  }

  protected proceedPagedItemsLoading(
    page: number,
    itemsPerPage: number,
    filters: IReceptionistFiltersParams,
    sorting: IPagedItemsSortingState<null>,
  ): Observable<IPagedItemsProceedResult<IReceptionist>> {
    return this.receptionistApiProviderService
      .getPaged(page, itemsPerPage, this._filtersState$.value)
      .pipe(map((result) => PagingApiMapperHelper.mapPagingApiResponseToProceedResult(page, result)));
  }
}
