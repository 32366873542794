import { Inject, Injectable, InjectionToken, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { IModalWindowComponent, IModalWindowConfig } from './modal-window-component.types';
import { first, takeUntil, tap } from 'rxjs/operators';

export const MODAL_WINDOW_COMPONENT = new InjectionToken('MODAL_WINDOW_COMPONENT');

@Injectable()
export class ModalWindowRef implements OnDestroy {
  public readonly isFullscreenMode$ = this.windowComponent.isFullscreenMode$;
  public readonly closeWindow$ = this.windowComponent.close$.asObservable();

  public readonly focused$ = this.windowComponent.focused$;

  private destroy$ = new Subject<void>();

  private closeHandler: (() => Observable<boolean>) | null = null;

  constructor(@Inject(MODAL_WINDOW_COMPONENT) private windowComponent: IModalWindowComponent) {
    this.windowComponent.registerCloseRequestFn(() => {
      this.closeWindow().pipe(takeUntil(this.destroy$)).subscribe();
    });
  }

  setCloseHandler(handler: () => Observable<boolean>) {
    this.closeHandler = handler;
  }

  closeWindow(): Observable<boolean> {
    return (this.closeHandler?.() ?? of(true)).pipe(
      first(),
      tap((closeAllowed) => {
        if (closeAllowed) {
          this.windowComponent.close$.next();
          return;
        }
      }),
    );
  }

  updateConfig(partialConfig: Partial<IModalWindowConfig>) {
    this.windowComponent.updateConfig(partialConfig);
  }

  toggleWindowMode() {
    this.windowComponent.toggleWindowMode();
  }

  setFocused() {
    this.windowComponent.setFocused();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
