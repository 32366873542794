import { NgModule } from '@angular/core';
import { TimeSlotComponent } from './components/slot/time-slot.component';
import { TimeSlotsComponent } from './components/time-slots/time-slots.component';
import { CommonModule } from '@angular/common';
import { ActionsDropdownModule, ButtonModule } from '@lib/ui';
import { IconsModule } from '@lib/icons';

@NgModule({
  declarations: [TimeSlotsComponent, TimeSlotComponent],
  imports: [CommonModule, ActionsDropdownModule, IconsModule, ButtonModule],
  exports: [TimeSlotsComponent],
})
export class TimeSlotsModule {}
