import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { IRequisitionLogsItem, IRequisitionLogItemDTO, RequisitionLogItemViewModelFactory } from '@project/view-models';
import { ApiService, IPagingResponse, PagingApiService } from '@core/http';
import { TGuid } from '@core/helpers';
import { map } from 'rxjs/operators';
import { IRequisitionLogsFiltersParams } from '@project/data-providers';

@Injectable({
  providedIn: 'root',
})
export class RequisitionLogsApiProviderService {
  constructor(private apiService: ApiService, private pagingApiService: PagingApiService) {}

  getById(requisitionId: TGuid): Observable<IRequisitionLogsItem> {
    return this.apiService
      .get<IRequisitionLogItemDTO>(`${environment.environmentVariables.apiCoreUrl}/Requisition/${requisitionId}/logs`)
      .pipe(map((dto) => RequisitionLogItemViewModelFactory.createGetViewModel(dto)));
  }

  getPaged(
    page: number,
    itemsPerPage: number,
    query?: IRequisitionLogsFiltersParams,
  ): Observable<IPagingResponse<IRequisitionLogsItem>> {
    return this.pagingApiService
      .request<IRequisitionLogItemDTO, IRequisitionLogsItem>({
        url: `${environment.environmentVariables.apiCoreUrl}/Requisition/logs`,
        additionalQueryParams: query,
        itemsPerPage,
        itemFactory: (item) => RequisitionLogItemViewModelFactory.createGetViewModel(item),
        pageNumber: page,
      })
      .pipe(map((response) => response));
  }
}
