import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeslotsListPreviewComponent } from './timeslots-list-preview/timeslots-list-preview.component';
import { TimeSlotsModule } from '@lib/time-slots';
import { TranslateModule } from '@project/translate';
import { ButtonModule } from '@lib/ui';

@NgModule({
  declarations: [TimeslotsListPreviewComponent],
  exports: [TimeslotsListPreviewComponent],
  imports: [CommonModule, TimeSlotsModule, TranslateModule, ButtonModule],
})
export class TimeslotsListPreviewModule {}
