<ul class="tabs" [class.-bordered]="!hideBorder">
  <li *ngFor="let tab of tabs$ | async" [ngClass]="tab.itemClass">
    <a [routerLink]="tab.url" class="tab" [routerLinkActive]="'active'">
      {{ tab.label }}
    </a>
  </li>
</ul>
<div *ngIf="!hideOutlet">
  <router-outlet></router-outlet>
</div>
