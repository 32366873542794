import { OnDestroy, OnInit, TemplateRef, ViewContainerRef, Directive } from '@angular/core';
import { EUserPermission } from '@project/shared';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserProfileDataService } from '@project/services';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class HasAccess implements OnInit, OnDestroy {
  protected permissionsToCheck$ = new BehaviorSubject<EUserPermission[]>([]);

  private hasView = false;

  private sub: Subscription;

  constructor(
    protected userProfileDataService: UserProfileDataService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.sub = this.permissionsToCheck$
      .pipe(switchMap((permissions) => this.checkPermissions(permissions)))
      .subscribe((hasAccess) => {
        if (!this.hasView && hasAccess) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
        }

        if (this.hasView && !hasAccess) {
          this.viewContainer.clear();
          this.hasView = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  protected abstract checkPermissions(permissions: EUserPermission[]): Observable<boolean>;
}
