import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export type TIcon =
  | 'actions-dots'
  | 'add'
  | 'apple'
  | 'arrow-angle-to-top-grey'
  | 'call-end-white'
  | 'call-light'
  | 'chat-light'
  | 'chat-white'
  | 'cross'
  | 'download'
  | 'dropdown-arrow'
  | 'info-white'
  | 'facebook'
  | 'gear'
  | 'google'
  | 'mic-white'
  | 'mic-off-white'
  | 'people'
  | 'plus-in-circle'
  | 'send-blue'
  | 'search'
  | 'timer'
  | 'videocam-white'
  | 'videocam-off-white'
  | 'upload'
  | 'upload-white'
  | 'edit'
  | 'history'
  | 'menu'
  | 'cancel';

export const ICONS_URLS: Record<TIcon, string> = {
  'actions-dots': '/shared-assets/icons/actions-dots.svg',
  add: '/shared-assets/icons/add.svg',
  apple: '/shared-assets/icons/apple.svg',
  'arrow-angle-to-top-grey': '/shared-assets/icons/arrow_angle_to_top-grey.svg',
  'call-light': '/shared-assets/icons/call-light.svg',
  'call-end-white': '/shared-assets/icons/call_end-white.svg',
  'chat-light': '/shared-assets/icons/chat-light.svg',
  'chat-white': '/shared-assets/icons/chat-white.svg',
  cross: '/shared-assets/icons/cross.svg',
  download: '/shared-assets/icons/download.svg',
  'dropdown-arrow': '/shared-assets/icons/dropdown-arrow.svg',
  'info-white': '/shared-assets/icons/info-white.svg',
  facebook: '/shared-assets/icons/facebook.svg',
  gear: '/shared-assets/icons/gear.svg',
  google: '/shared-assets/icons/google.svg',
  'mic-white': '/shared-assets/icons/mic-white.svg',
  'mic-off-white': '/shared-assets/icons/mic_off-white.svg',
  people: '/shared-assets/icons/people.svg',
  'plus-in-circle': '/shared-assets/icons/plus-in-circle.svg',
  'send-blue': '/shared-assets/icons/send-blue.svg',
  search: '/shared-assets/icons/search.svg',
  timer: '/shared-assets/icons/timer.svg',
  'videocam-white': '/shared-assets/icons/videocam-white.svg',
  'videocam-off-white': '/shared-assets/icons/videocam_off-white.svg',
  upload: '/shared-assets/icons/upload.svg',
  'upload-white': '/shared-assets/icons/upload_white.svg',
  edit: '/shared-assets/icons/edit.svg',
  history: '/shared-assets/icons/history.svg',
  menu: '/shared-assets/icons/menu.svg',
  cancel: '/shared-assets/icons/cancel.svg',
};

@Component({
  selector: 'lib-icon',
  template: '',
  styleUrls: ['./icon.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input() type: TIcon;

  @HostBinding('style.backgroundImage')
  public get bgImage(): string {
    if (!this.type) {
      return `none`;
    }
    return `url(${ICONS_URLS[this.type]})`;
  }
}
