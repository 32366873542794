import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ITimeSlot } from '../../models/time-slots.model';

@Component({
  selector: 'lib-time-slot',
  templateUrl: './time-slot.component.html',
  styleUrls: ['./time-slot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSlotComponent {
  @Input() timeSlot: ITimeSlot;
  @Input() allActionsDisabled: boolean = false;
  @HostBinding('class.show-end') @Input() showEndTime: boolean = false;
}
