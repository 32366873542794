import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalBodyComponent } from './body/modal-body.component';
import { ModalFooterComponent } from './footer/modal-footer.component';
import { ModalHeaderComponent } from './header/modal-header.component';

@NgModule({
  declarations: [ModalBodyComponent, ModalFooterComponent, ModalHeaderComponent],
  imports: [CommonModule],
  exports: [ModalBodyComponent, ModalFooterComponent, ModalHeaderComponent],
})
export class ModalDialogLayoutModule {}
