/**
 * Write masks in this plugin notation
 * https://github.com/JsDaddy/ngx-mask
 */
export const INPUT_MASKS = {
  cpf: '000.000.000-00',
  date: '00.00.0000',
  timeAs24hours: '00:00',
};

/**
 * values have to be matched with the same INPUT_MASKS value
 */
export const INPUT_PLACEHOLDERS = {
  cpf: '385.965.931-60',
  date: '19.12.1990',
  timeAs24hoursMorning: '09:05',
  timeAs24hoursEvening: '18:15',
  country: 'Brasil',
  zipcode: '02452005',
  state: 'São Paulo',
  city: 'São Paulo',
  district: 'Centro',
  street: 'Av. Paulista',
  // eslint-disable-next-line id-blacklist
  number: '300',
  complement: 'Ap.500',
};
