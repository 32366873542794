import { Injectable } from '@angular/core';
import { EUserPermission } from '@project/shared';
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import { filter, first, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { EServiceSocketMessageTypeDTO, IRequisition, IServiceMessageEvent } from '@project/view-models';
import { IModalComponentRef, ModalOverlayService } from '@lib/modal';
import { CallIsReadyToBeginOverlayComponent } from '../call-is-ready-to-begin-overlay/call-is-ready-to-begin-overlay.component';
import { IFeatureService, UserProfileDataService } from '@project/services';
import {
  ChatApiProviderService,
  SocketMessagesDataProviderService,
  RequisitionApiProviderService,
} from '@project/data-providers';
import { TGuid } from '@core/helpers';
import { VideoCallManagerService } from 'src/app/features/modules/video-call/video-call-manager.service';

@Injectable()
export class VideoConferenceManagerService implements IFeatureService {
  private _showCallButton$ = new BehaviorSubject<boolean>(false);
  public showCallButton$ = this._showCallButton$.asObservable();
  private destroyed$ = new Subject();

  private currentResponseDialogRef: IModalComponentRef<CallIsReadyToBeginOverlayComponent>;

  constructor(
    private userProfileDataService: UserProfileDataService,
    private videoCallManagerService: VideoCallManagerService,
    private modalOverlayService: ModalOverlayService,
    private socketMessagesProviderService: SocketMessagesDataProviderService,
    private chatApiProviderService: ChatApiProviderService,
    private requisitionApiProviderService: RequisitionApiProviderService,
  ) {}

  initialise(): Observable<void> {
    if (this.userProfileDataService.hasPermissions([EUserPermission.responseToSession])) {
      this.subscribeToNewSessionIsOnline();
    }

    if (this.userProfileDataService.hasPermissions([EUserPermission.makeCall])) {
      this._showCallButton$.next(true);
    }

    return of(null);
  }

  destroy(): Observable<void> {
    this._showCallButton$.next(false);
    this.destroyed$.next();
    return of(null);
  }

  doCall(requisition: IRequisition): Promise<void> {
    return this.videoCallManagerService.doCall(requisition);
  }

  private subscribeToNewSessionIsOnline() {
    let currentCallId: TGuid = null;
    let currentCallParticipantId: TGuid = null;

    const preventRequisitionRequest$ = new Subject();

    const callStarted$: Observable<IRequisition> = this.socketMessagesProviderService.callStarted$.pipe(
      tap((event) => {
        currentCallId = event.chatId;
        currentCallParticipantId = event.userId;
      }),
      withLatestFrom(this.videoCallManagerService.isVideoSessionOffline$.pipe(map((isOffline) => !isOffline))),
      filter(([, isCallExist]) => !isCallExist),
      switchMap(() =>
        this.requisitionApiProviderService
          .getFirst()
          .pipe(first((chat) => Boolean(chat)))
          .pipe(takeUntil(preventRequisitionRequest$)),
      ),
    );

    const currentCallParticipantDisconnected$ = this.socketMessagesProviderService.userDisconnected$.pipe(
      filter((event) => event.userId === currentCallParticipantId),
    );

    const currentCallEnded$ = this.socketMessagesProviderService.callEnded$.pipe(
      filter((event) => event.userId === currentCallParticipantId && event.chatId === currentCallId),
    );

    const callDecline$: Observable<void> = merge(currentCallParticipantDisconnected$, currentCallEnded$).pipe(
      map(() => null),
    );

    callStarted$.pipe(takeUntil(this.destroyed$)).subscribe((requisition) => {
      this.showCallIsReadyOverlay(requisition);
    });

    callDecline$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      currentCallId = null;
      currentCallParticipantId = null;
      preventRequisitionRequest$.next();
      this.hideCallIsReadyOverlay();
    });
  }

  private showCallIsReadyOverlay(requisition: IRequisition) {
    if (this.currentResponseDialogRef) {
      return;
    }

    const preventSubscriptions$ = new Subject();

    this.currentResponseDialogRef = this.modalOverlayService.openOverlay(
      CallIsReadyToBeginOverlayComponent,
      {
        requisition,
      },
      {
        fullScreen: true,
        closeByEsc: false,
      },
    );

    this.currentResponseDialogRef.instance.declineClicked$
      .pipe(
        tap(() => this.hideCallIsReadyOverlay()),
        switchMap(() =>
          this.chatApiProviderService.sendServiceMessage(requisition.chatId, EServiceSocketMessageTypeDTO.CallDeclined),
        ),
        takeUntil(preventSubscriptions$),
      )
      .subscribe(() => {
        preventSubscriptions$.next();
        preventSubscriptions$.complete();
        this.currentResponseDialogRef = null;
      });

    this.currentResponseDialogRef.instance.joinClicked$.pipe(takeUntil(preventSubscriptions$)).subscribe(() => {
      this.videoCallManagerService
        .doCall(requisition)
        .catch(() => null)
        .then(() => {
          preventSubscriptions$.next();
          preventSubscriptions$.complete();
          this.hideCallIsReadyOverlay();
        });
    });
  }

  private hideCallIsReadyOverlay() {
    this.currentResponseDialogRef?.close();
    this.currentResponseDialogRef = null;
  }
}
