import { EUserPermission, FormComponent, SharedFormOptions } from '@project/shared';
import { LibValidators } from '@core/helpers';
import { ISelectOption } from '@lib/ui/form-elements';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
import { UserProfileDataService } from '@project/services';
import { HealthCentersApiProviderService } from '@project/data-providers';
import { IHealthCenterAdmin, IHealthCenterAdminToInvite } from '@project/view-models';
import { TranslateService } from '@project/translate';
import { HealthCenterAdminInviteUtils } from '../health-center-admin-invite.common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-health-center-admin-invite-details-form',
  templateUrl: './health-center-admin-invite-details-form.component.html',
  styleUrls: ['./health-center-admin-invite-details-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealthCenterAdminInviteDetailsFormComponent extends FormComponent implements OnInit, OnDestroy {
  @Output() dataSubmit = new EventEmitter<IHealthCenterAdminToInvite>();
  @Input() hcAdmin: IHealthCenterAdmin;
  @Input() isPending = false;

  public form = this.fb.group(HealthCenterAdminInviteUtils.getHealthCenterAdminInviteFormDeclaration());

  public genderOptions: ISelectOption[] = SharedFormOptions.genderOptions();

  public healthCentersSelectOptions$ = new BehaviorSubject<ISelectOption[]>([]);

  private destroyed$ = new Subject();

  public canSelectHealthCenter = false;

  constructor(
    private fb: FormBuilder,
    private userProfileDataService: UserProfileDataService,
    private healthCentersApiProviderService: HealthCentersApiProviderService,
  ) {
    super();

    if (this.userProfileDataService.hasPermissions([EUserPermission.manageHealthCenters])) {
      this.initRequiredHealthCenterSelection();
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  protected onFormInit() {
    this.form.get('gender').setValue(this.genderOptions[0].value);

    if (this.hcAdmin != null) {
      this.form.get('firstName').setValue(this.hcAdmin.firstName);
      this.form.get('lastName').setValue(this.hcAdmin.lastName);

      this.form.get('email').setValue(this.hcAdmin.email);

      this.form.get('gender').setValue(this.hcAdmin.gender);
      this.form.get('accessToken').setValue(this.hcAdmin.accessToken);
    }
  }

  protected doCleanSubmit() {
    const userData = this.createInviteDTO();

    this.dataSubmit.emit(userData);

    if (this.hcAdmin != null) {
      return;
    }

    const { gender } = this.form.value;
    this.form.reset();
    this.form.get('gender').patchValue(gender);
  }

  private createInviteDTO(): IHealthCenterAdminToInvite {
    const values = this.form.value;

    return {
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      gender: values.gender,
      phone: values.phone,
      health_center_id: values.healthCenterId,
      http_execute: values.http_execute,
      access_token: values.accessToken ? values.accessToken : false,
    };
  }

  private initRequiredHealthCenterSelection() {
    this.canSelectHealthCenter = true;

    const control = this.form.get('healthCenterId');
    control.setValidators([LibValidators.required(TranslateService.localize('validations.required'))]);

    this.healthCentersApiProviderService
      .getAll()
      .pipe(
        map((items) =>
          items.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        ),
        takeUntil(this.destroyed$),
      )
      .subscribe(
        (options) => {
          this.healthCentersSelectOptions$.next(options);
        },
        () => {
          this.healthCentersSelectOptions$.next([]);
        },
      );
  }
}
