import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ValidPageNumberForRouteGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const { page } = route.params;

    const pageAsNumber = +page;

    if (!isNaN(pageAsNumber) && pageAsNumber > 0 && Math.ceil(pageAsNumber) === pageAsNumber) {
      return true;
    }

    const segments = state.url.split('?')[0].split('/');

    const segmentsValid: string[] = [];

    segments.some((segment) => {
      if (segment !== page) {
        segmentsValid.push(segment);
        return false;
      }

      return true;
    });

    if (segmentsValid.length) {
      return this.router.parseUrl(segmentsValid.join('/'));
    } else {
      return this.router.parseUrl('/');
    }
  }
}
