import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';
import StatusResponse = facebook.StatusResponse;
import { ExternalScriptLoaderService } from '@core/services';
import { environment } from '@env';

export interface IFacebookAuthResult {
  accessToken: string;
}

@Injectable()
export class FacebookAuthProviderService {
  constructor(private externalScriptLoaderService: ExternalScriptLoaderService) {}

  public signIn(): Observable<IFacebookAuthResult> {
    return defer(() =>
      this.getSDK().then((fb) => {
        return new Promise<IFacebookAuthResult>((resolve, reject) => {
          const authResponse = fb.getAuthResponse();
          if (authResponse) {
            resolve({ accessToken: authResponse.accessToken });
          }

          fb.login(
            (result: StatusResponse) => {
              if (result.status !== 'connected') {
                reject(result);
              }

              resolve({ accessToken: result.authResponse.accessToken });
            },
            { scope: '', enable_profile_selector: true },
          );
        });
      }),
    );
  }

  private getSDK(): Promise<typeof FB> {
    return this.externalScriptLoaderService.load('fbsdk', 'https://connect.facebook.net/en_US/sdk.js').then(() => {
      FB.init({
        appId: environment.appIds.facebookAppId,
        cookie: false,
        xfbml: false,
        version: 'v8.0',
      });

      return FB;
    });
  }
}
