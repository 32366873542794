import { Injectable } from '@angular/core';
import {
  DoctorsServerSideItemsProvider,
  IDoctorFiltersParams,
  ProfilesTagsApiProviderService,
} from '@project/data-providers';
import { EProfileTypeDTO, IProfileTag } from '@project/view-models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { EFeatureFlags } from '@app/config';
import { ActionsManagerService } from '@project/shared';
import { FeatureFlagService } from '../../feature-flag/feature-flag.service';
import {
  EProfileTagSettingsResult,
  IProfileTagSettingsResult,
  ProfileTagManagerService,
} from '../../profile-tags/profile-tag-manager.service';
import { TGuid } from '@core/helpers';

export enum EDoctorsModuleAction {
  OpenTagCreationSettings = 'OpenTagCreationSettings',
  PatchFilters = 'PatchFilters',
  SetTagSelected = 'SetTagSelected',
  ClearTagSelected = 'ClearTagSelected',
  LoadProfileTags = 'LoadProfileTags',
}

@Injectable({
  providedIn: 'root',
})
export class DoctorInviteManagerService extends ActionsManagerService<EDoctorsModuleAction> {
  protected actionsHandlersMap = {
    [EDoctorsModuleAction.OpenTagCreationSettings]: () => this.createTagAndAddToDoctors(),
    [EDoctorsModuleAction.PatchFilters]: (filters: IDoctorFiltersParams) => this.patchFilters(filters),
    [EDoctorsModuleAction.SetTagSelected]: () => this.setTagSelected(),
    [EDoctorsModuleAction.ClearTagSelected]: () => this.clearTagSelected(),
    [EDoctorsModuleAction.LoadProfileTags]: () => this.loadProfileTags().subscribe(),
  };

  constructor(
    private featureFlagService: FeatureFlagService,
    private profilesTagsApiProviderService: ProfilesTagsApiProviderService,
    private profileTagManagerService: ProfileTagManagerService,
    private doctorsServerSideItemsProvider: DoctorsServerSideItemsProvider,
  ) {
    super();
  }

  public readonly _profileTags$ = new BehaviorSubject<IProfileTag[]>([]);
  public readonly profileTags$ = this._profileTags$.asObservable();
  public readonly filtersState$ = this.doctorsServerSideItemsProvider.filtersState$;
  public readonly tagFilter$ = new BehaviorSubject<TGuid>(null);

  private loadProfileTags(): Observable<IProfileTag[]> {
    if (this.featureFlagService.isFeatureOn(EFeatureFlags.doctorsTags)) {
      return this.profilesTagsApiProviderService
        .getAll(EProfileTypeDTO.Doctor)
        .pipe(tap((tags) => this._profileTags$.next(tags)));
    } else {
      return of([]);
    }
  }

  private createTagAndAddToDoctors() {
    this.waitForTagOverlayResult().subscribe((res) => {
      if (res.type !== EProfileTagSettingsResult.Updated) {
        return;
      }
      this._profileTags$.next(this._profileTags$.value.concat([res.tag]));
    });
  }

  private waitForTagOverlayResult(tag?: IProfileTag): Observable<IProfileTagSettingsResult> {
    return this.profileTagManagerService.openSettings(tag, EProfileTypeDTO.Doctor);
  }

  private patchFilters(filters: Partial<IDoctorFiltersParams>) {
    this.doctorsServerSideItemsProvider.patchFilters(filters);
  }

  private setTagSelected(): void {
    this.tagFilter$.next(this.doctorsServerSideItemsProvider.filtersState.tag);
  }

  private clearTagSelected(): void {
    this.doctorsServerSideItemsProvider.resetFilters();
  }
}
