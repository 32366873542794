import { TGuid } from '@core/helpers';
import { EObservationMethodDTO } from './consts/observation-consts';
import { IObservation, IObservationDTO, ObservationViewModelFactory } from './observation.view-model';

interface IPatientObservationsDTO {
  patient_id: TGuid;
  observations: IObservationDTO[];
}

type TPatientObservationsMap<TPatientId extends string = TGuid> = Record<TPatientId, IObservation[]>;

export interface IObservationPatientsDataDTO {
  type: EObservationMethodDTO;
  patient_ids: TGuid[];
  patients: IPatientObservationsDTO[];
}

export interface IObservationPatientsData {
  method: EObservationMethodDTO;
  patientsObservations: TPatientObservationsMap;
}

export class ObservationPatientsDataViewModelFactory {
  static createFromDto(dto: IObservationPatientsDataDTO): IObservationPatientsData {
    return {
      method: dto.type,
      patientsObservations: this.buildPatientsObservationsMap(dto.patients),
    };
  }

  private static buildPatientsObservationsMap(data: IPatientObservationsDTO[]): TPatientObservationsMap {
    return data.reduce((acc: TPatientObservationsMap, item) => {
      acc[item.patient_id] = item.observations.map((dto) => ObservationViewModelFactory.createFromDTO(dto));
      return acc;
    }, {});
  }
}
