import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '@env';
import { TGuid } from '@core/helpers';
import {
  IScheduleCheckForDoctorResultDTO,
  IScheduleCheckForDoctorsResult,
  IScheduleGroupCreateData,
  IScheduleGroupCreateDTO,
  IScheduleGroupPostErrorDTO,
  ScheduleCheckForDoctorsResultViewModel,
  ScheduleRulesIntersectingError,
  ScheduleGroupViewModelFactory,
} from '@project/view-models';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ScheduleApiProviderService {
  constructor(private apiService: ApiService) {}

  getTimeSlotSizes(): Observable<number[]> {
    return this.apiService.get(`${environment.environmentVariables.apiCoreUrl}/ScheduleGroup/timeslots/sizes`);
  }

  remove(id: TGuid): Observable<void> {
    return this.apiService.delete(`${environment.environmentVariables.apiCoreUrl}/ScheduleGroup/${id}`);
  }

  checkForDoctor(id: TGuid): Observable<boolean> {
    return this.checkForDoctors([id]).pipe(map((result) => result[id]));
  }

  checkForDoctors(ids: TGuid[]): Observable<IScheduleCheckForDoctorsResult> {
    return this.apiService
      .get<IScheduleCheckForDoctorResultDTO[]>(
        `${environment.environmentVariables.apiCoreUrl}/ScheduleGroup/check/doctors`,
        { doctorIds: ids },
      )
      .pipe(map((dtos) => ScheduleCheckForDoctorsResultViewModel.createGetViewModelForResults(dtos)));
  }

  createScheduleForDoctor(doctorId: TGuid, schedule: IScheduleGroupCreateData): Observable<void> {
    const postData: IScheduleGroupCreateDTO = ScheduleGroupViewModelFactory.createPostDTO(schedule);
    return this.apiService
      .post(`${environment.environmentVariables.apiCoreUrl}/Doctors/${doctorId}/schedule`, postData)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          const error: Error | IScheduleGroupPostErrorDTO = err.error;

          // TODO Better to check error status instead of shape
          if ('intersecting_intervals' in error) {
            const errObj = new ScheduleRulesIntersectingError(error);
            return throwError(errObj);
          }

          return throwError(err);
        }),
      );
  }
}
