import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { map } from 'rxjs/operators';
import { InputBasicDirective } from '../input-basic.directive';

@Component({
  selector: 'lib-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent extends InputBasicDirective<boolean> implements ControlValueAccessor, OnChanges {
  @Input() checked: boolean;

  @Output() change = new EventEmitter<boolean>();

  public ariaTitle$ = this.value$.pipe(map((value) => (value ? 'Enabled' : 'Disabled')));

  constructor(@Optional() controlContainer: ControlContainer) {
    super(controlContainer);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.checked) {
      this._value$.next(this.checked);
      this.onChange?.(this.checked);
    }
    super.ngOnChanges(changes);
  }

  toggleValue() {
    if (this._disabled$.value) {
      return;
    }
    this._value$.next(!this._value$.getValue());
    this.onChange?.(this._value$.getValue());
    this.change.emit(this._value$.getValue());
  }

  writeValue(value: any): void {
    this._value$.next(Boolean(value));
  }
}
