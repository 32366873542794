export function getRandomFrom<T = any>(array: T[]): T {
  return array[Math.floor(array.length * Math.random())];
}

export function shuffle<T = any>(array: T[]): T[] {
  return [...array].sort(() => Math.random() - 0.5);
}

export function getLoopedItems<T = any>(array: T[], index: number, count: number): T[] {
  const leftCount = Math.floor((count - 1) / 2);
  const rightCount = Math.ceil((count - 1) / 2);

  const from = index - leftCount;
  const to = index + rightCount;

  const indexes: number[] = [];

  for (let i = from; i <= to; i++) {
    const ind = i % array.length;
    if (ind < 0) {
      indexes.push(array.length + ind);
    } else if (ind >= array.length) {
      indexes.push(ind - array.length);
    } else {
      indexes.push(ind);
    }
  }

  return indexes.map((ind) => array[ind]);
}

export function getArrayFromTo(from: number, to: number): number[] {
  const length = to >= from ? to - from + 1 : 0;
  return new Array(length).fill(0).map((item, index) => index + from);
}

export function padArrayRight<T = any>(values: T[], length: number): Array<T | null> {
  const res = values.slice(0, length);

  for (let i = res.length; i < length; i++) {
    res.push(null);
  }

  return res;
}

export function sortNumberAscend(a: number, b: number) {
  return a - b;
}

export function flatArrayToMap<T extends { id: string }>(items: T[]): Record<string, T> {
  return (items || []).reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
}

export function pushUniqueWithIdToArray<T extends { id: string | number }>(items: T[], item: T): T[] {
  if (items.find((current) => current.id === item.id)) {
    return items.slice();
  }

  return items.concat([item]);
}

export function pushOrReplaceItemWithIdToArray<T extends { id: string | number }>(items: T[], item: T): T[] {
  if (items.find((current) => current.id === item.id)) {
    return items.map((current) => (current.id === item.id ? item : current));
  }

  return items.concat([item]);
}

export function removeExistedWithIdFromArray<T extends { id: string | number }>(items: T[], item: T): T[] {
  return items.filter((current) => current.id !== item.id);
}

export function keepUniqueOnly<T = any>(items: T[]): T[] {
  return Array.from(new Set(items).values());
}

export function pushUniqueToArray<T = any>(items: T[], item: T): T[] {
  if (items.includes(item)) {
    return items.slice();
  }

  return items.concat([item]);
}

// TODO Add spec
export function isArraysContentEqual<T = any>(a: T[], b: T[]): boolean {
  if (!a.every((item) => b.includes(item))) {
    return false;
  }

  if (!b.every((item) => a.includes(item))) {
    return false;
  }

  return true;
}

type TSortableValue = string | number | Date;

export function sortByIncrementFn(a: TSortableValue, b: TSortableValue) {
  return a > b ? 1 : a < b ? -1 : 0;
}

export function sortByDecrementFn(a: TSortableValue, b: TSortableValue) {
  return a > b ? -1 : a < b ? 1 : 0;
}
