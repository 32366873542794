<div class="time">
  <span>
    {{ timeSlot.startTime | date: "HH:mm" }}
  </span>
  <span *ngIf="showEndTime">
    {{ timeSlot.endTime | date: "HH:mm" }}
  </span>
</div>
<div class="card-container" [ngClass]="timeSlot.state">
  <div class="card">
    <span class="content">
      {{ timeSlot.content }}
    </span>
    <div class="actions">
      <lib-actions-dropdown *ngIf="timeSlot.actions?.length > 1" [position]="'left-bottom'">
        <div actionsDropdownToggle><lib-icon [type]="'actions-dots'"></lib-icon></div>
        <div actionsDropdownContent>
          <button
            class="action"
            *ngFor="let action of timeSlot.actions"
            lib-dropdown-action
            (click)="action.onClick()"
            [disabled]="action.disabled"
          >
            {{ action.name }}
          </button>
        </div>
      </lib-actions-dropdown>
      <button
        *ngIf="timeSlot.actions?.length === 1"
        libButton
        class="action"
        (click)="timeSlot.actions[0].onClick()"
        [disabled]="allActionsDisabled || timeSlot.actions[0].disabled"
      >
        {{ timeSlot.actions[0].name }}
      </button>
    </div>
  </div>
</div>
