import { Injectable } from '@angular/core';
import {
  IRequisitionEncounterHistoryFiltersParams,
  ObservationsApiProviderService,
  RequisitionApiProviderService,
  RequisitionLogsApiProviderService,
  RequisitionEncounterHistoryServerSideItemsProviderService,
  SummaryNotesApiProviderService,
} from '@project/data-providers';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import {
  IDays,
  IObservationFull,
  IPrescriptionDocument,
  IRequisitionLogsItem,
  IRequisitionWithQuestionsAnswers,
  ISummaryNote,
} from '@project/view-models';
import { catchError, map, tap } from 'rxjs/operators';
import { NotificationsService } from '@lib/notifications';
import { TranslateService } from '@project/translate';
import { IPagedItemsState } from '@core/data-providers';
import { TGuid } from '@core/helpers';
import { ISummaryNotesManagerService } from '@modules';
import { ActionsManagerService } from '@project/shared';

export enum ESessionLogModuleAction {
  PatchFilters = 'PatchFilters',
  LoadSessionLogByPage = 'LoadSessionLogByPage',
}

@Injectable()
export class PatientEncounterHistoryManagerService extends ActionsManagerService<ESessionLogModuleAction> {
  protected actionsHandlersMap = {
    [ESessionLogModuleAction.PatchFilters]: (filters: IRequisitionEncounterHistoryFiltersParams) =>
      this.setFilter(filters),
    [ESessionLogModuleAction.LoadSessionLogByPage]: (page: number) => this.loadItems(page).subscribe(),
  };

  public pageItems$: Observable<IRequisitionLogsItem[]> = this.encounterHistoryServerSideItemsProviderService.items$;
  public pagingState$: Observable<IPagedItemsState> = this.encounterHistoryServerSideItemsProviderService.pagingState$;
  public isFetching$: Observable<boolean> = this.encounterHistoryServerSideItemsProviderService.isFetching$;

  private _selectedRequisition$ = new BehaviorSubject<IRequisitionWithQuestionsAnswers | null>(null);
  public selectedRequisition$ = this._selectedRequisition$.asObservable();

  private _notes$ = new BehaviorSubject<ISummaryNote[]>([]);
  public notes$ = this._notes$.asObservable();

  private _selectedLogObservations$ = new BehaviorSubject<IObservationFull[]>([]);
  public selectedLogObservations$ = this._selectedLogObservations$.asObservable();

  private _prescriptions = new BehaviorSubject<IPrescriptionDocument['data']['results']>([]);
  public prescriptions$ = this._prescriptions.asObservable();

  private LOGS_PER_PAGE = 15;

  private readonly _days$ = new BehaviorSubject<IDays[]>([]);
  public readonly days$ = this._days$.asObservable();
  public readonly filtersState$ = this.encounterHistoryServerSideItemsProviderService.filtersState$;

  constructor(
    private encounterHistoryServerSideItemsProviderService: RequisitionEncounterHistoryServerSideItemsProviderService,
    private notificationService: NotificationsService,
    private summaryNotesApiProviderService: SummaryNotesApiProviderService,
    private requisitionApiProviderService: RequisitionApiProviderService,
    private observationsApiProviderService: ObservationsApiProviderService,
  ) {
    super();

    this._days$.next([
      { description: TranslateService.localize('session.logs.day'), id: 1 },
      { description: TranslateService.localize('session.logs.week'), id: 2 },
      { description: TranslateService.localize('session.logs.month'), id: 3 },
    ]);
  }

  loadPrescriptionDocuments(requisitionId: string): Observable<void> {
    return this.requisitionApiProviderService.getRequisitionPrescriptionDocuments(requisitionId).pipe(
      tap((response) => {
        this._prescriptions.next(response.data.results);
      }),
      catchError(() => {
        this.notificationService.error({
          message: TranslateService.localize('nouns.error'),
        });
        return of(null);
      }),
      map(() => null),
    );
  }

  setFilter(filter: Partial<IRequisitionEncounterHistoryFiltersParams>) {
    this.encounterHistoryServerSideItemsProviderService.patchFilters(filter);

    this.loadItems(1).subscribe({
      error: () => null,
    });
  }

  loadItems(page: number, setLoadingAction: boolean = true): Observable<void> {
    if (setLoadingAction) {
      this.setActionInProgress(ESessionLogModuleAction.LoadSessionLogByPage);
    }

    return this.encounterHistoryServerSideItemsProviderService.updateItems(page, this.LOGS_PER_PAGE).pipe(
      catchError(() => {
        this.notificationService.error({
          message: TranslateService.localize('nouns.error'),
        });
        return of(null);
      }),
      tap((log) => {
        if (setLoadingAction) {
          this.clearActionInProgress();
        }
      }),
      map(() => null),
    );
  }

  loadLogDetails(requisitionId: TGuid): Observable<void> {
    return this.requisitionApiProviderService.getWithAnswersById(requisitionId).pipe(
      catchError(() => {
        this.notificationService.error({
          message: TranslateService.localize('nouns.error'),
        });
        return of(null);
      }),
      tap((item) => this._selectedRequisition$.next(item)),
      map(() => null),
    );
  }

  loadLogSummaryNotes(requisitionId: TGuid): Observable<void> {
    return this.summaryNotesApiProviderService.getAllRelatedToRequisition(requisitionId).pipe(
      catchError(() => {
        this.notificationService.error({
          message: TranslateService.localize('nouns.error'),
        });
        return of([]);
      }),
      tap((notes) => this._notes$.next(notes)),
      map(() => null),
    );
  }

  loadLogObservations(requisitionId: TGuid): Observable<void> {
    const patientId =
      this._selectedRequisition$.value?.id === requisitionId ? this._selectedRequisition$.value.patientProfileId : null;

    if (!patientId) {
      this.notificationService.error({
        message: TranslateService.localize('nouns.error'),
      });
      this._selectedLogObservations$.next([]);
      return of(null);
    }

    return this.observationsApiProviderService.getFullObservationsByPatientIdAsHcEmployer(patientId).pipe(
      catchError(() => {
        this.notificationService.error({
          message: TranslateService.localize('nouns.error'),
        });
        return of([]);
      }),
      tap((observations) => this._selectedLogObservations$.next(observations)),
      map(() => null),
    );
  }

  /**
   * We need this method to implement interface
   */
  createNote(note: string): Observable<void> {
    return throwError('Cannot create note from log');
  }
}
