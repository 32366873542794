export function shadowDownload(url: string) {
  const linkElement: HTMLAnchorElement = document.createElement('a');
  linkElement.href = url;
  linkElement.download = '';
  document.body.appendChild(linkElement);

  linkElement.onclick = (event: Event) => {
    event.stopPropagation();
  };

  linkElement.click();
  document.body.removeChild(linkElement);
}
