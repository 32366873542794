import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GoogleAuthProviderService, IGoogleAuthResult } from './google-auth-provider.service';
import { FacebookAuthProviderService, IFacebookAuthResult } from './facebook-auth-provider.service';
import { AppleAuthProviderService, IAppleAuthResult } from './apple-auth-provider.service';

@Injectable()
export class SocialAuthorizationService {
  constructor(
    private googleAuthProviderService: GoogleAuthProviderService,
    private facebookAuthProviderService: FacebookAuthProviderService,
    private appleAuthProviderService: AppleAuthProviderService,
  ) {}

  loginFacebook(): Observable<IFacebookAuthResult> {
    return this.facebookAuthProviderService.signIn();
  }

  loginGoogle(): Observable<IGoogleAuthResult> {
    return this.googleAuthProviderService.signIn();
  }

  loginApple(): Observable<IAppleAuthResult> {
    return this.appleAuthProviderService.signIn();
  }
}
