import { ISocketMessage } from './common';
import { snakeCaseToPascalCase, TGuid } from '@core/helpers';
import { ESocketMessageTypeDTO } from '../consts/socket-message-type';
import { ERequisitionStageStatusDTO, ERequisitionStatusDTO } from '../consts/requisition-consts';
import {
  IRequisitionStage,
  IRequisitionStageDTO,
  RequisitionStageViewModelFactory,
} from '../requisition-stage.view-model';

export interface IRequisitionUpdateEventDTO extends ISocketMessage {
  type: ESocketMessageTypeDTO.RequisitionInformation;
  requisition_id: TGuid;
  requisition_status: ERequisitionStatusDTO;
  active_stage: IRequisitionStageDTO;
}

export interface IRequisitionUpdateEvent {
  requisitionId: TGuid;
  requisitionStatus: ERequisitionStatusDTO;
  activeStage: IRequisitionStage | null;
}

export class RequisitionUpdateEventSocketMessageViewModelFactory {
  static createFromDTO(dto: IRequisitionUpdateEventDTO): IRequisitionUpdateEvent {
    const activeStageDTO: IRequisitionStageDTO = dto.active_stage
      ? { ...dto.active_stage, status: this.mapActiveStageStatus(dto.active_stage.status) }
      : null;

    return {
      requisitionId: dto.requisition_id,
      requisitionStatus: this.mapRequisitionStatus(dto.requisition_status),
      activeStage: activeStageDTO ? RequisitionStageViewModelFactory.createGetViewModel(activeStageDTO) : null,
    };
  }

  /**
   * Ugly hack to handle the difference between snake_case value for socket message and PascalCase for API DTO.
   * @TODO Remove it in next versions when API will provide us all values as snake_case
   * @param status
   * @private
   */
  private static mapActiveStageStatus(status: string): ERequisitionStageStatusDTO {
    return snakeCaseToPascalCase(status) as ERequisitionStageStatusDTO;
  }

  private static mapRequisitionStatus(status: string): ERequisitionStatusDTO {
    return snakeCaseToPascalCase(status) as ERequisitionStatusDTO;
  }
}
