import { Injectable } from '@angular/core';
import { SummaryNotesApiProviderService } from '@project/data-providers';
import { Observable } from 'rxjs';
import { ModalOverlayService } from '@lib/modal';
import {
  CloseRequisitionWithSummaryNoteOverlayComponent,
  ICloseRequisitionWithSummaryNoteOverlayComponentInputParams,
} from './close-requisition-with-summary-note-overlay/close-requisition-with-summary-note-overlay.component';
import { takeUntil } from 'rxjs/operators';
import { TGuid } from '@core/helpers';
import { IRequisition } from '@project/view-models';

@Injectable()
export class CloseRequisitionWithSummaryNoteService {
  constructor(
    private summaryNotesApiProviderService: SummaryNotesApiProviderService,
    private modalOverlayService: ModalOverlayService,
  ) {}

  public hasDoctorNotesForChat(requisitionId: TGuid, doctorId: TGuid): Observable<boolean> {
    return this.summaryNotesApiProviderService.checkExistence({ doctorId, requisitionId });
  }

  public showOverlay(requisition: IRequisition): Observable<void> {
    const ref = this.modalOverlayService.openOverlay<
      CloseRequisitionWithSummaryNoteOverlayComponent,
      ICloseRequisitionWithSummaryNoteOverlayComponentInputParams
    >(CloseRequisitionWithSummaryNoteOverlayComponent, {
      requisition,
    });

    return ref.submit$.pipe(takeUntil(ref.close$));
  }
}
