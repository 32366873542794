import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'lib-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  @Input() url: string;
  @Input() fallbackTheme: 'blue' | 'white' = 'blue';

  @HostBinding('class') get theme(): string {
    return `fallback-theme-${this.fallbackTheme}`;
  }
}
