import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-aside-page-header',
  templateUrl: './aside-page-header.component.html',
  styleUrls: ['./aside-page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsidePageHeaderComponent {
  @HostBinding('attr.force-small') @Input() forceSmall = false;
}
