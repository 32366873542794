import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TabsControlService } from '../tabs-control.service';

@Component({
  selector: 'lib-tabs-router',
  templateUrl: './tabs-router.component.html',
  styleUrls: ['./tabs-router.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TabsControlService],
})
export class TabsRouterComponent {
  @Input() hideBorder: boolean;
  @Input() hideOutlet: boolean;

  public tabs$ = this.tabsControlService.tabs$;

  constructor(private tabsControlService: TabsControlService) {}
}
