import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { TGuid } from '@core/helpers';
import { map, tap } from 'rxjs/operators';
import {
  HealthCenterViewModelFactory,
  IHealthCenter,
  IHealthCenterCreateRequestPayloadDTO,
  IHealthCenterDTO,
} from '@project/view-models';

@Injectable({
  providedIn: 'root',
})
export class HealthCentersApiProviderService {
  constructor(private apiService: ApiService) {}

  getAll(): Observable<IHealthCenter[]> {
    return this.apiService
      .get<IHealthCenterDTO[]>(`${environment.environmentVariables.apiCoreUrl}/HealthCenters`)
      .pipe(map((items) => items.map((item) => HealthCenterViewModelFactory.createFromDTO(item))));
  }

  getById(id: TGuid): Observable<IHealthCenter> {
    return this.apiService
      .get<IHealthCenterDTO>(`${environment.environmentVariables.apiCoreUrl}/HealthCenters/${id}`)
      .pipe(map((item) => HealthCenterViewModelFactory.createFromDTO(item)));
  }

  create(data: IHealthCenterCreateRequestPayloadDTO): Observable<TGuid> {
    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/HealthCenters`, data);
  }

  update(id: TGuid, data: IHealthCenterCreateRequestPayloadDTO): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/HealthCenters/${id}`, data);
  }

  remove(id: TGuid): Observable<void> {
    return this.apiService.delete(`${environment.environmentVariables.apiCoreUrl}/HealthCenters/${id}`);
  }

  uploadLogo(logo: File): Observable<any> {
    const formData: FormData = new FormData();

    formData.append('file', logo);

    return this.apiService.postWithForm<string>(
      `${environment.environmentVariables.apiCoreUrl}/HealthCenters/logo`,
      formData,
    );
  }
}
