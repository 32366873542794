import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';
import { debounce, filter, tap } from 'rxjs/operators';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputBasicDirective } from '../input-basic.directive';

@Component({
  selector: 'lib-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchInputComponent),
      multi: true,
    },
  ],
})
export class SearchInputComponent extends InputBasicDirective<string> implements OnInit, OnDestroy {
  @Input() placeholder: string;
  @Input() debounceTime = 1000;
  @Input() minimalAllowedLengthToSearch = 1;
  @Input() pending = false;

  @Output() dirtySearchStringChange = new EventEmitter<string>();
  @Output() minimalAllowedLengthNotReached = new EventEmitter<boolean>();
  @Output() inputValueChange: EventEmitter<string> = new EventEmitter<string>();

  public inputValue = '';
  @ViewChild('roleName') roleNameElement: ElementRef;
  private propagateValue$ = new Subject<string>();
  private trackValuePropagatingSubscription = Subscription.EMPTY;

  constructor(@Optional() controlContainer: ControlContainer) {
    super(controlContainer);
  }

  ngOnInit() {
    this._value$.next('');

    this.trackPropagatingValue();
    super.ngOnInit();
  }

  change(inputValue: string) {
    this.inputValueChange.emit(inputValue);
    if (inputValue.length === 0 && this._value$.value.length > 0) {
      this.trackValuePropagatingSubscription.unsubscribe();
      this.trackPropagatingValue();

      this.dirtySearchStringChange.emit('');
      this.onInputHandler('');
      this.minimalAllowedLengthNotReached.emit(false);

      return;
    }
    this.propagateValue$.next(inputValue);
  }

  onBlur(e: Event) {
    this.trackValuePropagatingSubscription.unsubscribe();
    if (this.inputValue.length < this.minimalAllowedLengthToSearch) {
      this.inputValue = '';
    }
    this.trackPropagatingValue();
    this.minimalAllowedLengthNotReached.emit(false);

    if (this._value$.value !== this.inputValue) {
      this.onInputHandler(this.inputValue);
    }

    super.onBlur(e);
  }

  ngOnDestroy() {
    this.trackValuePropagatingSubscription.unsubscribe();
  }

  private trackPropagatingValue() {
    this.trackValuePropagatingSubscription = this.propagateValue$
      .pipe(
        tap((value) => this.dirtySearchStringChange.emit(value)),
        tap((value) => this.minimalAllowedLengthNotReached.emit(value.length < this.minimalAllowedLengthToSearch)),
        debounce(() => timer(this.debounceTime)),
        filter((value) => value.length >= this.minimalAllowedLengthToSearch),
      )
      .subscribe((value) => {
        if (this._value$.value !== value) {
          this.onInputHandler(value);
        }
      });
  }

  writeValue(value: string) {
    if (this.inputValue !== value) {
      this.inputValue = value ?? '';
    }
  }
}
