import { Injectable } from '@angular/core';
import { getEnumValues } from '@core/helpers';
import { EDoctorSpecializationDTO, getDoctorSpecializationLocalization } from '@project/view-models';
import { ISelectOption } from '@lib/ui/form-elements';

@Injectable({
  providedIn: 'root',
})
export class DoctorSpecializationDataProvider {
  getSelectOptions() {
    const options = getEnumValues(EDoctorSpecializationDTO).map((specialization) => ({
      value: specialization,
      label: getDoctorSpecializationLocalization(specialization),
    }));

    options.sort((a, b) => a.label.localeCompare(b.label));

    return options;
  }
}
