import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IChatNotificationsSubscriptionDTO } from '@project/view-models';
import { environment } from '@env';
import { ApiService } from '@core/http';

@Injectable({
  providedIn: 'root',
})
export class WebsocketApiProviderService {
  constructor(private apiService: ApiService) {}

  subscribeNotifications(): Observable<IChatNotificationsSubscriptionDTO> {
    return this.apiService.post(`${environment.environmentVariables.apiChatUrl}/nc/subscribe`);
  }
}
