import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { IDataDeletion } from '@project/view-models';
import { Observable } from 'rxjs';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class DataDeletionService {
  private dataDeletionRedirectionUrl = environment.environmentVariables.dataDeletionRedirection;

  constructor(private apiService: ApiService) {}

  saveDataDeletionRequest(data: IDataDeletion): Observable<string> {
    //Solicitar exclusão de dados
    return this.apiService.post<string>(this.dataDeletionRedirectionUrl, data);
  }
}
