import { Injectable } from '@angular/core';
import { environment } from '@env';
import { AbstractFeatureFlagService } from '@core/services';
import { EFeatureFlags, MANUALLY_ENABLED_FEATURE_FLAGS } from '@app/config';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService extends AbstractFeatureFlagService<EFeatureFlags> {
  protected checkFeature(flag: EFeatureFlags): boolean {
    if (MANUALLY_ENABLED_FEATURE_FLAGS.includes(flag)) {
      return true;
    }

    const flags: string[] = environment.environmentVariables.featureFlags || [];
    return flags.includes(flag);
  }
}
