<div
  class="accordion"
  [class.accordion--rounded]="useRoundedCorners"
  [class.accordion--with-bottom-border]="useBottomBorder"
  #dropdown="libDropdown"
  libDropdown
  [activeByDefault]="isOpened"
>
  <div class="accordion__header">
    <ng-content select="[accordionHeader]"></ng-content>
    <button libDropdownToggle class="accordion__toggle-button" [class.active]="dropdown.active$ | async">
      <lib-icon type="arrow-angle-to-top-grey"></lib-icon>
    </button>
  </div>
  <div
    class="accordion__body"
    [class.accordion__body--default]="useDefaultBodyStyle"
    *libDropdownContent
    [@accordionBodyCollapse]
  >
    <ng-content></ng-content>
    <ng-container *ngTemplateOutlet="templates.first"></ng-container>
  </div>
</div>
