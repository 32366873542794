import { DateFormatter, TGuid, TPositiveIntNumber } from '@core/helpers';
import {
  IScheduleRuleWorkingHours,
  IScheduleRuleWorkingHoursCreateData,
  IScheduleRuleWorkingHoursCreateDTO,
  IScheduleRuleWorkingHoursDTO,
  ScheduleRuleWorkingHoursViewModelFactory,
} from './schedule-rule-working-hours.view-model';
import { TranslateService } from '@project/translate';
import { STATIC_DATE_FORMAT, TStaticDateFormat } from '@project/shared';

export interface IScheduleDailyRuleDTO {
  id: TGuid;
  starting_from: TStaticDateFormat | null;
  working_days: TPositiveIntNumber;
  days_off: TPositiveIntNumber;
  working_hours: IScheduleRuleWorkingHoursDTO[];
}

export interface IScheduleDailyRuleCreateDTO {
  starting_from: TStaticDateFormat | null;
  working_days: TPositiveIntNumber;
  days_off: TPositiveIntNumber;
  working_hours: IScheduleRuleWorkingHoursCreateDTO[];
}

export interface IScheduleDailyRule {
  id: TGuid;
  startingFrom: Date | null;
  isStartingFromDateMatter: boolean;
  summaryLabel: string;
  workingDays: TPositiveIntNumber;
  daysOff: TPositiveIntNumber;
  workingHours: IScheduleRuleWorkingHours[];
}

export interface IScheduleDailyRuleCreateData {
  startingFrom: Date | null;
  workingDays: TPositiveIntNumber;
  daysOff: TPositiveIntNumber;
  workingHours: IScheduleRuleWorkingHoursCreateData[];
}

export class ScheduleDailyRuleViewModelFactory {
  static createGetViewModel(dto: IScheduleDailyRuleDTO): IScheduleDailyRule {
    return {
      id: dto.id,
      startingFrom: dto.starting_from
        ? DateFormatter.stringToDate(dto.starting_from, { format: STATIC_DATE_FORMAT })
        : null,
      summaryLabel: this.getSummaryLabel(dto),
      workingDays: dto.working_days,
      daysOff: dto.days_off,
      isStartingFromDateMatter: dto.days_off > 0,
      workingHours: dto.working_hours.map((item) => ScheduleRuleWorkingHoursViewModelFactory.createGetViewModel(item)),
    };
  }

  static createPostDTO(data: IScheduleDailyRuleCreateData): IScheduleDailyRuleCreateDTO {
    return {
      starting_from: data.startingFrom
        ? DateFormatter.dateToString(data.startingFrom, { format: STATIC_DATE_FORMAT })
        : null,
      working_days: data.workingDays,
      days_off: data.daysOff,
      working_hours: data.workingHours.map((item) => ScheduleRuleWorkingHoursViewModelFactory.createPostDTO(item)),
    };
  }

  private static getSummaryLabel(dto: IScheduleDailyRuleDTO): string {
    if (dto.days_off === 0) {
      return TranslateService.localize('working-hours.schedule.daily-summary-label');
    } else {
      return TranslateService.localize(
        'working-hours.schedule.daily-summary-label-with-off#workingWeeksCount#offWeeksCount',
        {
          workingWeeksCount: dto.working_days,
          offWeeksCount: dto.days_off,
        },
      );
    }
  }
}
