import { isPlatformBrowser } from '@angular/common';

function isInternetExplorer(): boolean {
  const ua = (window.navigator && window.navigator.userAgent) || '';
  return ua.indexOf('MSIE') > -1 || !!ua.match(/Trident/);
}

// Microsoft Edge browser before 2020 year
function isMsLegacyEdgeBrowser(): boolean {
  const ua = (window.navigator && window.navigator.userAgent) || '';
  return ua.indexOf('Edge') > -1;
}

export function isCSSFilterSupported(platformId): boolean {
  if (!isPlatformBrowser(platformId)) {
    return false;
  }
  /**
   * Because css filter has some issues in MS Edge too
   */
  return !isInternetExplorer() && !isMsLegacyEdgeBrowser();
}
