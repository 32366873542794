import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { fromEvent, Observable, of } from 'rxjs';
import { debounceTime, map, shareReplay, startWith } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  public resize$: Observable<number> = of(1920);

  constructor(@Inject(PLATFORM_ID) private platformId) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.initResize();
  }

  private initResize() {
    this.resize$ = fromEvent(window, 'resize').pipe(
      debounceTime(10),
      map(() => window.innerWidth),
      startWith(window.innerWidth),
      shareReplay(1),
    );
  }
}
