import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ERequisitionStatusDTO, IRequisitionUpdateEvent } from '@project/view-models';
import { SocketMessagesDataProviderService } from '@project/data-providers';
import { IFeatureService } from './interfaces/feature-serivice.interface';
import { TGuid } from '@core/helpers';
import { IModalComponentRef, ModalOverlayService } from '@lib/modal';
import { ModalSaudeIdMarketplaceComponent } from '../components/modal-saudeId-marketplace/modal-saudeId-marketplace.component';

@Injectable({
  providedIn: 'root',
})
export class RequisitionIsUpdatedEventFeatureService implements IFeatureService {
  private _requisitionUpdated$ = new Subject<IRequisitionUpdateEvent>();
  public requisitionUpdated$ = this._requisitionUpdated$.asObservable();

  private _requisitionClosed$ = new Subject<TGuid>();
  public requisitionClosed$ = this._requisitionClosed$.asObservable();

  private destroyed$ = new Subject();

  private modalSaudeIdMarketplaceRef: IModalComponentRef<ModalSaudeIdMarketplaceComponent>;

  constructor(
    private socketMessagesProviderService: SocketMessagesDataProviderService,
    private modalOverlayService: ModalOverlayService,
  ) {}

  public initialise(): Observable<void> {
    this.listenToSocketMessagesToUpdateRequisition();
    return of(null);
  }

  public destroy(): Observable<void> {
    this.destroyed$.next();
    return of(null);
  }

  private listenToSocketMessagesToUpdateRequisition() {
    this.socketMessagesProviderService.requisitionUpdated$.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
      if (event.requisitionStatus === ERequisitionStatusDTO.Closed) {
        this._requisitionClosed$.next(event.requisitionId);

        const storageAuthInfos = JSON.parse(localStorage.getItem('auth')).properties;
        const haveSaudeIdModalProp = storageAuthInfos.find((prop) => prop.name === 'enableSuggestions');
        if (haveSaudeIdModalProp) {
          this.modalSaudeIdMarketplaceRef = this.modalOverlayService.openOverlay<ModalSaudeIdMarketplaceComponent>(
            ModalSaudeIdMarketplaceComponent,
          );
        }
        return;
      }

      this._requisitionUpdated$.next(event);
    });
  }
}
