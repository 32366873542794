import { Injectable } from '@angular/core';
import {
  ServerSidePagedItemsProviderService,
  IPagedItemsProceedResult,
  PagingApiMapperHelper,
} from '@core/data-providers';
import { IHealthCenterAdmin } from '../view-models/health-center-admin.view-model';
import { HealthCenterAdminsApiProviderService } from './api-providers/_index';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IHCAdminFiltersParams {
  search?: string;
}

@Injectable()
export class HealthCenterAdminsServerSideItemsProviderService extends ServerSidePagedItemsProviderService<
  IHealthCenterAdmin,
  IHCAdminFiltersParams,
  null
> {
  constructor(private hcAdminApiProviderService: HealthCenterAdminsApiProviderService) {
    super();
  }

  protected getDefaultFilters(): IHCAdminFiltersParams {
    return {};
  }

  protected proceedPagedItemsLoading(
    page,
    itemsPerPage,
    filtersState,
    sortingState,
  ): Observable<IPagedItemsProceedResult<IHealthCenterAdmin>> {
    return this.hcAdminApiProviderService
      .getPaged(page, itemsPerPage, this._filtersState$.value)
      .pipe(
        map((result) => PagingApiMapperHelper.mapPagingApiResponseToProceedResult<IHealthCenterAdmin>(page, result)),
      );
  }
}
