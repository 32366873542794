import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  EObservationParameterValueCategoryDTO,
  IObservationBinahParameterValue,
  IObservationDataSnapshot,
} from '@project/view-models';

@Component({
  selector: 'app-observation-binah-data-table-preview',
  templateUrl: './observation-binah-data-table-preview.component.html',
  styleUrls: ['./observation-binah-data-table-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObservationBinahDataTablePreviewComponent {
  @Input() observationData: IObservationDataSnapshot<IObservationBinahParameterValue[]>[] = [];

  public readonly categories = EObservationParameterValueCategoryDTO;

  get binahDataFirstRow(): IObservationDataSnapshot<IObservationBinahParameterValue[]> | null {
    return this.observationData[0];
  }
}
