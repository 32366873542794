<lib-card class="mb-4">
  <lib-card-body>
    <div class="mb-4">
      <b class="fz-m-1">{{ appointment.start | date: "HH:mm" }} - {{ appointment.finish | date: "HH:mm" }}</b>
      <span class="ml-2 text-color-muted">{{ appointment.start | date: "fullDate" }}</span>
    </div>
    <div class="content">
      <app-profile-preview
        *ngIf="patientToPreview"
        [avatarUrl]="patientToPreview.photoUrl"
        [name]="patientToPreview.fullName"
        [summaryInfoList]="patientToPreview.summaryInfo"
        [profileType]="patientToPreview.profileType"
      >
      </app-profile-preview>
      <app-profile-preview
        *ngIf="doctorToPreview"
        [avatarUrl]="doctorToPreview.photoUrl"
        [name]="doctorToPreview.fullName"
        [summaryInfoList]="[doctorToPreview.specializationLabel]"
        [profileType]="doctorToPreview.profileType"
      >
      </app-profile-preview>

      <div>
        <ng-content></ng-content>
      </div>
    </div>
  </lib-card-body>
</lib-card>
