import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from '../custom-overlay-container';
import { MODAL_WINDOW_COMPONENT, ModalWindowRef } from './modal-window-ref';
import { IModalWindowComponent, IModalWindowConfig } from './modal-window-component.types';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';

const DEFAULT_WINDOW_CONFIG: Partial<IModalWindowConfig> = {
  title: '',
  defaultWindowModeSize: { width: 550, height: 425 },
  minWindowModeSize: { width: 300, height: 350 },
  maxWindowModeSize: { width: 700, height: 700 },
};

const DEFAULT_WINDOW_POSITION = { x: 100, y: 0 };
type DragPosition = CdkDrag['freeDragPosition'];

@Component({
  selector: 'lib-modal-window',
  templateUrl: './modal-window.component.html',
  styleUrls: ['./modal-window.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ModalWindowRef,
    {
      provide: MODAL_WINDOW_COMPONENT,
      useExisting: forwardRef(() => ModalWindowComponent),
    },
  ],
  animations: [
    trigger('modalAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
      transition(':leave', [animate(150, style({ opacity: 0 }))]),
    ]),
  ],
})
export class ModalWindowComponent implements OnInit, IModalWindowComponent {
  @Input() config: IModalWindowConfig;

  @Output() readonly close$ = new EventEmitter();

  @ViewChild('window', { static: true, read: ElementRef }) private windowElementRef: ElementRef<HTMLDivElement>;
  @ViewChild('window', { static: true, read: CdkDrag }) private windowCdkDrag: CdkDrag;
  @ViewChild('viewContainer', { static: true, read: ViewContainerRef }) private _viewContainer: ViewContainerRef;

  @HostBinding('class.focused') public focused = true;
  public focused$ = new BehaviorSubject<boolean>(true);

  @HostBinding('class.dragging') public dragging = false;
  @HostBinding('@modalAnimation') private readonly useAnimation = true;

  public readonly fullScreenModeDragPosition: DragPosition = { x: 0, y: 0 };
  public savedDragPosition: DragPosition = DEFAULT_WINDOW_POSITION;

  private _isFullscreenMode$ = new BehaviorSubject<boolean>(true);
  public isFullscreenMode$ = this._isFullscreenMode$.asObservable();

  private destroy$ = new Subject<boolean>();

  constructor(
    public componentElementRef: ElementRef<HTMLElement>,
    @Inject(OverlayContainer) private overlayContainer: CustomOverlayContainer,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  get isFullscreenMode(): boolean {
    return this._isFullscreenMode$.value;
  }

  get viewContainerRef(): ViewContainerRef {
    return this._viewContainer;
  }

  @HostListener('document:click', ['$event.target'])
  trackFocus(targetElement: HTMLElement) {
    if (this.focused) {
      return;
    }

    const isClickByWindow = this.windowElementRef.nativeElement.contains(targetElement);
    if (isClickByWindow) {
      this.setFocused();
    }
  }

  public closeRequestFn: () => void = () => this.close$.emit();

  ngOnInit() {
    this.overlayContainer
      .isOverlayFocused(this.windowElementRef.nativeElement)
      .pipe(takeUntil(this.destroy$))
      .subscribe((isFocused) => {
        this.focused = isFocused;
        this.focused$.next(isFocused);
        this.changeDetectorRef.markForCheck();
      });
    this.setFocused();

    this.config = {
      ...DEFAULT_WINDOW_CONFIG,
      ...this.config,
    };
  }

  registerCloseRequestFn(closeRequestFn: () => void): void {
    this.closeRequestFn = closeRequestFn;
  }

  onDragStarted() {
    this.dragging = true;
  }

  onDragEnded() {
    this.dragging = false;
  }

  updateConfig(partialConfig: Partial<IModalWindowConfig>) {
    this.config = {
      ...this.config,
      ...partialConfig,
    };
    this.changeDetectorRef.markForCheck();
  }

  toggleWindowMode() {
    if (this.config.disableChangeWindowMode) {
      return;
    }

    this.isFullscreenMode ? this.enterWindowMode() : this.enterFullscreenMode();
    this.changeDetectorRef.markForCheck();
  }

  setFocused() {
    this.overlayContainer.focusOverlay(this.windowElementRef.nativeElement);
  }

  private enterWindowMode() {
    this.overlayContainer.unfreezeBody();
    this._isFullscreenMode$.next(false);
  }

  private enterFullscreenMode() {
    this.overlayContainer.freezeBody();
    this.savedDragPosition = this.windowCdkDrag.getFreeDragPosition();
    this._isFullscreenMode$.next(true);
  }
}
