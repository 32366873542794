import { getEnumValues, toFixedIfDecimal } from '@core/helpers';
import {
  BINAH_NO_VALUE_LABEL_PLACEHOLDER,
  BINAH_PARAMETER_MEASUREMENT_UNIT,
  EBinahParamDataKeyDTO,
  EMeasurementUnit,
  getBinahParameterEnumValueLabel,
  getObservationBinahParameterLocalization,
  getObservationBinahParameterMeasurementUnitLocalization,
  TBinahParameterRawValueDTO,
  TBinalDataDTO,
} from './consts/observation-binah-consts';
import { EObservationParameterValueCategoryDTO } from './consts/observation-consts';

export interface IObservationBinahParameterValue {
  label: string;
  parameter: EBinahParamDataKeyDTO;
  rawValue: TBinahParameterRawValueDTO;
  measurementUnit: EMeasurementUnit;
  measurementUnitLabel: string;
  measurementUnitLabelShort: string;
  valueToDisplay: string;
  valueCategory: EObservationParameterValueCategoryDTO;
}

export class ObservationBinahParameterValueViewModelFactory {
  static map(
    dto: TBinalDataDTO,
    binahKeys: EBinahParamDataKeyDTO[] = getEnumValues(EBinahParamDataKeyDTO),
  ): IObservationBinahParameterValue[] {
    return binahKeys.map((parameter) => {
      const normalizedRawValue: TBinahParameterRawValueDTO = this.normalizeRawValue(dto[parameter]?.value);
      const measurementUnit = BINAH_PARAMETER_MEASUREMENT_UNIT[parameter];

      return {
        label: getObservationBinahParameterLocalization(parameter),
        parameter,
        rawValue: normalizedRawValue,
        measurementUnit: null,
        measurementUnitLabel: getObservationBinahParameterMeasurementUnitLocalization({
          unit: measurementUnit,
          useFull: true,
        }),
        measurementUnitLabelShort: getObservationBinahParameterMeasurementUnitLocalization({
          unit: measurementUnit,
        }),
        valueToDisplay: this.getParameterValueToDisplay(parameter, normalizedRawValue),
        valueCategory: dto[parameter]?.status,
      };
    });
  }

  private static getParameterValueToDisplay(
    parameter: EBinahParamDataKeyDTO,
    rawValue: TBinahParameterRawValueDTO | undefined,
  ): string {
    const enumValueToDisplay = getBinahParameterEnumValueLabel(parameter, rawValue);

    if (enumValueToDisplay) {
      return enumValueToDisplay;
    }

    if (typeof rawValue === 'number') {
      return toFixedIfDecimal(rawValue, 2).toString();
    }

    if (this.isEmptyValue(rawValue)) {
      return BINAH_NO_VALUE_LABEL_PLACEHOLDER;
    }

    return rawValue;
  }

  private static normalizeRawValue(rawValue: TBinahParameterRawValueDTO | undefined): TBinahParameterRawValueDTO {
    if (typeof rawValue === 'string') {
      const parsedNumber = parseFloat(rawValue);

      if (!isNaN(parsedNumber)) {
        return parsedNumber;
      }
    }

    return rawValue;
  }

  private static isEmptyValue(rawValue: TBinahParameterRawValueDTO): boolean {
    return rawValue === undefined || rawValue === null || rawValue === '';
  }
}
