<app-chat-messages-list class="messages-list"></app-chat-messages-list>
<app-chat-form *ngIf="!isReadOnly" class="form"></app-chat-form>

<div *ngIf="state$ | async as state" class="loader" [class.offline]="state === states.Offline" [@loader]>
  <ng-container [ngSwitch]="state">
    <ng-container *ngSwitchCase="states.Offline">
      {{ "phrases.you-are-offline-message" | translate }}
    </ng-container>
    <ng-container *ngSwitchCase="states.Loading"> {{ "nouns.loading" | translate }}... </ng-container>
  </ng-container>
</div>
