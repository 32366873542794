import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TButtonAppearance, TButtonSize } from '../../button/public-api';

@Component({
  selector: 'lib-button-dropdown',
  templateUrl: './button-dropdown.component.html',
  styleUrls: ['./button-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonDropdownComponent {
  @Input() label: string;
  @Input() hideOnItemClick = true;
  @Input() appearance: TButtonAppearance = 'primary';
  @Input() size: TButtonSize = 'm';
  @Input() disabled = false;
  @Input() isPending = false;
}
