import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { map } from 'rxjs/operators';
import {
  IProfileTag,
  IProfileTagCreateData,
  IProfileTagDTO,
  ProfileTagViewModelFactory,
  EProfileTypeDTO,
} from '@project/view-models';
import { TGuid } from '@core/helpers';

@Injectable({
  providedIn: 'root',
})
export class ProfilesTagsApiProviderService {
  constructor(private apiService: ApiService) {}

  getAll(profileType: EProfileTypeDTO): Observable<IProfileTag[]> {
    return this.apiService
      .get<IProfileTagDTO[]>(`${environment.environmentVariables.apiCoreUrl}/profiles/tags?profileType=${profileType}`)
      .pipe(map((tagsDto) => tagsDto.map((dto) => ProfileTagViewModelFactory.createGetViewModel(dto))));
  }

  remove(id: TGuid): Observable<void> {
    return this.apiService.delete(`${environment.environmentVariables.apiCoreUrl}/profiles/tags/${id}`);
  }

  create(data: IProfileTagCreateData): Observable<IProfileTag> {
    const dto = ProfileTagViewModelFactory.createPostDTO(data);
    return this.apiService
      .post<IProfileTagDTO>(`${environment.environmentVariables.apiCoreUrl}/profiles/tags`, dto)
      .pipe(map((resultDto) => ProfileTagViewModelFactory.createGetViewModel(resultDto)));
  }

  update(id: TGuid, data: IProfileTagCreateData): Observable<void> {
    const dto = ProfileTagViewModelFactory.createPostDTO(data);
    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/profiles/tags/${id}`, dto);
  }
}
