import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { DropdownService } from './dropdown.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[libDropdownContent]',
})
export class DropdownContentDirective implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private service: DropdownService,
  ) {}

  ngOnInit() {
    const sub = this.service.active$.subscribe((active: boolean) => {
      if (active) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });

    this.subs.push(sub);
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }
}
