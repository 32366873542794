import { Injectable } from '@angular/core';
import { filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { merge, Observable, of } from 'rxjs';

import { executeSideObservableOperator, TGuid } from '@core/helpers';
import { IChatMessage } from '@project/view-models';
import { ChatsMessagesStore } from './chat/chats-messages.store';
import { SocketMessagesDataProviderService } from './socket-messages.data-provider.service';
import { ChatApiProviderService } from './api-providers/chat-api-provider.service';

@Injectable({
  providedIn: 'root',
})
export class ChatDataProvider {
  public isOffline$ = this.socketMessagesProviderService.isOffline$;
  private chatsMessagesStore = new ChatsMessagesStore();

  private readonly CHAT_MESSAGES_PAGE_SIZE = 100;

  constructor(
    private socketMessagesProviderService: SocketMessagesDataProviderService,
    private chatApiProviderService: ChatApiProviderService,
  ) {}

  public subscribeToChatMessages$(chatId: TGuid): Observable<IChatMessage[]> {
    const handleChatUpdates$ = merge(this.handleNewMessageEvent(chatId), this.handleSocketReconnected(chatId));

    return (this.chatsMessagesStore.areChatMessagesLoaded(chatId) ? of(null) : this.updateChat(chatId)).pipe(
      switchMap(() => this.chatsMessagesStore.getChatMessages$(chatId)),
      executeSideObservableOperator(handleChatUpdates$),
      finalize(() => this.chatsMessagesStore.clearChatMessagesForChat(chatId)),
    );
  }

  public subscribeToChatLoading(chatId: TGuid): Observable<boolean> {
    return this.chatsMessagesStore.getChatMessagesLoadingState$(chatId);
  }

  public subscribeToChatMessagesFullyLoaded(chatId: TGuid): Observable<boolean> {
    return this.chatsMessagesStore.getChatMessagesFullyLoadedState$(chatId);
  }

  public loadPreviousMessagesPage(chatId: TGuid): Observable<void> {
    const oldestMessage = this.chatsMessagesStore.getOldestMessageInChat(chatId);

    this.chatsMessagesStore.setChatMessagesLoadingState(chatId, true);
    return this.loadChatMessagesPage(chatId, oldestMessage.date).pipe(
      tap(() => this.chatsMessagesStore.setChatMessagesLoadingState(chatId, false)),
    );
  }

  private loadChatMessagesPage(chatId: TGuid, from?: Date): Observable<void> {
    return this.chatApiProviderService.getChatMessagesPage(chatId, this.CHAT_MESSAGES_PAGE_SIZE, from).pipe(
      tap((messages) => this.chatsMessagesStore.addChatMessages(chatId, messages)),
      tap((messages) => {
        if (messages.length < this.CHAT_MESSAGES_PAGE_SIZE) {
          this.chatsMessagesStore.setChatMessagesFullyLoadedState(chatId, true);
        }
      }),
      map(() => null),
    );
  }

  private updateChat(chatId: TGuid): Observable<void> {
    this.chatsMessagesStore.setChatMessagesLoadingState(chatId, true);

    return this.loadChatMessagesPage(chatId).pipe(
      tap(() => this.chatsMessagesStore.setChatMessagesLoadingState(chatId, false)),
      map(() => null),
    );
  }

  private handleNewMessageEvent(chatId: TGuid): Observable<void> {
    return this.socketMessagesProviderService.chatMessage$.pipe(
      tap((message: IChatMessage) => console.log({ message })),
      filter((message) => message.chatId === chatId),
      tap((message: IChatMessage) => this.chatsMessagesStore.addChatMessages(message.chatId, [message])),
      map(() => null),
    );
  }

  private handleSocketReconnected(chatId: TGuid): Observable<void> {
    return this.socketMessagesProviderService.reconnected$.pipe(
      switchMap(() => this.updateChat(chatId)),
      map(() => null),
    );
  }
}
