import { LoadChildrenCallback } from '@angular/router';
import { EUserPermission } from '@project/shared';
import { TranslateService } from '@project/translate';
import { ESidebarIconType } from './icons';
import { EFeatureFlags } from './feature-flags';
import { RoutesBuilderService } from './routes-builder.service';

export interface ISiteModule {
  rootRouteBase: string;
  rootFullRoute: string;
  sidebarLabelCreator: () => string;
  browserTabTitleCreator: () => string;
  lazyModule: LoadChildrenCallback;
  sidebarIcon: ESidebarIconType;
  permissions?: EUserPermission[];
  permissionCheckFailRedirectUrl?: string;
  featureFlagsToCheck?: EFeatureFlags[];
}

export const SITE_USER_MODULES: ISiteModule[] = [
  {
    rootRouteBase: RoutesBuilderService.HEALTH_CENTERS_LIST.base,
    rootFullRoute: RoutesBuilderService.HEALTH_CENTERS_LIST.root(),
    sidebarIcon: ESidebarIconType.MedicalSign,
    lazyModule: () => import('../lazy-modules/health-centers/health-centers.module').then((m) => m.HealthCentersModule),
    browserTabTitleCreator: () => TranslateService.localize('sidebar.menu-labels.health-centers'),
    sidebarLabelCreator: () => TranslateService.localize('sidebar.menu-labels.health-centers'),
    permissions: [EUserPermission.manageHealthCenters],
  },
  {
    rootRouteBase: RoutesBuilderService.DOCTORS_LIST.base,
    rootFullRoute: RoutesBuilderService.DOCTORS_LIST.root(),
    sidebarIcon: ESidebarIconType.People,
    lazyModule: () => import('../lazy-modules/doctors/doctors.module').then((m) => m.DoctorsModule),
    browserTabTitleCreator: () => TranslateService.localize('sidebar.menu-labels.doctors'),
    sidebarLabelCreator: () => TranslateService.localize('sidebar.menu-labels.doctors'),
    permissions: [EUserPermission.manageDoctors],
  },
  {
    rootRouteBase: RoutesBuilderService.PATIENTS_LIST.base,
    rootFullRoute: RoutesBuilderService.PATIENTS_LIST.root(),
    sidebarIcon: ESidebarIconType.People,
    lazyModule: () => import('../lazy-modules/patients/patients.module').then((m) => m.PatientsModule),
    browserTabTitleCreator: () => TranslateService.localize('sidebar.menu-labels.patients'),
    sidebarLabelCreator: () => TranslateService.localize('sidebar.menu-labels.patients'),
    permissions: [EUserPermission.managePatients],
  },
  {
    rootRouteBase: RoutesBuilderService.ADMINISTRATORS_LIST.base,
    rootFullRoute: RoutesBuilderService.ADMINISTRATORS_LIST.root(),
    sidebarIcon: ESidebarIconType.People,
    lazyModule: () =>
      import('../lazy-modules/administrators/administrators.module').then((m) => m.AdministratorsModule),
    browserTabTitleCreator: () => TranslateService.localize('sidebar.menu-labels.administrators'),
    sidebarLabelCreator: () => TranslateService.localize('sidebar.menu-labels.administrators'),
    permissions: [EUserPermission.manageGeneralAdmins],
  },
  {
    rootRouteBase: RoutesBuilderService.HEALTH_CENTERS_ADMINISTRATORS_LIST.base,
    rootFullRoute: RoutesBuilderService.HEALTH_CENTERS_ADMINISTRATORS_LIST.root(),
    sidebarIcon: ESidebarIconType.People,
    lazyModule: () =>
      import('../lazy-modules/health-centers-admins/health-centers-admins.module').then(
        (m) => m.HealthCentersAdminsModule,
      ),
    browserTabTitleCreator: () => TranslateService.localize('sidebar.menu-labels.health-center-administrators'),
    sidebarLabelCreator: () => TranslateService.localize('sidebar.menu-labels.health-center-administrators'),
    permissions: [EUserPermission.manageHealthCenterAdmins],
  },
  {
    rootRouteBase: RoutesBuilderService.OBSERVATIONS.base,
    rootFullRoute: RoutesBuilderService.OBSERVATIONS.root(),
    sidebarIcon: ESidebarIconType.Eye,
    lazyModule: () => import('../lazy-modules/observations/observations.module').then((m) => m.ObservationsModule),
    browserTabTitleCreator: () => TranslateService.localize('sidebar.menu-labels.observations'),
    sidebarLabelCreator: () => TranslateService.localize('sidebar.menu-labels.observations'),
    permissions: [EUserPermission.viewObservations],
    featureFlagsToCheck: [EFeatureFlags.observations],
  },
  {
    rootRouteBase: RoutesBuilderService.RECEPTIONISTS_LIST.base,
    rootFullRoute: RoutesBuilderService.RECEPTIONISTS_LIST.root(),
    sidebarIcon: ESidebarIconType.People,
    lazyModule: () => import('../lazy-modules/receptionists/receptionists.module').then((m) => m.ReceptionistsModule),
    browserTabTitleCreator: () => TranslateService.localize('sidebar.menu-labels.receptionists'),
    sidebarLabelCreator: () => TranslateService.localize('sidebar.menu-labels.receptionists'),
    permissions: [EUserPermission.manageReceptionists],
  },
  {
    rootRouteBase: RoutesBuilderService.WORKING_HOURS.base,
    rootFullRoute: RoutesBuilderService.WORKING_HOURS.root(),
    sidebarIcon: ESidebarIconType.Calendar,
    lazyModule: () => import('../lazy-modules/working-hours/working-hours.module').then((m) => m.WorkingHoursModule),
    browserTabTitleCreator: () => TranslateService.localize('sidebar.menu-labels.working-hours'),
    sidebarLabelCreator: () => TranslateService.localize('sidebar.menu-labels.working-hours'),
    permissions: [EUserPermission.manageHealthCenterWorkingHours],
  },
  {
    rootRouteBase: RoutesBuilderService.SESSION_BY_RECEPTIONIST.base,
    rootFullRoute: RoutesBuilderService.SESSION_BY_RECEPTIONIST.root(),
    sidebarIcon: ESidebarIconType.Document,
    lazyModule: () =>
      import('../lazy-modules/sessions/sessions-receptionist/receptionist-sessions.module').then(
        (m) => m.ReceptionistSessionsModule,
      ),
    browserTabTitleCreator: () => TranslateService.localize('sidebar.menu-labels.sessions'),
    sidebarLabelCreator: () => TranslateService.localize('sidebar.menu-labels.sessions'),
    permissions: [EUserPermission.haveReceptionistsSession],
  },
  {
    rootRouteBase: RoutesBuilderService.MY_OBSERVATIONS_AS_PATIENT.base,
    rootFullRoute: RoutesBuilderService.MY_OBSERVATIONS_AS_PATIENT.root(),
    sidebarIcon: ESidebarIconType.PersonCard,
    lazyModule: () =>
      import('../lazy-modules/patient-observations/patient-observations.module').then(
        (m) => m.PatientObservationsModule,
      ),
    browserTabTitleCreator: () => TranslateService.localize('sidebar.menu-labels.my-observations'),
    sidebarLabelCreator: () => TranslateService.localize('sidebar.menu-labels.my-observations'),
    permissions: [EUserPermission.viewOwnObservationsAsPatient],
  },
  {
    rootRouteBase: RoutesBuilderService.ENCOUNTER_HISTORY_AS_PATIENT.base,
    rootFullRoute: RoutesBuilderService.ENCOUNTER_HISTORY_AS_PATIENT.root(),
    sidebarIcon: ESidebarIconType.Document,
    lazyModule: () =>
      import('../lazy-modules/patient-encounter-history/patient-encounter-history.module').then(
        (m) => m.PatientEncounterHistoryModule,
      ),
    browserTabTitleCreator: () => TranslateService.localize('sidebar.menu-labels.encounter-history'),
    sidebarLabelCreator: () => TranslateService.localize('sidebar.menu-labels.encounter-history'),
    permissions: [EUserPermission.havePatientSession],
  },
  {
    rootRouteBase: RoutesBuilderService.SESSION_LOGS.base,
    rootFullRoute: RoutesBuilderService.SESSION_LOGS.root(),
    sidebarIcon: ESidebarIconType.PersonCard,
    lazyModule: () => import('../lazy-modules/sessions-logs/sessions-logs.module').then((m) => m.SessionsLogsModule),
    browserTabTitleCreator: () => TranslateService.localize('sidebar.menu-labels.sessions-logs'),
    sidebarLabelCreator: () => TranslateService.localize('sidebar.menu-labels.sessions-logs'),
    permissions: [EUserPermission.manageSessionsLogs],
  },
  {
    rootRouteBase: RoutesBuilderService.LOGINS_LOGS.base,
    rootFullRoute: RoutesBuilderService.LOGINS_LOGS.root(),
    sidebarIcon: ESidebarIconType.PersonCard,
    lazyModule: () => import('../lazy-modules/login-logs/login-logs.module').then((m) => m.LoginLogsModule),
    browserTabTitleCreator: () => TranslateService.localize('sidebar.menu-labels.login-logs'),
    sidebarLabelCreator: () => TranslateService.localize('sidebar.menu-labels.login-logs'),
    permissions: [EUserPermission.manageLoginLogs],
  },
  {
    rootRouteBase: RoutesBuilderService.SENDGRID.base,
    rootFullRoute: RoutesBuilderService.SENDGRID.root(),
    sidebarIcon: ESidebarIconType.Dashboard,
    lazyModule: () => import('../lazy-modules/sendgrid/sendgrid.module').then((m) => m.SendGridModule),
    browserTabTitleCreator: () => TranslateService.localize('sidebar.menu-labels.sendgrid'),
    sidebarLabelCreator: () => TranslateService.localize('sidebar.menu-labels.sendgrid'),
    permissions: [EUserPermission.manageSendgrid],
  },
  {
    rootRouteBase: RoutesBuilderService.ENCOUNTER_HISTORY_AS_DOCTOR.base,
    rootFullRoute: RoutesBuilderService.ENCOUNTER_HISTORY_AS_DOCTOR.root(),
    sidebarIcon: ESidebarIconType.Document,
    lazyModule: () =>
      import('../lazy-modules/doctor-encounter-history/doctor-encounter-history.module').then(
        (m) => m.DoctorEncounterHistoryModule,
      ),
    browserTabTitleCreator: () => TranslateService.localize('sidebar.menu-labels.encounter-history'),
    sidebarLabelCreator: () => TranslateService.localize('sidebar.menu-labels.encounter-history'),
    permissions: [EUserPermission.haveDoctorSession],
  },
  {
    rootRouteBase: RoutesBuilderService.ENCOUNTER_HISTORY_AS_RECEPCIONIST.base,
    rootFullRoute: RoutesBuilderService.ENCOUNTER_HISTORY_AS_RECEPCIONIST.root(),
    sidebarIcon: ESidebarIconType.Document,
    lazyModule: () =>
      import('../lazy-modules/recepcionist-encounter-history/recepcionist-encounter-history.module').then(
        (m) => m.RecepcionistEncounterHistoryModule,
      ),
    browserTabTitleCreator: () => TranslateService.localize('sidebar.menu-labels.encounter-history'),
    sidebarLabelCreator: () => TranslateService.localize('sidebar.menu-labels.encounter-history'),
    permissions: [EUserPermission.haveReceptionistsSession],
  },
];

export const SITE_USER_SETTINGS_MODULES: ISiteModule[] = [
  {
    rootRouteBase: RoutesBuilderService.SETTINGS.base,
    rootFullRoute: RoutesBuilderService.SETTINGS.root(),
    sidebarIcon: ESidebarIconType.Settings,
    lazyModule: () => import('../lazy-modules/settings/settings.module').then((m) => m.SettingsModule),
    browserTabTitleCreator: () => TranslateService.localize('sidebar.menu-labels.main-settings'),
    sidebarLabelCreator: () => TranslateService.localize('sidebar.menu-labels.main-settings'),
  },
  {
    rootRouteBase: RoutesBuilderService.HEALTH_CENTER_SETTINGS.base,
    rootFullRoute: RoutesBuilderService.HEALTH_CENTER_SETTINGS.root(),
    sidebarIcon: ESidebarIconType.MedicalSign,
    lazyModule: () => import('../lazy-modules/hc-settings/hc-settings.module').then((m) => m.HcSettingsModule),
    browserTabTitleCreator: () => TranslateService.localize('sidebar.menu-labels.hc-settings'),
    sidebarLabelCreator: () => TranslateService.localize('sidebar.menu-labels.hc-settings'),
    permissions: [EUserPermission.manageSelfHealthCenterSettings],
  },
];
