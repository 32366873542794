import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { TGuid } from '@core/helpers';
import { map } from 'rxjs/operators';
import { GeneralAdminViewModelFactory, IGeneralAdmin, IGeneralAdminDTO } from '@project/view-models';

@Injectable({
  providedIn: 'root',
})
export class GeneralAdminsApiProviderService {
  constructor(private apiService: ApiService) {}

  getAll(): Observable<IGeneralAdmin[]> {
    return this.apiService
      .get<IGeneralAdminDTO[]>(`${environment.environmentVariables.apiCoreUrl}/admins/generals`)
      .pipe(map((admins) => admins.map((admin) => GeneralAdminViewModelFactory.createFromDTO(admin))));
  }

  getById(id: TGuid): Observable<IGeneralAdmin> {
    return this.apiService
      .get<IGeneralAdminDTO>(`${environment.environmentVariables.apiCoreUrl}/admins/generals/${id}`)
      .pipe(map((admin) => GeneralAdminViewModelFactory.createFromDTO(admin)));
  }

  remove(id: TGuid): Observable<void> {
    return this.apiService.delete(`${environment.environmentVariables.apiAuthUrl}/profile/admin/${id}/delete`);
  }
}
