import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import {
  EObservationMethodDTO,
  IObservation,
  IObservationDataSnapshot,
  IObservationDataSnapshotDTO,
  IObservationDTO,
  IObservationFull,
  ObservationDataSnapshotViewModelFactory,
  ObservationViewModelFactory,
} from '@project/view-models';
import { environment } from '@env';
import { map, switchMap } from 'rxjs/operators';
import { sortByDecrementFn, TGuid } from '@core/helpers';

@Injectable({
  providedIn: 'root',
})
export class PatientObservationsApiProviderService {
  constructor(private apiService: ApiService) {}

  getActive(): Observable<IObservation[]> {
    return this.apiService
      .get<IObservationDTO[]>(`${environment.environmentVariables.apiCoreUrl}/patient/observations/active`)
      .pipe(map((observations) => observations.map((item) => ObservationViewModelFactory.createFromDTO(item))));
  }

  getArchive(): Observable<IObservation[]> {
    return this.apiService
      .get<IObservationDTO[]>(`${environment.environmentVariables.apiCoreUrl}/patient/observations/archive`)
      .pipe(map((observations) => observations.map((item) => ObservationViewModelFactory.createFromDTO(item))));
  }

  getById(id: TGuid): Observable<IObservation> {
    return this.apiService
      .get<IObservationDTO>(`${environment.environmentVariables.apiCoreUrl}/patient/observations/${id}`)
      .pipe(map((observationDto) => ObservationViewModelFactory.createFromDTO(observationDto)));
  }

  getFullById(id: TGuid): Observable<IObservationFull> {
    return this.getById(id).pipe(
      switchMap((observation) =>
        this.getObservationDataSnapshots(observation.id, observation.method).pipe(
          map((data) => ObservationViewModelFactory.createFull(observation, data)),
        ),
      ),
    );
  }

  private getObservationDataSnapshots(
    observationId: TGuid,
    method: EObservationMethodDTO,
  ): Observable<IObservationDataSnapshot[]> {
    return this.apiService
      .get<IObservationDataSnapshotDTO[]>(
        `${environment.environmentVariables.apiCoreUrl}/patient/observations/${observationId}/data`,
      )
      .pipe(
        map((dtoItems) => dtoItems.map((dto) => ObservationDataSnapshotViewModelFactory.createFromDTO(dto, method))),
        map((items) => items.sort((a, b) => sortByDecrementFn(a.createdDate, b.createdDate))),
      );
  }
}
