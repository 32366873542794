import { ERequisitionStageStatusDTO, ERequisitionStageTypeDTO } from './consts/requisition-consts';
import { DateFormatter, TDateISOString } from '@core/helpers';

export interface IRequisitionStageDTO {
  status: ERequisitionStageStatusDTO;
  type: ERequisitionStageTypeDTO;
  expiration_date: TDateISOString | null;
}

export interface IRequisitionStage {
  status: ERequisitionStageStatusDTO;
  type: ERequisitionStageTypeDTO;
  expirationDate: Date;
}

export class RequisitionStageViewModelFactory {
  static createGetViewModel(dto: IRequisitionStageDTO): IRequisitionStage {
    return {
      status: dto.status,
      type: dto.type,
      expirationDate: DateFormatter.stringToDate(dto.expiration_date),
    };
  }
}
