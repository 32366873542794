import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoCallManagerService } from './video-call-manager.service';
import { AskForMediaDevicesOverlayComponent } from './ask-for-media-devices-overlay/ask-for-media-devices-overlay.component';
import { MediaDevicesRequiresMessageOverlayComponent } from './media-devices-requires-message-overlay/media-devices-requires-message-overlay.component';
import { ModalModule } from '@lib/modal';
import { IconsModule } from '@lib/icons';
import { ButtonModule } from '@lib/ui';
import { VideoCallsOverlayComponent } from './video-calls-overlay/video-calls-overlay.component';
import { ParticipantVideoComponent } from './participant-video/participant-video.component';
import { TranslateModule } from '@project/translate';
import { AvatarModule } from '@modules/avatar';
import { RatingModule } from 'src/app/project/components/modal-rating/modal-rating.module';
import { ModalRatingService } from 'src/app/project/components/modal-rating/modal-rating.service';
import { ChatRoomModule } from '@modules';
import { VideoconferenceComponent } from '../../../lazy-modules/videoconference/videoconference.component';
import { VideoconferenceImpl } from '../../../lazy-modules/videoconference/videoconference.impl';
import { VideoconferenceFactoryProvider } from '../../../lazy-modules/videoconference/providers';

@NgModule({
  declarations: [
    AskForMediaDevicesOverlayComponent,
    MediaDevicesRequiresMessageOverlayComponent,
    VideoCallsOverlayComponent,
    ParticipantVideoComponent,
    VideoconferenceComponent,
  ],
  imports: [
    CommonModule,
    ModalModule,
    IconsModule,
    ButtonModule,
    AvatarModule,
    TranslateModule,
    AvatarModule,
    RatingModule,
    ChatRoomModule,
  ],
})
export class VideoCallModule {
  static forRoot(): ModuleWithProviders<VideoCallModule> {
    return {
      ngModule: VideoCallModule,
      providers: [VideoCallManagerService, ModalRatingService, VideoconferenceImpl, VideoconferenceFactoryProvider],
    };
  }
}
