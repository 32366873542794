import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsidePageHeaderComponent } from './aside-page-header/aside-page-header.component';
import { AvatarWithContentComponent } from './avatar-with-content/avatar-with-content.component';
import { StatsPageContainerComponent } from './stats-page-container/stats-page-container.component';
import { AsidePageSectionComponent } from './aside-page-section/aside-page-section.component';
import { ListPageLayoutHeaderComponent } from './list-page-layout-header/list-page-layout-header.component';
import { BulletComponent } from './bullet/bullet.component';
import { AsidePageFooterComponent } from './aside-page-footer/aside-page-footer.component';
import { AvatarModule } from '../avatar/public-api';

@NgModule({
  declarations: [
    AsidePageHeaderComponent,
    AvatarWithContentComponent,
    StatsPageContainerComponent,
    AsidePageSectionComponent,
    ListPageLayoutHeaderComponent,
    BulletComponent,
    AsidePageFooterComponent,
  ],
  exports: [
    AsidePageHeaderComponent,
    AvatarWithContentComponent,
    StatsPageContainerComponent,
    AsidePageSectionComponent,
    ListPageLayoutHeaderComponent,
    BulletComponent,
    AsidePageFooterComponent,
  ],
  imports: [CommonModule, AvatarModule, AvatarModule],
})
export class LayoutElementsModule {}
