export type TTimeSlotSizeMinutes = number;

export enum EScheduleRuleTypeDTO {
  Weeks = 'Weeks',
  Days = 'Days',
}

export enum EScheduleDayDTO {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}

export enum EScheduleStatusDTO {
  Active = 'Active',
  Future = 'Future',
  Past = 'Past',
}

// TODO Add translations
export function getScheduleRuleTypeLocalizedLabel(ruleType: EScheduleRuleTypeDTO): string | undefined {
  const dictionary: Record<EScheduleRuleTypeDTO, () => string> = {
    [EScheduleRuleTypeDTO.Weeks]: () => 'Weeks',
    [EScheduleRuleTypeDTO.Days]: () => 'Days',
  };
  return dictionary[ruleType]?.();
}

// TODO Add translations
export function getDayLocalizedLabel(params: { day: EScheduleDayDTO; useFull?: boolean }): string | undefined {
  const dictionaryLong: Record<EScheduleDayDTO, () => string> = {
    [EScheduleDayDTO.Sunday]: () => 'Sunday',
    [EScheduleDayDTO.Monday]: () => 'Monday',
    [EScheduleDayDTO.Tuesday]: () => 'Tuesday',
    [EScheduleDayDTO.Wednesday]: () => 'Wednesday',
    [EScheduleDayDTO.Thursday]: () => 'Thursday',
    [EScheduleDayDTO.Friday]: () => 'Friday',
    [EScheduleDayDTO.Saturday]: () => 'Saturday',
  };

  const dictionaryShort: Record<EScheduleDayDTO, () => string> = {
    [EScheduleDayDTO.Sunday]: () => 'Sun',
    [EScheduleDayDTO.Monday]: () => 'Mon',
    [EScheduleDayDTO.Tuesday]: () => 'Tue',
    [EScheduleDayDTO.Wednesday]: () => 'Wed',
    [EScheduleDayDTO.Thursday]: () => 'Thu',
    [EScheduleDayDTO.Friday]: () => 'Fri',
    [EScheduleDayDTO.Saturday]: () => 'Sat',
  };

  return params.useFull ? dictionaryLong[params.day]?.() : dictionaryShort[params.day]?.();
}
