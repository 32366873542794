import { ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList, TemplateRef } from '@angular/core';
import {
  animate,
  animateChild,
  AnimationStyleMetadata,
  group,
  query,
  sequence,
  style,
  transition,
  trigger,
} from '@angular/animations';

const closedStateStyle: AnimationStyleMetadata = style({
  opacity: 0,
  height: 0,
  paddingTop: 0,
  paddingBottom: 0,
});

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    // TODO Some problems occur if one accordion inside another
    trigger('accordionBodyCollapse', [
      transition(':enter', [
        closedStateStyle,
        sequence([animate('0.35s ease-out'), query('*', [animateChild()], { optional: true })]),
      ]),
      transition(':leave', [
        group([animate('0.25s ease-in', closedStateStyle), query('*', [animateChild()], { optional: true })]),
      ]),
    ]),
  ],
})
export class AccordionComponent {
  @Input() isOpened = false;
  @Input() useDefaultBodyStyle = true;
  @Input() useRoundedCorners = true;
  @Input() useBottomBorder = true;
  @ContentChildren(TemplateRef) public readonly templates: QueryList<TemplateRef<any>>;
}
