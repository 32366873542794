import { TGuid } from '@core/helpers';
import { Injectable } from '@angular/core';
import {
  IPagedItemsProceedResult,
  ServerSidePagedItemsProviderService,
  PagingApiMapperHelper,
} from '@core/data-providers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ERequisitionStatusDTO, IRequisitionLogsItem } from '@project/view-models';
import { RequisitionLogsApiProviderService } from './api-providers/requisition-logs-api-provider.service';
import { EDoctorStatusType } from 'src/app/lazy-modules/doctors/consts';

export interface IRequisitionLogsFiltersParams {
  search?: string;
  filter?: number;
  tag?: TGuid;
  status?: ERequisitionStatusDTO;
  doctorstatus?: EDoctorStatusType;
}

@Injectable()
export class RequisitionLogsServerSideItemsProviderService extends ServerSidePagedItemsProviderService<
  IRequisitionLogsItem,
  IRequisitionLogsFiltersParams,
  null
> {
  constructor(private requisitionLogsApiProviderService: RequisitionLogsApiProviderService) {
    super();
  }

  protected getDefaultFilters(): Partial<IRequisitionLogsFiltersParams> {
    return {};
  }

  protected proceedPagedItemsLoading(
    page,
    itemsPerPage,
    filtersState,
    sortingState,
  ): Observable<IPagedItemsProceedResult<IRequisitionLogsItem>> {
    return this.requisitionLogsApiProviderService
      .getPaged(page, itemsPerPage, this._filtersState$.value)
      .pipe(
        map((result) => PagingApiMapperHelper.mapPagingApiResponseToProceedResult<IRequisitionLogsItem>(page, result)),
      );
  }
}
