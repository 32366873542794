<mat-form-field>
  <mat-select [formControl]="serverSideCtrl" placeholder="{{ 'nouns.cid' | translate }}">
    <mat-option>
      <ngx-mat-select-search
        [formControl]="serverSideFilteringCtrl"
        [searching]="isSearching"
        placeholderLabel="{{ 'sessions-details.type-cid' | translate }}"
        noEntriesFoundLabel="{{ 'sessions-details.cid-not-found' | translate }}"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ option.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
