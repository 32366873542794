import {
  ConferenceData,
  FEATURE_CALL,
  FEATURE_RECORD_SOUND,
  FEATURE_SOUND,
  FEATURE_VIDEO,
  Videoconference,
  VideoConferenceProvider,
} from './definitions';
import { VideoconferenceFactoryProvider } from './providers';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VideoconferenceImpl implements Videoconference {
  private videoConferenceProvider: VideoConferenceProvider;
  private factoryProvider: VideoconferenceFactoryProvider;

  constructor(factoryProvider: VideoconferenceFactoryProvider) {
    this.factoryProvider = factoryProvider;
  }

  startProvider(conferenceData: ConferenceData): void {
    this.videoConferenceProvider = this.factoryProvider.getProvider(conferenceData);
  }

  getRemoteParticipants(): (string | number)[] {
    return this.videoConferenceProvider.getRemoteParticipants();
  }

  remoteVideoObservable(): Observable<boolean> {
    return this.videoConferenceProvider.remoteVideoObservable;
  }

  remoteAudioObservable(): Observable<boolean> {
    return this.videoConferenceProvider.remoteAudioObservable;
  }

  localVideoObservable(): Observable<boolean> {
    return this.videoConferenceProvider.localVideoObservable;
  }

  localAudioObservable(): Observable<boolean> {
    return this.videoConferenceProvider.localAudioObservable;
  }

  getName(): string {
    return this.videoConferenceProvider.name;
  }

  async toggleSound(): Promise<void> {
    await this.videoConferenceProvider.toggleSound();
  }

  async toggleVideo(): Promise<void> {
    await this.videoConferenceProvider.toggleVideo();
  }

  async join(): Promise<void> {
    await this.videoConferenceProvider.join();
  }

  async left(): Promise<void> {
    await this.videoConferenceProvider.left();
  }

  remoteFallbackObservable(): Observable<boolean> {
    return this.videoConferenceProvider.remoteFallbackObservable;
  }

  getFeatures(): string[] {
    return this.videoConferenceProvider.features();
  }

  hasFeature(name: string) {
    return this.getFeatures().includes(name);
  }

  hasFeatureVideo(): boolean {
    return this.hasFeature(FEATURE_VIDEO);
  }

  hasFeatureSound(): boolean {
    return this.hasFeature(FEATURE_SOUND);
  }

  hasFeatureRecordSound(): boolean {
    return this.hasFeature(FEATURE_RECORD_SOUND);
  }

  hasFeatureCall(): boolean {
    return this.hasFeature(FEATURE_CALL);
  }
}
