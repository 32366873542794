<div
  #window
  cdkDrag
  libResize
  (cdkDragStarted)="onDragStarted()"
  (cdkDragEnded)="onDragEnded()"
  [libResizeMaxSize]="config.maxWindowModeSize"
  [libResizeMinSize]="config.minWindowModeSize"
  [cdkDragDisabled]="isFullscreenMode"
  [cdkDragBoundary]="componentElementRef"
  [cdkDragFreeDragPosition]="isFullscreenMode ? fullScreenModeDragPosition : savedDragPosition"
  class="window"
  [class.fullscreen]="isFullscreenMode"
  [class.minimizedScreen]="!isFullscreenMode"
  [style.width.px]="config.defaultWindowModeSize.width"
  [style.height.px]="config.defaultWindowModeSize.height"
>
  <div
    *ngIf="!config.hideHeaderInFullscreenMode"
    cdkDragHandle
    (dblclick)="toggleWindowMode()"
    class="header"
    [class.fullscreen]="isFullscreenMode"
  >
    <span>{{ config.title }}</span>
    <div class="actions">
      <button *ngIf="!config.disableChangeWindowMode" class="button-ghost" (click)="toggleWindowMode()">
        <span *ngIf="isFullscreenMode; else maximiseIcon" class="icon minimise"></span>
        <ng-template #maximiseIcon><span class="icon maximise"></span></ng-template>
      </button>
      <button class="button-ghost" *ngIf="!config.hideCloseButton" (click)="closeRequestFn()">
        <span class="icon close"></span>
      </button>
    </div>
  </div>
  <div class="body">
    <ng-container #viewContainer></ng-container>
    <div *ngIf="!config.disableResize && !isFullscreenMode" libResizeTrigger class="resize-trigger"></div>
  </div>
</div>
