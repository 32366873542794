import { TranslateService } from '@project/translate';

export enum EDoctorStatusDTO {
  Active = 'Active',
  Away = 'Away',
}

export function getDoctorStatusLocalization(status: EDoctorStatusDTO): string | undefined {
  const map: Record<EDoctorStatusDTO, () => string> = {
    [EDoctorStatusDTO.Active]: () => TranslateService.localize('status.online'),
    [EDoctorStatusDTO.Away]: () => TranslateService.localize('status.offline'),
  };

  return map[status]?.();
}
