import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';
import { BehaviorSubject, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { RoutesBuilderService } from '@app/config';
import { BroadcastEventsService } from '@core/services';
import { CloseAsidePageEvent, EAsideOutlets } from '@project/shared';
import { filter, takeUntil } from 'rxjs/operators';
import { NotificationsService } from '@lib/notifications';
import { TranslateService } from '@project/translate';
import { SummaryApiProviderService } from '@project/data-providers';
import { UserProfileDataService } from '@project/services';

enum ESidePageState {
  Opened = 'opened',
  Closed = 'closed',
}

@Component({
  selector: 'app-aside-outlet',
  templateUrl: './aside-outlet.component.html',
  styleUrls: ['./aside-outlet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('asideSlideInOut', [
      state(
        ESidePageState.Opened,
        style({
          opacity: 1,
          transform: 'translateX(0)',
        }),
      ),
      state(
        ESidePageState.Closed,
        style({
          opacity: 0.5,
          transform: 'translateX(100%)',
          boxShadow: 'none',
        }),
      ),
      transition(ESidePageState.Opened + ' => ' + ESidePageState.Closed, [
        group([animate('0.25s ease-in'), query('*', [animateChild()])]),
      ]),
      transition(ESidePageState.Closed + ' => ' + ESidePageState.Opened, [
        group([animate('0.5s ease-out'), query('*', [animateChild()])]),
      ]),
    ]),
  ],
})
export class AsideOutletComponent implements OnInit, OnDestroy {
  @Input() outletName: EAsideOutlets;

  private _asidePageState$ = new BehaviorSubject<ESidePageState>(ESidePageState.Closed);
  public asidePageState$ = this._asidePageState$.asObservable();

  public readonly outlets = EAsideOutlets;

  private destroyed$ = new Subject<void>();
  public isMedicalRecordFilled = false;
  public isDoctor = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private routesBuilderService: RoutesBuilderService,
    private broadcastEventsService: BroadcastEventsService,
    private notificationsService: NotificationsService,
    private summaryApiProviderService: SummaryApiProviderService,
    private userProfileDataService: UserProfileDataService,
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public setAsideVisibility(show: boolean): void {
    this._asidePageState$.next(show ? ESidePageState.Opened : ESidePageState.Closed);
    this.changeDetectorRef.detectChanges();
  }

  onCloseSideBar(): void {
    const isSessionDetailsAside = window.location.pathname.includes('globalAside:session-details');
    console.log('isSessionDetailsAside', isSessionDetailsAside);

    if (
      !this.isMedicalRecordFilled &&
      this._asidePageState$.value === ESidePageState.Opened &&
      isSessionDetailsAside &&
      this.isDoctor
    ) {
      this.notificationsService.error({
        message: TranslateService.localize('sessions-details-fill-form'),
      });
      return;
    }

    this.resetAsideNavigation();
  }

  private resetAsideNavigation(): void {
    this.routesBuilderService.closeAsideRoute(this.activatedRoute, this.outletName);
  }

  private initSubscriptions(): void {
    this.summaryApiProviderService.isMedicalRecordFilled$.pipe(takeUntil(this.destroyed$)).subscribe((filled) => {
      this.isMedicalRecordFilled = filled;
      this.changeDetectorRef.detectChanges();
    });

    this.userProfileDataService
      .isDoctor()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isDoctor) => {
        this.isDoctor = isDoctor;
        this.changeDetectorRef.detectChanges();
      });

    this.broadcastEventsService
      .eventsByType$(CloseAsidePageEvent)
      .pipe(
        filter((event) => event.outlet === this.outletName),
        filter(() => this._asidePageState$.value === ESidePageState.Opened),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.resetAsideNavigation();
      });
  }
}
