import { Injectable } from '@angular/core';
import { IRequisition } from '@project/view-models';
import { defer, Observable } from 'rxjs';
import { TranslateService } from '@project/translate';
import { EUserPermission } from '@project/shared';
import { map, switchMap } from 'rxjs/operators';
import { ModalOverlayService } from '@lib/modal';
import { UserProfileDataService } from '@project/services';
import { RequisitionApiProviderService } from '@project/data-providers';
import { CloseRequisitionWithSummaryNoteService } from './close-requisition-with-summary-note.service';

@Injectable()
export class CloseRequisitionWithConfirmService {
  constructor(
    private closeRequisitionWithSummaryNoteService: CloseRequisitionWithSummaryNoteService,
    private modalOverlayService: ModalOverlayService,
    private userProfileDataService: UserProfileDataService,
    private requisitionApiProviderService: RequisitionApiProviderService,
  ) {}

  closeRequisition(
    requisition: IRequisition,
    options: { bypassSumaryNote?: boolean } = {},
  ): Observable<boolean | never> {
    console.log(requisition);

    const confirmOverlay$ = defer(() =>
      this.modalOverlayService.confirm(TranslateService.localize('questions.are-you-sure?')),
    );
    const closeWithNoteOverlay$ = defer(() => this.closeRequisitionWithSummaryNoteService.showOverlay(requisition));

    return defer(() => {
      if (
        !options.bypassSumaryNote &&
        this.userProfileDataService.hasPermissions([EUserPermission.haveToAddSummaryNoteOnCloseConversationIfHasNoOne])
      ) {
        return this.closeRequisitionWithSummaryNoteService
          .hasDoctorNotesForChat(requisition.id, this.userProfileDataService.profile.id)
          .pipe(
            switchMap((hasNotes) => (hasNotes ? confirmOverlay$ : closeWithNoteOverlay$)),
            switchMap(() => this.requisitionApiProviderService.close(requisition.id)),
            map(() => true),
          );
      } else {
        return confirmOverlay$.pipe(
          switchMap(() => this.requisitionApiProviderService.close(requisition.id)),
          map(() => true),
        );
      }
    });
  }
}
