import { AbstractControl, FormArray, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { DateFormatter } from './date-utils';
import { Cpf } from './utils/cpf';
import { isTimeIn24FormatValid } from './utils/dates/dates';

interface IFieldError {
  [key: string]: any;
}

const allowValidation = (control: AbstractControl): boolean => {
  return control.touched && !control.disabled;
};

export class LibValidators {
  static required(message: string): ValidatorFn {
    return (control: AbstractControl): IFieldError | null => {
      if (!allowValidation(control)) {
        return null;
      }

      return Validators.required(control)
        ? {
            requiredValidation: message,
          }
        : null;
    };
  }

  static equal(requiredValue: string | boolean, message: string): ValidatorFn {
    return (control: AbstractControl): IFieldError | null => {
      if (!allowValidation(control)) {
        return null;
      }

      return control.value !== requiredValue
        ? {
            equalValidation: message,
          }
        : null;
    };
  }

  static minLength(minLength: number, message: string) {
    return (control: AbstractControl): IFieldError | null => {
      if (!allowValidation(control)) {
        return null;
      }

      return control.value?.length < minLength
        ? {
            minLengthValidation: message,
          }
        : null;
    };
  }

  static maxLength(maxLength: number, message: string) {
    return (control: AbstractControl): IFieldError | null => {
      if (!allowValidation(control)) {
        return null;
      }

      return control.value?.length > maxLength
        ? {
            maxLengthValidation: message,
          }
        : null;
    };
  }

  static pattern(regexp: RegExp, message: string) {
    return (control: AbstractControl): IFieldError | null => {
      if (!allowValidation(control)) {
        return null;
      }

      return !regexp.test(control.value)
        ? {
            patternValidation: message,
          }
        : null;
    };
  }

  static email(message: string): ValidatorFn {
    return (control: AbstractControl): IFieldError | null => {
      if (!allowValidation(control)) {
        return null;
      }

      // https://emailregex.com/
      const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      const email = control.value;

      const isValid = regexp.test(email);

      return !isValid
        ? {
            emailValidation: message,
          }
        : null;
    };
  }

  static sameValueAs(target: AbstractControl, message: string): ValidatorFn {
    return (control: AbstractControl): IFieldError | null => {
      if (!allowValidation(control)) {
        return null;
      }

      const valid = control.value && control.value === target.value;

      return !valid
        ? {
            sameValueAs: message,
          }
        : null;
    };
  }

  static date(format: string, message: string): ValidatorFn {
    return (control: AbstractControl) => {
      if (!allowValidation(control)) {
        return null;
      }

      // This validator should validate only existed value, so do nothing if no value
      if (!control.value) {
        return null;
      }

      return DateFormatter.isStringIsValidDate(control.value, format) ? null : { dateValidation: message };
    };
  }

  static isoDateString(message: string): ValidatorFn {
    return (control: AbstractControl) => {
      if (!allowValidation(control)) {
        return null;
      }

      // This validator should validate only existed value, so do nothing if no value
      if (!control.value) {
        return null;
      }

      const date = new Date(control.value);
      return !isNaN(date.getDate()) ? null : { dateValidation: message };
    };
  }

  static timeIn24HoursFormat(format: string = 'HH:MM', message: string): ValidatorFn {
    return (control: AbstractControl) => {
      if (!allowValidation(control)) {
        return null;
      }

      // This validator should validate only existed value, so do nothing if no value
      if (!control.value) {
        return null;
      }

      return isTimeIn24FormatValid(control.value, format) ? null : { timeIn24HoursFormat: message };
    };
  }

  static integer(message: string): ValidatorFn {
    return (control: AbstractControl) => {
      if (!allowValidation(control)) {
        return null;
      }

      const intValue = parseInt(control.value, 10);
      const floatValue = parseFloat(control.value);
      const valid = !isNaN(intValue) && intValue.toString() === control.value && intValue === floatValue;

      return !valid
        ? {
            positiveInteger: message,
          }
        : null;
    };
  }

  static numberOnly(message: string): ValidatorFn {
    return (control: AbstractControl) => {
      if (!allowValidation(control)) {
        return null;
      }
      const valid = (control.value || '').match('^[0-9]*$');

      return !valid || valid[0] === ''
        ? {
            numberOnly: message,
          }
        : null;
    };
  }

  static rangeNumber(from: number, to: number, message: string): ValidatorFn {
    return (control: AbstractControl) => {
      if (!allowValidation(control)) {
        return null;
      }

      const numberValue = parseFloat(control.value);
      const valid =
        !isNaN(numberValue) && numberValue.toString() === control.value && numberValue >= from && numberValue <= to;

      return !valid
        ? {
            rangeNumber: message,
          }
        : null;
    };
  }

  static cpf(message: string): ValidatorFn {
    return (control: AbstractControl) => {
      if (!allowValidation(control)) {
        return null;
      }

      // This validator should validate only existed value, so do nothing if no value
      if (!control.value) {
        return null;
      }

      return Cpf.isValid(control.value) ? null : { cpfValidation: message };
    };
  }

  static enumValue<E extends Record<string, string | number>>(enumToCheck: E, message: string): ValidatorFn {
    return (control: AbstractControl) => {
      if (!allowValidation(control)) {
        return null;
      }

      // This validator should validate only existed value, so do nothing if no value
      if (control.value === null || control.value === undefined) {
        return null;
      }

      return Object.values(enumToCheck).includes(control.value) ? null : { oneOfEnumValidation: message };
    };
  }
}
