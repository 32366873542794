import { TDateISOString, TGuid, DateFormatter } from '@core/helpers';
import { IProfile, IProfileDTO, ProfileViewModelFactory } from './profile.view-model';

export interface ISummaryNoteDTO {
  id: TGuid;
  chat_id: TGuid;
  doctor_id: TGuid;
  requisition_id: TGuid;
  updated_at: TDateISOString;
  note: string | null;
  profiles: IProfileDTO[];
}

export interface ISummaryNote {
  id: TGuid;
  chatId: TGuid;
  doctorId: TGuid;
  requisitionId: TGuid;
  updatedAt: Date;
  note: string | null;
  authorProfile: IProfile | null;
}

export interface ISummaryNoteHistoryDTO {
  note: string;
  created_at: Date;
}

export interface ISummaryNoteHistory {
  note: string;
  createdAt: Date;
}

export class SummaryNoteViewModelFactory {
  static createFromDTO(dto: ISummaryNoteDTO): ISummaryNote {
    return {
      id: dto.id,
      chatId: dto.chat_id,
      doctorId: dto.doctor_id,
      requisitionId: dto.requisition_id,
      updatedAt: DateFormatter.stringToDate(dto.updated_at),
      note: dto.note,
      authorProfile: this.getProfile(dto, dto.doctor_id),
    };
  }

  private static getProfile(dto: ISummaryNoteDTO, profileId: TGuid): IProfile | null {
    const profileDTO = dto?.profiles?.find((profile) => profile.id === profileId);
    return profileDTO ? ProfileViewModelFactory.create(profileDTO) : null;
  }
}

export class SummaryNoteHistoryViewModeFactory {
  static createFromDTO(dto: ISummaryNoteHistoryDTO): ISummaryNoteHistory {
    return {
      note: dto.note,
      createdAt: dto.created_at,
    };
  }
}
