import { Injectable } from '@angular/core';
import {
  IPagedItemsProceedResult,
  ServerSidePagedItemsProviderService,
  PagingApiMapperHelper,
} from '@core/data-providers';
import { Observable } from 'rxjs';
import { PatientsApiProviderService } from './api-providers/patients-api-provider.service';
import { map } from 'rxjs/operators';
import { EInvitationStatusDTO, IPatient } from '@project/view-models';
import { TGuid } from '@core/helpers';
import { EPatientStatusType } from '../../lazy-modules/patients/consts';

export interface IPatientFiltersParams {
  tag?: TGuid;
  search?: string;
  status?: EInvitationStatusDTO;
  statusPatient?: EPatientStatusType;
}

@Injectable({
  providedIn: 'root',
})
export class PatientServerSideItemsProviderService extends ServerSidePagedItemsProviderService<
  IPatient,
  IPatientFiltersParams,
  null
> {
  constructor(private patientsApiProviderService: PatientsApiProviderService) {
    super();
  }

  protected getDefaultFilters(): IPatientFiltersParams {
    return {};
  }

  protected proceedPagedItemsLoading(
    page,
    itemsPerPage,
    filtersState,
    sortingState,
  ): Observable<IPagedItemsProceedResult<IPatient>> {
    return this.patientsApiProviderService
      .getPaged(page, itemsPerPage, this._filtersState$.value)
      .pipe(map((result) => PagingApiMapperHelper.mapPagingApiResponseToProceedResult<IPatient>(page, result)));
  }
}
