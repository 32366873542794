import { Injectable } from '@angular/core';
import { TGuid } from '@core/helpers';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '@core/http';
import { environment } from '@env';
import {
  EDoctorSpecializationDTO,
  IQueueInfo,
  IRequisition,
  IRequisitionAnswerDTO,
  IRequisitionCreateDTO,
  IRequisitionDTO,
  IRequisitionStatus,
  IRequisitionStatusDTO,
  IRequisitionWithQuestionsAnswers,
  RequisitionStatusViewModelFactory,
  RequisitionViewModelFactory,
  IReason,
  IReasonDTO,
  ReasonsViewModelFactory,
  IRequisitionReason,
  IRequisitionCreateCalendarDTO,
  IPrescriptionDocument,
} from '@project/view-models';
import { map, switchMap } from 'rxjs/operators';
import { QuestionnaireApiProviderService } from './questionnaire-api-provider.service';

export enum ERequisitionStatusFilter {
  All = 'All',
  Active = 'Active',
  Closed = 'Closed',
}

@Injectable({
  providedIn: 'root',
})
export class RequisitionApiProviderService {
  constructor(
    private apiService: ApiService,
    private questionnaireApiProviderService: QuestionnaireApiProviderService,
  ) {}

  private readonly _hasRequisition$ = new BehaviorSubject<boolean>(null);
  public readonly hasRequisition$ = this._hasRequisition$.asObservable();

  getAll(filter?: ERequisitionStatusFilter): Observable<IRequisition[]> {
    return this.apiService
      .get<IRequisitionDTO[]>(`${environment.environmentVariables.apiCoreUrl}/Requisition`, { filter })
      .pipe(
        map((results) => {
          this._hasRequisition$.next(results.length > 0);
          return results.map((result) => RequisitionViewModelFactory.createGetViewModel(result));
        }),
      );
  }

  getRequisitionPrescriptionDocuments(id: TGuid): Observable<IPrescriptionDocument> {
    return this.apiService.get<IPrescriptionDocument>(
      `${environment.environmentVariables.apiMemedUrl}/Document/pdf?requisitionId=${id}`,
    );
  }

  getFirst(filter?: ERequisitionStatusFilter): Observable<IRequisition> {
    return this.getAll(filter).pipe(map((results) => results[0]));
  }

  getById(id: TGuid): Observable<IRequisition> {
    return this.apiService
      .get<IRequisitionDTO>(`${environment.environmentVariables.apiCoreUrl}/Requisition/${id}`)
      .pipe(map((result) => RequisitionViewModelFactory.createGetViewModel(result)));
  }

  getAllStatus(id: TGuid): Observable<IRequisitionStatus[]> {
    return this.apiService
      .get<IRequisitionStatusDTO[]>(`${environment.environmentVariables.apiCoreUrl}/Requisition/status/history/${id}`)
      .pipe(map((result) => result.map((result) => RequisitionStatusViewModelFactory.createFromDTO(result))));
  }

  getWithAnswersById(id: TGuid): Observable<IRequisitionWithQuestionsAnswers> {
    return this.apiService
      .get<IRequisitionDTO>(`${environment.environmentVariables.apiCoreUrl}/Requisition/${id}`)
      .pipe(
        switchMap((requisition) =>
          this.questionnaireApiProviderService
            .getById(requisition.questionnaire_id)
            .pipe(
              map((questionnaire) =>
                RequisitionViewModelFactory.createGetViewModelWithAnswers(requisition, questionnaire),
              ),
            ),
        ),
      );
  }

  getQueueInfo(): Observable<IQueueInfo> {
    return this.apiService.get(`${environment.environmentVariables.apiCoreUrl}/Requisition/queue/info`);
  }

  createForAppointmentCalendar(timeSlotId: TGuid, patientId: TGuid): Observable<void> {
    const body: IRequisitionCreateCalendarDTO = {
      questionnaire_id: 'eee30e26-d8b4-4b0a-89a0-34648c74c185',
      questionnaire_answers: {
        answers: [
          {
            question_id: 'e1724d27-8482-473b-be14-a704c52c5b33',
            allowed_values: [
              {
                id: '630cdce3-8a04-4125-a020-2988b4fceff3',
              },
            ],
          },
          {
            question_id: '33adf6c6-7b53-446b-9544-09299a937cb9',
            allowed_values: [
              {
                id: '0420966d-75b1-4f9d-b679-432cbcf73cf6',
              },
            ],
          },
          {
            question_id: '11a8e0dc-e25b-487d-9095-e302e353b607',
            allowed_values: [
              {
                id: '0420966d-75b1-4f9d-b679-432cbcf73cf6',
              },
            ],
          },
        ],
      },
      time_slot_id: timeSlotId,
      patient_id: patientId,
    };

    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/Requisition/appointment/create`, body);
  }

  createForAppointment(questionnaireId: TGuid, questionnaireAnswers: IRequisitionAnswerDTO[]): Observable<void> {
    const body: IRequisitionCreateDTO = {
      questionnaire_answers: {
        answers: questionnaireAnswers,
      },
      questionnaire_id: questionnaireId,
    };

    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/Requisition/`, body);
  }

  createForLiveQueue(questionnaireId: TGuid, questionnaireAnswers: IRequisitionAnswerDTO[]): Observable<void> {
    const body: IRequisitionCreateDTO = {
      questionnaire_answers: {
        answers: questionnaireAnswers,
      },
      questionnaire_id: questionnaireId,
    };

    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/Requisition/toqueue`, body);
  }

  assignToLiveQueue(id: TGuid, targetDoctorSpecialization: EDoctorSpecializationDTO): Observable<void> {
    return this.apiService.post(
      `${environment.environmentVariables.apiCoreUrl}/Requisition/${id}/assign/specialization`,
      targetDoctorSpecialization,
    );
  }

  close(id: TGuid): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/Requisition/${id}/close`);
  }

  closeAppointmentAndUnassingTimeSlot(timeSlotId: string, requisitionId: TGuid): Observable<void> {
    return this.apiService.delete(`${environment.environmentVariables.apiCoreUrl}/Requisition/close/appointment`, {
      time_slot_id: timeSlotId,
      requisition_id: requisitionId,
    });
  }

  assignToTimeSlot(timeSlotId: string, requisitionId: TGuid): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/timeslots/assign`, {
      time_slot_id: timeSlotId,
      requisition_id: requisitionId,
    });
  }

  unassingTimeSlot(timeSlotId: string, requisitionId: TGuid): Observable<void> {
    return this.apiService.delete(`${environment.environmentVariables.apiCoreUrl}/timeslots/unassign`, {
      time_slot_id: timeSlotId,
      requisition_id: requisitionId,
    });
  }

  sendReadyStatus(requisitionId: TGuid): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/Requisition/${requisitionId}/ready`);
  }
  sendChatStart(requisitionId: TGuid): Observable<void> {
    return this.apiService.post(
      `${environment.environmentVariables.apiCoreUrl}/Conferences/${requisitionId}/chatstart`,
    );
  }

  getAllReasons(): Observable<IReason[]> {
    return this.apiService
      .get<IReasonDTO[]>(`${environment.environmentVariables.apiCoreUrl}/Reason`)
      .pipe(map((dto) => dto.map((dto) => ReasonsViewModelFactory.createFromDTO(dto))));
  }

  sendReasons(reason: IRequisitionReason): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiCoreUrl}/Requisition/reason/create`, reason);
  }
}
