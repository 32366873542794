import { Injectable } from '@angular/core';
import {
  IPagedItemsProceedResult,
  ServerSidePagedItemsProviderService,
  PagingApiMapperHelper,
} from '@core/data-providers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISendGridResponse } from '@project/view-models';
import { SendGridApiProviderService } from './api-providers/sendgrid-api-provider.service';
import { ISendgridFiltersParams } from './sendgrid-bounces-server-side-items-provider.service';

@Injectable()
export class SendGridInvalidsServerSideItemsProviderService extends ServerSidePagedItemsProviderService<
  ISendGridResponse,
  ISendgridFiltersParams,
  null
> {
  constructor(private sendgridApiProviderService: SendGridApiProviderService) {
    super();
  }

  protected getDefaultFilters(): Partial<ISendgridFiltersParams> {
    return {};
  }

  protected proceedPagedItemsLoading(page, itemsPerPage): Observable<IPagedItemsProceedResult<ISendGridResponse>> {
    return this.sendgridApiProviderService
      .getAllInvalidsPaged(page, itemsPerPage)
      .pipe(
        map((result) => PagingApiMapperHelper.mapPagingApiResponseToProceedResult<ISendGridResponse>(page, result)),
      );
  }
}
