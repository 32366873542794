import { ISelectOption } from '@lib/ui/form-elements';
// noinspection ES6PreferShortImport // Should use this import to prevent circular dependencies
import { EGenderDTO, getGenderLocalization } from '../view-models/consts/gender';

export namespace SharedFormOptions {
  export const genderOptions: () => ISelectOption[] = () => [
    { label: getGenderLocalization(EGenderDTO.Female), value: EGenderDTO.Female },
    { label: getGenderLocalization(EGenderDTO.Male), value: EGenderDTO.Male },
  ];
}
