import { DateFormatter, TDateISOString, TGuid } from '@core/helpers';
import {
  EObservationMethodDTO,
  EObservationParameterValueCategoryDTO,
  EObservationStatusDTO,
  getObservationMethodLocalization,
  getObservationParameterValueCategoryLocalization,
} from './consts/observation-consts';
import { IProfileShort, IProfileShortDTO, ProfileViewModelFactory } from './profile.view-model';
import {
  IObservationDataSnapshot,
  IObservationDataSnapshotDTO,
  ObservationDataSnapshotViewModelFactory,
} from './observation-data-snapshot.view-model';

export interface IObservationDTO {
  id: TGuid;
  method: EObservationMethodDTO;
  status: EObservationStatusDTO;
  patient: IProfileShortDTO;
  started_at?: TDateISOString;
  completed_at?: TDateISOString;
  interval?: number;
  binah_threshold_status: EObservationParameterValueCategoryDTO;
}

export interface IObservation {
  id: TGuid;
  method: EObservationMethodDTO;
  methodLabel: string;
  patient: IProfileShort | null;
  startedAt: Date | null;
  completedAt: Date | null;
  interval: number | null;
  binahThresholdStatus: EObservationParameterValueCategoryDTO;
  binahThresholdStatusLabel: string;
  // statusLabel: string; // Statuses are not working properly for `09 Jul 2020` so need to hide and don't use them before fixes on backend side
  // status: EObservationStatus;
}

export interface IObservationFull extends IObservation {
  data: IObservationDataSnapshot[];
}

export class ObservationViewModelFactory {
  static createFromDTO(dto: IObservationDTO): IObservation {
    return {
      id: dto.id,
      method: dto.method,
      methodLabel: getObservationMethodLocalization(dto.method),
      startedAt: dto.started_at ? DateFormatter.stringToDate(dto.started_at) : null,
      completedAt: dto.completed_at ? DateFormatter.stringToDate(dto.completed_at) : null,
      interval: dto.interval ?? null,
      patient: dto.patient ? ProfileViewModelFactory.createShort(dto.patient) : null,
      binahThresholdStatus: dto.binah_threshold_status,
      binahThresholdStatusLabel: getObservationParameterValueCategoryLocalization(dto.binah_threshold_status),
    };
  }

  static createFull(item: IObservation, data: IObservationDataSnapshot[]): IObservationFull {
    return {
      ...item,
      data,
    };
  }

  static createFullFromDTO(dto: IObservationDTO, dataDto: IObservationDataSnapshotDTO[]): IObservationFull {
    return {
      ...this.createFromDTO(dto),
      data: (dataDto || []).map((item) => ObservationDataSnapshotViewModelFactory.createFromDTO(item, dto.method)),
    };
  }
}
