import { IRequisitionParticipant } from '@project/view-models';

export interface IVideoCallSession {
  participantId: string;
  stream: MediaStream;
  profile: IRequisitionParticipant | null;
  hasAudio: boolean;
  hasVideo: boolean;
}

export enum ESessionState {
  Offline = 'Offline',
  Connecting = 'Connecting',
  Online = 'Online',
  Error = 'Error',
}

export enum ECurrentVideoSessionState {
  Offline = 'Offline',
  Connecting = 'Connecting',
  Online = 'Online',
  Error = 'Error',
  CallDeclined = 'CallDeclined',
}
