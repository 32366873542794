import { AGORA, VOXEET, AgoraData, ConferenceData, VideoConferenceProvider } from '../definitions';
import { AgoraProvider } from './agora.provider';

export class VideoconferenceFactoryProvider {
  public getProvider(conferenceData: ConferenceData): VideoConferenceProvider {
    switch (conferenceData.provider.toUpperCase()) {
      case AGORA:
        return new AgoraProvider(conferenceData as AgoraData);
      case VOXEET:
        return null;
      default:
        return null;
    }
  }
}
