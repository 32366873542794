import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { INewRegister, IPublicGroup } from '@project/view-models';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { ISelectOption } from '@lib/ui/form-elements';
import { map } from 'rxjs/operators';
import { TGuid } from '@core/helpers';

@Injectable({
  providedIn: 'root',
})
export class FirstAccessRegisterService {
  constructor(private apiService: ApiService) {}

  saveFirstAccess(abreviation: string, data: INewRegister): Observable<void> {
    return this.apiService.post(`${environment.environmentVariables.apiAuthUrl}/firstaccess/sus/${abreviation}`, data);
  }

  getGroups(healthCenterId: TGuid, profileType: string): Observable<ISelectOption[]> {
    return this.apiService
      .get(
        `${environment.environmentVariables.apiCoreUrl}/firstaccess/groups?healthCenterId=${healthCenterId}&profileType=${profileType}`,
      )
      .pipe(
        map((response: IPublicGroup[]) =>
          response.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        ),
      );
  }
}
