import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProjectClientIdService } from '../project-client-id.service';

@Injectable()
export class HttpClientIdInterceptorService implements HttpInterceptor {
  constructor(private projectClientIdService: ProjectClientIdService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clientId = this.projectClientIdService.getClientId();

    const headers = req.headers.set('PTM-Client-Id', clientId);

    const newRequest = req.clone({
      headers,
    });

    return next.handle(newRequest);
  }
}
