import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { NotificationRef } from '../notification-ref';
import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';
import { ENotificationStatus, INotificationAction } from '../consts';

@Component({
  selector: 'lib-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'translate(-50%, 30px)',
        }),
        group([
          animate(
            '0.75s ease-out',
            style({
              opacity: 1,
              transform: 'translate(-50%, 0)',
            }),
          ),
          query('*', [animateChild()]),
        ]),
      ]),
      transition(':leave', [
        style({
          opacity: 1,
          transform: 'translate(-50%, 0)',
          position: 'absolute',
        }),
        animate(
          '0.5s ease-out',
          style({
            opacity: 0,
            transform: 'translate(-50%, -60px)',
          }),
        ),
      ]),
    ]),
    trigger('countdown', [
      transition(
        '* => down',
        [
          style({
            width: '100%',
          }),
          animate('{{time}}ms linear'),
        ],
        {
          params: {
            time: 10000,
          },
        },
      ),
    ]),
  ],
})
export class NotificationItemComponent {
  @Input() notification: NotificationRef = null;

  @HostBinding('@slideInOut') public _useAnimation: true;

  public readonly statuses = ENotificationStatus;

  onCountdownEnd() {
    this.close();
  }

  close() {
    this.notification.close();
  }

  onActionClick(action: INotificationAction) {
    action.onClick();
    if (!this.notification.unclosable) this.close();
  }
}
