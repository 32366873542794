import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FindDropdownPositionDirective } from './find-dropdown-position.directive';

@NgModule({
  declarations: [FindDropdownPositionDirective],
  exports: [FindDropdownPositionDirective],
  imports: [CommonModule],
})
export class FindDropdownPositionDirectiveModule {}
