import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListItemsTableComponent } from './list-items-table/list-items-table.component';
import { ListItemsTableItemComponent } from './list-items-table-item/list-items-table-item.component';
import { ListItemsTableHeaderComponent } from './list-items-table-header/list-items-table-header.component';
import { FormElementsModule } from '@lib/ui';

@NgModule({
  declarations: [ListItemsTableComponent, ListItemsTableItemComponent, ListItemsTableHeaderComponent],
  exports: [ListItemsTableComponent, ListItemsTableItemComponent, ListItemsTableHeaderComponent],
  imports: [CommonModule, FormElementsModule],
})
export class ListItemsTableModule {}
