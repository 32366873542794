import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Observable, of, timer } from 'rxjs';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, filter, mapTo, switchMap } from 'rxjs/operators';

type TState = 'Start' | 'End';

@Component({
  selector: 'lib-page-load-progress',
  templateUrl: './page-load-progress.component.html',
  styleUrls: ['./page-load-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('pageLoader', [
      transition('Start => End', [
        style({ width: '*', opacity: 1 }),
        animate('200ms ease-out', style({ width: '100%' })),
        animate(500, style({ opacity: 0 })),
      ]),
      transition('* => Start', [
        animate(1, style({ opacity: 1, width: '0%' })), // To reset styles of End state, Without 'animate(1,... )' here is some issues
        animate('25000ms cubic-bezier(.1,1.06,.2,.9)', style({ opacity: 1, width: '66%' })),
      ]),
    ]),
  ],
})
export class PageLoadProgressComponent implements OnInit {
  public state$: Observable<TState>;

  constructor(private router: Router) {}

  ngOnInit() {
    this.state$ = this.router.events.pipe(
      filter(
        (event) =>
          event instanceof NavigationError ||
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationStart,
      ),
      debounceTime(50), // filtering instant route changes without any delay and state change
      switchMap(
        (event): Observable<TState> => {
          if (event instanceof NavigationStart) {
            return of('Start');
          }

          return timer(300).pipe(mapTo('End'));
        },
      ),
      distinctUntilChanged(),
    );
  }
}
