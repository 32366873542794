import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'lib-modal-backdrop',
  templateUrl: './modal-backdrop.component.html',
  styleUrls: ['./modal-backdrop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('modalAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
      transition(':leave', [animate(300, style({ opacity: 0 }))]),
    ]),
  ],
})
export class ModalBackdropComponent implements OnDestroy {
  @Input() fullScreen = false;
  @Input() closeByEsc = true;
  @Input() closeByBackdropClick = true;

  @ViewChild('viewContainer', { static: true, read: ViewContainerRef }) private _viewContainer: ViewContainerRef;

  private _close$ = new Subject();
  public close$ = this._close$.asObservable();

  @HostBinding('@modalAnimation') private _useAnimation = true;

  constructor() {}

  @HostListener('document:keydown.esc')
  onEscPress() {
    if (this.closeByEsc) {
      this.close();
    }
  }

  ngOnDestroy(): void {
    this._close$.next();
    this._close$.complete();
  }

  get viewContainerRef(): ViewContainerRef {
    return this._viewContainer;
  }

  onBackdropClick() {
    if (this.closeByBackdropClick) {
      this.close();
    }
  }

  private close() {
    this._close$.next();
  }
}
