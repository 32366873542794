import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { ConferencesApiProviderService } from '@project/data-providers';
import { TGuid } from '@core/helpers';
import {
  EVoxeetConferenceServiceEvent,
  EVoxeetParticipantStatus,
  IVoxeetParticipant,
  IVoxeetSDK,
} from './voxeet-sdk.types';
import { finalize, first, switchMap, tap } from 'rxjs/operators';

enum EInitializationState {
  Offline = 'Offline',
  InProgress = 'InProgress',
  Online = 'Online',
}

interface IParticipantInfo {
  id: TGuid;
}

interface IParticipantStreamInfo {
  participantId: TGuid;
  stream: MediaStream;
}

@Injectable({
  providedIn: 'root',
})
export class WebRtcProviderService {
  private initializationState$ = new BehaviorSubject<EInitializationState>(EInitializationState.Offline);

  private readonly _participantAdded$ = new Subject<IParticipantInfo>();
  private readonly _participantRemoved$ = new Subject<IParticipantInfo>();
  private readonly _participantStreamUpdated$ = new Subject<IParticipantStreamInfo>();

  public readonly participantAdded$ = this._participantAdded$.asObservable();
  public readonly participantRemoved$ = this._participantRemoved$.asObservable();
  public readonly participantStreamUpdated$ = this._participantStreamUpdated$.asObservable();

  private SDK: IVoxeetSDK;

  constructor(private conferencesApiProviderService: ConferencesApiProviderService) {}

  public startCallLegacy(conferenceAlias: string): Promise<void> {
    return this.getSDK()
      .then((SDK) =>
        SDK.session.participant
          ? SDK.conference.create({ alias: conferenceAlias }).then((conference) => SDK.conference.join(conference, {}))
          : Promise.reject('No current participant'),
      )
      .then(() => null);
  }

  public leaveCall(): Promise<void> {
    return this.getSDK().then((SDK) =>
      SDK.conference.current ? SDK.conference.leave() : Promise.reject('No call existed'),
    );
  }

  public startAudio(): Promise<void> {
    return this.getSDK().then((SDK) => SDK.conference.startAudio(SDK.session.participant));
  }

  public stopAudio(): Promise<void> {
    return this.getSDK().then((SDK) => SDK.conference.stopAudio(SDK.session.participant));
  }

  public startVideo(): Promise<void> {
    return this.getSDK().then((SDK) => SDK.conference.startVideo(SDK.session.participant, {}));
  }

  public stopVideo(): Promise<void> {
    return this.getSDK().then((SDK) => SDK.conference.stopVideo(SDK.session.participant));
  }

  public startSession(userInfo: { id: TGuid; name: string; avatarUrl: string }): Promise<void> {
    if (this.initializationState$.value === EInitializationState.Online) {
      return Promise.resolve();
    }

    return this.initializationState$
      .pipe(
        first((state) => state === EInitializationState.Offline),
        tap(() => this.initializationState$.next(EInitializationState.InProgress)),
        switchMap(() => forkJoin([this.getSDK(), this.conferencesApiProviderService.getToken()])),
        switchMap(([SDK, token]) => {
          SDK.initializeToken(token.access_token, () => {
            return Promise.resolve(token.refresh_token);
          });

          return SDK.session.open({ name: userInfo.name, externalId: userInfo.id, avatarUrl: userInfo.avatarUrl });
        }),
        finalize(() => this.initializationState$.next(EInitializationState.Online)),
      )
      .toPromise()
      .catch(() => this.closeSession());
  }

  public closeSession(): Promise<void> {
    if (this.initializationState$.value === EInitializationState.Offline) {
      return Promise.resolve();
    }

    return this.initializationState$
      .pipe(
        first((state) => state === EInitializationState.Online),
        tap(() => this.initializationState$.next(EInitializationState.InProgress)),
        switchMap(() => this.getSDK()),
        switchMap((SDK) => SDK.session.close()),
        finalize(() => this.initializationState$.next(EInitializationState.Offline)),
      )
      .toPromise()
      .then(() => null);
  }

  private getSDK(): Promise<IVoxeetSDK> {
    if (this.SDK) {
      return Promise.resolve(this.SDK);
    }

    return import(
      /* webpackChunkName: "voxeet-sdk-library" */
      '@voxeet/voxeet-web-sdk'
    ).then((module) => {
      this.SDK = module.default;

      this.initEvents(this.SDK);

      return this.SDK;
    });
  }

  private initEvents(SDK: IVoxeetSDK) {
    SDK.conference.on(EVoxeetConferenceServiceEvent.StreamAdded, (participant: IVoxeetParticipant, stream) => {
      this._participantStreamUpdated$.next({
        participantId: this.getUserIdFromVoxeetParticipant(participant),
        stream,
      });
    });

    SDK.conference.on(EVoxeetConferenceServiceEvent.StreamUpdated, (participant: IVoxeetParticipant, stream) => {
      this._participantStreamUpdated$.next({
        participantId: this.getUserIdFromVoxeetParticipant(participant),
        stream,
      });
    });

    SDK.conference.on(EVoxeetConferenceServiceEvent.StreamRemoved, (participant: IVoxeetParticipant) => {
      this._participantStreamUpdated$.next({
        participantId: this.getUserIdFromVoxeetParticipant(participant),
        stream: null,
      });
    });

    SDK.conference.on(EVoxeetConferenceServiceEvent.ParticipantAdded, (participant: IVoxeetParticipant) => {
      this._participantAdded$.next({
        id: this.getUserIdFromVoxeetParticipant(participant),
      });
    });

    SDK.conference.on(EVoxeetConferenceServiceEvent.ParticipantUpdated, (participant: IVoxeetParticipant) => {
      if (participant.status === EVoxeetParticipantStatus.Left) {
        this._participantRemoved$.next({
          id: this.getUserIdFromVoxeetParticipant(participant),
        });
      }
    });
  }

  private getUserIdFromVoxeetParticipant(participant: IVoxeetParticipant): TGuid {
    // We use userId as name for participant, so we need to use name to get Id
    return participant.info.externalId;
  }
}
