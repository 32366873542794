import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileTagSettingsOverlayComponent } from './profile-tag-settings-overlay/profile-tag-settings-overlay.component';
import { ModalModule } from '@lib/modal';
import { ButtonModule, FormElementsModule, ModalDialogLayoutModule } from '@lib/ui';
import { ProfileTagManagerService } from './profile-tag-manager.service';
import { TranslateModule } from '@project/translate';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [ProfileTagSettingsOverlayComponent],
  imports: [
    CommonModule,
    ModalModule,
    ButtonModule,
    FormElementsModule,
    ModalDialogLayoutModule,
    TranslateModule,
    MatSlideToggleModule,
  ],
  providers: [ProfileTagManagerService],
})
export class ProfileTagsModule {}
