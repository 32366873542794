import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabComponent } from './tab/tab.component';
import { TabsComponent } from './tabs/tabs.component';
import { RouterModule } from '@angular/router';
import { TabsRouterComponent } from './tabs-router/tabs-router.component';

@NgModule({
  declarations: [TabComponent, TabsComponent, TabsRouterComponent],
  exports: [TabComponent, TabsComponent, TabsRouterComponent],
  imports: [CommonModule, RouterModule],
})
export class TabsModule {}
