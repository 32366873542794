import { ContentChild, Directive, ElementRef, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { DropdownContentDirective } from './dropdown-content.directive';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { DropdownToggleDirective } from './dropdown-toggle.directive';
import { DropdownService } from './dropdown.service';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[libDropdown]',
  exportAs: 'libDropdown',
  providers: [DropdownService],
})
export class DropdownDirective implements OnInit, OnDestroy {
  @Input() activeByDefault = false;
  @Input() hideByOutsideClick = false;

  @ContentChild(DropdownContentDirective, { static: true }) private content: DropdownContentDirective;
  @ContentChild(DropdownToggleDirective, { static: true }) private contentToggle: DropdownToggleDirective;

  public active$: Observable<boolean> = this.service.active$;

  private subs: Subscription[] = [];

  constructor(
    private service: DropdownService,
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId,
  ) {}

  ngOnInit() {
    if (!this.content) {
      throw new Error('Dropdown content is not specified');
    }

    if (!this.contentToggle) {
      throw new Error('Dropdown content toggle is not specified');
    }

    this.service.setActive(this.activeByDefault);

    if (this.hideByOutsideClick) {
      this.subscribeToOutsideClickEvent();
    }
  }

  hideDropdown() {
    this.service.setActive(false);
  }

  openDropdown() {
    this.service.setActive(true);
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  private subscribeToOutsideClickEvent() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const sub = fromEvent(document, 'click')
      .pipe(
        map((event: MouseEvent) => event.target),
        filter((target: HTMLElement) => !this.elementRef.nativeElement.contains(target)),
      )
      .subscribe(() => {
        this.service.setActive(false);
      });

    this.subs.push(sub);
  }
}
