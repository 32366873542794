import { UserInformationCenterManagerService } from './../../../../lazy-modules/user-information-center/user-information-center-manager.service';
import { ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UserProfileDataService } from '@project/services';
import { Observable } from 'rxjs';
import { IProfileFull, IRequisitionNotification } from '@project/view-models';

@Component({
  selector: 'app-sidebar-user-card',
  templateUrl: './sidebar-user-card.component.html',
  styleUrls: ['./sidebar-user-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarUserCardComponent implements OnInit {
  public userData$: Observable<IProfileFull | null> = this.userProfileDataService.profile$;
  public unreadNotifications: IRequisitionNotification[];

  constructor(
    private userProfileDataService: UserProfileDataService,
    private userInformationCenterManagerService: UserInformationCenterManagerService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.userInformationCenterManagerService.unreadNotifications$.subscribe((unreadNotifications) => {
      this.unreadNotifications = unreadNotifications;
      this.changeDetectorRef.markForCheck();
    });
  }
}
