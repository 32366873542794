import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { AuthorisationStateService } from '../authorisation-state.service';

@Injectable({
  providedIn: 'root',
})
export class NotAuthorizedGuard implements CanActivate, CanLoad {
  constructor(private authorizationStateService: AuthorisationStateService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkWithRedirect();
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    return this.checkWithRedirect();
  }

  private checkWithRedirect(): boolean {
    const authorized = this.authorizationStateService.isAuthorized();

    if (authorized) {
      this.router.navigateByUrl('/');
    }
    return !authorized;
  }
}
