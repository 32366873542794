import { ITaskShort, ITaskShortDTO, TaskShortViewModelFactory } from './task-short.view-model';
import { IRequisitionDTO } from './dto/requisition.dto';
import { IRequisition, RequisitionViewModelFactory } from './requisition.view-model';

export interface ITaskFullDTO extends ITaskShortDTO {
  requisition: IRequisitionDTO;
}

export interface ITaskFull extends ITaskShort {
  requisition: IRequisition;
}

export class TaskFullViewModelFactory {
  static createGetViewModel(dto: ITaskFullDTO): ITaskFull {
    return {
      ...TaskShortViewModelFactory.createGetViewModel(dto),
      requisition: RequisitionViewModelFactory.createGetViewModel(dto.requisition),
    };
  }
}
