import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  Type,
} from '@angular/core';
import {
  EProfileTypeDTO,
  IDoctor,
  IDoctorToInvite,
  IPatientToInvite,
  IUsersInvitationResult,
  TUserToInvite,
  TUserToInviteDTO,
} from '@project/view-models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { NotificationsService } from '@lib/notifications';
import { TranslateService } from '@project/translate';
import { IModalComponent, IModalComponentRef, ModalOverlayService } from '@lib/modal';
import { UserInviteService } from '../user-invite.service';

import { DoctorInviteImportModalComponent } from '../doctors/doctor-invite-import-modal/doctor-invite-import-modal.component';
import { PatientInviteImportModalComponent } from '../patients/patient-invite-import-modal/patient-invite-import-modal.component';
import { ReceptionistInviteImportModalComponent } from '../receptionists/receptionist-invite-import-modal/receptionist-invite-import-modal.component';
import { ExportToCsv } from 'export-to-csv';
import { TGuid } from '@core/helpers';

@Component({
  selector: 'app-user-invite',
  templateUrl: './user-invite.component.html',
  styleUrls: ['./user-invite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInviteComponent implements OnDestroy, OnChanges {
  @Input() userType: EProfileTypeDTO;
  @Input() pageTitle: string;
  @Input() editableUser: TUserToInvite;
  @Input() isPending = false;
  @Input() isEditing: string;
  @Input() fatherPatientId?: TGuid;
  @Input() importDesable? = false;
  @Input() fatherTagId?: TGuid;

  @Output() invitedUsers = new EventEmitter<boolean>();
  @Output() editedUser = new EventEmitter<Record<string, unknown>>();

  public _errorMessage$ = new BehaviorSubject<TUserToInviteDTO[]>([]);
  public inviteError$ = this._errorMessage$.asObservable();

  private _usersList$ = new BehaviorSubject<TUserToInvite[]>([]);
  public usersList$ = this._usersList$.asObservable();

  public hasUsersToInvite$: Observable<boolean> = this.usersList$.pipe(map((users) => !!users.length));

  private _inProgress$ = new BehaviorSubject<boolean>(false);
  public inProgress$ = this._inProgress$.asObservable();

  public readonly profileTypes = EProfileTypeDTO;

  private csvImportModalRef?: IModalComponentRef<any>;
  private destroy$ = new Subject();

  private readonly importModalsByUserTypes: { [type in EProfileTypeDTO]?: Type<IModalComponent> } = {
    [EProfileTypeDTO.Doctor]: DoctorInviteImportModalComponent,
    [EProfileTypeDTO.Patient]: PatientInviteImportModalComponent,
    [EProfileTypeDTO.Receptionist]: ReceptionistInviteImportModalComponent,
  };

  public canImportFromFile = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userType) {
      this.canImportFromFile = !!this.importModalsByUserTypes[this.userType];
    }
  }

  constructor(
    private notificationsService: NotificationsService,
    private userInviteService: UserInviteService,
    private modalOverlayService: ModalOverlayService,
  ) {}

  addUser(userData: TUserToInvite) {
    if (this.isEditMode) {
      if (this.userType === EProfileTypeDTO.Doctor) {
        this.editedUser.emit({
          ...this.editableUser,
          firstName: userData.first_name,
          lastName: userData.last_name,
          gender: userData.gender,
          specialization: (userData as IDoctorToInvite).specialization,
          phone: userData.phone,
          licenses: (userData as IDoctorToInvite).licenses,
          birthDate: new Date(userData.date_birth),
          email: userData.email,
          cpf: (userData as IDoctorToInvite).cpf,
          tag: userData.tag ?? null,
        });
      } else {
        this.editedUser.emit({
          ...this.editableUser,
          firstName: userData.first_name,
          lastName: userData.last_name,
          gender: userData.gender,
          phone: userData.phone,
          birthDate: new Date(userData.date_birth),
          email: userData.email,
          accessToken: userData.access_token,
          tag: userData.tag ?? null,
        });
      }

      return;
    }

    const usersToInvite = this._usersList$.value;
    if (usersToInvite.find((userToInvite) => userToInvite.email === userData.email)) {
      this.notificationsService.error({
        message: TranslateService.localize('invitations.errors.email-duplicate'),
      });
      return;
    }

    if (this.userType === EProfileTypeDTO.Patient && this.fatherPatientId) {
      (userData as IPatientToInvite).father_patient_id = this.fatherPatientId;
    }

    this._usersList$.next(this._usersList$.value.concat([userData]));
  }

  addUsers(usersData: TUserToInvite[]) {
    const usersToInvite = this._usersList$.value;
    const filteredUsersToAdd = usersData
      // Filter duplicates by email in batch
      .filter((userToAdd, index) => usersData.findIndex((user) => user.email === userToAdd.email) === index)
      // Filter duplicates by email with list to invite
      .filter((userToAdd) => !usersToInvite.find((userToInvite) => userToInvite.email === userToAdd.email));

    if (usersData.length !== filteredUsersToAdd.length) {
      this.notificationsService.error({
        message: TranslateService.localize('invitations.errors.email-duplicate-multi'),
      });
    }

    this._usersList$.next(this._usersList$.value.concat(filteredUsersToAdd));
  }

  sendInvitations() {
    const users = this._usersList$.value;

    this._inProgress$.next(true);

    this.userInviteService.InviteAllUsers(users, this.userType).subscribe((result: IUsersInvitationResult) => {
      if (result.count_failed === 0 && result.count_success === 0 && result.errorMessages.length > 0) {
        let html = '<table>';
        result.errorMessages.map((x) => {
          html += '<tr><td>' + x + '</td></tr>';
        });
        html += '</table>';

        this.notificationsService.error({
          message: html,
          durationMs: 5000,
        });
      } else {
        if (!result.isSuccess) {
          const success = result.count_success;
          const failed = result.count_failed;

          let html = '<table>';
          result.errorMessages.map((x) => {
            html += '<tr><td>' + x.replace(',', '') + '</td></tr>';
          });
          html +=
            '<tr><td>[Total: ' + (failed + success) + ', Success: ' + success + ', Failed: ' + failed + ']<td></tr>';
          html += '</table>';

          this._errorMessage$.next(result.failedUsersFormData);

          this.notificationsService.error({
            message: html,
            durationMs: 15000,
          });
        } else {
          this.notificationsService.success({
            message: TranslateService.localize('success-messages.users-are-invited'),
          });
          this.invitedUsers.next(true);
        }
      }

      this._usersList$.next([]); // TODO need to keep users with errors and ability to edit it
      this._inProgress$.next(false);
    });
  }

  removeUserFromList(userData: TUserToInvite) {
    const usersToInvite = this._usersList$.value;
    this._usersList$.next(usersToInvite.filter((userDataInList) => userDataInList.email !== userData.email));
  }

  importUsers() {
    const modalComponent = this.importModalsByUserTypes[this.userType];
    this.csvImportModalRef = this.modalOverlayService.openOverlay(modalComponent);
    this.csvImportModalRef.submit$.pipe(takeUntil(this.destroy$)).subscribe((users) => this.addUsers(users));
  }

  getModelInviteInvalids(data: TUserToInviteDTO) {
    return {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      gender: data.gender,
      ...data,
      phone: data.phone,
      date_birth: data.date_birth ? data.date_birth : '',
    };
  }

  downloadInvalidsCsv(): void {
    this.inviteError$.subscribe((users: TUserToInviteDTO[]) => {
      const mappedUsers = users.map((data) => {
        delete data.tag;
        delete data.http_execute;
        return this.getModelInviteInvalids(data);
      });

      const csvExporter = new ExportToCsv({
        fieldSeparator: ';',
        filename: 'InvilidList',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: false,
        showTitle: false,
        title: '',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        headers: [],
      });

      if (mappedUsers.length > 0) {
        csvExporter.generateCsv(mappedUsers);
      }
    });
  }

  ngOnDestroy(): void {
    this.csvImportModalRef?.close();
    this.destroy$.next();
    this._errorMessage$.next([]);
    this.invitedUsers.next(false);
  }

  get isEditMode(): boolean {
    return this.editableUser != null;
  }
}
