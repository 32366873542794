import { DateFormatter, TDateISOString } from '@core/helpers';
import { EObservationMethodDTO } from './consts/observation-consts';
import {
  IObservationBinahParameterValue,
  ObservationBinahParameterValueViewModelFactory,
} from './observation-binah-parameter-value.view-model';
import { TBinalDataDTO } from './consts/observation-binah-consts';

type TPossibleObservationData = TBinalDataDTO; // for extend like: TBinalDataDTO | TSomeAnyValue

export interface IObservationDataSnapshotDTO {
  created_date: TDateISOString;
  data: TPossibleObservationData;
}

type TPossibleObservationDataValue = IObservationBinahParameterValue[];

export interface IObservationDataSnapshot<
  DataType extends TPossibleObservationDataValue = TPossibleObservationDataValue
> {
  createdDate: Date | null;
  method: EObservationMethodDTO;
  isErrorDuringDataParsing?: boolean;
  data: DataType;
}

export class ObservationDataSnapshotViewModelFactory {
  static createFromDTO(dto: IObservationDataSnapshotDTO, method: EObservationMethodDTO): IObservationDataSnapshot {
    const dataParserHandlers: Record<
      EObservationMethodDTO,
      (rawData: TPossibleObservationData) => TPossibleObservationDataValue
    > = {
      [EObservationMethodDTO.Binah]: this.createBinahDataFromDTO,
    };

    let data: TPossibleObservationDataValue = [];
    let isErrorDuringParsing = false;

    try {
      data = dataParserHandlers[method](dto.data);
    } catch (e) {
      isErrorDuringParsing = true;
    }

    return {
      createdDate: dto.created_date ? DateFormatter.stringToDate(dto.created_date) : null,
      method,
      data: data.sort((a, b) => (a.parameter > b.parameter ? 1 : -1)),
      isErrorDuringDataParsing: isErrorDuringParsing,
    };
  }

  private static createBinahDataFromDTO(rawData: TBinalDataDTO): IObservationBinahParameterValue[] {
    return ObservationBinahParameterValueViewModelFactory.map(rawData);
  }
}
