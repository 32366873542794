<div class="fallback">
  <svg viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M46.5 15.5C46.5 24.0637 39.5638 31 31 31C22.4363 31 15.5 24.0637 15.5 15.5C15.5 6.93625 22.4363 0 31 0C39.5638 0 46.5 6.93625 46.5 15.5ZM38.75 15.5C38.75 11.2375 35.2625 7.75 31 7.75C26.7375 7.75 23.25 11.2375 23.25 15.5C23.25 19.7625 26.7375 23.25 31 23.25C35.2625 23.25 38.75 19.7625 38.75 15.5ZM31 34.875C20.6537 34.875 0 40.0675 0 50.375V58.125C0 60.2562 1.74375 62 3.875 62H58.125C60.2562 62 62 60.2562 62 58.125V50.375C62 40.0675 41.3462 34.875 31 34.875ZM7.75 50.4137V54.25H54.25V50.375C53.475 47.6237 41.4625 42.625 31 42.625C20.5375 42.625 8.525 47.6237 7.75 50.4137Z"
    />
  </svg>
</div>
<div *ngIf="url" class="image" [libBackgroundImage]="url"></div>
