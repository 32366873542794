import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChatRoomService } from '../chat-room.service';
import { UserProfileDataService } from '@project/services';

@Component({
  selector: 'app-chat-form',
  templateUrl: './chat-form.component.html',
  styleUrls: ['./chat-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatFormComponent implements OnInit, OnDestroy {
  public form = new FormGroup({
    message: new FormControl(''),
  });

  public isShouldPreventMessageSending$ = this.chatRoomService.isShouldPreventMessageSending$;
  public isUploading$ = this.chatRoomService.isUploadingFile$;

  private destroy$ = new Subject();

  constructor(private chatRoomService: ChatRoomService, private profileService: UserProfileDataService) {}

  ngOnInit() {
    this.isShouldPreventMessageSending$.pipe(takeUntil(this.destroy$)).subscribe((isShouldPreventMessageSending) => {
      if (isShouldPreventMessageSending) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  onMessageEnter(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.submit();
  }

  submit() {
    const { message } = this.form.value;
    this.form.reset();

    if (!message) {
      return;
    }

    const messageToSend = message.trim();

    if (!messageToSend) {
      return;
    }

    this.chatRoomService.sendMessage(messageToSend);
  }

  onFileChange(event) {
    const files = event.target.files;
    if (files.length > 0) {
      this.chatRoomService.uploadFiles(files);
    }
  }

  get isUserPatient(): boolean {
    return this.profileService.profile.isPatient;
  }
}
