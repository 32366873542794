import { NgModule } from '@angular/core';
import { PagingControlComponent } from './paging-control.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [PagingControlComponent],
  imports: [CommonModule],
  exports: [PagingControlComponent],
  providers: [],
})
export class PagingControlModule {}
