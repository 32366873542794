import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { TGuid } from '@core/helpers';
import { isPlatformBrowser } from '@angular/common';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { ChatRoomService, IChatMessageFull } from '../chat-room.service';
import { asyncScheduler, scheduled, Subject } from 'rxjs';

const SAFE_BOTTOM_POSITION_TO_SCROLL_TO_THE_END_PX = 100;
const LOAD_MORE_MESSAGES_SCROLL_TOP_THRESHOLD_PX = 220;

@Component({
  selector: 'app-chat-messages-list',
  templateUrl: './chat-messages-list.component.html',
  styleUrls: ['./chat-messages-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessagesListComponent implements OnInit, OnDestroy {
  public messagesWithMeta$ = this.chatRoomService.chatRoomFullMessages$.pipe(
    tap(() => {
      if (isPlatformBrowser(this.platformId)) {
        this.checkScrollToTheEnd();
      }
    }),
  );

  public isLoading = true;
  public chatMessagesFullyLoaded = true;
  public loadingPreviousMessages = false;

  public readonly MIN_MESSAGES_COUNT_TO_SHOW_IS_FULLY_LOADED_INFO = 50;

  private destroy$ = new Subject();

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private elementRef: ElementRef<HTMLElement>,
    private chatRoomService: ChatRoomService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.chatRoomService.isChatLoading$.pipe(takeUntil(this.destroy$)).subscribe((isLoading) => {
      this.isLoading = isLoading;
      this.changeDetectorRef.markForCheck();
    });

    this.chatRoomService.chatMessagesFullyLoaded$.pipe(takeUntil(this.destroy$)).subscribe((fullyLoaded) => {
      this.chatMessagesFullyLoaded = fullyLoaded;
      this.changeDetectorRef.markForCheck();
    });
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event) {
    const container = event.target as HTMLDivElement;

    if (!this.isLoading) {
      this.checkForLoadMoreMessages(container);
    }
  }

  trackById(index, item: IChatMessageFull): TGuid {
    return item.id;
  }

  private checkForLoadMoreMessages(container: HTMLDivElement) {
    if (this.chatMessagesFullyLoaded || this.loadingPreviousMessages) {
      return;
    }

    if (container.scrollTop < LOAD_MORE_MESSAGES_SCROLL_TOP_THRESHOLD_PX) {
      this.loadingPreviousMessages = true;
      const previousListHeight = container.scrollHeight;

      this.chatRoomService
        .loadMoreMessages()
        .pipe(
          switchMap(() => scheduled(this.messagesWithMeta$, asyncScheduler)), // Next operators will executed after messages was rendered
          tap(() => {
            /*
             For cases when we scrolled top before new data fetched (move to start of fetched messages)
            */
            if (container.scrollHeight - container.scrollTop > previousListHeight) {
              requestAnimationFrame(() => (container.scrollTop = container.scrollHeight - previousListHeight));
            }
          }),
          takeUntil(this.destroy$),
        )
        .subscribe(() => (this.loadingPreviousMessages = false));
    }
  }

  private checkScrollToTheEnd() {
    const { scrollHeight, scrollTop, clientHeight } = this.elementRef.nativeElement;

    const scrollPositionBeforeEnd = scrollHeight - scrollTop - clientHeight;

    if (scrollPositionBeforeEnd < SAFE_BOTTOM_POSITION_TO_SCROLL_TO_THE_END_PX) {
      this.scrollToBottom();
    }
  }

  private scrollToBottom() {
    requestAnimationFrame(() => (this.elementRef.nativeElement.scrollTop = this.elementRef.nativeElement.scrollHeight));
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
