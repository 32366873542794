import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecondsToTimePipe } from './seconds-to-time/seconds-to-time.pipe';

@NgModule({
  declarations: [SecondsToTimePipe],
  imports: [CommonModule],
  exports: [SecondsToTimePipe],
})
export class TimeFormatterModule {
  static stringToDate(deleted_at: null): Date {
    throw new Error('Method not implemented.');
  }
}
