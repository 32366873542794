import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  private configSubject = new BehaviorSubject<any>({
    disable_left_nav: false,
    only_appointment: false,
    only_schedule: false,
  });

  config$ = this.configSubject.asObservable();

  constructor() {
    const storedConfig = JSON.parse(localStorage.getItem('featureConfig'));
    if (storedConfig) {
      this.configSubject.next({ ...storedConfig });
    }
  }

  getConfig(): Observable<any> {
    return this.config$;
  }

  setConfig(key: string, value: any) {
    const updatedConfig = { ...this.configSubject.value, [key]: value };
    this.configSubject.next(updatedConfig);

    localStorage.setItem('featureConfig', JSON.stringify(updatedConfig));
  }
}
