import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ChatRoomService } from '../chat-room.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { asapScheduler, combineLatest, Observable } from 'rxjs';
import { map, observeOn } from 'rxjs/operators';
import { BroadcastEventsService } from '@core/services';
import { ChatEnterEvent, ChatLeaveEvent } from '@project/shared';
import { IRequisition } from '@project/view-models';

enum EState {
  Loading = 'Loading',
  Offline = 'Offline',
}

@Component({
  selector: 'app-chat-room',
  templateUrl: './chat-room.component.html',
  styleUrls: ['./chat-room.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ChatRoomService],
  animations: [
    trigger('loader', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate('0.5s ease-out'),
      ]),
      transition(':leave', [
        animate(
          '0.25s ease-in',
          style({
            opacity: 0,
          }),
        ),
      ]),
    ]),
  ],
})
export class ChatRoomComponent implements OnChanges, OnInit, OnDestroy {
  @Input() requisition: IRequisition;
  @Input() isReadOnly: boolean;

  public state$: Observable<EState> = combineLatest([
    this.chatRoomService.isChatLoading$,
    this.chatRoomService.isOffline$,
  ]).pipe(
    map(([isFetching, isOffline]) => {
      if (isFetching) {
        return EState.Loading;
      }

      if (isOffline) {
        return EState.Offline;
      }

      return null;
    }),
    observeOn(asapScheduler), // Without this we have some issues with using this observable inside view (look like it's angular issue)
  );

  public readonly states = EState;

  constructor(private chatRoomService: ChatRoomService, private broadcastEventsService: BroadcastEventsService) {}

  ngOnInit(): void {
    if (this.requisition?.chatId) {
      this.broadcastEventsService.broadcast(new ChatEnterEvent(this.requisition.chatId));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.requisition) {
      this.chatRoomService.setRequisition(this.requisition);
    }
  }

  ngOnDestroy(): void {
    if (this.requisition?.chatId) {
      this.broadcastEventsService.broadcast(new ChatLeaveEvent(this.requisition.chatId));
    }
  }
}
