export interface ISendGridResponseGeneral {
  id: string;
  created: number;
  email: string;
}

export interface ISendGridResponse extends ISendGridResponseGeneral {
  reason: string;
  status?: string;
}

export interface ISendGridResponseSpam extends ISendGridResponseGeneral {
  ip: string;
}

export interface ISendGridResponseDTO {
  id: string;
  created: number;
  email: string;
  reason: string;
  status?: string;
}

export interface ISendGridResponseSpamDTO {
  id: string;
  created: number;
  email: string;
  ip: string;
}

export class SendGridResponseViewModelFactory {
  static createFromDTO(dto: ISendGridResponseDTO): ISendGridResponse {
    const id = dto.id;
    const created = dto.created;
    const email = dto.email;
    const reason = dto.reason;
    const status = dto.status;

    return {
      id: dto.id,
      created: dto.created,
      email: dto.email,
      reason: dto.reason,
      status: dto.status,
    };
  }

  static createSpamFromDTO(dto: ISendGridResponseSpamDTO): ISendGridResponseSpam {
    const id = dto.id;
    const created = dto.created;
    const email = dto.email;
    const ip = dto.ip;

    return {
      id: dto.id,
      created: dto.created,
      email: dto.email,
      ip: dto.ip,
    };
  }
}
