import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class HttpTimezoneInterceptorService implements HttpInterceptor {
  constructor(@Inject(PLATFORM_ID) private platformId) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let timezone = '';

    if (isPlatformBrowser(this.platformId)) {
      try {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || '';
      } catch (e) {
        console.error(e);
      }
    }

    const headers = req.headers.set('PTM-Timezone', timezone);

    const newRequest = req.clone({
      headers,
    });

    return next.handle(newRequest);
  }
}
