<form [formGroup]="form" (ngSubmit)="doDirtySubmit()">
  <ng-container *ngFor="let ruleFormGroup of form.get('rules')['controls']; let ruleIndex = index">
    <app-accordion class="mb-6" [isOpened]="true" [useDefaultBodyStyle]="false">
      <div accordionHeader class="row row--align-center">
        <div class="col">
          <b>{{ "working-hours.schedule.new-rule-title" | translate }}</b>
        </div>
        <div class="col-content">
          <button libButton appearance="grey-outline" size="s" (click)="removeRule(ruleIndex)">
            {{ "actions.remove" | translate }}
          </button>
        </div>
      </div>
      <app-scheduler-rule-nested-form [parentFormGroup]="ruleFormGroup"></app-scheduler-rule-nested-form>
    </app-accordion>
  </ng-container>

  <lib-form-control [targetFormControl]="rulesControl"></lib-form-control>

  <button type="button" libButton [appearance]="'outline'" [size]="'s'" (click)="addRuleTemplate()">
    {{ "working-hours.schedule.add-rule-button-label" | translate }}
  </button>

  <br />
  <br />
  <div *ngIf="intersectionsErrors$ | async as intersectionErrors">
    <section class="mb-2 text-danger" *ngFor="let intersection of intersectionErrors">
      {{
        "working-hours.intersecting-error.interval-description#from#to"
          | translate: { from: intersection.start | date: "short", to: intersection.end | date: "short" }
      }}
      <ul>
        <li *ngFor="let source of intersection.sources">
          {{
            "working-hours.intersecting-error.rule-description#ruleIndex#workingHoursRuleIndex"
              | translate: { ruleIndex: source.ruleId + 1, workingHoursRuleIndex: source.workingHourId + 1 }
          }}
        </li>
      </ul>
    </section>
  </div>
  <div class="row row--align-center">
    <div class="col text-align-right">
      {{ "working-hours.schedule-form.labels.effective-from" | translate }}
    </div>
    <div class="col-content">
      <lib-input
        class="effective-from-field"
        formControlName="effective_from"
        [size]="'m'"
        [mask]="dateFieldMask"
        [placeholder]="dateFieldPlaceholder"
      ></lib-input>
    </div>
    <div class="col-content">
      <button
        type="submit"
        libButton
        [size]="'m'"
        [disabled]="!(formValid$ | async) || (submitting$ | async)"
        [isPending]="submitting$ | async"
      >
        {{ "actions.submit" | translate }}
      </button>
    </div>
  </div>
</form>
