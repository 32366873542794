import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ListTableManagerService } from '../list-table-manager.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tr[appListTableHead]',
  templateUrl: './list-items-table-header.component.html',
  styleUrls: ['./list-items-table-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemsTableHeaderComponent {
  public allowSelection$ = this.listTableManagerService.allowSelection$;
  public readonly isCurrentPageSelected$ = this.listTableManagerService.isCurrentPageSelected$;

  constructor(private listTableManagerService: ListTableManagerService) {}

  onSelectionChange(isSelected: boolean) {
    this.listTableManagerService.setCurrentPageSelection(isSelected);
  }
}
