<form [formGroup]="form" (ngSubmit)="doDirtySubmit()">
  <div class="row">
    <div class="col-6">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.first-name" | translate }}</span>
          <lib-input formControlName="firstName" name="firstName"></lib-input>
        </label>
      </lib-form-control>
    </div>
    <div class="col-6">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.last-name" | translate }}</span>
          <lib-input formControlName="lastName" name="lastName"></lib-input>
        </label>
      </lib-form-control>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.cpf-number" | translate }}</span>
          <lib-input formControlName="cpf" name="cpf"></lib-input>
        </label>
      </lib-form-control>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.email" | translate }}</span>
          <lib-input formControlName="email" name="email" [trim]="true"></lib-input>
        </label>
      </lib-form-control>
    </div>
    <div class="col-12">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.gender" | translate }}</span>
          <lib-select [options]="genderOptions" formControlName="gender"></lib-select>
        </label>
      </lib-form-control>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.specialization" | translate }}</span>
          <lib-select
            [options]="doctorSpecializationOptions"
            formControlName="specialization"
            [size]="'m'"
            (change)="changeSpecializationPsychologist($event.target.value)"
          ></lib-select>
        </label>
      </lib-form-control>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.phone" | translate }}</span>
          <lib-input formControlName="phone" name="phone" [size]="'m'"></lib-input>
        </label>
      </lib-form-control>
    </div>
    <div class="col-6">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.date-of-birth" | translate }}</span>
          <lib-input
            formControlName="dateOfBirth"
            name="dateOfBirth"
            [mask]="dateInputMask"
            [placeholder]="dateInputPlaceholder"
            [size]="'m'"
          ></lib-input>
        </label>
      </lib-form-control>
    </div>
  </div>

  <div id="licenses" formArrayName="licenses">
    <div class="row" *ngFor="let licenseForm of licenses.controls; let i = index" [formGroup]="licenseForm">
      <div class="col-4">
        <lib-form-control>
          <label>
            <span libLabel>{{ "nouns.state" | translate }}</span>
            <lib-select [options]="states$ | async" formControlName="state_id" [size]="'m'"></lib-select>
          </label>
        </lib-form-control>
      </div>
      <div class="col-4">
        <lib-form-control>
          <label>
            <span libLabel>{{ "nouns.license" | translate }}</span>
            <lib-input
              formControlName="license"
              type="number"
              [size]="'m'"
              (keyup)="setLicenceToValidate($event.target.value)"
            >
            </lib-input>
          </label>
        </lib-form-control>
      </div>

      <div class="col-2">
        <lib-form-control>
          <label>
            <span libLabel>{{ "actions.remove" | translate }}</span>
            <button class="btn-remove" libButton [appearance]="'outline'" [size]="'m'" (click)="removeLicense(i)">
              X
            </button>
          </label>
        </lib-form-control>
      </div>
    </div>
  </div>

  <div class="col-2 addButton">
    <lib-form-control>
      <label>
        <button libButton [appearance]="'outline'" [size]="'m'" (click)="addLicense(0)">
          +
        </button>
      </label>
    </lib-form-control>
  </div>

  <div class="row" *ngIf="doctor == null">
    <div class="col-12">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.group" | translate }}</span>
        </label>
        <app-doctor-invite-control></app-doctor-invite-control>
      </lib-form-control>
    </div>
  </div>

  <button
    libButton
    [isPending]="isPending"
    type="submit"
    [disabled]="!(formValid$ | async)"
    [appearance]="'outline'"
    [size]="'m'"
  >
    {{ doctor == null ? ("actions.add-to-list" | translate) : ("doctors.edit-button" | translate) }}
  </button>
</form>
