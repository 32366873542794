import { DateFormatter, TDateISOString, TGuid } from '@core/helpers';
import { ENotificationCenterTypeDTO } from './consts/requisition-consts';

export interface IRequisitionNotificationDTO {
  id: TGuid;
  title: string;
  subtitle: string;
  message: string;
  type: ENotificationCenterTypeDTO;
  time: TDateISOString;
  read_at: TDateISOString | null;
}

export interface IRequisitionNotification {
  id: TGuid;
  title: string;
  subtitle: string;
  message: string;
  type: ENotificationCenterTypeDTO;
  time: Date;
  read_at: Date | null;
}

export class RequisitionNotificationItemViewModelFactory {
  static createGetViewModel(dto: IRequisitionNotificationDTO): IRequisitionNotification {
    return {
      id: dto.id,
      title: dto.title,
      subtitle: dto.subtitle,
      message: dto.message,
      type: dto.type,
      time: dto.time ? DateFormatter.stringToDate(dto.time) : null,
      read_at: dto.read_at ? DateFormatter.stringToDate(dto.read_at) : null,
    };
  }
}
