import { ChangeDetectionStrategy, Component, InjectionToken, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ListTableManagerService } from '../list-table-manager.service';

@Component({
  selector: 'app-list-items-table',
  templateUrl: './list-items-table.component.html',
  styleUrls: ['./list-items-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ListTableManagerService],
})
export class ListItemsTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() listItemsServiceToken: InjectionToken<any>;
  @Input() allowSelection: boolean;

  constructor(private listTableManagerService: ListTableManagerService) {}

  ngOnInit(): void {
    if (!this.listItemsServiceToken) {
      if (this.allowSelection) {
        console.error('No token for app-list-items-table!!');
      }
      return;
    }

    this.listTableManagerService.init(this.listItemsServiceToken);
  }

  ngOnChanges() {
    this.listTableManagerService.setAllowedSelection(this.allowSelection);
  }

  ngOnDestroy() {
    this.listTableManagerService.destroy();
  }
}
