import { Component, OnDestroy, OnInit } from '@angular/core';
import { LogoutService, UserProfileDataService } from '@project/services';
import { EUserPermission } from '@project/shared';
import { CloseRequisitionsService } from '@features/modules';
import { ModalOverlayService } from '@lib/modal';

@Component({
  selector: 'app-authorized-user-features-container',
  template: '<router-outlet></router-outlet>',
})
export class AuthorizedUserFeaturesContainerComponent implements OnInit, OnDestroy {
  constructor(
    private userProfileDataService: UserProfileDataService,
    private logoutService: LogoutService,
    private modalOverlayService: ModalOverlayService,
    private closeRequisitionsService: CloseRequisitionsService,
  ) {}

  ngOnInit() {
    this.registerLogoutOperation();
  }

  ngOnDestroy() {
    this.modalOverlayService.closeAllOverlays();
  }

  private registerLogoutOperation() {
    if (this.userProfileDataService.hasPermissions([EUserPermission.haveToBeAskedToCloseRequisitionsOnLogout])) {
      this.logoutService.addLogoutOperation(
        () => this.closeRequisitionsService.askAndCloseRequisitionsIfExist().toPromise(),
        1,
      );
    }

    this.logoutService.addLogoutOperation(() => {
      this.userProfileDataService.clear();
      return Promise.resolve();
    }, Number.MAX_SAFE_INTEGER);
  }
}
