import { Component, EventEmitter, Input } from '@angular/core';
import { IModalComponent } from '@lib/modal';
import { EMediaDevices } from '@core/services';

@Component({
  selector: 'app-ask-for-media-devices-overlay',
  templateUrl: './ask-for-media-devices-overlay.component.html',
  styleUrls: ['./ask-for-media-devices-overlay.component.scss'],
})
export class AskForMediaDevicesOverlayComponent implements IModalComponent {
  @Input() mediaDeviceToAsk: EMediaDevices = null;

  public readonly mediaDevices = EMediaDevices;

  public submit$ = new EventEmitter();
  public close$ = new EventEmitter();
}
