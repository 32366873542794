import { NgModule } from '@angular/core';
import { RatingComponent } from './modal-rating.component';
import { TranslateModule } from '../../translate/translate.module';
import { ModalDialogLayoutModule, ButtonModule, FormElementsModule } from '@lib/ui';
import { BarRatingModule } from 'ngx-bar-rating';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [RatingComponent],
  imports: [
    CommonModule,
    BrowserModule,
    TranslateModule,
    ModalDialogLayoutModule,
    ButtonModule,
    FormElementsModule,
    BarRatingModule,
    MatSnackBarModule,
  ],
  exports: [RatingComponent],
})
export class RatingModule {}
