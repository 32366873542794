<input type="checkbox" [checked]="toggled$ | async" id="check" />
<label *ngIf="enabledLeftNav$ | async" for="check">
  <img [src]="urlMenu" alt="ícone de menu" (click)="setToggle(null)" />
</label>
<aside class="aside" *ngIf="toggled$ | async" [@fadeInOut]="!isDesktop">
  <app-sidebar class="sidebar" (respToggled)="stateCheckbox($event)"></app-sidebar>
</aside>
<main>
  <router-outlet></router-outlet>
</main>

<app-aside-outlet [outletName]="asideOutlets.SidebarAsideOutletPrimary"></app-aside-outlet>
<app-aside-outlet [outletName]="asideOutlets.SidebarAsideOutletSecondary"></app-aside-outlet>
