import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormComponent, INPUT_MASKS, INPUT_PLACEHOLDERS, SharedFormOptions } from '@project/shared';
import { ISelectOption } from '@lib/ui/form-elements';
import { FormBuilder } from '@angular/forms';
import { IPatient, IPatientToInvite, IProfileTag } from '@project/view-models';
import { PatientInviteUtils } from '../patient-invite.common';
import { EPatientsModuleAction, PatientInviteManagerService } from '../patient-invite-manager.service';
import { fillWithZeros } from 'src/app/core/helpers/utils/numbers/numbers';
import { TGuid } from '@core/helpers';
import { v4 as uuid } from 'uuid';
import { TranslateService } from '@project/translate';
import { NotificationsService } from '@lib/notifications';

@Component({
  selector: 'app-patient-invite-details-form',
  templateUrl: './patient-invite-details-form.component.html',
  styleUrls: ['./patient-invite-details-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientInviteDetailsFormComponent extends FormComponent implements OnInit {
  @Input() patient: IPatient;
  @Input() isPending = false;
  @Input() fatherPatientId?: TGuid = null;
  @Input() fatherTagId?: TGuid = null;

  @Output() dataSubmit = new EventEmitter<IPatientToInvite>();

  public form = this.fb.group(PatientInviteUtils.getPatientInviteFormDeclaration());

  public readonly genderOptions: ISelectOption[] = SharedFormOptions.genderOptions();
  public readonly dateInputMask = INPUT_MASKS.date;
  public readonly dateInputPlaceholder = INPUT_PLACEHOLDERS.date;
  public readonly tagFilter$ = this.patientInviteManagerService.tagFilter$;

  constructor(
    private fb: FormBuilder,
    private patientInviteManagerService: PatientInviteManagerService,
    private notificationsService: NotificationsService,
  ) {
    super();
  }

  protected onFormInit() {
    if (!this.fatherPatientId) {
      this.form.controls['email'].enable();
    } else {
      this.form = this.fb.group(PatientInviteUtils.getPatientInviteDependentFormDeclaration());
      this.patientInviteManagerService.dispatchAction(EPatientsModuleAction.SetTagById, this.fatherTagId);
      this.form.get('tag').setValue(this.fatherTagId);
    }

    this.form.get('gender').setValue(this.genderOptions[0].value);

    if (this.patient != null) {
      console.log(this.patient.birthDate);
      this.form.get('firstName').setValue(this.patient.firstName);
      this.form.get('lastName').setValue(this.patient.lastName);

      this.form.get('email').setValue(this.patient.email);

      this.form.get('gender').setValue(this.patient.gender);

      this.form.get('phone').setValue(this.patient.phone);

      if (this.patient.birthDate != null) {
        this.form
          .get('dateOfBirth')
          .setValue(
            `${fillWithZeros(this.patient.birthDate.getUTCDate(), 2)}.${fillWithZeros(
              this.patient.birthDate.getUTCMonth() + 1,
              2,
            )}.${this.patient.birthDate.getUTCFullYear()}`,
          );
      }

      if (this.patient.tags.length > 0) {
        this.patientInviteManagerService.dispatchAction(EPatientsModuleAction.SetTagById, this.patient.tags[0].id);
      }
    }
  }

  protected doCleanSubmit() {
    this.patientInviteManagerService.dispatchAction(EPatientsModuleAction.SetTagSelected);
    this.form.get('tag').patchValue(this.tagFilter$.value);

    if (!this.form.get('tag').value) {
      this.notificationsService.error({
        message: TranslateService.localize('errors.required-group'),
      });
      return;
    }

    if (this.fatherPatientId) {
      this.form.get('email').patchValue(uuid() + '+dep@gmail.com');
      this.form.controls['email'].enable();
    }

    const userData = PatientInviteUtils.createPatientInviteDto(this.form.value);

    this.dataSubmit.emit(userData);

    if (this.patient != null) {
      return;
    }

    const { gender } = this.form.value;
    this.form.reset();
    this.form.get('gender').patchValue(gender);
    this.patientInviteManagerService.dispatchAction(EPatientsModuleAction.ClearTagSelected);

    if (this.fatherPatientId) {
      this.form.controls['email'].disable();
      this.form.get('tag').setValue(this.fatherTagId);
      this.patientInviteManagerService.dispatchAction(EPatientsModuleAction.SetTagById, this.fatherTagId);
    }
  }
}
