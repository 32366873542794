import { IFeatureService } from '@project/services';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { SocketMessagesDataProviderService } from '@project/data-providers';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class PatientQueuePositionService implements IFeatureService {
  private readonly _patientQueuePosition$ = new BehaviorSubject<number>(null);
  public readonly patientQueuePosition$ = this._patientQueuePosition$.asObservable();

  private destroy$ = new Subject();

  constructor(private socketMessagesProviderService: SocketMessagesDataProviderService) {}

  initialise(): Observable<void> {
    this.initInfoUpdateBySocketMessages();
    return of(null);
  }

  destroy(): Observable<void> {
    this.destroy$.next();
    return of(null);
  }

  private initInfoUpdateBySocketMessages() {
    this.socketMessagesProviderService.queueInfoUpdate$.pipe(takeUntil(this.destroy$)).subscribe((info) => {
      this._patientQueuePosition$.next(info.length);
    });
  }
}
