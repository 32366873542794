import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChild, Input, OnDestroy } from '@angular/core';
import { AbstractControl, FormControlName } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'lib-form-control',
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormControlComponent implements OnDestroy, AfterContentInit {
  @Input() showOnlyFirstError = false;
  @Input() targetFormControl?: AbstractControl;
  @ContentChild(FormControlName) private child: FormControlName;

  private _messages$ = new BehaviorSubject<string[]>([]);
  public messages$ = this._messages$.asObservable();

  private subs: Subscription[] = [];

  ngAfterContentInit(): void {
    if (this.targetFormControl) {
      this.subscribeToErrors(this.targetFormControl);
    } else if (this.child?.control) {
      this.subscribeToErrors(this.child.control);
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  private subscribeToErrors(control: AbstractControl) {
    const sub = control.statusChanges.pipe(startWith(control.status)).subscribe(() => {
      const errors = this.extractErrorMessages(control.errors);
      this._messages$.next(errors);
    });
    this.subs.push(sub);
  }

  private extractErrorMessages(errorObj: object | null): string[] {
    if (!errorObj) {
      return [];
    }

    const errorKeys = this.showOnlyFirstError ? Object.keys(errorObj).slice(0, 1) : Object.keys(errorObj);
    return errorKeys.map((key) => (typeof errorObj[key] === 'string' ? errorObj[key] : ''));
  }
}
