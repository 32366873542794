import { Directive, Input } from '@angular/core';
import { EUserPermission } from '@project/shared';
import { HasAccess } from './has-access';
import { Observable } from 'rxjs';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hasAccess]',
})
export class HasAccessDirective extends HasAccess {
  @Input() set hasAccess(permissions: EUserPermission[]) {
    this.permissionsToCheck$.next(permissions || []);
  }

  protected checkPermissions(permissions: EUserPermission[]): Observable<boolean> {
    return this.userProfileDataService.hasPermissions$(permissions);
  }
}
