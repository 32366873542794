import { TGuid } from '@core/helpers';

export interface IHealthCenterDTO {
  id: TGuid;
  name: string | null;
  logo_url: string | null;
  has_vaccine_module: boolean | null;
  allow_measurement: boolean;
  patient_can_schedule: boolean;
}

export interface IHealthCenterCreateRequestPayloadDTO {
  name: string;
  has_vaccine_module: boolean;
  allow_mensuranment: boolean;
  domain: string;
  patient_can_schedule: boolean;
}

export interface IHealthCenter {
  id: TGuid;
  name: string | null;
  logoUrl: string | null;
  has_vaccine_module: boolean | null;
  allow_measurement: boolean | null;
  patient_can_schedule: boolean | null;
}

export class HealthCenterViewModelFactory {
  static createFromDTO(dto: IHealthCenterDTO): IHealthCenter {
    return {
      id: dto.id,
      name: dto.name,
      logoUrl: dto.logo_url,
      has_vaccine_module: dto.has_vaccine_module,
      allow_measurement: dto.allow_measurement,
      patient_can_schedule: dto.patient_can_schedule,
    };
  }
}
