import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractFeatureFlagsGuard } from '@core/services';
import { FeatureFlagService } from './feature-flag.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsGuard extends AbstractFeatureFlagsGuard {
  constructor(featureFlagService: FeatureFlagService, router: Router) {
    super(featureFlagService, router);
  }
}
