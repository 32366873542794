<main>
  <div *ngIf="mediaDeviceToAsk === mediaDevices.Audio">
    <div class="icon">
      <lib-icon type="mic-white"></lib-icon>
    </div>

    <h1 class="h1 text-align-center">
      <span>
        {{ "media-devices-overlay.ask.microphone.title" | translate }}
      </span>
    </h1>
  </div>

  <div *ngIf="mediaDeviceToAsk === mediaDevices.Video">
    <div class="icon">
      <lib-icon type="videocam-white"></lib-icon>
    </div>

    <h1 class="h1 text-align-center">
      <span>
        {{ "media-devices-overlay.ask.camera.title" | translate }}
      </span>
    </h1>
  </div>

  <div class="device" *ngIf="mediaDeviceToAsk === mediaDevices.Both">
    <div class="icon">
      <lib-icon type="videocam-white"></lib-icon>
      <lib-icon type="mic-white"></lib-icon>
    </div>

    <h1 class="h1 text-align-center">
      <span>
        {{ "media-devices-overlay.ask.both.title" | translate }}
      </span>
    </h1>
  </div>

  <p class="text-align-center">
    {{ "media-devices-overlay.ask.message" | translate }}
  </p>

  <button libButton appearance="light-muted-outline" size="l" (click)="close$.emit()">
    {{ "actions.cancel" | translate }}
  </button>
</main>
