import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputBasicDirective } from '../input-basic.directive';

type TSelectValue = string;
export interface ISelectOption {
  value: TSelectValue;
  label: string;
}

@Component({
  selector: 'lib-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent extends InputBasicDirective<string> implements OnInit, ControlValueAccessor {
  @Input() placeholder = ' ';
  @Input() options: ISelectOption[] = [];

  constructor(@Optional() controlContainer: ControlContainer) {
    super(controlContainer);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  writeValue(value: string) {
    this._value$.next(value ? value + '' : '');
  }

  onChangeHandler(value: TSelectValue) {
    if (this._disabled$.getValue()) {
      return;
    }
    this._value$.next(value);
    this.onChange(value);
  }
}
