import { NotificationsService } from '@lib/notifications';
import { IDoctorBasicDataDTO } from '@project/view-models';
import { TranslateService } from '@project/translate';

export function validateDoctorFields(
  doctorBasicData: IDoctorBasicDataDTO,
  notificationService: NotificationsService,
): boolean {
  const fieldTranslations = {
    doctor_id: TranslateService.localize('field.doctor.id'),
    name: TranslateService.localize('field.doctor.name'),
    last_name: TranslateService.localize('field.doctor.last.name'),
    date_of_bird: TranslateService.localize('field.doctor.date.of.bird'),
    cpf: TranslateService.localize('field.doctor.cpf'),
    crm: TranslateService.localize('field.doctor.crm'),
    uf: TranslateService.localize('field.doctor.uf'),
    health_center_id: TranslateService.localize('field.doctor.health.center.id'),
    specialist_id: TranslateService.localize('field.doctor.specialist.id'),
  };

  const requiredFields = Object.keys(fieldTranslations);
  const missingFields = requiredFields.filter((field) => !doctorBasicData[field]);

  if (missingFields.length > 0) {
    const translatedFields = missingFields.map((field) => fieldTranslations[field]);
    const missingFieldsMessage = `${TranslateService.localize('doctors.unfilled.field')} ${translatedFields.join(
      ', ',
    )}`;
    notificationService.error({ message: missingFieldsMessage });
    return false;
  }

  return true;
}
