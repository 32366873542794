import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { NotificationsService } from '@lib/notifications';
import { environment } from '@env';

type TShouldIgnoreUnhandledGlobalErrorCallback = (error: Error) => boolean;
export type TShouldIgnoreUnhandledGlobalErrorCallbackId = string;

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  private shouldIgnoreErrorCallbacksStore: Record<
    TShouldIgnoreUnhandledGlobalErrorCallbackId,
    TShouldIgnoreUnhandledGlobalErrorCallback
  > = {};

  constructor(private notificationsService: NotificationsService, private zone: NgZone) {}

  addShouldIgnoreErrorCallback(
    callback: TShouldIgnoreUnhandledGlobalErrorCallback,
  ): TShouldIgnoreUnhandledGlobalErrorCallbackId {
    const id: TShouldIgnoreUnhandledGlobalErrorCallbackId = Date.now() + '';
    this.shouldIgnoreErrorCallbacksStore[id] = callback;
    return id;
  }

  removeShouldIgnoreErrorCallback(id: TShouldIgnoreUnhandledGlobalErrorCallbackId) {
    delete this.shouldIgnoreErrorCallbacksStore[id];
  }

  handleError(error: Error) {
    if (!(error instanceof Error)) {
      return;
    }

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }

    const shouldIgnoreError = Object.keys(this.shouldIgnoreErrorCallbacksStore).some((id) => {
      const callback = this.shouldIgnoreErrorCallbacksStore[id];
      return callback(error);
    });

    if (shouldIgnoreError) {
      return;
    }

    if (!environment.production) {
      this.showErrorNotification(error);
    }

    this.logError(error);
  }

  private logError(error: Error) {
    const groupLabel = error.message?.slice(0, 100) || 'Error';
    console.groupCollapsed('%c%s', `color: #da4d4d; font-size: 1rem`, groupLabel);
    console.dir(error);
    console.groupEnd();
  }

  private showErrorNotification(error: Error) {
    this.zone.run(() => {
      this.notificationsService.error({
        title: error.name,
        message: error.message?.substr(0, 300),
        durationMs: Infinity,
      });
    });
  }
}
