import { Directive, HostListener, Input } from '@angular/core';
import { DropdownService } from './dropdown.service';

@Directive({
  selector: '[libDropdownToggle]',
})
export class DropdownToggleDirective {
  /**
   * Allowing toggle to close the dropdown on click, if dropdown is opened.
   * Set to false to prevent closing dropdown on toggle click and close dropdown by another way
   */
  @Input() canClose = true;
  @Input() canOpen = true;

  constructor(private service: DropdownService) {}

  @HostListener('click', ['$event'])
  public onClickHandler(event: MouseEvent) {
    if (event) {
      event.preventDefault();
    }

    if (this.service.active && !this.canClose) {
      return;
    }

    if (!this.service.active && !this.canOpen) {
      return;
    }

    this.service.toggleActive();
  }
}
