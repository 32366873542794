abstract class Time {
  constructor(protected rawValue: string) {}
  abstract isValid(): boolean;
}

class Hours extends Time {
  isValid(): boolean {
    return this.rawValue >= '00' && this.rawValue <= '23';
  }
}

class Minutes extends Time {
  isValid(): boolean {
    return this.rawValue >= '00' && this.rawValue <= '59';
  }
}

class Seconds extends Time {
  isValid(): boolean {
    return this.rawValue >= '00' && this.rawValue <= '59';
  }
}

enum ETimeFormatPlaceholders {
  Hours = 'HH',
  Minutes = 'MM',
  Seconds = 'SS',
}

function areSeparatorMatch(separators: string[], format: string, value: string): boolean {
  return separators.every((separator) => {
    const indexForFormat = format.indexOf(separator);
    const indexForValue = value.indexOf(separator);

    if (indexForFormat === indexForValue) {
      format = format.substr(indexForFormat + 1);
      value = value.substr(indexForValue + 1);
      return true;
    }

    return false;
  });
}

export function isTimeIn24FormatValid(value: string, format: string): boolean {
  if (value.length !== format.length) {
    return false;
  }

  const times: ETimeFormatPlaceholders[] = [
    ETimeFormatPlaceholders.Hours,
    ETimeFormatPlaceholders.Minutes,
    ETimeFormatPlaceholders.Seconds,
  ];

  if (!times.some((time) => format.indexOf(time) >= 0)) {
    return false;
  }

  function extractSeparators(source: string): string {
    return times.reduce((acc, item) => {
      return acc.replace(item, '');
    }, source);
  }

  const separators: string[] = extractSeparators(format).split('');

  /*const areSeparatorsMatchResult = extractSeparators(format)
    .split('')
    .every((separator) => {
      return format.indexOf(separator) === value.indexOf(separator);
    });*/

  const areSeparatorsMatchResult = areSeparatorMatch(separators, format, value);
  if (!areSeparatorsMatchResult) {
    return false;
  }

  const existedTimes = times.filter((time) => format.indexOf(time) >= 0);

  const timesValues: Time[] = existedTimes.map((placeholder) => {
    const startIndex = format.indexOf(placeholder);
    const timeValue = value.substr(startIndex, 2);
    if (placeholder === ETimeFormatPlaceholders.Hours) {
      return new Hours(timeValue);
    }

    if (placeholder === ETimeFormatPlaceholders.Minutes) {
      return new Minutes(timeValue);
    }

    if (placeholder === ETimeFormatPlaceholders.Seconds) {
      return new Seconds(timeValue);
    }
  });

  return timesValues.every((time) => time.isValid());
}
