import { LibValidators } from '@core/helpers';
import { ValidatorFn } from '@angular/forms';
import { TranslateService } from '@project/translate';

export function getPasswordValidators(): ValidatorFn[] {
  return [
    LibValidators.required(TranslateService.localize('validations.required')),
    LibValidators.pattern(
      /^(?=.*[\u00BF-\u1FFF\u2C00-\uD7FFA-Za-z])(?=.*[\d])/,
      TranslateService.localize('validations.numbers-and-letters-required'),
    ),
    LibValidators.minLength(9, TranslateService.localize('validations.min-length', { count: 9 })),
  ];
}
