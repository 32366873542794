import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OfflineStatusMessageComponent } from './offline-status-message/offline-status-message.component';

@NgModule({
  declarations: [OfflineStatusMessageComponent],
  exports: [OfflineStatusMessageComponent],
  imports: [CommonModule, RouterModule],
})
export class UserFeaturesModule {}
