import { TranslateService } from '@project/translate';
import { CsvParserService } from '@core/services';
import { FormBuilder } from '@angular/forms';
import { NotificationsService } from '@lib/notifications';
import { map, takeUntil } from 'rxjs/operators';
import { EGenderDTO } from '@project/view-models';
import { FormHelpers, IRowValidationResult } from '@core/helpers';
import { IPatientInviteFormModel, PatientInviteUtils } from '../patient-invite.common';
import { EPatientsModuleAction, PatientInviteManagerService } from '../patient-invite-manager.service';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy } from '@angular/core';
import { IModalComponent } from '@lib/modal';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-patient-invite-import-modal',
  templateUrl: 'patient-invite-import-modal.component.html',
  styleUrls: ['./patient-invite-import-modal.component.scss'],
})
export class PatientInviteImportModalComponent implements IModalComponent, OnDestroy {
  close$ = new EventEmitter<void>();
  submit$ = new EventEmitter();

  private _validItems$ = new BehaviorSubject<IRowValidationResult<IPatientInviteFormModel>[]>([]);
  public validItems$ = this._validItems$.asObservable();

  private _invalidItems$ = new BehaviorSubject<IRowValidationResult<IPatientInviteFormModel>[]>([]);
  public invalidItems$ = this._invalidItems$.asObservable();

  public readonly columnLabelsMap: { [K in keyof IPatientInviteFormModel]: string } = {
    firstName: TranslateService.localize('nouns.first-name'),
    lastName: TranslateService.localize('nouns.last-name'),
    email: TranslateService.localize('nouns.email'),
    gender: TranslateService.localize('nouns.gender'),
    phone: TranslateService.localize('nouns.phone'),
    dateOfBirth: TranslateService.localize('nouns.date-of-birth'),
    tag: TranslateService.localize('nouns.group'),
  };

  private readonly destroy$ = new Subject();
  private readonly tagFilter$ = this.patientInviteManagerService.tagFilter$;

  constructor(
    private csvParserService: CsvParserService,
    private formBuilder: FormBuilder,
    private notificationsService: NotificationsService,
    private changeDetectorRef: ChangeDetectorRef,
    private patientInviteManagerService: PatientInviteManagerService,
  ) {}

  close() {
    this.close$.emit();
  }

  doSuccess() {
    this.submit$.next(
      this._validItems$.value.map((records) => PatientInviteUtils.createPatientInviteDto(records.record)),
    );
  }

  onSelectFile(event: Event) {
    this._invalidItems$.next([]);
    this._validItems$.next([]);

    this.patientInviteManagerService.dispatchAction(EPatientsModuleAction.SetTagSelected);

    const fileInput = event.target as HTMLInputElement;
    const file = fileInput.files[0];
    if (!file) {
      return;
    }

    this.csvParserService
      .parseFile(file, ['firstName', 'lastName', 'email', 'gender', 'phone', 'dateOfBirth'])
      .pipe(
        map((records) =>
          records.map(
            (record): IPatientInviteFormModel => ({
              ...record,
              firstName: record.firstName.trim(),
              lastName: record.lastName.trim(),
              phone: record.phone.trim(),
              email: record.email.trim(),
              dateOfBirth: record.dateOfBirth.trim().replace(/[/]/g, '.'),
              gender: EGenderDTO[record.gender.trim()] ?? record.gender.trim(),
              tag: this.tagFilter$.value ?? '00000000-0000-0000-0000-000000000000',
            }),
          ),
        ),
        map((records) => {
          return FormHelpers.validateRecords(
            records,
            this.formBuilder.group(
              PatientInviteUtils.getPatientInviteFormDeclaration({
                emailEnabled: true,
              }),
            ),
          );
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (validatedResult: IRowValidationResult<IPatientInviteFormModel>[]) => {
          let invalid: IRowValidationResult<IPatientInviteFormModel>[] = [];
          let valid: IRowValidationResult<IPatientInviteFormModel>[] = [];

          invalid = validatedResult.filter((validatedRecord) => !validatedRecord.isValid);
          valid = validatedResult
            .filter((validatedRecord) => validatedRecord.isValid)
            .map((validatedRecord) => validatedRecord);

          const res = [];
          validatedResult.map((item: IRowValidationResult<IPatientInviteFormModel>) => {
            const existItem = res.find((x) => x.record.email === item.record.email);
            if (existItem) {
              invalid.push(item);
            }
            res.push(item);
          }, []);

          invalid = invalid.filter((v, i, a) => a.findIndex((t) => t.record.email === v.record.email) === i);

          valid = valid.filter((v) => invalid.find((r) => r) !== v);

          this._validItems$.next(valid);
          this._invalidItems$.next(invalid);
        },
        (err) => {
          console.warn(err);
          this.notificationsService.error({
            message: TranslateService.localize('import-invites.errors.parse-file-error'),
          });

          fileInput.value = null;
          this.changeDetectorRef.markForCheck();
        },
      );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.patientInviteManagerService.dispatchAction(EPatientsModuleAction.ClearTagSelected);
  }
}
