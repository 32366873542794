import { Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService implements OnDestroy {
  private onSubject = new Subject<{ key: string; value: any }>();
  public changes = this.onSubject.asObservable().pipe(share());

  constructor() {
    this.start();
  }

  ngOnDestroy() {
    this.stop();
  }

  public getStorage() {
    const s = [];
    for (let i = 0; i < localStorage.length; i++) {
      s.push({
        key: localStorage.key(i),
        value: localStorage.getItem(localStorage.key(i)),
      });
    }
    return s;
  }

  public store(key: string, data: any): void {
    localStorage.setItem(key, data);
    this.onSubject.next({ key, value: data });
  }

  public clear(key) {
    localStorage.removeItem(key);
    this.onSubject.next({ key, value: null });
  }

  private start(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea === localStorage) {
      const v = event.newValue;
      this.onSubject.next({ key: event.key, value: v });
    }
  }

  private stop(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
    this.onSubject.complete();
  }
}
