import { TGuid } from '@core/helpers';
import {
  IPagedItemsProceedResult,
  IPagedItemsSortingState,
  PagingApiMapperHelper,
  ServerSidePagedItemsProviderService,
} from '@core/data-providers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDoctor } from '@project/view-models';
import { DoctorsApiProviderService } from './api-providers/doctors-api-provider.service';
import { Injectable } from '@angular/core';

export interface IDoctorFiltersParams {
  tag?: TGuid;
  search?: string;
  status?: string;
  attendance?: boolean;
}

@Injectable()
export class DoctorsServerSideItemsProvider extends ServerSidePagedItemsProviderService<
  IDoctor,
  IDoctorFiltersParams,
  null
> {
  constructor(private doctorsApiProviderService: DoctorsApiProviderService) {
    super();
  }

  protected getDefaultFilters(): IDoctorFiltersParams {
    return {};
  }

  protected proceedPagedItemsLoading(
    page: number,
    itemsPerPage: number,
    filters: IDoctorFiltersParams,
    sorting: IPagedItemsSortingState<null>,
  ): Observable<IPagedItemsProceedResult<IDoctor>> {
    return this.doctorsApiProviderService
      .getPaged(page, itemsPerPage, this._filtersState$.value)
      .pipe(map((result) => PagingApiMapperHelper.mapPagingApiResponseToProceedResult(page, result)));
  }
}
