<p
  *ngIf="
    chatMessagesFullyLoaded && (messagesWithMeta$ | async)?.length >= MIN_MESSAGES_COUNT_TO_SHOW_IS_FULLY_LOADED_INFO
  "
  class="onboarding-message"
>
  {{ "empty-message.no-previous-messages" | translate }}
</p>

<ul *ngIf="(messagesWithMeta$ | async)?.length; else emptyMessageTemplate">
  <ng-container *ngFor="let message of messagesWithMeta$ | async; trackBy: trackById">
    <li [class.my]="message.isMyMessage" [class.same-author]="message.sameAuthorAsPrevious">
      <app-chat-message
        [message]="message.chatMessage"
        [hideHeader]="message.sameAuthorAsPrevious"
        [isMyMessage]="message.isMyMessage"
        [user]="message.user"
        [attachment]="message.chatMessage.attachmentDownloadLink"
      ></app-chat-message>
    </li>
  </ng-container>
</ul>

<ng-template #emptyMessageTemplate>
  <p *ngIf="!isLoading" class="onboarding-message">
    {{ "empty-message.no-chat-messages" | translate }}
  </p>
</ng-template>
