<div [formGroup]="parentFormGroup">
  <div class="main-fields-body">
    <table class="main-fields-table">
      <tr>
        <td class="type-column">
          <lib-form-control [showOnlyFirstError]="true" class="mb-0">
            <label>
              <span libLabel [hidden]="true">{{ "working-hours.schedule-form.labels.rule-type" | translate }}</span>
              <lib-select [options]="typeOptions" formControlName="type" [placeholder]="null" [size]="'m'"></lib-select>
            </label>
          </lib-form-control>
        </td>
        <td class="working-column">
          <lib-form-control [showOnlyFirstError]="true" class="mb-0">
            <label class="one-line-form-label">
              <button libButton appearance="text-placeholder">
                {{ "working-hours.schedule-form.labels.working-period" | translate }}
              </button>
              <span libLabel [hidden]="true">{{
                "working-hours.schedule-form.labels.working-period" | translate
              }}</span>
              <lib-input type="number" formControlName="workingPeriod" [size]="'m'"></lib-input>
            </label>
          </lib-form-control>
        </td>
        <td style="width: 38%;">
          <lib-form-control [showOnlyFirstError]="true" class="mb-0">
            <label class="one-line-form-label">
              <button libButton appearance="text-placeholder">
                {{ "working-hours.schedule-form.labels.skip-period" | translate }}
              </button>
              <span libLabel [hidden]="true">{{ "working-hours.schedule-form.labels.skip-period" | translate }}</span>
              <lib-input type="number" formControlName="skipPeriod" [size]="'m'"></lib-input>
            </label>
          </lib-form-control>
        </td>
      </tr>

      <tr *ngIf="isWeekSelected$ | async">
        <td></td>
        <td [colSpan]="2">
          <lib-form-control [targetFormControl]="daysOfWeekControl" class="mb-0">
            <div class="days-of-week-checkbox-group">
              <ng-container *ngFor="let day of daysOfWeek">
                <lib-checkbox
                  class="days-of-week-checkbox"
                  [checked]="(daysOfWeekValue$ | async).includes(day.value)"
                  (change)="onDayCheckedChange(day.value, $event)"
                >
                  {{ day.label }}
                </lib-checkbox>
              </ng-container>
            </div>
          </lib-form-control>
        </td>
      </tr>

      <tr *ngIf="needToShowStartPoint$ | async">
        <td [colSpan]="2" class="text-align-right">
          <button libButton appearance="text-placeholder" size="m">
            {{ "working-hours.schedule-form.labels.start-date-point" | translate }}
          </button>
        </td>
        <td>
          <lib-form-control [showOnlyFirstError]="true" class="mb-0">
            <label>
              <span libLabel [hidden]="true">{{
                "working-hours.schedule-form.labels.start-date-point" | translate
              }}</span>
              <lib-input
                formControlName="startingFrom"
                [size]="'m'"
                [mask]="dateInputMask"
                [placeholder]="dateInputPlaceholder"
              ></lib-input>
            </label>
          </lib-form-control>
        </td>
      </tr>
    </table>
  </div>

  <div class="working-hours-fields-body">
    <table class="working-hours-table">
      <thead>
        <tr>
          <th style="width: 40%;">{{ "working-hours.schedule-form.labels.working-hours" | translate }}</th>
          <th style="width: 20%;">{{ "working-hours.schedule-form.labels.timeslot-size" | translate }}</th>
          <th>{{ "working-hours.schedule-form.labels.role" | translate }}</th>
          <th class="min-width-column"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let workingHoursFormGroup of workingHoursControlFormGroups;
            let ruleIndex = index;
            let last = last;
            let first = first
          "
          [formGroup]="workingHoursFormGroup"
        >
          <td>
            <div class="row">
              <div class="col">
                <lib-form-control class="mb-0">
                  <label>
                    <lib-input
                      formControlName="timeFrom"
                      size="m"
                      appearance="white"
                      [mask]="timeInputMask"
                      [placeholder]="timeInputPlaceholderFrom"
                    ></lib-input>
                  </label>
                </lib-form-control>
              </div>
              <div class="col-content col--no-padding">
                <button libButton appearance="text-placeholder" size="m">—</button>
              </div>
              <div class="col">
                <lib-form-control class="mb-0">
                  <label>
                    <lib-input
                      formControlName="timeTo"
                      size="m"
                      appearance="white"
                      [mask]="timeInputMask"
                      [placeholder]="timeInputPlaceholderTo"
                    ></lib-input>
                  </label>
                </lib-form-control>
              </div>
            </div>
          </td>
          <td>
            <lib-form-control class="mb-0">
              <label>
                <lib-select
                  [options]="timeslotSizeOptions$ | async"
                  formControlName="timeSlotSize"
                  size="m"
                  appearance="white"
                ></lib-select>
              </label>
            </lib-form-control>
          </td>
          <td>
            <lib-form-control class="mb-0">
              <label>
                <lib-select
                  [options]="doctorSpecializationOptions"
                  formControlName="doctorSpeciality"
                  size="m"
                  appearance="white"
                ></lib-select>
              </label>
            </lib-form-control>
          </td>
          <td>
            <ng-container *ngIf="last; else removeButton">
              <button
                type="button"
                libButton
                [onlyIcon]="true"
                [appearance]="'outline'"
                [size]="'m'"
                (click)="addWorkingHoursRuleTemplate()"
              >
                +
              </button>
            </ng-container>
            <ng-template #removeButton>
              <button
                type="button"
                libButton
                [onlyIcon]="true"
                [appearance]="'grey-outline'"
                [size]="'m'"
                (click)="removeWorkingHoursRuleTemplate(ruleIndex)"
              >
                -
              </button>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
