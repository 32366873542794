<lib-card class="hcCard" *ngIf="healthCenter; else noHealthCenterTemplate">
  <div class="left">
    <lib-icon class="icon mb-2" [type]="'people'" [ngClass]="queueStatus"></lib-icon>
    <span class="counter">{{ peopleInQueueCount }}</span>
    <span class="counter-label text-color-muted m-0">{{ "session.people-in-queue" | translate }}</span>
  </div>
  <div>
    <p class="hcCard__description">{{ healthCenter.health_center_name }}</p>
  </div>
  <div>
    <button libButton (click)="impersonate(healthCenter.health_center_id)" [disabled]="disabled">
      {{ "session.use-this-hc" | translate }}
    </button>
  </div>
</lib-card>
<ng-template #noHealthCenterTemplate>
  <lib-card class="card">
    <div class="left">
      <lib-icon class="icon mb-2" [type]="'people'" [ngClass]="queueStatus"></lib-icon>
      <span class="counter">{{ peopleInQueueCount }}</span>
      <span class="counter-label text-color-muted m-0">{{ "session.people-in-queue" | translate }}</span>
    </div>
    <div class="right">
      <p class="description text-color-muted">
        <ng-content></ng-content>
      </p>
    </div>
  </lib-card>
</ng-template>
