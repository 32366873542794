import { Injectable } from '@angular/core';
import {
  IPagedItemsProceedResult,
  ServerSidePagedItemsProviderService,
  PagingApiMapperHelper,
} from '@core/data-providers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IRequisitionLogsPatient } from '@project/view-models';
import { DoctorEncounterHistoryApiProviderService } from './api-providers/doctor-encounter-history-api-provider.service';

export interface IDoctorEncounterHistoryFiltersParams {
  search?: string;
  filter?: number;
}

@Injectable()
export class DoctorEncounterHistoryServerSideItemsProviderService extends ServerSidePagedItemsProviderService<
  IRequisitionLogsPatient,
  IDoctorEncounterHistoryFiltersParams,
  null
> {
  constructor(private DoctorEncounterHistoryApiProviderService: DoctorEncounterHistoryApiProviderService) {
    super();
  }

  protected getDefaultFilters(): Partial<IDoctorEncounterHistoryFiltersParams> {
    return {};
  }

  protected proceedPagedItemsLoading(
    id,
    page,
    itemsPerPage,
  ): Observable<IPagedItemsProceedResult<IRequisitionLogsPatient>> {
    return this.DoctorEncounterHistoryApiProviderService.getPagedAside(id, page, itemsPerPage).pipe(
      map((result) => PagingApiMapperHelper.mapPagingApiResponseToProceedResult<IRequisitionLogsPatient>(page, result)),
    );
  }
}
