import { DateFormatter, TDateISOString, TGuid } from '@core/helpers';

export interface IChatAttachment {
  download_url: string;
  original: string;
  size: number;
  stored: string;
}

export interface IChatMessageDTO {
  user_id: TGuid;
  created: TDateISOString;
  chat_id: TGuid;
  text: string;
  attachment: IChatAttachment | null;
  modified: TDateISOString;
}

export interface IChatMessage {
  uniqueKey: string;
  chatId: TGuid;
  userId: TGuid;
  date: Date;
  dateFormatted: string;
  text: string;
  attachmentDownloadLink?: string;
}

export class ChatMessageViewModelFactory {
  static createMessageFromDTO(dto: IChatMessageDTO): IChatMessage {
    const messageDate = DateFormatter.stringToDate(dto.created);

    return {
      uniqueKey: this.generateMessageUniqueKey(dto),
      chatId: dto.chat_id,
      date: messageDate,
      dateFormatted: ChatMessagesHelper.formatMessageDate(messageDate),
      text: dto.text,
      userId: dto.user_id,
      attachmentDownloadLink: dto.attachment?.download_url,
    };
  }

  private static generateMessageUniqueKey(dto: IChatMessageDTO): string {
    return `${dto.chat_id}_${dto.created}_${dto.user_id}`;
  }
}

export class ChatMessagesHelper {
  static formatMessageDate(date: Date): string {
    const todayDateMessageFormat = 'hh:mm';
    const fullDateMessageFormat = 'DD MMM, hh:mm';
    const format = DateFormatter.isToday(date) ? todayDateMessageFormat : fullDateMessageFormat;
    return DateFormatter.dateToString(date, { format });
  }

  static sortByDateIncrement(messages: IChatMessage[]): IChatMessage[] {
    return messages.sort((a: IChatMessage, b: IChatMessage) => (a.date < b.date ? -1 : 1));
  }
}
