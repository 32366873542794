import { DateFormatter, TDateISOString } from '@core/helpers';

export interface IBinahConfigDTO {
  app_key: string;
  license_key: string;
  expiration_date: TDateISOString;
}

export interface IBinahConfig {
  appKey: string;
  licenseKey: string;
  expirationDate: Date;
}

export class BinahConfigViewModelFactory {
  static createFromDTO(dto: IBinahConfigDTO): IBinahConfig {
    return {
      appKey: dto.app_key,
      expirationDate: DateFormatter.stringToDate(dto.expiration_date),
      licenseKey: dto.license_key,
    };
  }

  static createDTO(config: IBinahConfig): IBinahConfigDTO {
    return {
      expiration_date: DateFormatter.dateToString(config.expirationDate),
      app_key: config.appKey,
      license_key: config.licenseKey,
    };
  }
}
