<form [formGroup]="form" (ngSubmit)="doDirtySubmit()">
  <div class="row">
    <div class="col-6">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.first-name" | translate }}</span>
          <lib-input formControlName="firstName" name="firstName"></lib-input>
        </label>
      </lib-form-control>
    </div>
    <div class="col-6">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.last-name" | translate }}</span>
          <lib-input formControlName="lastName" name="lastName"></lib-input>
        </label>
      </lib-form-control>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.email" | translate }}</span>
          <lib-input formControlName="email" name="email" [trim]="true"></lib-input>
        </label>
      </lib-form-control>
    </div>
    <div class="col-6">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.gender" | translate }}</span>
          <lib-select [options]="genderOptions" formControlName="gender" [size]="'m'"></lib-select>
        </label>
      </lib-form-control>
    </div>
    <div class="col-6" *ngIf="canSelectHealthCenter">
      <lib-form-control>
        <label>
          <span libLabel>{{ "nouns.health-center" | translate }}</span>
          <lib-select
            [options]="healthCentersSelectOptions$ | async"
            formControlName="healthCenterId"
            [size]="'m'"
          ></lib-select>
        </label>
      </lib-form-control>
    </div>
    <div>
      <lib-card-body>
        <lib-form-control class="mb-0">
          <label>
            <span libLabel>{{ "health-centers.form-access-token" | translate }}</span>
            <mat-slide-toggle color="primary" formControlName="accessToken"></mat-slide-toggle>
          </label>
        </lib-form-control>
      </lib-card-body>
    </div>
  </div>

  <button
    libButton
    [isPending]="isPending"
    type="submit"
    [disabled]="!(formValid$ | async)"
    [appearance]="'outline'"
    [size]="'m'"
  >
    {{ hcAdmin == null ? ("actions.add-to-list" | translate) : ("health-centers.edit-button" | translate) }}
  </button>
</form>
