import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ScheduleApiProviderService } from '@project/data-providers';
import { UserProfileDataService } from '@project/services';
import { map } from 'rxjs/operators';
import { RoutesBuilderService } from '@app/config';

enum ESessionsTypes {
  LiveSessions,
  AppointmentsSessions,
}

@Injectable()
export class SessionsTypeGuard implements CanActivate {
  private skipNextRoutePath: string;
  private readonly stateToRouteMap: Record<ESessionsTypes, { url: string; routePath: string }> = {
    [ESessionsTypes.AppointmentsSessions]: {
      url: RoutesBuilderService.SESSION_BY_DOCTOR.appointmentsSessions(),
      routePath: RoutesBuilderService.SESSION_BY_DOCTOR.baseAppointmentsSessions,
    },
    [ESessionsTypes.LiveSessions]: {
      url: RoutesBuilderService.SESSION_BY_DOCTOR.liveSessions(),
      routePath: RoutesBuilderService.SESSION_BY_DOCTOR.baseLiveSessions,
    },
  };

  constructor(
    private scheduleApiProviderService: ScheduleApiProviderService,
    private userProfileDataService: UserProfileDataService,
    private router: Router,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | true {
    if (this.skipNextRoutePath === next.routeConfig.path) {
      this.skipNextRoutePath = null;
      return true;
    }

    return this.scheduleApiProviderService.checkForDoctor(this.userProfileDataService.profile.id).pipe(
      map((doctorHasSchedule) => {
        const nextRoute = next.routeConfig.path;
        const targetRoute = this.stateToRouteMap[
          doctorHasSchedule ? ESessionsTypes.AppointmentsSessions : ESessionsTypes.LiveSessions
        ];

        if (targetRoute.routePath === nextRoute) {
          return true;
        }

        this.skipNextRoutePath = targetRoute.routePath;
        return this.router.parseUrl(targetRoute.url);
      }),
    );
  }
}
