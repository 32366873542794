import { Injectable } from '@angular/core';
import { IFeatureService } from '@project/services';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TechnicalSupportChatFeatureService implements IFeatureService {
  private iframe: HTMLIFrameElement = null;

  public initialise(): Observable<void> {
    if (this.iframe) {
      return;
    }

    const iframe = document.createElement('iframe');
    iframe.name = 'iframe-webchat';
    iframe.id = 'iframe-webchat';
    document.body.appendChild(iframe),
      iframe.setAttribute('src', '/assets/webchat.html'),
      iframe.setAttribute(
        'style',
        'position: fixed;right:0;height:85px; width:85px; margin: 10px 20px;transform: translateZ(0);z-index: 9998;bottom: 40px;',
      ),
      iframe.setAttribute('frameborder', '0');

    this.iframe = iframe;

    return of(null);
  }

  destroy(): Observable<void> {
    this.iframe.remove();
    this.iframe = null;

    return of(null);
  }
}
