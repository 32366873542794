<ng-container>
  <ng-container>
    <lib-button-dropdown
      [label]="(selectedTag$ | async)?.name || ('actions.select-group' | translate)"
      [appearance]="'primary'"
    >
      <lib-button-dropdown-menu-item (click)="openNewTagCreations()">
        <span class="tag-selection-menu-item">
          <span>{{ "patients.controls.add-new-tag-button-label" | translate }}</span>
          <lib-icon type="plus-in-circle"></lib-icon>
        </span>
      </lib-button-dropdown-menu-item>

      <lib-button-dropdown-menu-item (click)="setTagToDoctor(null)">
        {{ "patients.empty-group-menu-label" | translate }}
      </lib-button-dropdown-menu-item>
      <lib-button-dropdown-menu-item *ngFor="let tag of profileTags$ | async" (click)="setTagToDoctor(tag)">
        <span>
          {{ tag.name }}
        </span>
      </lib-button-dropdown-menu-item>
    </lib-button-dropdown>
  </ng-container>
</ng-container>
