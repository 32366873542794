import {
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { exhaustMap, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

export interface IDragInsideContainerCoords {
  x: number;
  y: number;
}

@Directive({
  selector: '[libDragInsideContainer]',
})
export class DragInsideContainerDirective implements OnInit, OnDestroy {
  @Input() initialCoords: IDragInsideContainerCoords;

  @Output() coordsChange = new EventEmitter<IDragInsideContainerCoords>();

  private subs: Subscription[] = [];

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private ngZone: NgZone,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId,
  ) {}

  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  get parent(): HTMLElement {
    return this.element.parentElement;
  }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.renderer.setStyle(this.element, 'position', 'absolute');
    this.renderer.setStyle(this.element, 'top', '0');
    this.renderer.setStyle(this.element, 'left', '0');

    if (this.initialCoords) {
      this.setCoords(this.initialCoords);
    }

    this.ngZone.runOutsideAngular(() => {
      const sub = this.initDrag().subscribe((d) => {
        this.ngZone.run(() => this.coordsChange.emit(d));
      });
      this.subs.push(sub);
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  private initDrag(): Observable<IDragInsideContainerCoords> {
    return fromEvent(this.parent, 'mousedown').pipe(
      exhaustMap((downEvent: MouseEvent) =>
        fromEvent(document, 'mousemove').pipe(
          startWith(downEvent),
          tap((event) => {
            event.stopPropagation();
            event.preventDefault();
          }),
          map(
            (event: MouseEvent): IDragInsideContainerCoords => {
              return {
                x: event.clientX,
                y: event.clientY,
              };
            },
          ),
          takeUntil(fromEvent(document, 'mouseup')),
        ),
      ),

      map((rawCoords) => {
        const rect = this.parent.getBoundingClientRect();

        const offsetX = rawCoords.x - rect.x;
        const offsetY = rawCoords.y - rect.y;

        const width = rect.width;
        const height = rect.height;

        const x = this.normalize(Math.round((offsetX * 100) / width));
        const y = this.normalize(Math.round((offsetY * 100) / height));

        this.setCoords({ x, y });

        return {
          x,
          y,
        };
      }),
    );
  }

  private normalize(v: number) {
    return v < 0 ? 0 : v > 100 ? 100 : v;
  }

  private setCoords(coords: IDragInsideContainerCoords) {
    const width = this.parent.offsetWidth;
    const height = this.parent.offsetHeight;
    const x = this.normalize(coords.x);
    const y = this.normalize(coords.y);
    const transformValue = `translate(calc(${(width * x) / 100}px - 50%), calc(${(height * y) / 100}px - 50%))`;
    this.renderer.setStyle(this.element, 'transform', transformValue);
  }
}
