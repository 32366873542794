import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SESSIONS_SPECIALIST_ROUTES } from './sessions-specialist-routes';
import { SessionsTypeGuard } from './sessions-type-guard';

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(SESSIONS_SPECIALIST_ROUTES)],
  providers: [SessionsTypeGuard],
})
export class SessionsSpecialistModule {}
