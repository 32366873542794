<mat-tab-group
  [selectedIndex]="selected.value"
  (selectedIndexChange)="selected.setValue($event)"
  animationDuration="100ms"
>
  <mat-tab *ngFor="let patient of patientList; let patientIndex = index" [label]="patient">
    <div class="component-container">
      <ng-container *ngIf="patientList.length > 1">
        <form [formGroup]="forms[patientIndex]" class="section-container --mt multi-patients-container">
          <div class="dependent-section">
            <p class="title">{{ "nouns.cpf-number" | translate }}</p>
            <lib-input
              formControlName="cpf"
              [mask]="documentInputMask"
              [placeholder]="documentInputPlaceholder"
              [size]="'m'"
              (change)="onChangeDocument(patientIndex, $event.target.value)"
            ></lib-input>
          </div>
          <div class="dependent-section">
            <p class="title">{{ "nouns.date-of-birth" | translate }}</p>
            <lib-input
              formControlName="dateBirth"
              [mask]="dateInputMask"
              [placeholder]="dateInputPlaceholder"
              [size]="'m'"
              (change)="onChangeBirthDate(patientIndex, $event.target.value)"
            ></lib-input>
          </div>
        </form>
      </ng-container>

      <div class="section-container --mt">
        <p class="title">
          {{ "sessions-details.anamnesis" | translate }}
        </p>
        <lib-textarea
          placeholder="{{ 'sessions-details.type-anamnesis' | translate }}"
          [(ngModel)]="patientsMedicalRecords.medical_record[patientIndex].anamnesis"
          (change)="onChangeAnamnesis(patientIndex, $event.target.value)"
        ></lib-textarea>
      </div>

      <div class="section-container --gray">
        <ul>
          <li class="section-row">
            <p class="section-header">
              {{ "sessions-details.insert-CID" | translate }}
            </p>
            <p class="section-header">
              {{ "sessions-details.status" | translate }}
            </p>
          </li>
          <li
            *ngFor="
              let cid of patientsMedicalRecords.medical_record[patientIndex].medical_record_cid;
              let cidIndex = index
            "
            class="section-row"
          >
            <app-cid-select (onCidSelected)="onSelectCid(patientIndex, cidIndex, $event)"></app-cid-select>
            <lib-select
              size="m"
              placeholder="{{ 'sessions-details.select-status' | translate }}"
              (change)="onSelectCidStatus(patientIndex, cidIndex, $event.target.value)"
              [options]="cidStatusOptions"
              [disabled]="isCidEmpty(patientIndex, cidIndex)"
            ></lib-select>

            <button
              class="row-btn --add"
              [ngClass]="isCidStatusEmpty(patientIndex, cidIndex) ? 'disabled' : ''"
              [disabled]="isCidStatusEmpty(patientIndex, cidIndex)"
              *ngIf="isPositionForAddButton(patientIndex, 'cid', cidIndex)"
              (click)="onClickAddCID(patientIndex)"
            >
              <span>+</span>
            </button>
            <button
              class="row-btn --remove"
              *ngIf="isPositionForRemoveButton(patientIndex, 'cid', cidIndex)"
              (click)="onClickRemoveCID(patientIndex, cidIndex)"
            >
              <span>+</span>
            </button>
          </li>
        </ul>
      </div>

      <div class="section-container --gray">
        <ul>
          <li class="section-row">
            <p class="section-header">
              {{ "sessions-details.exam-request" | translate }}
            </p>
          </li>
          <li
            *ngFor="
              let tuss of patientsMedicalRecords.medical_record[patientIndex].medical_record_tuss;
              let tussIndex = index
            "
            class="section-row"
          >
            <div class="row-span-2">
              <app-tuss-select (onTussSelected)="onSelectTuss(patientIndex, tussIndex, $event)"></app-tuss-select>
            </div>
            <button
              class="row-btn --add"
              [ngClass]="isTussEmpty(patientIndex, tussIndex) ? 'disabled' : ''"
              [disabled]="isTussEmpty(patientIndex, tussIndex)"
              *ngIf="isPositionForAddButton(patientIndex, 'tuss', tussIndex)"
              (click)="onClickAddTuss(patientIndex)"
            >
              <span>+</span>
            </button>
            <button
              class="row-btn --remove"
              *ngIf="isPositionForRemoveButton(patientIndex, 'tuss', tussIndex)"
              (click)="onClickRemoveTuss(patientIndex, tussIndex)"
            >
              <span>+</span>
            </button>
          </li>
        </ul>
      </div>

      <div class="section-container">
        <p class="title">{{ "sessions-details.outcome" | translate }}</p>
        <div class="outcome-input">
          <lib-select
            (change)="onChangeOutcome(patientIndex, $event.target.value)"
            size="m"
            [options]="outcomeOptions"
            placeholder="{{ 'sessions-details.select-outcome' | translate }}"
          ></lib-select>
        </div>
      </div>

      <footer class="footer">
        <button (click)="onClickSubmit()" [disabled]="!isFormValid" libButton size="m">
          {{ "sessions-details.end-consultation" | translate }}
        </button>
      </footer>
    </div>
    <ng-template mat-tab-label *ngIf="patientIndex !== 0">
      <mat-icon class="close-icon" (click)="onClickRemoveTab(patientIndex)">close</mat-icon>
      {{ patient }}
    </ng-template>
  </mat-tab>
  <mat-tab disabled>
    <ng-template mat-tab-label>
      <button mat-icon-button (click)="onClickAddTab()" class="add-button" [disabled]="patientList.length > 5">
        <mat-icon class="add-icon">add</mat-icon>
      </button>
    </ng-template>
  </mat-tab>
</mat-tab-group>
