import { Directive, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { map, share, switchMap } from 'rxjs/operators';

@Directive({
  selector: '[libCountdown]',
  exportAs: 'libCountdown',
})
export class CountdownDirective implements OnChanges {
  @Input('libCountdownExpireDate') expireDate: Date;
  @Input('libCountdownManualSecondsCounts') manualSecondsCounts: number;

  @Output('libCountdownSecondsLeftUpdate') secondsLeftUpdate = new EventEmitter<number>();

  private readonly _expireDate$ = new BehaviorSubject<Date>(new Date());

  public readonly secondsLeft$: Observable<number> = this._expireDate$.pipe(
    switchMap(() => timer(0, 500)),
    map(() => {
      const now = new Date();
      const millisecondsLeft = this._expireDate$.value.getTime() - now.getTime();

      if (millisecondsLeft <= 0) {
        return 0;
      }

      return Math.ceil(millisecondsLeft / 1000);
    }),
    share(),
  );

  public readonly isExpired$: Observable<boolean> = this.secondsLeft$.pipe(map((secondsLeft) => secondsLeft <= 0));

  ngOnChanges(changes: SimpleChanges) {
    this._expireDate$.next(this.getExpireDate());
  }

  private getExpireDate(): Date {
    if (this.expireDate) {
      return this.expireDate;
    }

    if (!this.manualSecondsCounts || this.manualSecondsCounts < 0 || typeof this.manualSecondsCounts !== 'number') {
      return new Date();
    }

    const targetDate = new Date();
    targetDate.setSeconds(targetDate.getSeconds() + this.manualSecondsCounts);

    return targetDate;
  }
}
