import { ISocketMessage } from './common';
import { ESocketMessageTypeDTO } from '../consts/socket-message-type';
import { TGuid } from '@core/helpers';

export interface IQueueInfo {
  size: number;
}

export interface IQueueInfoUpdateEventDTO extends ISocketMessage {
  length: number;
  waiting_time: number;
  type:
    | ESocketMessageTypeDTO.PatientsQueueInformation
    | ESocketMessageTypeDTO.DoctorsQueueInformation
    | ESocketMessageTypeDTO.ReceptionistQueueInformation;
  health_center_id: string;
}

export interface IQueueInfoUpdateEvent {
  length: number;
  waitingTimeMinutes: number;
  healthCenterId: TGuid;
}

export class QueueInfoUpdateEventSocketMessageViewModelFactory {
  static createMessageFromDto(dto: IQueueInfoUpdateEventDTO): IQueueInfoUpdateEvent {
    return {
      length: dto.length,
      waitingTimeMinutes: dto.waiting_time,
      healthCenterId: dto.health_center_id,
    };
  }
}
