import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { EUserPermission } from '@project/shared';
import { RoutesBuilderService } from '@app/config';
import { UserProfileDataService } from '../user-profile-data.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectToHomepageGuard implements CanActivate {
  constructor(private userProfileDataService: UserProfileDataService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    if (this.userProfileDataService.hasPermissions([EUserPermission.haveReceptionistsSession])) {
      return this.router.parseUrl(RoutesBuilderService.SESSION_BY_RECEPTIONIST.root());
    }

    if (
      this.userProfileDataService.hasAtLeastOnePermission([
        EUserPermission.havePatientSession,
        EUserPermission.haveDoctorSession,
      ])
    ) {
      return this.router.parseUrl(RoutesBuilderService.SESSIONS.root());
    }

    if (this.userProfileDataService.hasPermissions([EUserPermission.manageDoctors])) {
      return this.router.parseUrl(RoutesBuilderService.DOCTORS_LIST.root());
    }

    if (this.userProfileDataService.hasPermissions([EUserPermission.manageGeneralAdmins])) {
      return this.router.parseUrl(RoutesBuilderService.ADMINISTRATORS_LIST.root());
    }

    if (this.userProfileDataService.hasPermissions([EUserPermission.manageHealthCenterAdmins])) {
      return this.router.parseUrl(RoutesBuilderService.HEALTH_CENTERS_ADMINISTRATORS_LIST.root());
    }

    if (this.userProfileDataService.hasPermissions([EUserPermission.managePatients])) {
      return this.router.parseUrl(RoutesBuilderService.PATIENTS_LIST.root());
    }

    // Allow showing empty page if no redirect is possible. It's just a fallback for permissions conflict cases on backend side
    return true;
  }
}
