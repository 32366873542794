import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownDirective } from './dropdown.directive';
import { DropdownContentDirective } from './dropdown-content.directive';
import { DropdownToggleDirective } from './dropdown-toggle.directive';

@NgModule({
  declarations: [DropdownDirective, DropdownContentDirective, DropdownToggleDirective],
  exports: [DropdownDirective, DropdownToggleDirective, DropdownContentDirective],
  imports: [CommonModule],
})
export class DropdownModule {}
