import { TranslateService } from '@project/translate';

export enum EInvitationStatusDTO {
  Queue = 'Queue',
  Sent = 'Sent',
  Error = 'Error',
  Accepted = 'Accepted',
  Resend = 'Resend',
  Null = 'Null',
}

export const INVITATION_STATUSES_WITHOUT_ACCEPTED: EInvitationStatusDTO[] = Object.values(EInvitationStatusDTO).filter(
  (v) => v !== EInvitationStatusDTO.Accepted && v !== EInvitationStatusDTO.Null,
);

export function getInvitationStatusLocalization(status: EInvitationStatusDTO): string | undefined {
  const map: Record<EInvitationStatusDTO, () => string> = {
    [EInvitationStatusDTO.Accepted]: () => TranslateService.localize('invitations.statuses-enum.Accepted'),
    [EInvitationStatusDTO.Error]: () => TranslateService.localize('invitations.statuses-enum.Error'),
    [EInvitationStatusDTO.Queue]: () => TranslateService.localize('invitations.statuses-enum.Queue'),
    [EInvitationStatusDTO.Resend]: () => TranslateService.localize('invitations.statuses-enum.Resend'),
    [EInvitationStatusDTO.Sent]: () => TranslateService.localize('invitations.statuses-enum.Sent'),
    [EInvitationStatusDTO.Null]: () => null,
  };
  return map[status]?.();
}
