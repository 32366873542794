import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

/**
 * Envelope around Meta service to set meta tags in more easier way
 */

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(private meta: Meta, private title: Title) {}

  setTitle(title: string) {
    this.title.setTitle(title);
    this.meta.updateTag({ name: 'og:title', property: 'og:title', content: title });
  }

  setImage(url: string | null) {
    const urlContent = url || '/assets/path/to/image.jpg';
    this.meta.updateTag({ name: 'og:image', property: 'og:image', content: urlContent });
    this.meta.updateTag({ property: 'twitter:image', content: urlContent });
  }

  setDescription(description: string) {
    this.meta.updateTag({ name: 'description', property: 'description', content: description });
    this.meta.updateTag({ name: 'og:description', property: 'og:description', content: description });
  }
}
