import { Injectable } from '@angular/core';
import { ChatApiProviderService } from '@project/data-providers';
import { NotificationsService } from '@lib/notifications';
import { TranslateService } from '@project/translate';
import { ModalRating } from './modal-rating.model';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class ModalRatingService {
  constructor(
    private notificationsService: NotificationsService,
    private chatApiProviderService: ChatApiProviderService,
  ) {}

  public sendRating(modalRating: ModalRating): Observable<void> {
    return Observable.create((observer) => {
      this.chatApiProviderService
        .sendRating(modalRating)
        .pipe(
          catchError((err, caught) => {
            this.notificationsService.error({
              message: TranslateService.localize('modals.rating.error'),
            });

            observer.complete();

            return caught;
          }),
        )
        .subscribe(() => {
          this.notificationsService.success({
            message: TranslateService.localize('modals.rating.acknowledgment'),
          });

          observer.complete();
        });
    });
  }
}
