import { T24HourClockTime, TGuid } from '@core/helpers';
import { EDoctorSpecializationDTO, getDoctorSpecializationLocalization } from './consts/doctor-specialization';
import { TTimeSlotSizeMinutes } from './consts/schedule-consts';

export interface IScheduleRuleWorkingHoursDTO {
  id: TGuid;
  working_hours_start: T24HourClockTime;
  working_hours_end: T24HourClockTime;
  time_slot_size: TTimeSlotSizeMinutes;
  specialization: EDoctorSpecializationDTO;
}

export interface IScheduleRuleWorkingHoursCreateDTO {
  working_hours_start: T24HourClockTime;
  working_hours_end: T24HourClockTime;
  time_slot_size: TTimeSlotSizeMinutes;
  specialization: EDoctorSpecializationDTO;
}

export interface IScheduleRuleWorkingHours {
  id: TGuid;
  workingHoursStart: T24HourClockTime;
  workingHoursEnd: T24HourClockTime;
  timeSlotSize: TTimeSlotSizeMinutes;
  doctorSpecialization: EDoctorSpecializationDTO;
  doctorSpecializationLabel: string;
}

export interface IScheduleRuleWorkingHoursCreateData {
  workingHoursStart: T24HourClockTime;
  workingHoursEnd: T24HourClockTime;
  timeSlotSize: TTimeSlotSizeMinutes;
  doctorSpecialization: EDoctorSpecializationDTO;
}

export class ScheduleRuleWorkingHoursViewModelFactory {
  static createGetViewModel(dto: IScheduleRuleWorkingHoursDTO): IScheduleRuleWorkingHours {
    return {
      id: dto.id,
      timeSlotSize: dto.time_slot_size,
      doctorSpecialization: dto.specialization,
      doctorSpecializationLabel: getDoctorSpecializationLocalization(dto.specialization),
      workingHoursStart: dto.working_hours_start,
      workingHoursEnd: dto.working_hours_end,
    };
  }

  static createPostDTO(data: IScheduleRuleWorkingHoursCreateData): IScheduleRuleWorkingHoursCreateDTO {
    return {
      specialization: data.doctorSpecialization,
      time_slot_size: data.timeSlotSize,
      working_hours_start: data.workingHoursStart,
      working_hours_end: data.workingHoursEnd,
    };
  }
}
