import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormComponent, INPUT_MASKS, INPUT_PLACEHOLDERS, SharedFormOptions } from '@project/shared';
import {
  IDoctorToInvite,
  IDoctor,
  IState,
  License,
  EDoctorSpecializationDTO,
  EGenderDTO,
  CdpProfessionValidateCrmResponse,
} from '@project/view-models';
import { ISelectOption } from '@lib/ui/form-elements';
import { AbstractControl, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  DoctorSpecializationDataProvider,
  AddressesApiProviderService,
  DoctorsApiProviderService,
} from '@project/data-providers';
import { DoctorInviteUtils, IDoctorInviteFormModel } from '../doctor-invite.common';
import { DEFAULT_DATE_FORMAT, fillWithZeros, LibValidators } from '@core/helpers';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@project/translate';
import { debounceTime, distinctUntilChanged, mergeMap, switchMap } from 'rxjs/operators';
import { DoctorInviteManagerService, EDoctorsModuleAction } from '../doctor-invite-manager.service';

@Component({
  selector: 'app-doctor-invite-details-form',
  templateUrl: './doctor-invite-details-form.component.html',
  styleUrls: ['./doctor-invite-details-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoctorInviteDetailsFormComponent extends FormComponent implements OnInit {
  @Input() doctor: IDoctor;
  @Input() isPending = false;

  @Output() dataSubmit = new EventEmitter<IDoctorToInvite>();

  public form = this.fb.group(DoctorInviteUtils.getDoctorInviteFromDeclaration(this.fb));

  public readonly genderOptions: ISelectOption[] = SharedFormOptions.genderOptions();
  public readonly dateInputMask = INPUT_MASKS.date;
  public readonly dateInputPlaceholder = INPUT_PLACEHOLDERS.date;
  public readonly tagFilter$ = this.doctorInviteManagerService.tagFilter$;

  public states$: BehaviorSubject<ISelectOption[]> = new BehaviorSubject<ISelectOption[]>([]);

  public doctorSpecializationOptions: ISelectOption[] = this.doctorSpecializationDataProvider.getSelectOptions();
  public licenseText = new BehaviorSubject<string>(null);

  constructor(
    private fb: FormBuilder,
    private doctorSpecializationDataProvider: DoctorSpecializationDataProvider,
    private addressesApiProviderService: AddressesApiProviderService,
    private doctorsApiProviderService: DoctorsApiProviderService,
    private doctorInviteManagerService: DoctorInviteManagerService,
  ) {
    super();
  }

  removeLicense(i: number) {
    if (this.licenses.length >= 2) {
      this.licenses.removeAt(i);
    }
  }

  get licenses() {
    return this.form.controls.licenses as FormArray;
  }

  addLicense(num: number) {
    const licenseForm = this.fb.group({
      state_id: ['', [LibValidators.required(TranslateService.localize('validations.required'))]],
      license: [
        '',
        [
          LibValidators.required(TranslateService.localize('validations.required')),
          LibValidators.numberOnly(TranslateService.localize('validations.should-be-integer')),
        ],
      ],
    });

    if (num === 0) {
      this.licenses.push(licenseForm);
    } else {
      for (let i = 0; i < num; i++) {
        this.licenses.push(licenseForm);
      }
    }
  }

  protected cleanLicense(licenses) {
    licenses.forEach((item) => {
      item.get('state_id').setValue(null);
      item.get('state_id').setErrors(null);
      item.get('license').setValue(null);
      item.get('license').setErrors(null);
    });
  }

  protected validateField(controls: AbstractControl[], license: string) {
    controls.forEach((item) => {
      if (item.get('license').value === license) {
        item.get('license').setErrors({ invalid: TranslateService.localize('validations.license-invalid') });
      }
    });
  }

  validateCRM(licenseValue: string) {
    if (this.form.get('specialization').value === '') {
      this.form.get('specialization').setErrors({ invalid: TranslateService.localize('validations.required') });
    } else if (this.form.get('specialization').value !== EDoctorSpecializationDTO.Psychologist) {
      const license = this.getFilterLicense(licenseValue);

      if (license.license.length > 0 && license.state_id.length > 0) {
        this.doctorsApiProviderService
          .validateLicense(license)
          .subscribe((cdpProfessionValidateCrmResponse: CdpProfessionValidateCrmResponse) => {
            if (cdpProfessionValidateCrmResponse.professions[0].error !== '') {
              this.validateField(this.licenses.controls, license.license);
            } else if (cdpProfessionValidateCrmResponse.professions[0].valid === false) {
              this.validateField(this.licenses.controls, license.license);
            }
          });
      }
    }
  }

  protected getFilterLicense(licenseValue: string): License {
    return this.licenses.value.find((license: License) => license.license === licenseValue);
  }

  protected onFormInit() {
    const brazil = '02226fa0-b0ab-418d-9ec1-d5a0856c37cc';
    this.addressesApiProviderService.getMyState(brazil).subscribe((states: IState[]) => {
      const select: ISelectOption[] = states.map((x: IState) => ({ value: x.state_id, label: x.name }));
      this.states$.next(select);
    });

    this.form
      .get('dateOfBirth')
      .setValidators([LibValidators.required(TranslateService.localize('validations.required'))]);

    this.form.get('gender').setValue(this.genderOptions[0].value);

    if (this.doctor != null) {
      this.form.get('firstName').setValue(this.doctor.firstName);
      this.form.get('lastName').setValue(this.doctor.lastName);
      this.form.get('cpf').setValue(this.doctor.cpf);

      this.form.get('email').setValue(this.doctor.email);

      this.form.get('gender').setValue(this.doctor.gender);
      this.form.get('specialization').setValue(this.doctor.specialization);

      this.form.get('phone').setValue(this.doctor.phone);

      if (this.doctor.birthDate != null) {
        this.form
          .get('dateOfBirth')
          .setValue(
            `${fillWithZeros(this.doctor.birthDate.getUTCDate(), 2)}.${fillWithZeros(
              this.doctor.birthDate.getUTCMonth() + 1,
              2,
            )}.${this.doctor.birthDate.getUTCFullYear()}`,
          );
      }

      this.addLicense(this.doctor.licenses.length);

      if (this.doctor.licenses.length > 0) {
        this.licenses.controls.forEach((controls, index) => {
          controls.get('state_id').setValue(this.doctor.licenses[index].state_id);
          controls.get('license').setValue(this.doctor.licenses[index].license);
        });
      }
    } else {
      this.addLicense(0);
    }

    this.handleLicenseValidation();
  }

  changeSpecializationPsychologist(value) {
    if (value === EDoctorSpecializationDTO.Psychologist) {
      this.cleanLicense(this.licenses.controls);
      this.resetFieldsLicense();
    }
  }

  protected handleLicenseValidation() {
    this.licenseText.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((license) => {
      if (license != null) {
        this.validateCRM(license);
      }
    });
  }

  setLicenceToValidate(license) {
    this.licenseText.next(license);
  }

  protected resetFieldsLicense() {
    this.licenses.value.forEach((item) => {
      if (this.licenses.value.indexOf(item) > 0) {
        this.licenses.removeAt(this.licenses.value.indexOf(item));
      }
    });
  }

  protected doCleanSubmit() {
    this.doctorInviteManagerService.dispatchAction(EDoctorsModuleAction.SetTagSelected);
    this.form.get('tag').patchValue(this.tagFilter$.value);

    const userData = DoctorInviteUtils.createDoctorInviteDto(this.form.value as IDoctorInviteFormModel);
    this.dataSubmit.emit(userData);

    if (this.doctor != null) {
      return;
    }

    const { gender, specialization } = this.form.value;
    this.form.reset();
    this.form.get('gender').patchValue(gender);
    this.form.get('specialization').patchValue(specialization);
    if (this.licenses.length > 1) {
      this.removeLicense(1);
    }
    this.doctorInviteManagerService.dispatchAction(EDoctorsModuleAction.ClearTagSelected);
  }
}
