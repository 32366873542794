import { NgModule, Optional, SkipSelf } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { NotificationItemComponent } from './notification-item/notification-item.component';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationsService } from './notifications.service';

@NgModule({
  declarations: [NotificationItemComponent],
  imports: [OverlayModule, CommonModule, BrowserAnimationsModule],
  exports: [],
  providers: [NotificationsService],
})
export class NotificationsModule {
  constructor(@Optional() @SkipSelf() parentModule: NotificationsModule) {
    if (parentModule) {
      throw new Error('NotificationsModule is already loaded. Import it in the AppModule only');
    }
  }
}
