import { Injectable } from '@angular/core';
import { UserInviteApiProviderService } from '@project/data-providers';
import {
  EProfileTypeDTO,
  IDoctor,
  IDoctorToInvite,
  IGeneralAdmin,
  IGeneralAdminToInvite,
  IHealthCenterAdmin,
  IHealthCenterAdminToInvite,
  IInvitationWithProfiles,
  IInvitationCreateResponse,
  IPatient,
  IPatientToInvite,
  IReceptionist,
  IReceptionistToInvite,
  TUserToInvite,
  IUsersInvitationResult,
  IInvitationCreateResponseDTO,
  UserInviteViewModelFactory,
  TUserToInviteDTO,
} from '@project/view-models';
import { BehaviorSubject, forkJoin, Observable, of, pipe } from 'rxjs';
import { catchError, exhaustMap, map, takeLast, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '../../translate/translate.service';

const GROUP_INVITE_NUMBER = 30;

type TInvitedUserProfile = IDoctor | IPatient | IHealthCenterAdmin | IGeneralAdmin | IReceptionist;

interface IProfiles {
  [EProfileTypeDTO.Doctor]?: IDoctor;
  [EProfileTypeDTO.Patient]?: IPatient;
  [EProfileTypeDTO.Admin]?: IGeneralAdmin;
  [EProfileTypeDTO.HealthCenterAdmin]?: IHealthCenterAdmin;
  [EProfileTypeDTO.Receptionist]?: IReceptionist;
}

export interface IInvitedUserData {
  invitation: IInvitationWithProfiles | null;
  profiles: IProfiles;
}

interface IInviteUsersData {
  doctors: TUserToInvite[];
  patients: TUserToInvite[];
  receptionists: TUserToInvite[];
  generals: TUserToInvite[];
  hc_admin: TUserToInvite[];
}

interface IReturnResult {
  count_faileds: number;
  count_success: number;
  error_message: string[];
  success: IInvitationCreateResponseDTO[];
  failed: IInviteUsersData;
}

export enum ProfileRoleType {
  Unknown = 0,
  Admin = 1,
  Patient = 2,
  Doctor = 3,
  HealthCenterAdmin = 4,
  Receptionist = 5,
}

@Injectable({
  providedIn: 'root',
})
export class UserInviteService {
  public _usersInvitationResut$ = new BehaviorSubject<IUsersInvitationResult>(null);

  constructor(private userInviteApiProviderService: UserInviteApiProviderService) {}

  private getProfileType(profileType: EProfileTypeDTO): ProfileRoleType {
    switch (profileType) {
      case EProfileTypeDTO.Admin:
        return ProfileRoleType.Admin;

      case EProfileTypeDTO.Doctor:
        return ProfileRoleType.Doctor;

      case EProfileTypeDTO.HealthCenterAdmin:
        return ProfileRoleType.HealthCenterAdmin;

      case EProfileTypeDTO.Patient:
        return ProfileRoleType.Patient;

      case EProfileTypeDTO.Receptionist:
        return ProfileRoleType.Receptionist;

      default:
        return ProfileRoleType.Unknown;
    }
  }

  getUsersFailed({ failed }: IReturnResult): TUserToInviteDTO[] {
    if (failed.patients != null) {
      return failed.patients.map((x) => UserInviteViewModelFactory.createPatientDTO(x as IPatientToInvite));
    } else if (failed.doctors != null) {
      return failed.doctors.map((x) => UserInviteViewModelFactory.createDoctorDTO(x as IDoctorToInvite));
    } else if (failed.generals != null) {
      return failed.generals.map((x) => UserInviteViewModelFactory.createGeneralAdminDTO(x as IGeneralAdminToInvite));
    } else if (failed.hc_admin != null) {
      return failed.hc_admin.map((x) =>
        UserInviteViewModelFactory.createHealthCenterAdminDTO(x as IHealthCenterAdminToInvite),
      );
    } else if (failed.receptionists != null) {
      return failed.receptionists.map((x) =>
        UserInviteViewModelFactory.createReceptionistDTO(x as IReceptionistToInvite),
      );
    }
  }

  group = (itens, maximo): TUserToInvite[][] => {
    return itens.reduce((acumulador, item, indice) => {
      const grupo = Math.floor(indice / maximo);

      acumulador[grupo] = [...(acumulador[grupo] || []), item];

      return acumulador;
    }, []);
  };

  public InviteAllUsers(users: TUserToInvite[], profileType: EProfileTypeDTO): Observable<IUsersInvitationResult> {
    const failedData: TUserToInviteDTO[] = [];
    const errorMessages: string[] = [];
    const lots = this.group(users, GROUP_INVITE_NUMBER);
    let countSuccess = 0;
    let countFailed = 0;

    return forkJoin(
      lots.map((users) =>
        this.inviteAllUserRequest(users, profileType).pipe(
          catchError((error: HttpErrorResponse) => {
            const result: IReturnResult = error.error;
            if (error.message != null && result.count_faileds === 0 && result.count_faileds === 0) {
              errorMessages.push(error.message);
            } else if (error.error?.errors) {
              console.error(`Validation error: ${JSON.stringify(error.error.errors)}`);
              errorMessages.push(TranslateService.localize('import-invites.validation-error'));
            } else {
              this.getUsersFailed(result).map((f) => failedData.push(f));

              countSuccess += result.count_success;
              countFailed += result.count_faileds;

              result.error_message.map((x) => errorMessages.push(x));
            }

            return of(true);
          }),
        ),
      ),
    ).pipe(
      map(() => ({
        invitedUsersData: [],
        failedUsersFormData: failedData,
        errorMessages,
        profileType,
        isSuccess: failedData.length === 0,
        count_success: countSuccess,
        count_failed: countFailed,
      })),
    );
  }

  private inviteAllUserRequest(
    users: TUserToInvite[],
    userType: EProfileTypeDTO,
  ): Observable<IInvitationCreateResponse[]> {
    const requests: Record<EProfileTypeDTO, Observable<IInvitationCreateResponse[]>> = {
      [EProfileTypeDTO.Patient]: this.userInviteApiProviderService.invites(
        users as IPatientToInvite[],
        this.getProfileType(userType),
      ),
      [EProfileTypeDTO.Doctor]: this.userInviteApiProviderService.invites(
        users as IDoctorToInvite[],
        this.getProfileType(userType),
      ),
      [EProfileTypeDTO.Admin]: this.userInviteApiProviderService.invites(
        users as IGeneralAdminToInvite[],
        this.getProfileType(userType),
      ),
      [EProfileTypeDTO.HealthCenterAdmin]: this.userInviteApiProviderService.invites(
        users as IHealthCenterAdminToInvite[],
        this.getProfileType(userType),
      ),
      [EProfileTypeDTO.Receptionist]: this.userInviteApiProviderService.invites(
        users as IReceptionistToInvite[],
        this.getProfileType(userType),
      ),
    };

    return requests[userType];
  }
}
